import React, { useState } from "react";
import "../../../../doctor_profile/css/allAppointments.css";
import "../../../../css/doctor.css";
import TodayConsultations from "./today-consultations";
import UpcomingConsultations from "./upcoming-consultations";
import HistoryConsultations from "./history-consultations";
function AllConsultations() {
  const [activeButton, setActiveButton] = useState("newrequest");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const renderContent = () => {
    if (activeButton === "newrequest") {
      return <TodayConsultations />;
    } else if (activeButton === "upcoming") {
      return <UpcomingConsultations />;
    } else if (activeButton === "history") {
      return <HistoryConsultations />;
    }
  };

  return (
    <div className="main_appointments">
      <div className="submenu_telecaller">
        <button
          className={`btn-all-appointment ${
            activeButton === "newrequest" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("newrequest")}
        >
          <span>Today's</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "upcoming" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("upcoming")}
        >
          <span>Upcoming</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "history" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("history")}
        >
          <span>History</span>
        </button>
      </div>
      <div className="line_doc"></div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default AllConsultations;
