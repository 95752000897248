import React, { useEffect, useRef, useState } from "react";
import AmbulanceSidebar from "../../../../../components/sidebarAmbulance";
import AmbulanceHeader from "../../../../../components/ambulanceHeader";
function AmbulanceAdminDashboard() {
  //   let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "ambulance");
  }, []);

  return (
    <>
      <AmbulanceSidebar />
      <div className="sidebarleft">
        <AmbulanceHeader />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <div>Dashboard - Ambulance ADMIN</div>
            <div
              style={{
                margin: "112px",
                paddingLeft: "535px",
              }}
            >
              <b style={{ width: "10px", height: "5px" }}>Coming Soon...</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AmbulanceAdminDashboard;
