import React, { useEffect, useRef, useState } from "react";
import SidebarGHC from "../../../../../components/SidebarGHC";
import HeaderGHC from "../../../../../components/HeaderGHC";
function GHCAdminDashboard() {
  //   let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "ahc-admin");
  }, []);

  return (
    <>
      <SidebarGHC />
      <div className="sidebarleft">
        <HeaderGHC />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <div>Dashboard - GHC ADMIN</div>
            <div
              style={{
                margin: "112px",
                paddingLeft: "535px",
              }}
            >
              <b style={{ width: "10px", height: "5px" }}>Coming Soon...</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GHCAdminDashboard;
