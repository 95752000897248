import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import Axios from "axios";
import { useParams } from "react-router-dom";
import MaterialTable from "material-table";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import uploadImage from "../../../images/upload.png";
import personImage from "../../../images/person.png";
import locationImage from "../../../images/location.png";
import rupeeImage from "../../../images/rupee.png";
import printImage from "../../../images/printer.png";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { BlobServiceClient } from "@azure/storage-blob";
import Swal from "sweetalert2";
function OrderDetails() {
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [personName, setPersonName] = useState("");
  const [personNumber, setpersonNumber] = useState("");
  const [personAddress, setPersonAddress] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [orderStatusSelected, setOrderStatusSelected] = useState("");
  const [prescriptionFile, setPrescriptionFile] = useState("");
  const [couponDiscount, setCouponDiscount] = useState("");
  const [delivery, setDelivery] = useState("");

  const [userId, setUserId] = useState("");
  const [prescriptionId, setPrescriptionId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");

  const [orderDetailsCount, setOrderDetailsCount] = useState("");
  const fileInputRef = useRef(null);

  let itemPrice = 0;
  let discount = 0;
  let tax = 0;
  let subTotal = 0;
  let totalAmount = 0;

  const imagepath = "https://suitsdata.blob.core.windows.net/productdev/";
  const imagePresPath =
    "https://suitsdata.blob.core.windows.net/prescriptiondev/";

  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const url = process.env.REACT_APP_URL;
  //const { selectedRow } = props.location.state;
  //console.log("in ordersdetails ", props);
  const { orderId } = useParams();

  const columns = [
    {
      title: "SL",
      field: "id",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Product Details",
      field: "description",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Product Type",
      field: "product_type",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Unit Price",
      field: "price",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Quantity",
      field: "quantity",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Amount",
      field: "price",
      render: (rowData) => {
        let totalPrice = rowData.price * rowData.quantity;

        return (
          <div>
            <span>{totalPrice}</span>
            {/* <br />
            <span style={{ fontSize: "12px", color: backgroundColor }}>
              {rowData.payment_status}
            </span> */}
          </div>
        );
      },
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    Axios.get(`${url}/getOrderDetails?order_id=${orderId}`)
      .then((response) => {
        // console.log("response ", response.data.result);
        setOrderDetailsData(response.data.result);
        setOrderDetailsCount(response.data.result.orderDetails.length);

        setPersonName(
          response.data.result.order.customer_address.contact_person_name
        );
        setpersonNumber(
          response.data.result.order.customer_address.contact_person_number
        );
        setPersonAddress(response.data.result.order.customer_address.address);
        setPaymentStatus(response.data.result.order.payment_status);
        setPrescriptionFile(
          response.data.result.order.user_prescription.prescription_file
        );

        if (response.data.result.order.coupon === null) {
          setCouponDiscount(0);
        } else {
          setCouponDiscount(
            parseInt(response?.data?.result?.order?.coupon?.discount)
          );
        }

        if (response?.data?.result?.order?.delivery_charge === null) {
          setDelivery(0);
        } else {
          setDelivery(parseInt(response?.data?.result?.order?.delivery_charge));
        }

        setUserId(response?.data?.result?.order?.user_id);
        setPrescriptionId(response.data.result.order.user_prescription.id);
        setOrderStatus(response.data.result.order.order_status);

        getDateFormat(response.data.result.order.createdAt);

        // console.log(response?.data?.result?.order?.user_id);
      })
      .catch((error) => {
        // console.error("Error ", error);
      });
  }, []);

  const handleOrderStatus = (event) => {
    setOrderStatus(event.target.value);
  };

  const handleSave = async () => {
    // console.log(orderId, userId);
    const requestBody = {
      orderId,
      user_id: userId,
      prescription_file: prescriptionFile,
      prescription_id: prescriptionId,
      orderStatus,
    };

    try {
      // const urlValue = `${url}/updateOrdersDetails`;

      Axios.put(`${url}/updateOrdersDetails`, requestBody).then((response) => {
        // console.log(response);
        if (response.data.Message === "Successfully Updated Details") {
          Swal.fire({
            title: "Successfully Updated Details!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000, // set the duration in milliseconds
          });
        }
      });

      // if (response.status !== 200) {
      //   throw new Error("Failed to update order details");
      // } else {
      //   // Handle different response scenarios
      //   const responseData = response.data;

      //   if (responseData.userPrescription) {
      //     // Prescription updated scenario
      //     // Access updated prescription details: responseData.userPrescription
      //     // Access updated order details: responseData.orders
      //     alert(responseData.Message);
      //   } else {
      //     // Order status updated scenario
      //     // Access updated order details: responseData.orders
      //     alert(responseData.Message);
      //   }
      // }
    } catch (error) {
      Swal.fire({
        title: "Failed to Update ",
        icon: "error",
        showConfirmButton: false,
        timer: 2000, // set the duration in milliseconds
      });
    }
  };

  const handlePrint = () => {
    //window.print();
    const contentDiv = document.getElementById("content-to-print");
    if (contentDiv) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write(contentDiv.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  const getDateFormat = (createdAtDate) => {
    const dateString = createdAtDate;
    const date = new Date(dateString);

    // Get the day name
    const options = { weekday: "short" };
    const dayName = new Intl.DateTimeFormat("en-US", options).format(date);

    // Get the month name and day
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getDate();

    // Get the year
    const year = date.getFullYear();

    // Get the time in 12-hour format
    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // Format the date string
    const formattedDate = `${dayName}, ${month} ${day} ${year}, ${time}`;
    setCreatedAt(formattedDate);
    // console.log("dateformat ", formattedDate);
  };

  const handleUploadDivClick = () => {
    fileInputRef.current.click();
  };
  const handleFileSelect = async (event) => {
    const azureAccountName = "suitsdata";

    const sasToken =
      "?sv=2021-12-02&ss=bf&srt=sco&sp=rwdlactfx&se=2029-03-29T11:31:53Z&st=2023-03-29T03:31:53Z&spr=https&sig=OX48TXg%2F6xwp3b32hwe7Tf6sggDFc4YpCvvwCU5EUj8%3D";

    const containerName = "prescriptiondev";
    const blobServiceClient = new BlobServiceClient(
      `https://${azureAccountName}.blob.core.windows.net${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const file = event.target.files[0];

    if (file) {
      // Create a unique file name
      const fileName = `${new Date().getTime()}-${file.name}`;
      // console.log("fileName ", fileName);

      // Get the block blob client
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      try {
        // Upload the file to Azure Blob Storage
        await blockBlobClient.uploadData(file);
        setPrescriptionFile(fileName);
        // console.log("File uploaded successfully!");
      } catch (error) {
        // console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div
          className="subCategoryEditcontainerAdd01"
          style={{ height: "100%" }}
        >
          <div className="subcategory" style={{ fontSize: "14px" }}>
            <b>
              <Link style={{ textDecoration: "none" }} to="/Orders">
                Orders
              </Link>{" "}
              / Order Details
            </b>

            {/* <MaterialTable
              title="General Orders"
              columns={columns}
              data={orderDetailsData}
            /> */}
          </div>
          <div
            style={{
              fontSize: "14px",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "65%" }}>
              <span>Order ID #{orderId}</span>
              <br />
              <div
                style={{
                  fontSize: "12px",
                  color: "grey",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div>
                  <CalendarMonthIcon
                    style={{ fontSize: "20px", paddingTop: "5px" }}
                  />
                </div>
                <div style={{ paddingTop: "5px" }}>
                  {createdAt && createdAt}
                </div>
              </div>
            </div>

            <div
              style={{ width: "35%", display: "flex", flexDirection: "row" }}
            >
              <div>
                <select
                  id="orderstatus"
                  value={orderStatus}
                  onChange={handleOrderStatus}
                  style={{
                    padding: "8px",
                    border: "1px solid #a8d4f7",
                    borderRadius: "4px",
                  }}
                >
                  <option value="">Change Order Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>

              <div>
                <button
                  onClick={handleSave}
                  style={{
                    padding: "9px",
                    marginLeft: "20px",
                    width: "80px",
                    backgroundColor: "#ffffff",
                    border: "1px solid #a8d4f7",
                    outline: "none",
                    borderRadius: "4px",
                  }}
                >
                  Save
                </button>
              </div>

              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={handlePrint}
                  style={{
                    marginLeft: "20px",
                    padding: "4px",
                    display: "flex",
                    flexDirection: "row",
                    width: "120px",
                    border: "1px solid #a8d4f7",
                    outline: "none",
                    borderRadius: "4px",
                  }}
                >
                  <img
                    src={printImage}
                    style={{
                      paddingTop: "4px",
                      paddingLeft: "4px",
                      fontSize: "12px",
                      height: "25px",
                    }}
                  />
                  {/* <LocalPrintshopOutlinedIcon
                    style={{ paddingTop: "4px", paddingLeft: "4px" }}
                  /> */}
                  <span style={{ paddingTop: "5px", paddingLeft: "4px" }}>
                    Print Invoice
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div id="content-to-print">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
                width: "86%",
                justifyContent: "space-between",
              }}
            >
              {/* For Customer */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#dcf1f7",
                    borderRadius: "50px",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${personImage})`,
                      width: "35px",
                      height: "35px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <div>Customer</div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    {personName && personName}
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    {personNumber && personNumber}
                  </div>
                </div>
              </div>

              {/* For Payment */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#dcf1f7",
                    borderRadius: "50px",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${locationImage})`,
                      width: "35px",
                      height: "35px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <div>Deliver To</div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>State : </span> Telangana
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>City : </span>Hyderabad
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>Street : </span> Near Govt School
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>Address : </span> {personAddress && personAddress}
                  </div>
                </div>
              </div>

              {/* For Payment */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#dcf1f7",
                    borderRadius: "50px",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${rupeeImage})`,
                      width: "35px",
                      height: "35px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <div>Payment</div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>Payment Status : </span>{" "}
                    <span style={{ color: "green" }}>
                      {paymentStatus && paymentStatus}
                    </span>
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>Transaction ID : </span>{" "}
                    {paymentStatus && paymentStatus}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="card" style={{ width: "70%" }}>
                <table id="jsonTable" className="orderDetails">
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Product Details</th>
                      <th>Product Type</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {orderDetailsData.orderDetails &&
                      orderDetailsData.orderDetails.map(function (rowData) {
                        if (rowData.product !== null) {
                          // console.log("imagedsec ", rowData?.product?.image[0]);
                          let amount =
                            parseInt(rowData.product.price) * rowData.quantity;
                          let taxValue = rowData.product.tax;
                          itemPrice = itemPrice + amount;
                          let discountValue =
                            (itemPrice * rowData.product.discount) / 100;
                          discount = discount + discountValue;
                          tax = tax + parseInt(taxValue) * rowData.quantity;
                          subTotal = itemPrice + tax;
                          let delivery_fees = delivery;

                          totalAmount =
                            delivery_fees +
                            (subTotal - discount) -
                            couponDiscount;

                          return (
                            <tr key={rowData.id} className="tableRow">
                              <td style={{ paddingLeft: "6px" }}>
                                {rowData.id}
                              </td>
                              <td
                                style={{
                                  paddingLeft: "6px",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {/* {console.log(
                                  "imagedsec ",
                                  rowData?.product?.image[0]
                                )} */}
                                <div>
                                  <img
                                    style={{ height: "60px" }}
                                    src={imagepath + rowData?.product?.image[0]}
                                  />
                                </div>
                                <div
                                  style={{
                                    paddingLeft: "8px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span style={{ fontSize: "15px" }}>
                                    {rowData?.product?.description}
                                  </span>
                                  <span style={{ fontSize: "12px" }}>
                                    Unit:{rowData?.product?.unit}
                                  </span>
                                </div>
                              </td>
                              <td
                                style={{ paddingLeft: "6px", fontSize: "15px" }}
                              >
                                {rowData?.product?.product_type}
                              </td>
                              <td
                                style={{ paddingLeft: "6px", fontSize: "15px" }}
                              >
                                {rowData?.product?.price}
                              </td>
                              <td
                                style={{ paddingLeft: "6px", fontSize: "15px" }}
                              >
                                {rowData.quantity}
                              </td>
                              <td
                                style={{ paddingLeft: "6px", fontSize: "15px" }}
                              >
                                {rowData?.product?.price * rowData.quantity}
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>

                {parseInt(orderDetailsCount) > 0 ? (
                  <table className="priceDetails">
                    <tr>
                      <td>Items Price :</td>
                      <td style={{ width: "100px" }}></td>
                      <td style={{ width: "70px" }}></td>
                      <td style={{ color: "#6f7273" }}>
                        Rs. {itemPrice.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Tax(Inc) :</td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "left", color: "#6f7273" }}>
                        Rs. {tax.toFixed(2)}
                      </td>
                    </tr>

                    <tr>
                      <td>Sub Total :</td>
                      <td></td>
                      <td></td>
                      <td style={{ color: "#6f7273" }}>
                        Rs. {subTotal.toFixed(2)}
                      </td>
                    </tr>

                    <tr>
                      <td>Items Discount :</td>
                      <td></td>
                      <td></td>
                      <td style={{ color: "#6f7273" }}>
                        Rs. {discount.toFixed(2)}
                      </td>
                    </tr>

                    <tr>
                      <td>Coupon Discount :</td>
                      <td></td>
                      <td></td>
                      <td style={{ color: "#6f7273" }}>
                        Rs.{" "}
                        {couponDiscount && parseInt(couponDiscount).toFixed(2)}
                      </td>
                    </tr>

                    <tr>
                      <td>Delivery Fee :</td>
                      <td></td>
                      <td></td>
                      <td style={{ color: "#6f7273" }}>Rs. {delivery}</td>
                    </tr>

                    <hr></hr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Total</td>
                      <td></td>
                      <td style={{ color: "green", fontWeight: "bold" }}>
                        {paymentStatus}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {" "}
                        Rs. {totalAmount.toFixed(2)}
                      </td>
                    </tr>
                  </table>
                ) : (
                  <div></div>
                )}
              </div>
              <div
                onClick={handleUploadDivClick}
                className="card"
                style={{
                  width: "30%",
                  height: "300px",
                  marginLeft: "20px",
                  placeItems: "center",
                  display: "grid",
                  cursor: "pointer",
                }}
              >
                {/* <div>Upload Image</div> */}
                {!setPrescriptionFile ? (
                  <>
                    <div
                      className="circle-image1"
                      style={{
                        backgroundImage: `url(${uploadImage})`,
                      }}
                    ></div>
                    <input
                      type="file"
                      accept="image/*,.pdf"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                    <span style={{ color: "grey", fontSize: "13px" }}>
                      Upload Prescription
                    </span>
                    <span style={{ color: "grey", fontSize: "13px" }}>
                      We will be allowed only:{" "}
                      <span
                        style={{ backgroundColor: "#dcf1f7", color: "#12a5db" }}
                      >
                        JPG
                      </span>{" "}
                      <span
                        style={{ backgroundColor: "#faece6", color: "#cf2544" }}
                      >
                        PNG
                      </span>{" "}
                      <span
                        style={{ backgroundColor: "#dcf1f7", color: "#12a5db" }}
                      >
                        PDF
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    <input
                      type="file"
                      accept="image/*,.pdf"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                    <img
                      src={imagePresPath + prescriptionFile}
                      style={{ height: "260px", width: "100%" }}
                      alt="Prescription"
                    />
                  </>
                )}
              </div>
            </div>

            {/* <form>
            <button type="reset" className="clearbuttonSubCategoryEdit01">
              Clear
            </button>
            <button type="submit" className="SubmitbuttonSubCategoryEdit01">
              Submit
            </button>
          </form> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetails;
