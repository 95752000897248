import "./styles1.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import logo1 from "../images/logo1.png";
import { RiEditLine, RiCalendar2Line, RiUserFollowLine } from "react-icons/ri";
import "../css/sidebar.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useEffect } from "react";

function ICESidebar() {
  const [iceActiveButton, setIceActiveButton] = useState(
    localStorage.getItem("iceActiveButton") || "/ice/dashboard"
  );
  let navigate = useNavigate();
  const history = createBrowserHistory();
  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleButtonClick = (buttonId) => {
    setIceActiveButton(buttonId);
    localStorage.setItem("iceActiveButton", buttonId);
    const path = `${buttonId.toLowerCase()}`;
    history.push(path);
    window.location.reload();
  };

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    return () => {
      handlePopState();
      localStorage.removeItem("iceActiveButton");
    };
  }, []);

  const handlePopState = () => {
    localStorage.removeItem("iceActiveButton");
    window.location.reload();
  };

  return (
    <>
      <div>
        <Sidebar
          className={`app ${toggled ? "toggled" : ""}`}
          style={{ height: "100%", position: "absolute" }}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        >
          <div>
            <Link to="/ice/dashboard">
              <img src={logo1} alt="logo" className="image1" />
            </Link>
          </div>
          <hr />
          <Menu>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                iceActiveButton === "/ice/dashboard" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/ice/dashboard")}
            >
              <p className="ahcLabelMenu"> DashBoard</p>{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                iceActiveButton === "/ice/requestlist" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/ice/requestlist")}
            >
              <p className="ahcLabelMenu">ICE Card Request</p>{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                iceActiveButton === "/ice/change-password" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/ice/change-password")}
            >
              <p className="ahcLabelMenu">Change Password</p>{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                iceActiveButton === "/ice/myprofile" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/ice/myprofile")}
            >
              <p className="ahcLabelMenu">My Profile</p>{" "}
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
}
export default ICESidebar;
