import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import "../../../../../css/adddonor.css";
import SidebarGHC from "../../../../../components/SidebarGHC";
import HeaderGHC from "../../../../../components/HeaderGHC";
function AddGHCAdminPlan() {
  let navigate = useNavigate();

  const [plan_name, setPlan_name] = useState("");
  const [plan_worth, setPlan_worth] = useState("");
  const [errors, setErrors] = useState({});
  const url = process.env.REACT_APP_URL;

  const validate = () => {
    const newErrors = {};

    if (!plan_name.trim()) {
      newErrors.plan_name = "Please plan name";
    }

    if (!plan_worth.trim()) {
      newErrors.plan_worth = "Please enter plan worth";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();

    const varData = {
      plan_name: plan_name,
      plan_cost: plan_worth,
    };
    if (isValid) {
      Axios.post(`${url}/insertGhcPlans`, { ...varData }).then((response) => {
        if (response.data.Message === "Successfully Created") {
          loginSuccess();
        }
      });
    }
  };

  const loginSuccess = async (event) => {
    Swal.fire("Successfully Added Membership Plans!", "", "success");
    navigate("/ghcadmin/planlists");
  };

  useEffect(() => {
    localStorage.setItem("ghcactiveButton", "/ghcadmin/planlists");
  }, []);

  const backbutton = () => {
    navigate("/ghcadmin/planlists");
  };

  return (
    <>
      <SidebarGHC />
      <div className="sidebarleft">
        <HeaderGHC />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  Membership Plans
                </span>
                <span>{" / Add Membership Plan "}</span>
              </div>
              <div className="add-new-donor">
                <h2 className="heading-addnewdonor1">
                  Add New Membership Plan
                </h2>
              </div>
              <div className="first-form">
                <div className="section2-ahc-plans">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="plan_name">
                      Plan Name<p className="redstar">*</p>
                    </label>
                    <input
                      id="plan_name"
                      className="input-row-2-ahc-plans"
                      type="text"
                      value={plan_name}
                      onChange={(event) => setPlan_name(event.target.value)}
                    />
                    {errors.plan_name && (
                      <div className="error">{errors.plan_name}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="plan_worth">
                      Plan Worth<p className="redstar">*</p>
                    </label>
                    <input
                      id="plan_worth"
                      className="input-row-2-ahc-plans"
                      type="text"
                      value={plan_worth}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setPlan_worth(numericValue);
                      }}
                    />
                    {errors.plan_worth && (
                      <div className="error">{errors.plan_worth}</div>
                    )}
                  </div>
                </div>
                <div className="section11">
                  <div className="cancle">
                    <button
                      type="cancel"
                      className="cancle1"
                      onClick={() => navigate("/ghcadmin/planlists")}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="submit">
                    <button type="submit" className="submit1" onSubmit>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default AddGHCAdminPlan;
