import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../css/allAppointments.css";
import "../../../css/doctor.css";
import NewRequest from "./newrequest";
import Upcoming from "./upcoming";
import History from "./history";

function AllAppointments() {
  const [doctorActiveButton, setDoctorActiveButton] = useState("newrequest");

  const handleButtonClick = (buttonId) => {
    setDoctorActiveButton(buttonId);
  };

  const renderContent = () => {
    if (doctorActiveButton === "newrequest") {
      return <NewRequest />;
    } else if (doctorActiveButton === "upcoming") {
      return <Upcoming />;
    } else if (doctorActiveButton === "history") {
      return <History />;
    }
  };

  return (
    <div className="main_appointments">
      <div className="submenu_doc">
        <button
          className={`btn-all-appointment ${
            doctorActiveButton === "newrequest" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("newrequest")}
        >
          <span>Today's</span>
        </button>
        <button
          className={`btn-all-appointment ${
            doctorActiveButton === "upcoming" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("upcoming")}
        >
          <span>Upcoming</span>
        </button>
        <button
          className={`btn-all-appointment ${
            doctorActiveButton === "history" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("history")}
        >
          <span>History</span>
        </button>
      </div>
      <div className="line_doc"></div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default AllAppointments;
