//import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ProSidebarProvider } from "react-pro-sidebar";
import App from "./App";
import { AuthProvider } from "./pages/context/AuthContext";
import { NotificatProvider } from "./pages/context/NotificationContext";
import { alpha } from "@material-ui/core/styles";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <ProSidebarProvider>
    <NotificatProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </NotificatProvider>
  </ProSidebarProvider>
);
// Below is the new index.js
/* import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import store from './redux';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
window.React = React;

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
); */
