import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import "../../css/adddonor.css";
import { useLocation } from "react-router-dom";
import Sidebars from "../../components/Sidebar";
function UpdateDoctor() {
  let navigate = useNavigate();

  const location = useLocation();
  const [name, setName] = useState("");
  const [regno, setRegno] = useState("");
  const [quli, setQuli] = useState("");
  const [doctorSpec, setDoctorSpec] = useState([]);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [spec, setSpec] = useState("");

  const [updatePassword, setUpdatePassword] = useState("");
  const [dob, setDob] = useState("");
  const [exp, setExp] = useState("");
  const [hospital_location, setHospital_location] = useState("");
  const [hospital_name, setHospital_name] = useState("");
  const [top_specialist, setTop_specialist] = useState("");

  const [errors, setErrors] = useState({});
  const url = process.env.REACT_APP_URL;

  const bloodData = location?.state?.data;
  useEffect(() => {
    localStorage.setItem("role", "admin");

    Axios.get(`${url}/getSpecializations`).then((response) => {
      const specList = {};
      response.data?.result?.forEach((item) => {
        specList[item.id] = item.name;
      });
      setDoctorSpec(specList);
    });
  }, []);

  useEffect(() => {
    if (bloodData?.doctor?.name) {
      setName(bloodData?.doctor?.name);
    }
    if (bloodData?.doctor?.license_no) {
      setRegno(bloodData?.doctor?.license_no);
    }

    if (bloodData?.doctor?.qualification) {
      setQuli(bloodData?.doctor?.qualification);
    }

    if (bloodData?.doctor?.phone_no) {
      setMobile(bloodData?.doctor?.phone_no);
    }
    if (bloodData?.doctor?.email) {
      setEmail(bloodData?.doctor?.email);
    }

    if (bloodData?.spec_id) {
      setSpec(bloodData?.spec_id);
    }
    if (bloodData?.doctor?.date_of_birth) {
      setDob(bloodData?.doctor?.date_of_birth);
    }
    if (bloodData?.doctor?.experience) {
      setExp(bloodData?.doctor?.experience);
    }

    if (bloodData?.doctor?.hospital_location) {
      setHospital_location(bloodData?.doctor?.hospital_location);
    }

    if (bloodData?.doctor?.hospital_name) {
      setHospital_name(bloodData?.doctor?.hospital_name);
    }

    if (bloodData?.doctor?.top_specialist) {
      setTop_specialist(bloodData?.doctor?.top_specialist);
    }
  }, [bloodData]);

  const validate = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "Doctor name required!";
    }

    if (!email) {
      newErrors.email = "Please enter an email address";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!spec) {
      newErrors.spec = "Specialty number required!";
    }

    if (!regno) {
      newErrors.regno = "Registration number required!";
    }

    if (!quli) {
      newErrors.quli = "Qualification is required!";
    }

    // if (!hospital_location) {
    //   newErrors.hospital_location = "Hospital Location is required!";
    // }

    // if (!hospital_name) {
    //   newErrors.exp = "Hospital name is required!";
    // }

    if (!mobile) {
      newErrors.mobile = "Please enter mobile number";
    } else if (!/^[0-9]{10}$/.test(mobile)) {
      newErrors.mobile = "Invalid mobile number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(bloodData, "dob");
  const handleSubmit = (event) => {
    const updateDonor = {
      doctor_id: bloodData?.doctor?.doctor_id,
      name: name,
      license_no: regno,
      qualification: quli,
      phone_no: mobile,
      email: email,
      spec_id: spec,
      experience: exp,
      hospital_location: hospital_location,
      top_specialist: top_specialist,
      hospital_name: hospital_name,
      date_of_birth: dob,
      passwordnew: updatePassword,
    };
    event.preventDefault();

    const isValid = validate();

    if (isValid) {
      Axios.put(`${url}/updateDoctorDetails`, { ...updateDonor }).then(
        (response) => {
          if (response.data.Message === "Successfully Updated Details") {
            loginSuccess();
          } else if (response.data.Message === "Mobile number already taken") {
            loginErrorMob();
          } else if (response.data.Message === "Email is already taken") {
            EmailloginErrorMob();
          }
        }
      );
    }
  };

  const loginErrorMob = async (event) => {
    Swal.fire("Mobile Number Already Taken !", "", "error");
  };

  const EmailloginErrorMob = async (event) => {
    Swal.fire("Email already taken, please use different email!", "", "error");
  };

  const backbutton = () => {
    navigate("/doctorsList");
  };

  const loginSuccess = async (event) => {
    Swal.fire("Successfully Updated Doctor Details!", "", "success");
    navigate("/doctorsList");
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  Doctor List
                </span>
                <span>{" / Update Doctor "}</span>
              </div>
              <div className="first-form">
                <div className="section2" style={{ marginTop: "2rem" }}>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="name">
                      Doctor Name<p className="redstar">*</p>
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="input-row-2"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="regno">
                      Registration Number<p className="redstar">*</p>
                    </label>
                    <input
                      id="regno"
                      className="input-row-2"
                      type="text"
                      value={regno}
                      onChange={(event) => setRegno(event.target.value)}
                    />
                    {errors.regno && (
                      <div className="error">{errors.regno}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="quli">
                      Qualification<p className="redstar">*</p>
                    </label>
                    <input
                      id="quli"
                      className="input-row-2"
                      type="text"
                      value={quli}
                      onChange={(event) => setQuli(event.target.value)}
                    />
                    {errors.quli && <div className="error">{errors.quli}</div>}
                  </div>
                </div>
                <div className="section2" style={{ marginTop: "2rem" }}>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile<p className="redstar">*</p>
                    </label>
                    <input
                      id="mobile"
                      type="text"
                      className="input-row-2"
                      value={mobile}
                      onChange={(event) => setMobile(event.target.value)}
                    />
                    {errors.mobile && (
                      <div className="error">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="email">
                      Email<p className="redstar">*</p>
                    </label>
                    <input
                      id="email"
                      className="input-row-2"
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="spec">
                      Specialty<p className="redstar">*</p>
                    </label>
                    <select
                      id="spec"
                      className="input-row-2"
                      type="text"
                      value={spec}
                      onChange={(event) => setSpec(event.target.value)}
                    >
                      <option value="">Select Specialty</option>
                      {/* Replace the below line with your options */}
                      {Object.keys(doctorSpec).map((id) => (
                        <option value={id} key={id}>
                          {doctorSpec[id]}{" "}
                        </option>
                      ))}
                    </select>
                    {errors.spec && <div className="error">{errors.spec}</div>}
                  </div>
                </div>

                <div className="section2" style={{ marginTop: "2rem" }}>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="exp">
                      Experience
                    </label>
                    <input
                      id="exp"
                      className="input-row-2"
                      type="text"
                      value={exp}
                      onChange={(event) => setExp(event.target.value)}
                    />
                  </div>
                  <div className="form-state">
                    <label
                      className="formLabeldonor"
                      htmlFor="hospital_location"
                    >
                      Hospital Location
                    </label>
                    <input
                      id="hospital_location"
                      className="input-row-2"
                      type="text"
                      value={hospital_location}
                      onChange={(event) =>
                        setHospital_location(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="hospital_name">
                      Hospital Name
                    </label>
                    <input
                      id="hospital_name"
                      className="input-row-2"
                      type="text"
                      value={hospital_name}
                      onChange={(event) => setHospital_name(event.target.value)}
                    />
                    {/* {errors.address && (
                      <div className="error">{errors.address}</div>
                    )} */}
                  </div>
                </div>

                <div className="section34" style={{ marginTop: "2rem" }}>
                  <div className="form-state" style={{ marginLeft: "2.6rem" }}>
                    <label className="formLabeldonor" htmlFor="exp">
                      Top Specialist
                    </label>
                    <select
                      id="hospital_name"
                      className="input-row-2"
                      type="text"
                      value={top_specialist}
                      onChange={(event) =>
                        setTop_specialist(event.target.value)
                      }
                    >
                      <option value="">Select Specialist</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {/* {errors.exp && <div className="error">{errors.exp}</div>} */}
                  </div>
                  <div className="form-state" style={{ marginLeft: "2.6rem" }}>
                    <label className="formLabeldonor" htmlFor="dob">
                      Date of Birth
                    </label>
                    <input
                      id="dob"
                      className="input-row-2"
                      type="date"
                      value={dob}
                      onChange={(event) => setDob(event.target.value)}
                    />
                    {/* {errors.dob && <div className="error">{errors.dob}</div>} */}
                  </div>
                  <div className="form-state" style={{ marginLeft: "2.6rem" }}>
                    <label className="formLabeldonor" htmlFor="updatePassword">
                      Update Password
                    </label>
                    <input
                      id="updatePassword"
                      className="input-row-2"
                      type="text"
                      value={updatePassword}
                      onChange={(event) =>
                        setUpdatePassword(event.target.value)
                      }
                    />
                    {/* {errors.address && (
                      <div className="error">{errors.address}</div>
                    )} */}
                  </div>
                </div>

                {/* handle button */}
                <div className="section11" style={{ marginTop: "2rem" }}>
                  <div className="cancle">
                    <button
                      type="cancel"
                      className="cancle1"
                      onClick={() => navigate("/doctorsList")}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="submit">
                    <button type="submit" className="submit1" onSubmit>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default UpdateDoctor;
