import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../../../../doctor_profile/css/popupModule.css";
import moment from "moment";
export default function ViewMyFollowUps({ openPopup, setOpenPopUp, data }) {
  const [pname, setPname] = useState("");
  const [dname, setDname] = useState("");
  const [mobile, setMobile] = useState("");
  const [dmobile, setDmobile] = useState("");
  const [dsp, setDsp] = useState("");
  const [consult, setConsult] = useState("");
  const [followup_message, setFollowup_Message] = useState("");
  const [actions, setActions] = useState("");
  const [slotid, setSlotid] = useState("");
  const [date, setDate] = useState("");
  const [followups, setFollowups] = useState([]);
  const handleClose = () => {
    setOpenPopUp(false);
  };

  useEffect(() => {
    if (data?.user_fullname) {
      setPname(data?.user_fullname);
    }

    if (data?.user_mobile) {
      setMobile(data?.user_mobile);
    }

    if (data?.doctor_specializations) {
      setDsp(data?.doctor_specializations);
    }

    if (data?.treatment_mode) {
      setConsult(data?.treatment_mode);
    }

    if (data?.appointment_date) {
      setDate(data?.appointment_date);
    }

    if (data?.appointment_time_slot) {
      setSlotid(data?.appointment_time_slot);
    }

    if (data?.doctor_name) {
      setDname(data?.doctor_name);
    }

    if (data?.doctor_phone_no) {
      setDmobile(data?.doctor_phone_no);
    }

    if (data?.followup_action) {
      setActions(data?.followup_action);
    }

    if (data?.followup_message) {
      setFollowup_Message(data?.followup_message);
    }

    if (data?.followups) {
      setFollowups(data?.followups);
    }
  }, [data]);

  return (
    <>
      {openPopup && (
        <Dialog className="popop" onClose={handleClose} open={openPopup}>
          <DialogTitle dividers style={{ marginBottom: "12px" }}>
            Follow Up Details
            <div className="closeIcontelcaller" onClick={handleClose}>
              <HighlightOffOutlinedIcon />
            </div>
          </DialogTitle>

          <form>
            <div className="first-form">
              <div className="formGroupsTelecaller">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="pname">
                      Patient Name:
                    </label>
                    <h4 className="form-control-input3-h4">{pname} </h4>
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="mobile">
                      Patient Name:
                    </label>
                    <h4 className="form-control-input3-h4">{mobile} </h4>
                  </div>
                </div>
              </div>

              <div className="formGroupsTelecaller">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="dname">
                      Doctor Name:
                    </label>
                    <h4 className="form-control-input3-h4">{dname} </h4>
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="dmobile">
                      Doctor Mobile:
                    </label>
                    <h4 className="form-control-input3-h4">{dmobile} </h4>
                  </div>
                </div>
              </div>

              <div className="formGroupsTelecaller">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="dsp">
                      Doctor Specializations:
                    </label>
                    <h4 className="form-control-input3-h4">{dsp} </h4>
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="consult">
                      Type of Consultation:
                    </label>
                    <h4 className="form-control-input3-h4">{consult} </h4>
                  </div>
                </div>
              </div>

              <div className="formGroupsTelecaller">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="date">
                      Appointment Date:
                    </label>
                    <h4 className="form-control-input3-h4">{date} </h4>
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="dmobile">
                      Time Slot:
                    </label>
                    <h4 className="form-control-input3-h4">{slotid} </h4>
                  </div>
                </div>
              </div>
              {followups.map((followup, index) => (
                <div key={index}>
                  <div className="formGroupsTelecaller">
                    <div className="form-doc-inline">
                      <div className="form-doc-inline-item">
                        <label
                          className="formLabelTelecaller"
                          htmlFor={`action-${index}`}
                        >
                          Followup Action:
                        </label>
                        <h4 className="form-control-input3-h4">
                          {followup.followup_action}
                        </h4>
                      </div>
                      <div className="form-doc-inline-item">
                        <label
                          className="formLabelTelecaller"
                          htmlFor={`date-${index}`}
                        >
                          Followup Date:
                        </label>
                        <h4 className="form-control-input3-h4">
                          {moment(followup.followup_date).format("DD-MM-YYYY")}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="formGroupsTelecallerMyFollowups">
                    <div className="form-doc-inline">
                      <div className="form-doc-inline-item">
                        <label
                          className="formLabelTelecaller"
                          htmlFor={`message-${index}`}
                        >
                          Followup Message:
                        </label>
                        <h4 className="form-control-input3-h4-myflups">
                          {followup.followup_message}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </Dialog>
      )}
    </>
  );
}
