import React, { useEffect, useState } from "react";
import "../../css/availability.css";
import Axios from "axios";
import Swal from "sweetalert2";

function OfflineAvailability(doc_id) {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const verifyDoctorToken = localStorage.getItem("doctor_token");
  const doc_id1 = doc_id.doc_id;
  const url = process.env.REACT_APP_URL;
  const consultation_type = "OFFLINE";
  useEffect(() => {
    const doc_id = doc_id1;
    Axios.get(`${url}/getTimeSlots`)
      .then((response) => {
        setTimeSlots(response.data.result);
      })
      .catch((error) => {});
    Axios.get(
      `${url}/getSlotsByDayforDoc?doc_id=${doc_id}&day=${selectedDay}&consultation_type=${consultation_type}`
    )
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const checkedSlots = response.data.map((slot) => ({
            id: slot.id,
            start_time: slot.start_time,
            end_time: slot.end_time,
            status: true,
          }));
          setSelectedSlots(checkedSlots);
        } else {
          // No slots available for the selected day, so set selectedSlots to an empty array or handle it accordingly
          setSelectedSlots([]);
        }
      })
      .catch((error) => {});
  }, [selectedDay, doc_id1, consultation_type]);

  useEffect(() => {
    // Retrieve the selected day from localStorage
    const storedSelectedDay = localStorage.getItem("selectedDay");
    if (storedSelectedDay) {
      setSelectedDay(storedSelectedDay);
    } else {
      const currentDay = new Date().toLocaleDateString("en-US", {
        weekday: "long",
      });
      setSelectedDay(currentDay);
    }
  }, []);

  useEffect(() => {
    const storedSelectedDay = localStorage.getItem("selectedDay");
    if (storedSelectedDay) {
      setSelectedDay(storedSelectedDay);
    } else {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const currentDate = new Date();
      const currentDay = days[currentDate.getDay()];
      setSelectedDay(currentDay);
    }
  }, []);

  const handleCheckboxChange = (e, day) => {
    const slotValue = e.target.value.split("-")[1];
    const slotId = e.target.value.split("-")[0];
    const checkSl = e.target.checked;

    setSelectedSlots((prevSlots) => {
      let updatedSlots = [...prevSlots];
      const slotIndex = updatedSlots.findIndex(
        (slot) => slot.id === slotValue && slot.day === day
      );
      if (slotIndex !== -1) {
        updatedSlots[slotIndex].status = checkSl;
      } else {
        updatedSlots.push({
          id: slotValue,
          day: day,
          status: checkSl,
        });

        updatedSlots = updatedSlots.map((slots) => {
          if (slots.id.toString() === slotValue.toString()) {
            return {
              ...slots,
              status: checkSl,
            };
          }
          return slots;
        });
      }
      return updatedSlots;
    });
    setSelectedDay(day);
  };

  const slotSuccess = async (event) => {
    Swal.fire({
      title: "Slots Removed Sucessfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const slotUpdate = async (event) => {
    Swal.fire({
      title: "Slots Updated Sucessfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${verifyDoctorToken}`,
      },
    };

    selectedSlots.forEach(({ id, day, status }) => {
      const requestBody = {
        slot_id: id,
        day: day,
        status: status,
        consultation_type: consultation_type,
      };
      Axios.put(`${url}/updateDocSchedule`, requestBody, config)
        .then((response) => {
          if (response.data.Message === "Successfully Deleted Record") {
            slotSuccess();
          } else if (response.data.Message === "Successfully Updated Details") {
            slotUpdate();
          } else {
            // console.log("Errr");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  };

  const renderTimeSlots = () => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const handleDayClick = (day) => {
      setSelectedDay(day);
      // Store the selected day in localStorage
      localStorage.setItem("selectedDay", day);
      window.location.reload();
    };

    return (
      <>
        <div>
          <ul className="available-day">
            {days.map((day) => (
              <li
                key={day}
                className={`day-select ${
                  selectedDay === day ? "selected" : ""
                }`}
                onClick={() => handleDayClick(day)}
              >
                {day}
              </li>
            ))}
          </ul>
          <div className="line_doc"></div>
          {selectedDay && <div>{renderSlotsForDay(selectedDay)}</div>}
        </div>
      </>
    );
  };

  const renderSlotsForDay = (day) => {
    const slotGroups = [];
    for (let i = 0; i < timeSlots.length; i += 2) {
      const slotGroup = timeSlots.slice(i, i + 2);
      slotGroups.push(slotGroup);
    }

    return slotGroups.map((slotGroup, index) => (
      <div
        key={index}
        style={{ marginBottom: "10px" }}
        className="slot-schedule"
      >
        {slotGroup.map((slot) => {
          return (
            <label key={slot.id} className="slot-timing">
              <input
                type="checkbox"
                value={`${day}-${slot.id}`}
                checked={selectedSlots.find(
                  (selectedSlot) =>
                    selectedSlot.id === slot.id && selectedSlot.status === true
                )}
                onChange={(e) => handleCheckboxChange(e, day)}
                className="slot-checkbox"
              />
              {slot.start_time} - {slot.end_time}
            </label>
          );
        })}
      </div>
    ));
  };
  return (
    <div className="main_appointments">
      <div className="available-container">
        {/* <div className="line_doc"></div> */}
        <form onSubmit={handleSubmit} className="availablity-form">
          {renderTimeSlots()}
          <div className="submit-button-container">
            <button
              type="submit"
              disabled={!selectedDay || selectedSlots.length === 0}
              className="slot-time-btn"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OfflineAvailability;
