import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import Header from "../../../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebars from "../../../components/Sidebar";
function BBSearchBloodGroup() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <p>BBSearchBloodGroup</p>
      </div>
    </>
  );
}
export default BBSearchBloodGroup;
