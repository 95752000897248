import React, { useEffect, useRef, useState } from "react";
import ICESidebar from "../../../../../components/sidebarICE";
import ICEHeader from "../../../../../components/iceheader";
function ICEAdminDashboard() {
  //   let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "ice-admin");
  }, []);

  return (
    <>
      <ICESidebar />
      <div className="sidebarleft">
        <ICEHeader />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <div>Dashboard - ICE ADMIN</div>
            <div
              style={{
                margin: "112px",
                paddingLeft: "535px",
              }}
            >
              <b style={{ width: "10px", height: "5px" }}>Coming Soon...</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ICEAdminDashboard;
