import React, { useEffect, useRef, useState } from "react";
import "../../../../../doctor_profile/css/allAppointments.css";
import "../../../../../css/doctor.css";
import MaterialTable from "material-table";
import moment from "moment";
import Page_Bb from "../../../../services_component/blood_bank/Page_bb";
import Axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PublishIcon from "@mui/icons-material/Publish";
import Swal from "sweetalert2";
import PopupAssignRow from "../assignRow";
import MultiplePopupRowAssign from "../assignMultipleRow";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function ManagerUpcomingConsultations() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [multiRow, setMultiRow] = useState(null);
  const [multiopen, setMultiopen] = useState(false);
  const url = process.env.REACT_APP_URL;

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const handleFollowUp = (rowData) => {
    setOpenPopUp(true);
    setSelectedRow(rowData);
  };

  const columns = [
    {
      title: "Patient Name",
      field: "user_fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "user_mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Name",
      field: "doctor_name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Mobile",
      field: "doctor_mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Specialization",
      field: "doctor_specializations[0]",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "user_gender",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Consultation Date",
      field: "appointment_taken_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_taken_date) {
          const formattedDate = moment(rowData.appointment_taken_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Reason",
      field: "reason_for_appointment",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Appointment Date",
      field: "appointment_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_date) {
          const formattedDate = moment(rowData.appointment_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Time Slot",
      field: "time_slot",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Consultation Fee",
      field: "doctor_consultation_fee",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => `₹${rowData?.doctor_consultation_fee}`,
    },

    {
      title: "Payment Method",
      field: "payment_mode",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Assign",
      render: (rowData) => (
        <div>
          <button
            className="follow-up-btn"
            onClick={() => handleFollowUp(rowData)}
          >
            <ArrowForwardIcon />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    // var page = page;
    setLoading(true);
    Axios.get(`${url}/getManagerUpcomingOfflineAppointments`).then(
      (response) => {
        setData(response.data.result);
        setDataCount(response.data.totalCount);
        setLoading(false);
      }
    );
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <div className="main_doc">
        <div className="main_newreq1">
          <div style={{ width: "100%" }}>
            <MaterialTable
              columns={columns}
              data={data}
              title="Upcoming Appointments"
              icons={customIcons}
              isLoading={loading}
              actions={[
                {
                  tooltip: "Remove All Selected Users",
                  icon: () => <button className="assign">Assign</button>,
                  // onClick={() => navigate("/add/blood-donor")},
                  onClick: (event, rowData) => {
                    setMultiopen(true), setMultiRow(rowData);
                  },
                },
              ]}
              options={{
                searchFieldStyle: { border: "2px solid #f00" },
                detailPanelColumnAlignment: "right",
                exportButton: false,
                selection: true,
                sorting: true,
                search: false,
                toolbar: true,
                searchOnEnter: true,
                debounceInterval: 500,
                searchAutoFocus: false,
                actionsColumnIndex: columns.length,
                searchFieldVariant: "outlined",
                stickyHeader: true,
                pageSize: 50,
                paginationType: "stepped",
                showFirstLastPageButtons: false,
                emptyRowsWhenPaging: false,
                headerStyle: {
                  size: "40px",
                  background: "rgba(0, 0, 0, 0.05)",
                  position: "sticky",
                  top: "0",
                },
                stickyHeader: true,
                maxBodyHeight: "34.5vh",
              }}
              components={{
                Pagination: (props) => (
                  <Page_Bb
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      {openPopUp && (
        <PopupAssignRow
          openPopup={openPopUp}
          setOpenPopUp={setOpenPopUp}
          data={selectedRow}
        />
      )}

      {multiopen && (
        <MultiplePopupRowAssign
          multiopen={multiopen}
          setMultiopen={setMultiopen}
          data={multiRow}
        />
      )}
    </>
  );
}

export default ManagerUpcomingConsultations;
