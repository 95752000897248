import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/addCoupon.css";
import Axios from "axios";
import Swal from "sweetalert2";
function CouponsEdit() {
  const [couponCode, setCouponCode] = useState("");
  const [couponTitle, setCouponTitle] = useState("");
  const [usersLimit, setUsersLimit] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [disount, setDisount] = useState("");
  const [minPurchase, setMinPurchase] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponTypes, setCouponTypes] = useState([]);
  const [selectedCouponType, setSelectedCouponType] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const url = process.env.REACT_APP_URL;
  const location = useLocation();
  let navigate = useNavigate();

  const editCoupons = location?.state?.data;

  useEffect(() => {
    if (editCoupons?.code) {
      setCouponCode(editCoupons?.code);
    }

    if (editCoupons?.title) {
      setCouponTitle(editCoupons?.title);
    }

    if (editCoupons?.user_limit) {
      setUsersLimit(editCoupons?.user_limit);
    }

    if (editCoupons?.discount_type) {
      setDiscountType(editCoupons?.discount_type);
    }

    if (editCoupons?.discount) {
      setDisount(editCoupons?.discount);
    }

    if (editCoupons?.min_purchase) {
      setMinPurchase(editCoupons?.min_purchase);
    }

    if (editCoupons?.start_date) {
      setStartDate(editCoupons?.start_date);
    }

    if (editCoupons?.expire_date) {
      setEndDate(editCoupons?.expire_date);
    }

    if (editCoupons?.coupon_type_id) {
      setSelectedCouponType(editCoupons?.coupon_type_id);
    }
  }, [editCoupons]);

  const validateForm = () => {
    let errors = {};

    if (!selectedCouponType) {
      errors.selectedCouponType = "Please select coupon type";
    }

    if (!couponTitle) {
      errors.couponTitle = "Coupon Title is required";
    }
    if (!usersLimit) {
      errors.usersLimit = "Please enter Users Limit";
    } else if (Number(usersLimit) < 0) {
      errors.usersLimit = "User limit cannot be negative";
    }
    if (!discountType) {
      errors.discountType = "Please select discount type";
    }
    if (!disount || disount?.trim() === "") {
      errors.disount = "Please enter discount";
    } else if (Number(disount) < 0) {
      errors.disount = "Discount cannot be negative";
    }
    if (!minPurchase) {
      errors.minPurchase = "Please enter minimum purchase";
    } else if (Number(minPurchase) < 0) {
      errors.minPurchase = "Minimum purchase cannot be negative";
    }
    if (!startDate) {
      errors.startDate = "Start date is required";
    }
    if (!endDate) {
      errors.endDate = "End date is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const verifyAdminToken = localStorage.getItem("token");
    let id = editCoupons?.id;
    if (validateForm()) {
      Axios.put(
        `${url}/updateCouponDetails`,
        {
          // code: couponCode,
          id: id,
          code: couponCode,
          title: couponTitle,
          min_purchase: minPurchase,
          discount: disount,
          discount_type: discountType,
          coupon_type_id: selectedCouponType,
          user_limit: usersLimit,
          start_date: startDate,
          expire_date: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${verifyAdminToken}`,
          },
        }
      ).then((response) => {
        if (response.data.Message === "Coupon details updated successfully.") {
          loginSuccess();
        }
      });
    }
  };

  const loginSuccess = async (event) => {
    Swal.fire({
      title: "Successfully Updated Coupon Details!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      navigate("/coupons");
    }, 2000);
  };

  useEffect(() => {
    fetchCouponTypes();
  }, []);

  const fetchCouponTypes = async () => {
    const verifyAdminToken = localStorage.getItem("token");
    Axios.get(`${url}/getCouponTypes`, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    }).then((response) => {
      const couponTypesData = response.data.result;
      setCouponTypes(couponTypesData);
    });
  };

  const backbutton = () => {
    navigate("/coupons");
  };

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="containerAdd01">
          <div className="subcategory">
            <header style={{ cursor: "pointer" }}>
              <b>
                <u onClick={backbutton}>Coupons</u> / <span>Edit coupons</span>
              </b>
            </header>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <br />
            <div className="AddCoupon-2-container">
              <div className="div-container-coupon">
                <label className="couponInfo">
                  <b>Coupon Information</b>
                </label>
                {/* div one */}
                <div className="colomproductCoupon">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <div className="codeGen">
                        <label
                          className="subCategoryLable01"
                          htmlFor="couponCode"
                        >
                          Coupon Code <div className="redStart">*</div>
                        </label>
                        <p className="genCodeText">Generate code</p>
                      </div>
                      <input
                        type="text"
                        className="Coupon_code"
                        id="couponCode"
                        name="couponCode"
                        value={couponCode}
                        disabled
                        // onChange={(event) => {
                        //   setCouponCode(event.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </div>

                {/* div two */}

                <div className="couponSecDiv">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="selectedCouponType"
                      >
                        Coupon Type <div className="redStart">*</div>
                      </label>
                      <select
                        id="selectedCouponType"
                        name="selectedCouponType"
                        className="couponTypeTitle"
                        value={selectedCouponType}
                        onChange={(event) => {
                          setSelectedCouponType(event.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {couponTypes.map((couponType) => (
                          <option key={couponType.id} value={couponType.id}>
                            {couponType.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        style={{ marginLeft: "1.2rem" }}
                        htmlFor="couponTitle"
                      >
                        Coupon Title <div className="redStart">*</div>
                      </label>
                      <input
                        type="text"
                        className="couponTypeTitle1"
                        id="couponTitle"
                        name="couponTitle"
                        value={couponTitle}
                        onChange={(event) => {
                          setCouponTitle(event.target.value);
                        }}
                      />
                    </div>
                    {formErrors.couponTitle && (
                      <div className="error" style={{ marginLeft: "1.2rem" }}>
                        {formErrors.couponTitle}
                      </div>
                    )}
                  </div>
                </div>

                {/* div three */}
                <div className="colomproductCoupon">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="usersLimit"
                      >
                        Limit For Same User <div className="redStart">*</div>
                      </label>
                      <input
                        type="number"
                        id="usersLimit"
                        name="usersLimit"
                        className="Coupon_code"
                        placeholder="-Ex:3"
                        value={usersLimit}
                        onChange={(event) => {
                          setUsersLimit(event.target.value);
                        }}
                      />
                    </div>
                    {formErrors.usersLimit && (
                      <div className="error">{formErrors.usersLimit}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="div-container-coupon1">
                <label className="subCategoryLable01">
                  <b>Discount</b>
                </label>
                {/* div one */}
                <div className="couponSecDiv">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="discountType"
                      >
                        Discount Type <div className="redStart">*</div>
                      </label>
                      <select
                        type="text"
                        id="discountType"
                        name="discountType"
                        className="couponTypeTitle"
                        value={discountType}
                        onChange={(event) => {
                          setDiscountType(event.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="Amount">Amount</option>
                        <option value="Percentage">Percentage</option>
                      </select>
                    </div>
                    {formErrors.discountType && (
                      <div className="error">{formErrors.discountType}</div>
                    )}
                  </div>
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        style={{ marginLeft: "1.2rem" }}
                        htmlFor="disount"
                      >
                        {discountType === "Percentage"
                          ? "Discount Percentage"
                          : "Discount"}{" "}
                        <div className="redStart">*</div>
                      </label>
                      <input
                        type="number"
                        id="disount"
                        name="disount"
                        className="couponTypeTitle1"
                        placeholder={
                          discountType === "Amount" ? "-Ex:3" : "-Ex:10%"
                        }
                        value={disount}
                        onChange={(event) => {
                          setDisount(event.target.value);
                        }}
                      />
                    </div>
                    {formErrors.disount && (
                      <div className="error">{formErrors.disount}</div>
                    )}
                  </div>
                </div>
                {/* div two */}
                <div className="couponSecDiv">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="minPurchase"
                      >
                        Minimum Purchase <div className="redStart">*</div>
                      </label>
                      <input
                        type="number"
                        id="minPurchase"
                        name="minPurchase"
                        className="couponTypeTitle"
                        placeholder="-Ex:3"
                        value={minPurchase}
                        onChange={(event) => {
                          setMinPurchase(event.target.value);
                        }}
                      />
                    </div>
                    {formErrors.minPurchase && (
                      <div className="error">{formErrors.minPurchase}</div>
                    )}
                  </div>
                </div>
                {/* div three */}
                <div className="couponSecDiv">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label className="subCategoryLable01" htmlFor="startDate">
                        Start Date <div className="redStart">*</div>
                      </label>
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="couponTypeTitle"
                        value={startDate}
                        onChange={(event) => {
                          setStartDate(event.target.value);
                        }}
                      />
                    </div>
                    {formErrors.startDate && (
                      <div className="error">{formErrors.startDate}</div>
                    )}
                  </div>
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        style={{ marginLeft: "1.2rem" }}
                        htmlFor="endDate"
                      >
                        End Date <div className="redStart">*</div>
                      </label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="couponTypeTitle1"
                        value={endDate}
                        onChange={(event) => {
                          setEndDate(event.target.value);
                        }}
                      />
                    </div>
                    {formErrors.endDate && (
                      <div className="error" style={{ marginLeft: "1.2rem" }}>
                        {formErrors.endDate}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="btn-products">
              <div>
                <button
                  type="button"
                  // onClick={handleClear}
                  className="clearbuttonProductsEdit01"
                >
                  Clear
                </button>
              </div>
              <div className="product-submit-btn">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="SubmitbuttonProductsEdit01"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CouponsEdit;
