import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import Axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import Swal from "sweetalert2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";

function CategoriesEdit() {
  const location = useLocation();
  const inputRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [ids, setIds] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [image, setImage] = useState("");

  const url = process.env.REACT_APP_URL;
  const eurl = "https://suitsdata.blob.core.windows.net";
  const prod_name = "category";

  const [nameError, setNameError] = useState("");

  const verifyToken = localStorage.getItem("token");

  let navigate = useNavigate();

  const azureAccountName = "suitsdata";
  const sasToken =
    "?sv=2021-12-02&ss=bf&srt=sco&sp=rwdlactfx&se=2029-03-29T11:31:53Z&st=2023-03-29T03:31:53Z&spr=https&sig=OX48TXg%2F6xwp3b32hwe7Tf6sggDFc4YpCvvwCU5EUj8%3D";
  const containerName = "category";

  const [urlphoto, setUrlPhoto] = useState(`${eurl}/${prod_name}/${image}`);

  // console.log(urlphoto);

  const editData = location?.state?.data;
  useEffect(() => {
    // Update the local storage role
    // console.log(editData);

    if (editData?.id) {
      setIds(editData?.id);
    }

    if (editData?.name) {
      setName(editData?.name);
    }

    if (editData?.status !== undefined) {
      setStatus(editData?.status === 1 ? true : false);
    }

    if (editData?.image) {
      setImage(editData?.image);
    }
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const name = file.name;
    setSelectedImage(file);
    setFile(file);
    setFileName(name);
  };

  const handleClear = () => {
    setName(editData?.name);
    setSelectedImage(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (name.trim() === "") {
      setNameError("Category Name is required");
      return;
    }

    const id = ids;
    const categoryName = name;
    const categoryStatus = status;

    const requestData = {
      id: id,
      name: categoryName,
      status: categoryStatus,
      image: image,
    };

    if (selectedImage) {
      requestData.image = selectedImage.name;
    }

    if (fileName && file) {
      const blobServiceClient = new BlobServiceClient(
        `https://${azureAccountName}.blob.core.windows.net${sasToken}`
      );

      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      const blobClient = containerClient.getBlockBlobClient(fileName);

      blobClient.uploadData(file);
    }

    Axios.put(`${url}/updateCategory`, requestData, {
      headers: {
        Authorization: `Bearer ${verifyToken}`,
      },
    })
      .then((res) => {
        // console.log(res);
        if (res.data.Message === "Category details updated successfully.") {
          loginSuccess1();
        } else if (res.data.Message) {
          loginErrorCamp();
        }
      })
      .catch((error) => {
        // console.error(error);
        // Handle the error
      });
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Categories Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });

    setTimeout(function () {
      navigate("/Categories");
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorCamp = async (event) => {
    Swal.fire("Error Updating Categories!", "", "error");
  };

  const backbutton = () => {
    navigate("/Categories");
  };
  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Category</u>/ Update
              </b>
            </header>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="maincontainer">
              <div className="subCatergoryEditinput1">
                <label className="subCategoryLable01">
                  <b>Category Name </b>
                  <div className="redStart">*</div>
                </label>
                <input
                  type="text"
                  className="subCategory01"
                  placeholder="Category Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError && <div className="error">{nameError}</div>}
              </div>

              <div className="subCatergoryEditinput3">
                <label className="subCategoryLable01">
                  <b> Status </b>
                  <div className="redStart">*</div>
                </label>
                <select
                  className="subCategory01"
                  value={status}
                  onChange={(event) => setStatus(event.target.value === "true")}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>

              <div className="input2">
                <label className="subCategoryLable01">
                  <b>Image</b>
                </label>
                {selectedImage ? (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected Image"
                    style={{ width: "150px", height: "150px" }}
                    className="uploadicon01"
                  />
                ) : (
                  urlphoto && (
                    <img
                      src={`${eurl}/${prod_name}/${image}`}
                      alt="Category"
                      style={{ width: "150px", height: "150px" }}
                      className="uploadicon01"
                      onClick={() => inputRef.current.click()}
                    />
                  )
                )}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  ref={inputRef}
                />
                <p className="caption01">
                  <InfoIcon style={{ width: "23px", height: "13px" }} />
                  Only one image is acceptable
                </p>
              </div>

              <div className="btnDiv01">
                <div className="buttondiv01">
                  <button
                    type="button"
                    onClick={handleClear}
                    className="clearbutton02"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="Submitbutton02"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CategoriesEdit;
