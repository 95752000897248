import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../../css/ahc-members-details.css";
import { useLocation } from "react-router-dom";
import moment from "moment";
import SidebarGHC from "../../../../../components/SidebarGHC";
import HeaderGHC from "../../../../../components/HeaderGHC";

function ViewAdminGHCMembers() {
  let navigate = useNavigate();
  const location = useLocation();
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [ocupation, setOcupation] = useState("");
  const [bg, setBg] = useState("");
  const [medicalCondition, setMedicalCondition] = useState("");

  const [memberId, setMemberId] = useState("");
  const [planName, setPlanName] = useState("");
  const [activateDate, setActivateDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [transRef, setTransRef] = useState("");

  const [nomineeName, setNomineeName] = useState("");
  const [nomineeDob, setNomineeDob] = useState("");
  const [nomineeRelation, setNomineeRelation] = useState("");

  const ghcMembersDetails = location?.state?.data;

  useEffect(() => {
    if (ghcMembersDetails?.fullname) {
      setFullname(ghcMembersDetails?.fullname);
    }

    if (ghcMembersDetails?.mobile) {
      setMobile(ghcMembersDetails?.mobile);
    }

    if (ghcMembersDetails?.email) {
      setEmail(ghcMembersDetails?.email);
    }

    if (ghcMembersDetails?.gender) {
      setGender(ghcMembersDetails?.gender);
    }

    if (ghcMembersDetails?.dob) {
      setDob(ghcMembersDetails?.dob);
    }

    if (ghcMembersDetails?.ghcmemberid) {
      setMemberId(ghcMembersDetails?.ghcmemberid);
    }

    if (ghcMembersDetails?.plan_name) {
      setPlanName(ghcMembersDetails?.plan_name);
    }

    if (ghcMembersDetails?.activation_date) {
      setActivateDate(ghcMembersDetails?.activation_date);
    }

    if (ghcMembersDetails?.due_date) {
      setDueDate(ghcMembersDetails?.due_date);
    }

    if (ghcMembersDetails?.paid_amount) {
      setAmountPaid(ghcMembersDetails?.paid_amount);
    }

    if (ghcMembersDetails?.transaction_reference) {
      setTransRef(ghcMembersDetails?.transaction_reference);
    }

    if (ghcMembersDetails?.nominee_dob) {
      setNomineeDob(ghcMembersDetails?.nominee_dob);
    }

    if (ghcMembersDetails?.nominee_name) {
      setNomineeName(ghcMembersDetails?.nominee_name);
    }

    if (ghcMembersDetails?.nominee_relation) {
      setNomineeRelation(ghcMembersDetails?.nominee_relation);
    }

    if (ghcMembersDetails?.bloodgroup) {
      setBg(ghcMembersDetails?.bloodgroup);
    }

    if (ghcMembersDetails?.address) {
      setAddress(ghcMembersDetails?.address);
    }

    if (ghcMembersDetails?.medicalcondition) {
      setMedicalCondition(ghcMembersDetails?.medicalcondition);
    }

    if (ghcMembersDetails?.occupation) {
      setOcupation(ghcMembersDetails?.occupation);
    }
  }, [ghcMembersDetails]);

  useEffect(() => {
    localStorage.setItem("ghcactiveButton", "/ghcadmin/membershipdetails");
  }, []);

  const backbutton = () => {
    navigate("/ghcadmin/membershipdetails");
  };

  return (
    <>
      <SidebarGHC />
      <div className="sidebarleft">
        <HeaderGHC />
        <div>
          <form>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  GHC Members
                </span>
                <span>{" / View GHC Member Details "}</span>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">GHC Member Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Full Name:
                    </label>
                    <h4 className="ahc-members-label-h4">{fullname}</h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile:
                    </label>
                    <h4 className="ahc-members-label-h4">{mobile}</h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Email:
                    </label>
                    <h4 className="ahc-members-label-h4">{email}</h4>
                  </div>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="gender">
                      Gender:
                    </label>
                    <h4 className="ahc-members-label-h4">{gender}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="dob">
                      Date of Birth:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {dob ? (
                        moment(dob).format("DD-MM-YYYY")
                      ) : (
                        <em>no info</em>
                      )}
                    </h4>
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="gender">
                      Address:
                    </label>
                    <h4 className="ahc-members-label-h4">{address}</h4>
                  </div>
                </div>

                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="gender">
                      Blood Group:
                    </label>
                    <h4 className="ahc-members-label-h4">{bg}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="dob">
                      Occupation:
                    </label>
                    <h4 className="ahc-members-label-h4">{ocupation}</h4>
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="gender">
                      Medical Conditions
                    </label>
                    <h4 className="ahc-members-label-h4">{medicalCondition}</h4>
                  </div>
                </div>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">GHC Membership Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="memberId">
                      Membership ID:
                    </label>
                    <h4 className="ahc-members-label-h4">{memberId}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="planName">
                      Plan Name:
                    </label>
                    <h4 className="ahc-members-label-h4">{planName}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="amountPaid">
                      Amount Paid:
                    </label>
                    <h4 className="ahc-members-label-h4">{amountPaid}</h4>
                  </div>
                </div>

                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="activateDate">
                      Activation Date:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {activateDate ? (
                        moment(activateDate).format("DD-MM-YYYY")
                      ) : (
                        <em>no info</em>
                      )}
                    </h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="dueDate">
                      Due Date:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {dueDate ? (
                        moment(dueDate).format("DD-MM-YYYY")
                      ) : (
                        <em>no info</em>
                      )}
                    </h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="transRef">
                      Transaction Reference:
                    </label>

                    <h4 className="ahc-members-label-h4">{transRef}</h4>
                  </div>
                </div>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">Nominee Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="activateDate">
                      Nominee Name:
                    </label>
                    <h4 className="ahc-members-label-h4">{nomineeName}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="dueDate">
                      Nominee Dob:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {nomineeDob ? (
                        moment(nomineeDob).format("DD-MM-YYYY")
                      ) : (
                        <em>no info</em>
                      )}
                    </h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="transRef">
                      Nominee Relationship:
                    </label>

                    <h4 className="ahc-members-label-h4">{nomineeRelation}</h4>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ViewAdminGHCMembers;
