import React, { useState, useEffect } from "react";
import NotificationsIcon from "@mui/icons-material/CalendarToday";
import VideocamIcon from "@mui/icons-material/VideoChat";
import MeetingRoomIcon from "@mui/icons-material/HealthAndSafety";
import AccessTimeIcon from "@mui/icons-material/CalendarMonth";
import docimg from "../../images/doctor.jpg";

import Header2 from "./Header2";
import AllAppointments from "./AllAppointments/allAppointments";
import OfflineDoc from "./offline/offlineDoc";
import OnlineDoc from "./online/onlineDoc";

import PopupDashboard from "./popupDashboard";

import Axios from "axios";

import "../../css/doctor.css";
import AvailabilityDashboard from "./availability/dash-availability";

function DashboarddDoc() {
  const [doctorActiveButton, setDoctorActiveButton] = useState(
    localStorage.getItem("doctorActiveButton") || "appointments"
  );
  const verifyDoctorToken = localStorage.getItem("doctor_token");
  const url = process.env.REACT_APP_URL;

  const [name, setName] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [email, setEmail] = useState("");
  const [aboutdoctor, setAboutdoctor] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [address, setAddress] = useState("");
  const [specializations, setSpecializations] = useState("");
  const [doc_id, setDoc_Id] = useState("");

  const [openPopUp, setOpenPopUp] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const editProfile = () => {
    setOpenPopUp(true);
  };

  const fetchData = async () => {
    try {
      await Axios.get(`${url}/getdoctorDetailswithToken`, {
        headers: {
          Authorization: `Bearer ${verifyDoctorToken}`,
        },
      }).then((response) => {
        localStorage.setItem("doctor_id", response.data.result.doctor_id);
        setName(response.data.result.name);
        setExperience(response.data.result.experience);
        setQualification(response.data.result.qualification);
        setEmail(response.data.result.email);
        setAboutdoctor(response.data.result.about_doctor);
        setPhone_no(response.data.result.phone_no);
        setAddress(response.data.result.address);
        setSpecializations(response.data.result.specializations);
        setDoc_Id(response.data.result.doctor_id);
      });
    } catch (error) {}
  };

  const handleButtonClick = (buttonId) => {
    setDoctorActiveButton(buttonId);
    localStorage.setItem("doctorActiveButton", buttonId);
  };

  const renderContent = () => {
    if (doctorActiveButton === "appointments") {
      return <AllAppointments />;
    } else if (doctorActiveButton === "onlineConsultation") {
      return <OnlineDoc />;
    } else if (doctorActiveButton === "offlineConsultation") {
      return <OfflineDoc />;
    } else if (doctorActiveButton === "availability") {
      return <AvailabilityDashboard doc_id={doc_id} />;
    }
    //  else if (doctorActiveButton === "availability") {
    //   return <Availability doc_id={doc_id} />;
    // }
  };

  const handlePopState = () => {
    localStorage.removeItem("doctorActiveButton");
    window.location.reload();
  };
  useEffect(() => {
    // Clear the stored doctorActiveButton value when the component unmounts
    return () => {
      handlePopState();
      localStorage.removeItem("doctorActiveButton");
    };
  }, []);

  return (
    <>
      <div className="main">
        <Header2 />

        <div className="profile_doc">
          <div className="image-container">
            <div
              className="circle-image"
              style={{ backgroundImage: `url(${docimg})` }}
            ></div>
          </div>
          <div className="name_doc">
            <div className="text_arrange">
              <div className="welcome_text">Welcome , Dr. {name}</div>
              <div className="drProfile">
                <div className="key_text">
                  <span className="bullet">&#8226;</span>
                  {specializations}
                </div>
                <div className="key_text">
                  <span className="bullet">&#8226;</span>
                  {qualification}
                </div>
                <div className="key_text">
                  <span className="bullet">&#8226;</span>
                  {experience}
                </div>
              </div>
              <div className="drProfile">
                <div className="key_text">
                  <strong>Contact Info : </strong> {phone_no} / {email}
                </div>
              </div>

              <div
                className="body_text"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {aboutdoctor}
              </div>
            </div>
            <div className="editProfile">
              <button className="edit_doc_button" onClick={editProfile}>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
        <div className="menu_doc">
          <button
            className={`button__doc_main ${
              doctorActiveButton === "appointments" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("appointments")}
          >
            <NotificationsIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>All Appointments</span>
          </button>
          <button
            className={`button__doc_main ${
              doctorActiveButton === "onlineConsultation" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("onlineConsultation")}
          >
            <VideocamIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Online Consultation</span>
          </button>
          <button
            className={`button__doc_main ${
              doctorActiveButton === "offlineConsultation" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("offlineConsultation")}
          >
            <MeetingRoomIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Offline Consultation</span>
          </button>
          <button
            className={`button__doc_main ${
              doctorActiveButton === "availability" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("availability")}
          >
            <AccessTimeIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Availability</span>
          </button>
        </div>
        <div className="table_doc">{renderContent()}</div>
      </div>
      {openPopUp && (
        <PopupDashboard openPopup={openPopUp} setOpenPopUp={setOpenPopUp} />
      )}
    </>
  );
}

export default DashboarddDoc;
