import React, { useState, useEffect, useRef } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import "../../../css/modal.css";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Axios from "axios";
import Swal from "sweetalert2";

export default function UpateUserPopup({ openPopup, setOpenPopUp, data }) {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState({});
  const [cityList, setCityList] = useState({});
  const [fullname, setFullname] = useState("");
  const [id, setId] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [bloodgroup, setBloodgroup] = useState("");
  const [stateid, setStateid] = useState("");
  const [districtid, setDistrictid] = useState("");
  const [cityid, setCityid] = useState("");
  const [donoryesno, setDonoryesno] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const url = process.env.REACT_APP_URL;
  let navigate = useNavigate();

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  useEffect(() => {
    if (data?.fullname) {
      setFullname(data?.fullname);
    }
    if (data?.id) {
      setMobile(data?.id);
    }

    if (data?.mobile) {
      setMobile(data?.mobile);
    }

    if (data?.email) {
      setEmail(data?.email);
    }

    if (data?.dob) {
      setDob(data?.dob);
    }

    if (data?.gender) {
      setGender(data?.gender);
    }

    if (data?.bloodgroup) {
      setBloodgroup(data?.bloodgroup);
    }

    if (data?.stateid) {
      setStateid(data?.stateid);
    }

    if (data?.districtid) {
      setDistrictid(data?.districtid);
    }

    if (data?.cityid) {
      setCityid(data?.cityid);
    }

    if (data?.donoryesno) {
      if (data?.donoryesno === "true") {
        var yess = "Yes";
        setDonoryesno(yess);
      } else {
        var nooo = "No";
        setDonoryesno(nooo);
      }
    }
  }, [data]);

  const validateForm = () => {
    let errors = {};
    if (!fullname) {
      errors.fullname = "Fullname is required";
    }
    if (!mobile) {
      errors.mobile = "Mobile is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      errors.mobile = "Please enter a 10-digit mobile number";
    }
    if (!email) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!dob) {
      errors.dob = "Date of birth is required";
    }
    if (!gender) {
      errors.gender = "Gender is required";
    }
    if (!bloodgroup) {
      errors.bloodgroup = "Blood Group is required";
    }
    if (!stateid) {
      errors.stateid = "State is required";
    }
    if (!districtid) {
      errors.districtid = "District is required";
    }
    if (!cityid) {
      errors.cityid = "City is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      Axios.post(`${url}/updateuser`, {
        fullname: fullname,
        mobile: mobile,
        email: email,
        dob: dob,
        gender: gender,
        bloodgroup: bloodgroup,
        city: cityid,
        donoryesno: donoryesno,
        id: data?.id,
      }).then((res) => {
        if (res.data.Message === "Successfully Updated") {
          loginSuccess();
        } else if (res.data.Message === "Mobile number already taken") {
          loginErrorMob();
        }
      });
    } // }
  };

  const handleStateChange = (e) => {
    setStateid(e.target.value);
    setCityList({});
    setDistrictList({});
    setDistrictid("");
    setCityid("");
  };

  const handleDistrictChange = (e) => {
    setDistrictid(e.target.value);
    setCityid("");
    setCityList({});
  };

  const loginErrorMob = async (event) => {
    Swal.fire("Mobile Number Already Taken !", "", "error");

    navigate("/users-list");
  };

  const loginSuccess = async (event) => {
    Swal.fire({
      title: "Update User data Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000, // set the duration in milliseconds
    });
    // navigate("/AllBloodbank");
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };
  const handleClose = () => {
    setOpenPopUp(false);
    setFormErrors({});
  };

  useEffect(() => {
    Axios.get(`${url}/statelist`).then((response) => {
      const newList = {};
      response?.data?.states?.forEach((item) => {
        newList[item.id] = item.statename;
      });

      setStateList(newList);
    });
  }, []);
  useEffect(() => {
    let state = stateid;
    if (state) {
      Axios.get(`${url}/districtsbystate/${state}`).then((response) => {
        const distList = {};

        response?.data?.result?.forEach((item) => {
          distList[item.id] = item.districtname;
        });

        setDistrictList(distList);
      });
    }
  }, [stateid]);

  // Define a separate function to fetch the cities and update the city list
  const updateCityList = (districtId) => {
    Axios.get(`${url}/citiesbydistrict/${districtId}`).then((response) => {
      const cityList = {};
      response?.data?.result?.forEach((item) => {
        cityList[item.id] = item.cityname;
      });
      setCityList(cityList);
    });
  };

  // Call the updateCityList function whenever districtid changes
  useEffect(() => {
    if (districtid) {
      updateCityList(districtid);
    }
  }, [districtid]);

  return (
    <>
      {" "}
      {openPopup && (
        <div>
          {" "}
          <Dialog onClose={handleClose} open={openPopup}>
            <DialogTitle dividers>
              View User
              <div className="closeIcons" onClick={handleClose}>
                <HighlightOffOutlinedIcon />
              </div>
            </DialogTitle>{" "}
            <DialogContent dividers color="#007196">
              <p className="uinfo">User Information</p>
            </DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="first-form">
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Full Name
                    </label>
                    <input
                      id="fullname"
                      type="text"
                      className="form-control-input1"
                      name="fullname"
                      value={fullname}
                      onChange={(event) => {
                        setFullname(event.target.value);
                      }}
                    />
                    {formErrors.fullname && (
                      <div className="error">{formErrors.fullname}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile Number
                    </label>
                    <input
                      id="mobile"
                      className="form-control-input1"
                      type="text"
                      name="mobile"
                      value={parseInt(mobile)}
                      onChange={(event) => {
                        setMobile(event.target.value);
                      }}
                    />
                    {formErrors.mobile && (
                      <div className="error">{formErrors.mobile}</div>
                    )}
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="email">
                      Email
                    </label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      className="form-control-input1"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                    {formErrors.email && (
                      <div className="error">{formErrors.email}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="dob">
                      Date of Birth
                    </label>
                    <input
                      id="dob"
                      className="form-control-input1"
                      type="date"
                      name="dob"
                      value={dob}
                      onChange={(event) => {
                        setDob(event.target.value);
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      min={new Date("1900-01-01").toISOString().split("T")[0]}
                    />
                    {formErrors.dob && (
                      <div className="error">{formErrors.dob}</div>
                    )}
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="gender">
                      Gender
                    </label>
                    <select
                      id="gender"
                      className="form-control-input1"
                      name="gender"
                      value={gender}
                      onChange={(event) => {
                        setGender(event.target.value);
                      }}
                    >
                      <option value="Male">Male</option>{" "}
                      <option value="Female">Female</option>{" "}
                      <option value="Others">Others</option>{" "}
                    </select>
                    {formErrors.gender && (
                      <div className="error">{formErrors.gender}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="bloodgroup">
                      Blood Group
                    </label>
                    <select
                      id="bloodgroup"
                      className="form-control-input1"
                      name="bloodgroup"
                      value={bloodgroup}
                      onChange={(event) => {
                        setBloodgroup(event.target.value);
                      }}
                    >
                      {" "}
                      <option value="">Select Blood Group</option>{" "}
                      <option value="A Positive">A Positive</option>{" "}
                      <option value="AB Positive">AB Positive</option>{" "}
                      <option value="B Positive">B Positive</option>{" "}
                      <option value="O Positive">O Positive</option>{" "}
                      <option value="A Negative">A Negative</option>{" "}
                      <option value="AB Negative">AB Negative</option>{" "}
                      <option value="B Negative">B Negative</option>{" "}
                      <option value="O Negative">O Negative</option>{" "}
                      <option value="Not known">Not known</option>{" "}
                    </select>
                    {formErrors.bloodgroup && (
                      <div className="error">{formErrors.bloodgroup}</div>
                    )}
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="donoryesno">
                      Donor Y/N
                    </label>
                    <select
                      id="donoryesno"
                      className="form-control-input1"
                      name="donoryesno"
                      value={donoryesno}
                      onChange={(event) => {
                        setDonoryesno(event.target.value);
                      }}
                    >
                      {" "}
                      <option value="">Choose an option</option>
                      <option value="Yes">Yes</option>{" "}
                      <option value="No">No</option>{" "}
                    </select>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="stateid">
                      State
                    </label>
                    <select
                      id="stateid"
                      className="form-control-input1"
                      name="stateid"
                      value={stateid}
                      onChange={handleStateChange}
                    >
                      <option value="">Select State</option>
                      {Object.keys(stateList).map((id) => (
                        <option value={id} key={id}>
                          {stateList[id]}{" "}
                        </option>
                      ))}
                    </select>
                    {formErrors.stateid && (
                      <div className="error">{formErrors.stateid}</div>
                    )}
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="districtid">
                      District
                    </label>
                    <select
                      id="districtid"
                      className="form-control-input1"
                      name="districtid"
                      value={districtid}
                      onChange={handleDistrictChange}
                    >
                      <option value="">Select District</option>
                      {Object.keys(districtList).map((id) => (
                        <option value={id} key={id}>
                          {districtList[id]}
                        </option>
                      ))}
                    </select>
                    {formErrors.districtid && (
                      <div className="error">{formErrors.districtid}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="cityid">
                      City
                    </label>
                    <select
                      id="cityid"
                      className="form-control-input1"
                      name="cityid"
                      value={cityid}
                      onChange={(event) => setCityid(event.target.value)}
                    >
                      <option value="">Select City</option>
                      {Object.keys(cityList).length > 0 &&
                        Object.keys(cityList).map((id) => (
                          <option value={id} key={id}>
                            {cityList[id]}
                          </option>
                        ))}
                    </select>
                    {formErrors.cityid && (
                      <div className="error">{formErrors.cityid}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="buttonSec">
                <div className="cancle">
                  <button
                    type="cancel"
                    className="cancle1"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
                <div className="submit">
                  <button className="submit1" onClick={handleSubmit}>
                    Update
                  </button>
                </div>
              </div>
            </form>
          </Dialog>{" "}
        </div>
      )}{" "}
    </>
  );
}
