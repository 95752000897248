import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../../../../doctor_profile/css/popupModule.css";
import Axios from "axios";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

export default function PopupAssignRow({ openPopup, setOpenPopUp, data }) {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedFullName, setSelectedFullName] = useState("");
  const [teleId, setTeleId] = useState("");

  const location = useLocation();

  const [formErrors, setFormErrors] = useState({});
  const url = process.env.REACT_APP_URL;

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleSubmit = () => {
    const varData = {
      appointment_id: data.appointment_id,
      telecaller_id: teleId,
      telecaller_manager_id: location?.state?.id,
    };

    Axios.post(`${url}/insertAssignedAppointment`, { ...varData })
      .then((response) => {
        if (response.data.Message === "Successfully Created") {
          loginSuccess1();
        }
      })
      .catch((error) => {});
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Appointment assigned successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await Axios.get(`${url}/telecalleremails`).then((response) => {
        setEmails(response.data.result);
      });
    } catch (error) {}
  };

  const handleEmailChange = (event) => {
    const selectedEmail = event.target.value;
    setSelectedEmail(selectedEmail);

    // Find the corresponding full name based on the selected email
    const selectedEmailData = emails.find(
      (email) => email.email === selectedEmail
    );

    if (selectedEmailData) {
      setSelectedFullName(selectedEmailData.fullname);
      setTeleId(selectedEmailData.id);
    } else {
      setSelectedFullName("");
      setTeleId("");
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!selectedEmail) {
      errors.selectedEmail = "Choose telecaller email";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      handleSubmit();
    }
  };

  return (
    <>
      {openPopup && (
        <Dialog className="popop" onClose={handleClose} open={openPopup}>
          <DialogTitle dividers style={{ marginBottom: "12px" }}>
            Assign to Telecaller
            <div className="closeIcontelcaller" onClick={handleClose}>
              <HighlightOffOutlinedIcon />
            </div>
          </DialogTitle>

          <form onSubmit={handleFormSubmit}>
            <div className="first-form">
              <div className="formGroupsec1">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabeldonor1" htmlFor="selectedEmail">
                      Select Email<p className="redstar">*</p>
                    </label>
                    <select
                      id="selectedEmail"
                      className="form-control-input2"
                      name="selectedEmail"
                      value={selectedEmail}
                      onChange={handleEmailChange}
                    >
                      <option value="">Select an email</option>
                      {emails.map((email) => (
                        <option key={email.id} value={email.email}>
                          {email.email}
                        </option>
                      ))}
                    </select>
                    {formErrors.selectedEmail && (
                      <div className="error-message">
                        {formErrors.selectedEmail}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="fullname">
                    Full Name
                  </label>
                  <input
                    id="fullname"
                    type="text"
                    className="form-control-input2"
                    name="fullname"
                    value={selectedFullName}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="buttonSec">
              <div className="cancle_ta">
                <button type="cancel" className="cancle1" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div className="submit">
                <button type="submit" className="submit1">
                  Assign
                </button>
              </div>
            </div>
          </form>
          <DialogActions>{/* Add your action buttons here */}</DialogActions>
        </Dialog>
      )}
    </>
  );
}
