import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import Axios from "axios";
import Swal from "sweetalert2";

function SubCategoriesAdd() {
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategoryName, setSubCategoryName] = useState("");
  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const verifyToken = localStorage.getItem("token");
  let navigate = useNavigate();
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    Axios.get(`${url}/getCategoriesList`)
      .then((response) => {
        setParentCategories(
          response.data.categories
            .filter((item) => item.status === 1)
            .map((item) => item.name)
        );
      })
      .catch((error) => {});
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validate();

    if (isValid) {
      const subname = subcategoryName;
      const parname = selectedCategory;
      const requestData = {
        name: subname,
        parent_name: parname,
      };
      Axios.post(`${url}/insertSubCategory`, requestData)
        .then((res) => {
          if (
            res.data.Message === "Sub Category details inserted successfully."
          ) {
            loginSuccess1();
          } else if (res.data.Message) {
            loginErrorCamp();
          }
        })
        .catch((error) => {
          if (error?.response?.data?.Message === "Duplicate Record") {
            loginErrorCampDup();
          }
        });
    }
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Added Sub Categories Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });

    setTimeout(function () {
      navigate("/SubCategories");
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorCamp = async (event) => {
    Swal.fire("Error Updating Categories!", "", "error");
  };

  const loginErrorCampDup = async (event) => {
    Swal.fire("Sub Category already exists!", "", "error");
  };

  const validate = () => {
    const newErrors = {};
    if (!selectedCategory) {
      newErrors.category = "Please select a parent category";
    }

    if (!subcategoryName || !/^[a-zA-Z&,\s]+$/.test(subcategoryName)) {
      newErrors.name =
        "Please select a valid parent category (letters, spaces, ampersand, and comma only)";
    } else if (!subcategoryName.trim()) {
      newErrors.name = "Sub category name is required";
    }

    setNameError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClear = () => {
    setSubCategoryName("");
    setSelectedCategory("");
    setNameError("");
    setCategoryError("");
  };
  const backbutton = () => {
    navigate("/SubCategories");
  };

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="containerAdd02">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Sub Category</u>/ Add New
              </b>
            </header>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="maincontainer">
              <div className="subCatergoryEditinput1">
                <label className="subCategoryLable01">
                  <b>Sub Category Name </b>
                  <div className="redStart">*</div>
                </label>
                <input
                  type="text"
                  className="subCategory01"
                  placeholder="Sub Category Name"
                  value={subcategoryName}
                  onChange={(e) => setSubCategoryName(e.target.value)}
                />
                {nameError.name && (
                  <div className="error">{nameError.name}</div>
                )}
              </div>
              <div className="subCatergoryEditinput2">
                <label className="subCategoryLable01">
                  <b> Parent Category Name </b>
                  <div className="redStart">*</div>
                </label>
                <select
                  className="subCategory01"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">-Select-</option>
                  {parentCategories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
                {nameError.category && (
                  <div className="error">{nameError.category}</div>
                )}
              </div>
            </div>
            <br />

            <button
              type="button"
              onClick={handleClear}
              className="clearbuttonSubCategoryEdit01"
            >
              Clear
            </button>
            <button type="submit" className="SubmitbuttonSubCategoryEdit01">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SubCategoriesAdd;
