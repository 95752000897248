import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../../../../css/login2.css";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import PersonIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import logo from "../../../../images/logo1.png";
import { useNavigate } from "react-router-dom";
import NotificationContext from "../../../context/NotificationContext";
const TelecallerManagerHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const TelecallerManagerToken = localStorage.getItem("tele-manager-token");
  const { count, setCount } = useContext(NotificationContext);
  let navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      TelecallerManagerToken === null ||
      TelecallerManagerToken === "" ||
      TelecallerManagerToken === "undefined"
    ) {
      navigate("/csr/manager/login");
    }

    fetchData();
  }, [TelecallerManagerToken]);

  const url = process.env.REACT_APP_URL;

  const fetchData = () => {
    Axios.get(`${url}/getTeleManagerNewAppointmentNotifications`).then(
      (response) => {
        if (response.data) {
          const appointmentIds = response.data.result.map(
            (appointment) => appointment.appointment_id
          );
          localStorage.setItem(
            "csrmNotiAppointmentIds",
            JSON.stringify(appointmentIds)
          );
          setCount(response?.data?.notificationCount);
        }
      }
    );
  };

  const logoutFun = async () => {
    window.location.href = "/csr/manager/login";
  };

  const handleChangePassword = async () => {
    navigate("/csr/manager/change-password");
  };

  const handleChangeNoti = async () => {
    navigate("/csr/manager/notifications");
  };

  const handlelogOut = () => {
    setAnchorEl(null);

    localStorage.removeItem("role");
    localStorage.removeItem("activeButton");
    localStorage.removeItem("telecaller_token");
    localStorage.removeItem("tele-manager-token");
    localStorage.removeItem("csrmNotiAppointmentIds");
    logoutFun();
  };

  return (
    <header className="header_inner1">
      <div className="header_logo1">
        <img src={logo} alt="Logo" />
      </div>
      <div className="header_right1">
        <button
          className="Link12"
          onClick={handleChangeNoti}
          style={{
            textDecoration: "none",
            color: "inherit",
            border: "none",
            background: "none",
            cursor: "pointer",
          }}
        >
          <NotificationsOutlinedIcon
            fontSize="large"
            style={{ color: "black" }}
          />
          {count > 0 && <div className="indicator2">{count}</div>}
        </button>
        <div className="iconPerson1">
          <p className="doctor-name1">Manager</p>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon
              style={{
                fontSize: 40,
                borderRadius: "50%",
                backgroundColor: "#ddd",
              }}
            />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <button
              onClick={handleChangePassword}
              style={{
                textDecoration: "none",
                color: "inherit",
                border: "none",
                background: "none",
              }}
            >
              <MenuItem>Change Password</MenuItem>
            </button>
            <Link
              onClick={handlelogOut}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Logout</MenuItem>
            </Link>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default TelecallerManagerHeader;
