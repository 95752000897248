import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");
  const token = localStorage.getItem("token");
  const storedUserRole = localStorage.getItem("role");

  // doctor login token
  const [doctorLogin, setDoctorLogin] = useState(false);
  const doctorToken = localStorage.getItem("doctor_token");

  // telecaller login token

  const [telecallerLogin, setTelecallerLogin] = useState(false);
  const telecallerToken = localStorage.getItem("telecaller_token");

  const [telecallerManager, setTelecallerManager] = useState(false);
  const TelecallerManagerToken = localStorage.getItem("tele-manager-token");

  // AHC admin login
  const [ahcAdminLogin, setAhcAdminLogin] = useState(false);
  const AhcAdminToken = localStorage.getItem("ahcToken");

  // GHC admin login
  const [ghcAdminLogin, setGhcAdminLogin] = useState(false);
  const GhcAdminToken = localStorage.getItem("ghcToken");

  // Ambulance admin login
  const [adminAmbulanceLogin, setAdminAmbulanceLogin] = useState(false);
  const adminAmbulanceToken = localStorage.getItem("ambulanceToken");

  // ICE admin login
  const [iceAdminLogin, setIceAdminLogin] = useState(false);
  const IceAdminToken = localStorage.getItem("iceToken");

  useEffect(() => {
    // admin
    if (token === null || token === "" || token === "undefined") {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }

    if (storedUserRole) {
      setUserRole(storedUserRole);
    }
    // doctor
    if (
      doctorToken === null ||
      doctorToken === "" ||
      doctorToken === "undefined"
    ) {
      setDoctorLogin(false);
    } else {
      setDoctorLogin(true);
    }
    // telecaller
    if (
      telecallerToken === null ||
      telecallerToken === "" ||
      telecallerToken === "undefined"
    ) {
      setTelecallerLogin(false);
    } else {
      setTelecallerLogin(true);
    }
    // telecaller-manager
    if (
      TelecallerManagerToken === null ||
      TelecallerManagerToken === "" ||
      TelecallerManagerToken === "undefined"
    ) {
      setTelecallerManager(false);
    } else {
      setTelecallerManager(true);
    }

    // AHC
    if (
      AhcAdminToken === null ||
      AhcAdminToken === "" ||
      AhcAdminToken === "undefined"
    ) {
      setAhcAdminLogin(false);
    } else {
      setAhcAdminLogin(true);
    }

    // GHC
    if (
      GhcAdminToken === null ||
      GhcAdminToken === "" ||
      GhcAdminToken === "undefined"
    ) {
      setGhcAdminLogin(false);
    } else {
      setGhcAdminLogin(true);
    }

    // Ambulance
    if (
      adminAmbulanceToken === null ||
      adminAmbulanceToken === "" ||
      adminAmbulanceToken === "undefined"
    ) {
      setAdminAmbulanceLogin(false);
    } else {
      setAdminAmbulanceLogin(true);
    }

    // ICE
    if (
      IceAdminToken === null ||
      IceAdminToken === "" ||
      IceAdminToken === "undefined"
    ) {
      setIceAdminLogin(false);
    } else {
      setIceAdminLogin(true);
    }
  }, [
    token,
    storedUserRole,
    doctorToken,
    telecallerToken,
    TelecallerManagerToken,
    GhcAdminToken,
    AhcAdminToken,
    IceAdminToken,
    adminAmbulanceToken,
  ]);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        userRole,
        setUserRole,
        doctorLogin,
        setDoctorLogin,
        telecallerLogin,
        setTelecallerLogin,
        telecallerManager,
        setTelecallerManager,
        ahcAdminLogin,
        setAhcAdminLogin,
        ghcAdminLogin,
        setGhcAdminLogin,
        adminAmbulanceLogin,
        setAdminAmbulanceLogin,
        iceAdminLogin,
        setIceAdminLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
