import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../pages/context/AuthContext";
import { useContext } from "react";
import logo from "../../../../images/logo.png";
import image from "../../../../images/ghcLoginlogo.png";
import "../../../../doctor_profile/css/doctorLogin.css";
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Axios from "axios";
import Swal from "sweetalert2";

function GHCAdminLogin() {
  const { setAhcAdminLogin } = useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const [passError, setPassError] = useState("");
  const [emailError, setEmailError] = useState("");

  let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginSuccess = async (event) => {
    // setAhcAdminLogin(true);
    const role = "ghcAdmin";
    localStorage.setItem("role", role);
    Swal.fire({
      title: "Logged In Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {
      navigate("/ghcadmin/dashboard");
    }, 2000);
  };

  const loginUser = async (event) => {
    Swal.fire({
      title: "Doctor doesn't exist!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const LoginFail = async (event) => {
    Swal.fire({
      title: "Wrong Password Combination!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const handleClickResetPassword = () => {
    navigate("/ghcadmin/forgot");
  };

  async function doctorLogin(formVal) {
    var email = formVal.email;
    var password = formVal.password;
    Axios.post(`${url}/ghcAdminLogin`, {
      email,
      password,
    }).then((response) => {
      localStorage.setItem("ghcToken", response.data.token);
      if (response.data.Message === "Succesfully logged in!") {
        loginSuccess();
      } else {
        if (response.data.Message === "Doctor doesn't exist") {
          loginUser();
        } else {
          LoginFail();
        }
      }
    });
  }
  return (
    <>
      <div className="dContainer">
        <div>
          <div className="doctorProfileLogo">
            <img src={logo} alt="Logo" />
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await doctorLogin(values);

                setStatus({ success: false });
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.Message });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} className="dForm">
                <h1 className="dsingIn">Sign In</h1>

                <Grid container spacing={3} style={{ marginLeft: "8rem" }}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email-login">
                        Username<span className="redstar"> *</span>
                      </InputLabel>
                      <OutlinedInput
                        id="email-login"
                        type="email"
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Username"
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        style={{ width: "60%" }}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                    {emailError && <div className="em_error">{emailError}</div>}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="password-login">
                        Password<span className="redstar"> *</span>
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        id="-password-login"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        style={{ width: "60%" }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="Enter password"
                      />
                      {touched.password && errors.password && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.password}
                        </FormHelperText>
                      )}
                    </Stack>
                    {passError && <div className="pass_error">{passError}</div>}
                  </Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      style={{ width: "60%" }}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      SIGN IN
                    </Button>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  ></Grid>
                </Grid>
                <Grid item xs={12}>
                  <span style={{ textDecoration: "none" }}>
                    <p
                      className="dforgotPass"
                      onClick={handleClickResetPassword}
                    >
                      Forgot password?
                    </p>
                  </span>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
        <img src={image} alt="image" className="ahcloginImage" />
      </div>
    </>
  );
}

export default GHCAdminLogin;
