import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebars from "../../../components/Sidebar";
import Axios from "axios";
import Paginate from "../../user_data_component/blood_requesties/paginate";
import "../../../css/bloodreqlist.css";
import moment from "moment";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const GHCHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [totalCou, setTotalCou] = useState([]);
  const tableRef = React.useRef();
  const url = process.env.REACT_APP_URL;

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
  };

  const columns = [
    {
      title: "Membership ID",
      field: "id",
      editable: false,
      emptyValue: () => <em>Not Member</em>,
    },
    {
      title: "Patient Name",
      field: "user_signup.fullname",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Plan Name",
      field: "ghc_plan.plan_name",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Plan Cost",
      field: "ghc_plan.plan_cost",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Paid Amount",
      field: "paid_amount",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Transaction Reference",
      field: "transaction_reference",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Activation Date",
      field: "activation_date",
      editable: false,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.activation_date) {
          const formattedDate = moment(rowData.activation_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Due date",
      field: "due_date",
      editable: false,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.due_date) {
          const formattedDate = moment(rowData.due_date).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },

    {
      title: "Nominee Name",
      field: "nominee_name",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Nominee Dob",
      field: "nominee_dob",
      editable: false,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.nominee_dob) {
          const formattedDate = moment(rowData.nominee_dob).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Nominee Relation",
      field: "nominee_relation",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    localStorage.setItem("role", "admin");
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getghcmembershipdetailshistory/${page}`).then(
      (response) => {
        setData(response.data.result);
        setTotalCou(response.data.totalcount);
        setLoading(false);
      }
    );
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MaterialTable
            tableRef={tableRef}
            title="GHC Membership History"
            columns={columns}
            data={data}
            icons={customIcons}
            style={{ zIndex: 1, borderRadius: "4px" }}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              rowStyle: {
                wordWrap: "break-word",
              },
              searchFieldVariant: "outlined",
              pageSize: 50,
              paginationType: "stepped",
              actionsColumnIndex: columns.length,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            components={{
              Pagination: (props) => (
                <Paginate
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default GHCHistory;
