import React, { useEffect, useRef, useState, useCallback } from "react";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import Header from "../../components/Header";
import "../../css/users.css";
import MatirialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
// import Paginate from "../blood_requesties/paginate";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import debounce from "lodash.debounce";
import Axios from "axios";
import Sidebars from "../../components/Sidebar";
import Swal from "sweetalert2";
import { useNavigate, Navigate } from "react-router-dom";
import PagiNote from "./pageNote";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function MasterData() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const tableRef = useRef();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = useCallback(
    debounce((search) => {
      fetchSearchResults(search);
    }, 500),
    []
  );
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  const columns = [
    {
      title: "State",
      field: "tblstate.statename",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "District",
      field: "tbldistrict.districtname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "City",
      field: "cityname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "City ID",
      field: "id",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/citylist/${page}`).then((response) => {
      setData(response.data.totalcount);
      setUserData(response.data.cities);
      setLoading(false);
    });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    Axios.post(`${url}/searchMasterData`, { search_text: search }).then(
      (response) => {
        setUserData(response.data.result.results);
        setData(response.data.result.totalCount);
        setLoading(false);
      }
    );
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    userData && userData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MatirialTable
            title="Master Data"
            columns={columns}
            tableRef={tableRef}
            data={userData}
            // isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => (
                  <button
                    onClick={() => navigate("/addMasterData")}
                    className="add_new_donor"
                  >
                    + Master Data
                  </button>
                ),
                isFreeAction: true,
              },
              {
                icon: () => <DeleteOutlinedIcon style={{ color: "#F44236" }} />,
                tooltip: "Delete",
                stickyHeader: true,
                onClick: (e, rowData) => {
                  var id = rowData.id;
                  // console.log(id, "id");
                  Swal.fire({
                    title: "Confirm Delete?",
                    showCancelButton: true,
                    confirmButtonText: `Confirm`,
                    confirmButtonColor: "#0f3374",
                    cancelButtonColor: "#d32a2a",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted Successfully!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 15000,
                      });

                      Axios.post(`${url}/deletecity`, { id })
                        .then((response) => {
                          // console.log(response);
                          if (
                            response.data.Message === "Successfully Removed"
                          ) {
                            // console.log("removed");
                          } else {
                            // console.log("errorrr");
                          }
                        })
                        .catch((error) => {
                          //.error(error);
                        });

                      setTimeout(function () {
                        window.location.reload();
                      }, 2000);

                      // tableRef.current.onQueryChange();
                    }
                  });
                },
              },
            ]}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              actionsColumnIndex: columns.length,
              stickyHeader: true,
              pageSize: 50,
              paginationType: "stepped",
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            components={{
              Pagination: (props) => (
                <PagiNote
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}
export default MasterData;
