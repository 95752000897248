import "./styles1.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import suitslab from "../images/suits-lab.png";
import logo1 from "../images/suits-lab.png";

import {
  RiEditLine,
  RiTeamLine,
  RiCalendar2Line,
  RiFolder2Line,
  RiUserFollowLine,
  RiUserUnfollowLine,
} from "react-icons/ri";
import "../css/sidebar.css";
import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  //useProSidebar
} from "react-pro-sidebar";

function SidebarLab() {
  let navigate = useNavigate();
  const history = createBrowserHistory();
  //const { collapseSidebar } = useProSidebar();
  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      <div>
        <Sidebar
          className={`app ${toggled ? "toggled" : ""}`}
          style={{ height: "100%", position: "absolute" }}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        >
          <div
          // style={{
          //   padding: "9px",
          //   // textTransform: "uppercase",
          //   fontWeight: "bold",
          //   fontSize: 14,
          //   letterSpacing: "1px",

          // }}
          >
            <Link to="">
              <img src={logo1} alt="logo" className="image1" />
            </Link>
          </div>
          <hr />
          {/* <Menu>
            {collapsed ? (
              <MenuItem
                icon={<FiChevronsRight />}
                // onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                //suffix={<FiChevronsLeft />}
                // onClick={handleCollapsedChange}
              >
                <div
                  // style={{
                  //   padding: "9px",
                  //   // textTransform: "uppercase",
                  //   fontWeight: "bold",
                  //   fontSize: 14,
                  //   letterSpacing: "1px",
                    
                  // }}
                >
                  
                  <img src={logo} alt="logo" class="image" />
                </div>
              </MenuItem>
            )}
            <hr />
          </Menu> */}

          <Menu>
            <MenuItem
              icon={<RiUserFollowLine />}
              onClick={() => navigate("/SuitsLab")}
            >
              DashBoard{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              onClick={() => navigate("/Providers")}
            >
              Lab Test Providers{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              onClick={() => navigate("/LabCategories")}
            >
              Lab Test Categories{" "}
            </MenuItem>

            <MenuItem
              icon={<RiCalendar2Line />}
              onClick={() => navigate("/Packages")}
            >
              Lab Test Packages
            </MenuItem>
            <MenuItem
              icon={<RiCalendar2Line />}
              onClick={() => navigate("/LabTests")}
            >
              Lab Tests
            </MenuItem>
            <MenuItem
              icon={<RiCalendar2Line />}
              onClick={() => navigate("/Bookings")}
            >
              Lab Test Bookings
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
}
export default SidebarLab;
