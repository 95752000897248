import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import Swal from "sweetalert2";
import Page_Bb from "../../services_component/blood_bank/Page_bb";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Switch from "@mui/material/Switch";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Coupons() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef = React.useRef();
  // const [searchText, setSearchText] = useState("");
  // const debouncedSearch = debounce((search) => fetchSearchResults(search), 500);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);

  const [statusFilter, setStatusFilter] = useState("");

  const url = process.env.REACT_APP_URL;

  const applyFilters = () => {
    const filteredData = data?.filter((item) => {
      let isStatusMatch = true;
      if (statusFilter === "active" && item.status !== 1) {
        isStatusMatch = false;
      }
      if (statusFilter === "inactive" && item.status !== 0) {
        isStatusMatch = false;
      }

      return isStatusMatch;
    });

    return filteredData;
  };

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const columns = [
    {
      title: "Coupon",
      field: "code",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Coupon Name",
      field: "title",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Coupon Type",
      field: "coupon_type_name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Discount Type",
      field: "discount_type",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Duration",
      field: "start_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        const startDate = new Date(rowData.start_date).toLocaleDateString(
          "en-US",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          }
        );
        const endDate = new Date(rowData.expire_date).toLocaleDateString(
          "en-US",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          }
        );
        const [startDay, startMonth, startYear] = startDate.split(" ");
        const [endDay, endMonth, endYear] = endDate.split(" ");
        const duration = ` ${startMonth} ${startDay} ${startYear} - ${endMonth} ${endDay}  ${endYear}`;
        // const couponTypeName = rowData.coupon_type_name || "N/A";
        return <span>{duration}</span>;
      },
    },
    {
      title: "Users Limit",
      field: "usageCount",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        const usageCount = rowData.usageCount;

        if (usageCount) {
          return <div>Used : {usageCount}</div>;
        }

        return <em>Used:0</em>;
      },
    },
    {
      title: "Status",
      field: "status",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => (
        <label className="switch">
          <input
            type="checkbox"
            checked={rowData.status === 1}
            onChange={() => handleStatusToggle(rowData)}
          />
          <span className="slider round"></span>
        </label>
      ),
    },
  ];

  const handleStatusToggle = (rowData) => {
    const id = rowData.id;
    const status = rowData.status;
    let booleanStatus;

    if (status === 1) {
      booleanStatus = false;
    } else if (status === 0) {
      booleanStatus = true;
    }

    const verifyAdminToken = localStorage.getItem("token");

    Axios.put(
      `${url}/updateCouponStatus?id=${id}&status=${booleanStatus}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${verifyAdminToken}`,
        },
      }
    )
      .then(() => {
        // Reload window here
        window.location.reload();
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  useEffect(() => {
    localStorage.setItem("role", "pharma");
  }, []);

  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const fetchData = (page) => {
    // console.log(page, "page");
    var page = page;
    const verifyAdminToken = localStorage.getItem("token");
    setLoading(true);
    Axios.get(`${url}/getCoupons/${page} `, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    })
      .then((response) => {
        // console.log(response.data.result);
        setData(response.data.result);
        setDataCount(response.data.totalcount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="container2">
          <MaterialTable
            title="Coupons"
            columns={columns}
            data={applyFilters()}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => (
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput label="Status" />}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <em>Status</em>;
                      }
                      return selected;
                    }}
                    className="statusFilter"
                  >
                    <option value="all">Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">In-Active</option>
                  </select>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <button
                    onClick={() => navigate("/CouponsAdd")}
                    className="add_blood_bank"
                  >
                    + New Coupon
                  </button>
                ),
                isFreeAction: true,
              },
              {
                icon: () => <BorderColorIcon style={{ color: "#007196" }} />,
                tooltip: "Edit",
                onClick: (e, rowData) => {
                  navigate("/couponsEdit", {
                    state: { data: rowData },
                  });
                },
              },
              {
                icon: () => <DeleteOutlinedIcon style={{ color: "#F44236" }} />,
                tooltip: "Delete",
                stickyHeader: true,
                onClick: (e, rowData) => {
                  var id = rowData.id;
                  var verifyAdminToken = localStorage.getItem("token");
                  // console.log(id, "diddd");
                  Swal.fire({
                    title: "Confirm Delete?",
                    showCancelButton: true,
                    confirmButtonText: `Confirm`,

                    confirmButtonColor: "#0f3374",
                    cancelButtonColor: "#d32a2a", // timer: 10000,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted Successfully!",
                        icon: "success",
                        showConfirmButton: false,
                      });

                      Axios.delete(`${url}/deleteCoupon?id=${id}`, {
                        headers: {
                          Authorization: `Bearer ${verifyAdminToken}`,
                        },
                      })
                        .then((response) => {
                          // console.log(response);
                          // console.log(response.Message, "approve");
                        })
                        .catch((error) => {
                          // console.error(error);
                        });
                      setTimeout(function () {
                        window.location.reload();
                      }, 2000);
                      tableRef.current.onQueryChange();
                    }
                  });
                },
              },
            ]}
            components={{
              Pagination: (props) => (
                <Page_Bb
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            options={{
              actionsColumnIndex: columns.length,
              detailPanelColumnAlignment: "right",
              pageSize: 50,
              exportButton: false,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: false,
              // searchOnEnter: true,
              debounceInterval: 500,
              // searchAutoFocus: false,
              // searchFieldVariant: "outlined",
              border: "0px",
              stickyHeader: true,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76vh",
            }}
            // onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}
export default Coupons;
