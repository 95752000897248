import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../css/login.css";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import NotificationContext from "../pages/context/NotificationContext";

const HeaderGHC = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const ghcToken = localStorage.getItem("ghcToken");
  const { count, setCount } = useContext(NotificationContext);

  let navigate = useNavigate();

  useEffect(() => {
    // fetchData(1); // replace 1 with the actual page number you want to fetch
  }, []);

  const fetchData = (page) => {
    var page = page;
    Axios.get(`${url}/notifications/${page}`).then((response) => {
      if (response.data) {
        if (response.data.result && response.data.result.count)
          setCount(response.data.result.count);
      }
    });
  };

  useEffect(() => {
    if (ghcToken === null || ghcToken === "" || ghcToken === "undefined") {
      navigate("/ghcadmin/login");
    }
  }, [ghcToken]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const url = process.env.REACT_APP_URL;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutFun = async () => {
    // alert("login successfull");
    window.location.href = "/ghcadmin/login";
  };

  function handleLogout() {
    setAnchorEl(null);

    localStorage.removeItem("ghcactiveButton");
    localStorage.removeItem("role");
    localStorage.removeItem("ghcToken");
    logoutFun();
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      await Axios.get(`${url}/getghcadmindetailswithToken`, {
        headers: {
          Authorization: `Bearer ${ghcToken}`,
        },
      }).then((response) => {
        setName(response?.data?.result?.name);
      });
    } catch (error) {}
  };

  return (
    <header className="header_inner">
      <div className="ahcHeaderLab">
        <div className="logos01"> </div>

        <div className="ahcIconPerson">
          <div className="ahcUserName">{name}</div>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon
              style={{
                fontSize: 40,
                borderRadius: "50%",
                backgroundColor: "#ddd",
              }}
            />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link
              to="/ghcadmin/myprofile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem onClick={handleClose}>My Profile</MenuItem>
            </Link>
            {/* <Link
              to="/ghcadmin/dashboard"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Change Password</MenuItem>
            </Link> */}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default HeaderGHC;
