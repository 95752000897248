import React, { useState } from "react";
import "../../../../doctor_profile/css/allAppointments.css";
import "../../../../css/doctor.css";
// import AllMyFollowUps from "./allmyfollowups";
function TelecallerReports() {
  const [activeButton, setActiveButton] = useState("allfollowups");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  //   const renderContent = () => {
  //     if (activeButton === "allfollowups") {
  //       return <AllMyFollowUps />;
  //     }
  //   };

  return (
    <div className="main_appointments">
      <div className="submenu_telecaller">
        <button
          className={`btn-all-appointment ${
            activeButton === "booked" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("booked")}
        >
          <span>Booked</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "rescheduled" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("rescheduled")}
        >
          <span>Rescheduled</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "completed" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("completed")}
        >
          <span>Completed</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "followup" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("followup")}
        >
          <span>Followup</span>
        </button>
      </div>
      <div className="line_doc"></div>
      {/* <div>{renderContent()}</div> */}
    </div>
  );
}

export default TelecallerReports;
