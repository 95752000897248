import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useContext } from "react";
import uploadImage from "../../../../../images/Vector.png";
import downloadImage from "../../../../../images/Vector (3).png";
import downloadImage1 from "../../../../../images/Vector (4).png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../../../services_component/blood_bank/Form.css";
import Axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import AmbulanceSidebar from "../../../../../components/sidebarAmbulance";
import AmbulanceHeader from "../../../../../components/ambulanceHeader";
function AdminAddAmbulanceDetails() {
  let navigate = useNavigate();
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  // const [status, setStatus] = useState("");
  const [pincode, setPincode] = useState("");
  const [ice1, setIce1] = useState("");
  const [ice2, setIce2] = useState("");
  const [errors, setErrors] = useState({});
  const [district, setDistrict] = useState("");
  const [stateList, setStateList] = useState({});
  const [districtList, setDistrictList] = useState({});
  const [cityList, setCityList] = useState({});

  const url = process.env.REACT_APP_URL;

  const validate = () => {
    const newErrors = {};

    if (!ice1.trim()) {
      newErrors.ice1 = "Please enter Ambulance name";
    }

    if (!ice2.trim()) {
      newErrors.ice2 = "Please enter Ambulance mobile";
    }
    // else if (!/^[0-9]{10}$/.test(ice2.trim())) {
    //   newErrors.ice2 = "Invalid Ambulance mobile";
    // }

    if (!address.trim()) {
      newErrors.address = "Please enter address";
    }

    if (!longitude.trim()) {
      newErrors.longitude = "Please enter Longitude";
    } else if (!/^-?\d{1,3}\.\d+$/.test(longitude)) {
      newErrors.longitude =
        "Longitude must be a valid number with up to 3 decimal places";
    }

    if (!latitude.trim()) {
      newErrors.latitude = "Please enter Latitude";
    } else if (!/^-?\d{1,3}\.\d+$/.test(latitude)) {
      newErrors.latitude =
        "Latitude must be a valid number with up to 3 decimal places";
    }
    if (!state.trim()) {
      newErrors.state = "Please enter state";
    }

    if (!city.trim()) {
      newErrors.city = "Please enter town/city";
    }

    // if (!city.trim()) {
    //   newErrors.status = "Please enter Status";
    // }

    if (!district.trim()) {
      newErrors.district = "Please enter district";
    }

    if (!pincode.trim()) {
      newErrors.pincode = "Please enter pin code";
    } else if (!/^[0-9]{6}$/.test(pincode.trim())) {
      newErrors.pincode = "Invalid pin code";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();

    if (isValid) {
      Axios.post(`${url}/addambulance`, {
        name: ice1,
        address: address,
        lat: latitude,
        long: longitude,
        mobile: ice2,
        city: city,
        // status: status,
        pincode: pincode,
      }).then((response) => {
        if (response.data.Message === "Successfully Added") {
          loginSuccess();
        }
        // else if (response.data.Message === "Mobile number already taken") {
        //   loginErrorMob();
        // }
      });
    }
  };

  const handleClear = () => {
    setIce1("");
    setIce2("");
    setAddress("");
    setState("");
    setDistrict("");
    setCity("");
    setPincode("");
    // setStatus("");
    setLatitude("");
    setLongitude("");
  };

  const loginSuccess = async (event) => {
    Swal.fire("Added Ambulance Successfully!", "", "success");

    navigate("/sans/list");
  };
  // const loginErrorMob = async (event) => {
  //   Swal.fire("Mobile Number Already Taken !", "", "error");
  //   navigate("/AllAmbulanceDetails");
  // };

  const backbutton = () => {
    navigate("/sans/list");
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];

    if (
      file &&
      (file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
        file.type === "text/csv")
    ) {
      const extension = file.name.split(".").pop().toLowerCase(); // get the file extension in lower case

      if (extension === "xls" || extension === "xlsx" || extension === "csv") {
        setSelectedFile(file); // set the selected file

        document.getElementById("file-upload").classList.remove("invalid"); // remove the invalid class
      } else {
        setSelectedFile(null);

        document.getElementById("file-upload").classList.add("invalid"); // add the invalid class

        alert("Please select a valid Excel or CSV file");

        const fileInput = document.getElementById("file-upload");

        fileInput.value = ""; // clear the file input
      }
    } else {
      setSelectedFile(null);

      document.getElementById("file-upload").classList.add("invalid"); // add the invalid class

      alert("Please select a valid Excel or CSV file");

      const fileInput = document.getElementById("file-upload");

      fileInput.value = ""; // clear the file input
    }
  };

  function handleCancel() {
    const fileInput = document.getElementById("file-upload");

    fileInput.value = ""; // clear the file input
  }

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();

      formData.append("csvFile", selectedFile, selectedFile.name); // include the filename

      try {
        const response = await Axios.post(
          `${url}/ambulancebulkupload`,
          formData
        );

        // console.log(response);

        if (response.data.Message === "Successfully added") {
          Swal.fire({
            title: "File uploaded successfully!",
            icon: "success",
            showConfirmButton: false,
          });

          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else if (
          response.data.Message ===
          "Invalid file type. Only CSV files are supported"
        ) {
          Swal.fire({
            title: "Invalid File Type!",
            icon: "error",
            showConfirmButton: false,
          });

          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
        // else if (response.data.Message === "mobile_UNIQUE must be unique") {
        //   Swal.fire({
        //     title: "Make sure all the Mobile numbers are Unique!",
        //     icon: "error",
        //     showConfirmButton: false,
        //   });
        //   setTimeout(function () {
        //     window.location.reload();
        //   }, 3000);
        // }
        else {
          Swal.fire({
            title: "Please ensure all the fields are entered!",
            icon: "error",
            showConfirmButton: false,
          });
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
      } catch (error) {
        Swal.fire({
          title: "Error uploading file!",
          icon: "error",
          showConfirmButton: false,
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    } else {
      Swal.fire({
        title: "Please select a file to upload!",
        icon: "error",
        showConfirmButton: false,
      });
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };

  const handleDownloadTemplate = () => {
    // create a new worksheet
    const ws = XLSX.utils.json_to_sheet([
      {
        name: "",
        address: "",
        mobile: "",
        city: "",
        pincode: "",
        lat: "",
        long: "",
        status: "",
      },
    ]);

    // convert the worksheet to a CSV file
    const csv = XLSX.utils.sheet_to_csv(ws);

    // create a blob from the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // create a link to download the CSV file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "template.csv";

    // click the link to trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // using the below useEffect to trigger the dropdowns accoring to state, district and city
  useEffect(() => {
    localStorage.setItem("ambulanceActiveButton", "/sans/list");

    Axios.get(`${url}/statelist`).then((response) => {
      const newList = {};

      response.data?.states?.forEach(
        (item) => (newList[item.id] = item.statename)
      );

      setStateList(newList);
    });

    var stateid = state;

    var districtid = district;

    if (stateid) {
      Axios.get(`${url}/districtsbystate/${stateid}`).then((response) => {
        const distList = {};
        // console.log(response.data.districts,"from backend distric")
        response.data?.result?.forEach(
          (item) => (distList[item.id] = item.districtname)
        );
        // console.log("district data", distList, response.data);
        setDistrictList(distList);
      });
    }
    if (districtid) {
      Axios.get(`${url}/citiesbydistrict/${districtid}`).then((response) => {
        const cityyList = {};
        response.data?.result?.forEach(
          (item) => (cityyList[item.id] = item.cityname)
        );
        // console.log("city dta", cityyList, response.data.cities);
        setCityList(cityyList);
      });
    }
  }, [state, district]);

  const handleStateChange = (e) => {
    setState(e.target.value);
    setCityList({});
    setDistrictList({});
    setDistrict("");
    setCity("");
  };

  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
    setCity("");
    setCityList({});
  };

  return (
    <>
      <AmbulanceSidebar />
      <div className="sidebarleft">
        <AmbulanceHeader />

        <div>
          <form onSubmit={handleUpload}>
            <div className="upload-container">
              <div className="back-button">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  Ambulance / All Ambulance
                </span>
                <span>{" / Add Ambulance "}</span>
              </div>
              <div className="budt">
                <a className="bulk-upload">Bulk Upload</a>
                <a
                  // href="#"
                  onClick={handleDownloadTemplate}
                  className="downloadTemplate"
                >
                  {" "}
                  <img className="imagev" src={downloadImage} /> Download
                  template{" "}
                </a>
              </div>
              <div className="form-upload">
                <div className="excelupload">
                  <label htmlFor="file-upload" className="label-drag">
                    <img className="imagevv" src={downloadImage1} />{" "}
                    <p>Drag and Drop here or</p>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".xls, .xlsx, .csv"
                    onChange={handleFileSelect}
                  />
                </div>
              </div>
              <div className="text">
                <div>
                  <p className="filesupported">
                    File Supported: CSV, Excel Sheet
                  </p>
                </div>
                <div>
                  <p className="maximumsize" style={{ textDecoration: "none" }}>
                    Maximun Size: 2MB
                  </p>
                </div>
              </div>
              <div className="btn-upload">
                <div>
                  <button
                    className="cancle-btn"
                    type="button"
                    onClick={handleUpload}
                  >
                    <img src={uploadImage} /> Upload
                  </button>
                </div>
                <div>
                  <button
                    className="upload-btn"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>

          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="add-new-donor">
                <h2 className="heading-addnewdonor">Add New Ambulance</h2>
              </div>

              <div className="first-form">
                <div className="section2">
                  <div className="form-dob">
                    <label className="label-container2" htmlFor="ice1">
                      Ambulance Name <span className="redstar"> *</span>
                    </label>
                    <input
                      id="ice1"
                      type="text"
                      className="input-row-2"
                      placeholder="        Enter Ambulance Name"
                      value={ice1}
                      onChange={(event) => setIce1(event.target.value)}
                    />
                    {errors.ice1 && <div className="error">{errors.ice1}</div>}
                  </div>
                  <div className="form-state">
                    <label className="label-state" htmlFor="ice2">
                      Ambulance Mobile/ Landline{" "}
                      <span className="redstar"> *</span>
                    </label>
                    <input
                      id="ice2"
                      className="input-row-8"
                      type="tel"
                      value={ice2}
                      placeholder="        Enter Number Here"
                      onChange={(event) => setIce2(event.target.value)}
                    />
                    {errors.ice2 && <div className="error">{errors.ice2}</div>}
                  </div>
                  <div className="form-city">
                    <label className="label-city" htmlFor="state">
                      State <span className="redstar"> *</span>
                    </label>
                    <select
                      id="state"
                      className="input-row-2"
                      value={state}
                      onChange={handleStateChange}
                    >
                      <option value="">Select State</option>
                      {Object.keys(stateList).map((id) => (
                        <option value={id} key={id}>
                          {stateList[id]}{" "}
                        </option>
                      ))}
                      {/* Add more options for all 50 states */}
                    </select>
                    {errors.state && (
                      <div className="error">{errors.state}</div>
                    )}
                  </div>
                </div>

                <div className="section2">
                  <div className="form-city">
                    <label className="label-city" htmlFor="district">
                      District <span className="redstar"> *</span>
                    </label>
                    <select
                      id="district"
                      className="input-row-2"
                      value={district}
                      onChange={handleDistrictChange}
                    >
                      <option value="">Select District</option>
                      {Object.keys(districtList).map((id) => (
                        <option value={id} key={id}>
                          {districtList[id]}{" "}
                        </option>
                      ))}
                      {/* Add more options for all available districts */}
                    </select>
                    {errors.district && (
                      <div className="error">{errors.district}</div>
                    )}
                  </div>

                  <div className="form-dob">
                    <label className="label-container2" htmlFor="city">
                      City <span className="redstar"> *</span>
                    </label>
                    <select
                      id="city"
                      className="input-row-8"
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                    >
                      <option value="">Select City</option>
                      {Object.keys(cityList).map((id) => (
                        <option value={id} key={id}>
                          {cityList[id]}{" "}
                        </option>
                      ))}
                      {/* Add more options for all available cities */}
                    </select>
                    {errors.city && <div className="error">{errors.city}</div>}
                  </div>
                  <div className="form-state">
                    <label className="label-state" htmlFor="address">
                      Address <span className="redstar"> *</span>
                    </label>
                    <input
                      id="address"
                      type="text"
                      className="input-row-2"
                      value={address}
                      placeholder="        Address"
                      onChange={(event) => setAddress(event.target.value)}
                    />
                    {errors.address && (
                      <div className="error">{errors.address}</div>
                    )}
                  </div>
                </div>

                <div className="section2">
                  <div className="form-dob">
                    <label className="label-container2" htmlFor="pincode">
                      Pincode <span className="redstar"> *</span>
                    </label>
                    <input
                      id="pincode"
                      className="input-row-8"
                      type="text"
                      value={pincode}
                      placeholder="        Pincode"
                      onChange={(event) => setPincode(event.target.value)}
                    />
                    {errors.pincode && (
                      <div className="error">{errors.pincode}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="label-state" htmlFor="longitude">
                      Longitude <span className="redstar"> *</span>
                    </label>
                    <input
                      id="longitude"
                      type="text"
                      className="input-row-8"
                      value={longitude}
                      placeholder="        Longitude"
                      onChange={(event) => setLongitude(event.target.value)}
                    />
                    {errors.longitude && (
                      <div className="error">{errors.longitude}</div>
                    )}
                  </div>
                  <div className="form-city">
                    <label className="label-city" htmlFor="latitude">
                      Latitude <span className="redstar"> *</span>
                    </label>
                    <input
                      id="latitude"
                      className="input-row-8"
                      type="text"
                      value={latitude}
                      placeholder="        Latitude"
                      onChange={(event) => setLatitude(event.target.value)}
                    />
                    {errors.latitude && (
                      <div className="error">{errors.latitude}</div>
                    )}
                  </div>
                </div>

                <div className="section11">
                  <div className="cancle">
                    <button className="cancle1" type="submit">
                      Submit
                    </button>
                  </div>
                  <div className="submit">
                    <button
                      className="submit1"
                      type="button"
                      onClick={handleClear}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default AdminAddAmbulanceDetails;
