import React, { createContext, useState, useEffect } from "react";

const NotificationContext = createContext();

export const NotificatProvider = ({ children }) => {
  const [count, setCount] = useState(0);

  return (
    <NotificationContext.Provider value={{ count, setCount }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
