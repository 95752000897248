import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../../../css/login2.css";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import PersonIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import logo from "../../../images/logo1.png";
import { useNavigate } from "react-router-dom";
import NotificationContext from "../../context/NotificationContext";
const TelecallerHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const telecallerToken = localStorage.getItem("telecaller_token");
  const teleId = localStorage.getItem("teleId");
  const { count, setCount } = useContext(NotificationContext);
  let navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      telecallerToken === null ||
      telecallerToken === "" ||
      telecallerToken === "undefined"
    ) {
      navigate("/csr/login");
    }

    fetchData();
  }, [telecallerToken]);

  const url = process.env.REACT_APP_URL;

  const fetchData = () => {
    const telecaller_id = teleId;
    Axios.get(
      `${url}/getTeleCallerNewAppointmentNotifications?telecaller_id=${telecaller_id}`
    ).then((response) => {
      if (response.data) {
        if (
          response?.data?.notificationCount &&
          response?.data?.notificationCount
        )
          setCount(response?.data?.notificationCount);
      }
    });
  };

  const logoutFun = async () => {
    window.location.href = "/csr/login";
  };

  const handlelogOut = () => {
    setAnchorEl(null);

    localStorage.removeItem("role");
    localStorage.removeItem("activeButton");
    localStorage.removeItem("telecaller_token");
    logoutFun();
    // navigate("/telecaller");
  };

  return (
    <header className="header_inner1">
      <div className="header_logo1">
        <img src={logo} alt="Logo" />
      </div>
      <div className="header_right1">
        <Link to="/csr/notification" className="Link1">
          <NotificationsOutlinedIcon
            fontSize="large"
            style={{ color: "black" }}
          />
          {count > 0 && <div className="indicator">{count}</div>}
        </Link>
        <div className="iconPerson1">
          <p className="doctor-name1">Community Support Representative</p>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon
              style={{
                fontSize: 40,
                borderRadius: "50%",
                backgroundColor: "#ddd",
              }}
            />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link
              to="/change-password"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Change Password</MenuItem>
            </Link>
            <Link
              onClick={handlelogOut}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Logout</MenuItem>
            </Link>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default TelecallerHeader;
