import React, { useEffect, useRef, useState, useCallback } from "react";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import Header from "../../components/Header";
import "../../css/users.css";
import MatirialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
// import Paginate from "../blood_requesties/paginate";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import Axios from "axios";
import Sidebars from "../../components/Sidebar";
import Swal from "sweetalert2";
import { useNavigate, Navigate } from "react-router-dom";
import DoctorsPage from "./doctorsPage";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function DoctorsList() {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const tableRef = useRef();
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const [searchText, setSearchText] = useState("");
  const debouncedSearch = useCallback(
    debounce((search) => {
      fetchSearchResults(search);
    }, 500),
    []
  );

  const verifyAdminToken = localStorage.getItem("token");
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  const columns = [
    {
      title: "Name",
      field: "doctor.name",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Registration Number",
      field: "doctor.license_no",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Specialty",
      field: "specialization.name",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Qualification",
      field: "doctor.qualification",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Mobile No",
      field: "doctor.phone_no",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Email ID",
      field: "doctor.email",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Experience",
      field: "doctor.experience",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Hospital Name",
      field: "doctor.hospital_name",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Hospital Location",
      field: "doctor.hospital_location",
      emptyValue: () => <em>no info</em>,
      export: true,
    },
    {
      title: "Status",
      field: "doctor.status",
      editable: false,
      emptyValue: () => <em>no info</em>,
      export: true,
      render: (rowData) => (
        <label className="switch">
          <input
            type="checkbox"
            checked={
              rowData?.doctor?.status === 1 ||
              rowData?.doctor?.status === "1" ||
              rowData?.doctor?.status === "ACTIVE"
            }
            onChange={() => handleStatusToggle(rowData)}
          />
          <span className="slider round"></span>
        </label>
      ),
    },
  ];

  const handleStatusToggle = (rowData) => {
    const id = rowData?.doctor?.doctor_id;
    const status = rowData?.doctor?.status;

    let booleanStatus;

    if (status === 1 || status === "1" || status === "ACTIVE") {
      booleanStatus = false;
    } else if (status === 0 || status === "0" || status === "INACTIVE") {
      booleanStatus = true;
    }
    Axios.put(`${url}/UpdateDoctorStatus`, {
      doctor_id: id,
      status: booleanStatus,
    })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getAlldoctorsDetails`, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    }).then((response) => {
      setData(response.data.totalcount);
      setUserData(response.data.result);
      setLoading(false);
    });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    Axios.post(`${url}/searchdoctor`, { search }).then((response) => {
      setUserData(response.data.result);
      setData(response?.data?.result?.length);
      setLoading(false);
    });
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    userData && userData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MatirialTable
            title="Doctor Information"
            columns={columns}
            tableRef={tableRef}
            data={userData}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => (
                  <button
                    onClick={() => navigate("/addDoctor")}
                    className="add_new_donor"
                  >
                    + Add Doctor
                  </button>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <BorderColorSharpIcon style={{ color: "#007196" }} />
                ),
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  navigate("/update-doctor", {
                    state: { data: rowData },
                  });
                },
              },
            ]}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              actionsColumnIndex: columns.length,
              stickyHeader: true,
              pageSize: 50,
              pageSizeOptions: [20, 30, 50],
              // paginationType: "stepped",
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            // components={{
            //   Pagination: (props) => (
            //     <DoctorsPage
            //       nPages={nPages}
            //       currentPage={currentPage}
            //       setCurrentPage={setCurrentPage}
            //     />
            //   ),
            // }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}

export default DoctorsList;
