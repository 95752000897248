import React, { useState, useEffect } from "react";
import NotificationsIcon from "@mui/icons-material/CalendarToday";
import VideocamIcon from "@mui/icons-material/VideoChat";
import MeetingRoomIcon from "@mui/icons-material/HealthAndSafety";
import AssessmentIcon from "@mui/icons-material/Assessment";
import docimg from "../../../images/support_7363068.png";
import TelecallerHeader from "../components/tc-header";
import Axios from "axios";
import "../../../css/doctor.css";
import AllConsultations from "../components/consultations/consultations";
import OnlineConsultation from "../components/online-consultations/online-consultations";
import OfflineConsultation from "../components/offline-consultations/offline-consultations";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import MyFollowUps from "../components/my-follow-ups/myfollowups";
import TelecallerReports from "../components/manage-reports/reports";

function TelecallerDashboard() {
  const [telecallerActiveButton, setTelecallerActiveButton] = useState(
    localStorage.getItem("telecallerActiveButton") || "onlineConsultation"
  );
  const verifyTelecallerToken = localStorage.getItem("telecaller_token");

  const url = process.env.REACT_APP_URL;

  const [name, setName] = useState("");
  const [experience, setExperience] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");

  const [openPopUp, setOpenPopUp] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const editProfile = () => {
    setOpenPopUp(true);
  };

  const fetchData = async () => {
    try {
      await Axios.get(`${url}/gettelecallerdetailswithToken`, {
        headers: {
          Authorization: `Bearer ${verifyTelecallerToken}`,
        },
      }).then((response) => {
        // console.log(response, "res");
        localStorage.setItem("teleId", response?.data?.result?.id);
        setName(response.data.result.fullname);
        setExperience(response.data.result.gender);
        setEmail(response.data.result.email);
        setPhone_no(response.data.result.mobile);
      });
    } catch (error) {}
  };

  const handleButtonClick = (buttonId) => {
    setTelecallerActiveButton(buttonId);
    localStorage.setItem("telecallerActiveButton", buttonId);
  };

  const renderContent = () => {
    if (telecallerActiveButton === "onlineConsultation") {
      return <OnlineConsultation />;
    } else if (telecallerActiveButton === "offlineConsultation") {
      return <OfflineConsultation />;
    } else if (telecallerActiveButton === "manageReports") {
      return <TelecallerReports />;
    } else if (telecallerActiveButton === "myfollowups") {
      return <MyFollowUps />;
    }
  };

  const handlePopState = () => {
    localStorage.removeItem("telecallerActiveButton");
    window.location.reload();
  };
  useEffect(() => {
    // Clear the stored telecallerActiveButton value when the component unmounts
    return () => {
      handlePopState();
      localStorage.removeItem("telecallerActiveButton");
    };
  }, []);

  return (
    <>
      <div className="main">
        <TelecallerHeader />

        <div className="profile_doc">
          <div className="image-container">
            <div
              className="circle-image"
              style={{ backgroundImage: `url(${docimg})` }}
            ></div>
          </div>
          <div className="name_doc">
            <div className="text_arrange">
              <div className="welcome_text">Welcome , Mr/Mrs. {name}</div>

              <div className="telecallerProfile">
                <div className="key_text">
                  <strong>Mobile : </strong>{" "}
                  <p style={{ marginLeft: "4px" }}>{phone_no}</p>
                </div>
                <div className="key_text">
                  <strong>Email : </strong>
                  <p style={{ marginLeft: "4px" }}> {email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu_doc">
          <button
            className={`button__doc_main ${
              telecallerActiveButton === "onlineConsultation" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("onlineConsultation")}
          >
            <VideocamIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Online Consultation</span>
          </button>
          <button
            className={`button__doc_main ${
              telecallerActiveButton === "offlineConsultation" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("offlineConsultation")}
          >
            <MeetingRoomIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Offline Consultation</span>
          </button>

          <button
            className={`button__doc_main ${
              telecallerActiveButton === "manageReports" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("manageReports")}
          >
            <AssessmentIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Reports</span>
          </button>

          <button
            className={`button__doc_main ${
              telecallerActiveButton === "myfollowups" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("myfollowups")}
          >
            <SettingsPhoneIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>My Follow-Ups</span>
          </button>
        </div>
        <div className="table_doc">{renderContent()}</div>
      </div>
      {openPopUp && (
        <PopupDashboard openPopup={openPopUp} setOpenPopUp={setOpenPopUp} />
      )}
    </>
  );
}

export default TelecallerDashboard;
