import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import Swal from "sweetalert2";
import Page_Bb from "../../services_component/blood_bank/Page_bb";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Switch from "@mui/material/Switch";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Products() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef1 = React.useRef();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = debounce((search) => fetchSearchResults(search), 500);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);

  const [statusFilter, setStatusFilter] = useState("");
  const [productTypeFilter, setProductTypeFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");

  const url = process.env.REACT_APP_URL;

  const uniqueProductTypes = [
    ...new Set(data?.map((item) => item.product_type)),
  ];

  //

  const uniqueCategoryTypes = [...new Set(data?.map((item) => item.catName))];

  const applyFilters = () => {
    const filteredData = data?.filter((item) => {
      let isStatusMatch = true;
      let isProductMatch = true;
      let isCategoryMatch = true;

      if (statusFilter === "active" && item.status !== 1) {
        isStatusMatch = false;
      }
      if (statusFilter === "inactive" && item.status !== 0) {
        isStatusMatch = false;
      }

      if (productTypeFilter && productTypeFilter !== "all") {
        isProductMatch = item.product_type === productTypeFilter;
      }

      if (categoryFilter && categoryFilter !== "all") {
        isCategoryMatch = item.catName === categoryFilter;
      }

      return isStatusMatch && isCategoryMatch && isProductMatch;
    });

    return filteredData;
  };

  const eurl = "https://suitsdata.blob.core.windows.net";
  const prod_name = "productdev";
  const name2 = "eno.png";

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const handleStatusToggle = (rowData) => {
    const id = rowData.id;
    const status = rowData.status;
    let booleanStatus;

    if (status === 1) {
      booleanStatus = false;
    } else if (status === 0) {
      booleanStatus = true;
    }
    // console.log(id, booleanStatus);
    Axios.put(`${url}/updateProductStatus`, {
      id: id,
      status: booleanStatus,
    })
      .then(() => {
        // Reload window here
        window.location.reload();
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const columns = [
    {
      title: "Product Name",
      field: "name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Product Type",
      field: "product_type",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Category",
      field: "product_categories.category.ParentCategory.name",
      render: (rowData) => {
        const categoryNames = rowData?.product_categories?.map(
          (category) => category?.category?.ParentCategory?.name
        );
        const categoryNameString = categoryNames.join(", ");

        if (categoryNameString) {
          return <div>{categoryNameString}</div>;
        }

        return <em>-NA-</em>;
      },
    },
    {
      title: "Cover Image",
      field: "image",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => (
        <img
          src={`${eurl}/${prod_name}/${rowData.image[0]}`}
          style={{ width: 50 }}
        />
      ),
    },
    {
      title: "Manufacturer",
      field: "manufacturer",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Capacity",
      field: "capacity",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Unit",
      field: "unit",

      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Stock",
      field: "total_stock",

      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Price",
      field: "price",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        const price = rowData.price;

        if (price) {
          const formattedPrice = price % 1 === 0 ? `${price}.00` : price;
          return <div>₹{formattedPrice}</div>;
        }
        return <em>0</em>;
      },
    },
    {
      title: "Status",
      field: "status",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => (
        <label className="switch">
          <input
            type="checkbox"
            checked={rowData.status === 1}
            onChange={() => handleStatusToggle(rowData)}
          />
          <span className="slider round"></span>
        </label>
      ),
    },
  ];

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "pharma");
  }, []);

  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getProductsWithCategories/${page} `)
      .then((response) => {
        // console.log(response.data.result, "dataaa");
        setData(response.data.result);
        setDataCount(response.data.totalcount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    Axios.get(`${url}/searchProduct?search=${search}`).then((response) => {
      // console.log(response, "data");
      setData(response?.data?.result);
      setDataCount(response?.data?.result.length);
      setLoading(false);
    });
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="container2">
          <MaterialTable
            title="Products"
            columns={columns}
            data={applyFilters()}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => (
                  <select
                    value={productTypeFilter}
                    onChange={(e) => setProductTypeFilter(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput label="Product Type" />}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <em>Product Type</em>;
                      }
                      return selected;
                    }}
                    className="productFilter"
                  >
                    <option value="all">Product Type</option>
                    {uniqueProductTypes.map((productType) => (
                      <option key={productType} value={productType}>
                        {productType}
                      </option>
                    ))}
                  </select>
                ),
                isFreeAction: true,
              },
              // {
              //   icon: () => (
              //     <select
              //       value={categoryFilter}
              //       onChange={(e) => setCategoryFilter(e.target.value)}
              //       displayEmpty
              //       input={<OutlinedInput label="Category" />}
              //       renderValue={(selected) => {
              //         if (selected === "") {
              //           return <em>Category</em>;
              //         }
              //         return selected;
              //       }}
              //       className="categoryFilter"
              //     >
              //       <option value="all">Category</option>
              //       {uniqueCategoryTypes.map((Category) => (
              //         <option key={Category} value={Category}>
              //           {Category}
              //         </option>
              //       ))}
              //     </select>
              //   ),
              //   isFreeAction: true,
              // },
              {
                icon: () => (
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput label="Status" />}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <em>Status</em>;
                      }
                      return selected;
                    }}
                    className="statusFilter"
                  >
                    <option value="all">Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">In-Active</option>
                  </select>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <button
                    onClick={() => navigate("/ProductsAdd")}
                    className="add_blood_bank"
                  >
                    + Add Products
                  </button>
                ),
                isFreeAction: true,
              },
              {
                icon: () => <BorderColorIcon style={{ color: "#007196" }} />,
                tooltip: "Edit",
                onClick: (e, rowData) => {
                  navigate("/ProductsEdit", {
                    state: { data: rowData },
                  });
                },
              },
              {
                icon: () => <DeleteOutlinedIcon style={{ color: "#F44236" }} />,
                tooltip: "Delete",
                stickyHeader: true,
                onClick: (e, rowData) => {
                  var id = rowData.id;
                  Swal.fire({
                    title: "Confirm Delete?",
                    showCancelButton: true,
                    confirmButtonText: `Confirm`,
                    confirmButtonColor: "#0f3374",
                    cancelButtonColor: "#d32a2a", // timer: 10000,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted Successfully!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000, // set the duration in milliseconds
                      });
                      Axios.delete(`${url}/deleteProduct?id=${id}`)
                        .then((response) => {
                          // console.log(response);
                          // console.log(response.Message, "approve");
                        })
                        .catch((error) => {
                          // console.error(error);
                        });
                      setTimeout(function () {
                        window.location.reload();
                      }, 2000);
                      tableRef.current.onQueryChange();
                    }
                  });
                },
              },
            ]}
            components={{
              Pagination: (props) => (
                <Page_Bb
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            options={{
              actionsColumnIndex: columns.length,
              detailPanelColumnAlignment: "right",
              pageSize: 50,
              exportButton: false,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              border: "0px",
              stickyHeader: true,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76vh",
            }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}
export default Products;
