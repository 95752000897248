import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SidebarPharma from "../../components/sidebarPharma";
import HeaderPharma from "../../components/HeaderPharma";
function DashboardPharma() {
  let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "pharma");
  }, []);

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <div>Dashboard - Pharmacy</div>
            <div
              style={{
                margin: "112px",
                paddingLeft: "535px",
              }}
            >
              <b style={{ width: "10px", height: "5px" }}>Coming Soon...</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DashboardPharma;
