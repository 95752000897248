import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../../../doctor_profile/css/allAppointments.css";
import "../../../../css/doctor.css";
import MaterialTable from "material-table";
import Page_Bb from "../../../services_component/blood_bank/Page_bb";
import Axios from "axios";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { BlobServiceClient } from "@azure/storage-blob";
import moment from "moment";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PublishIcon from "@mui/icons-material/Publish";
import Swal from "sweetalert2";
import PopupDashboardTelecaller from "../follow-up-popup";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function UpcomingOfflineConsultations() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const url = process.env.REACT_APP_URL;
  const teleId = localStorage.getItem("teleId");

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };
  const handleFollowUp = (rowData) => {
    setOpenPopUp(true);
    setSelectedRow(rowData);
  };

  const columns = [
    {
      title: "Patient Name",
      field: "user_fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "user_mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Name",
      field: "doctor_name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Mobile",
      field: "doctor_phone_no",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "user_gender",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Consultation Date",
      field: "appointment_taken_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_taken_date) {
          const formattedDate = moment(rowData.appointment_taken_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Type of Consultation",
      field: "treatment_mode",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Appointment Date",
      field: "appointment_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_date) {
          const formattedDate = moment(rowData.appointment_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Time Slot",
      field: "appointment_time_slot",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Consultation Fee",
      field: "consultation_fee",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => `₹${rowData?.consultation_fee}`,
    },

    {
      title: "Payment Method",
      field: "payment_mode",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Follow Up",
      render: (rowData) => (
        <div className="followUpDivContainer">
          <button
            className={`${
              rowData.appointment_notification_status === 1
                ? "redIconApprove"
                : "follow-up-btn-approve"
            }`}
            onClick={() => handleApprove(rowData)}
            title={
              rowData.appointment_notification_status === 0
                ? "Click to Approve"
                : "Already Approved"
            }
            disabled={
              rowData.appointment_notification_status === 0 ? false : true
            }
          >
            <CheckCircleOutlineIcon />
          </button>
          <button
            className="follow-up-btn"
            onClick={() => handleFollowUp(rowData)}
          >
            <ArrowForwardIcon />
          </button>
        </div>
      ),
    },
  ];

  const handleApprove = useCallback((rowData) => {
    var appointment_id = rowData.appointment_id;

    const status = rowData?.appointment_notification_status;
    let booleanStatus;

    if (status === 0) {
      booleanStatus = true;
    }

    Swal.fire({
      title: "Confirm Approval of Appointment?",
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#0f3374",
      cancelButtonColor: "#d32a2a",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.put(`${url}/updatedoctornotificationstatus`, {
          appointment_id: appointment_id,
          doc_notify: booleanStatus,
        })
          .then((response) => {
            if (
              response.data.Message ===
              "Doctor Notification Status updated successfully."
            ) {
              Swal.fire({
                title: "Appointment Approved!",
                icon: "success",
                showConfirmButton: true,
                timer: 2000, // set the duration in milliseconds
              });
              setTimeout(function () {
                window.location.reload();
              }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
            }
          })
          .catch((error) => {});
      }
    });
  }, []);

  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    const telecaller_id = teleId;
    setLoading(true);
    Axios.get(
      `${url}/gettelecallerUpcomingOfflineAppointments/${page}?telecaller_id=${telecaller_id} `
    ).then((response) => {
      setData(response.data.result);
      setDataCount(response.data.totalCount);
      setLoading(false);
    });
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <div className="main_doc">
        <div className="main_newreq1">
          <div style={{ width: "100%" }}>
            <MaterialTable
              columns={columns}
              data={data}
              icons={customIcons}
              options={{
                searchFieldStyle: { border: "2px solid #f00" },
                detailPanelColumnAlignment: "right",
                exportButton: true,
                exportButton: true,
                sorting: true,
                search: false,
                toolbar: false,
                searchOnEnter: true,
                debounceInterval: 500,
                searchAutoFocus: false,
                actionsColumnIndex: columns.length,
                searchFieldVariant: "outlined",
                stickyHeader: true,
                pageSize: 50,
                paginationType: "stepped",
                showFirstLastPageButtons: false,
                emptyRowsWhenPaging: false,
                headerStyle: {
                  size: "40px",
                  background: "rgba(0, 0, 0, 0.05)",
                  position: "sticky",
                  top: "0",
                },
                stickyHeader: true,
                maxBodyHeight: "34.5vh",
              }}
              components={{
                Pagination: (props) => (
                  <Page_Bb
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      {openPopUp && (
        <PopupDashboardTelecaller
          openPopup={openPopUp}
          setOpenPopUp={setOpenPopUp}
          data={selectedRow}
        />
      )}
    </>
  );
}

export default UpcomingOfflineConsultations;
