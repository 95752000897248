import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../pages/context/AuthContext";
import { useContext } from "react";
import logo from "../../../../images/logo.png";
import log1 from "../../../../images/amulanceAdmin.png";
import recg from "../../../../images/rec.png";
import "../../../../css/login.css";
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Axios from "axios";
import Swal from "sweetalert2";

function AmbulanceAdminLogin() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [passError, setPassError] = useState("");
  const { setAdminAmbulanceLogin, setUserRole } = useContext(AuthContext);
  const [emailError, setEmailError] = useState("");
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginUser = async (event) => {
    Swal.fire({
      title: "User doesn't exist!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const LoginFail = async (event) => {
    Swal.fire({
      title: "Wrong Password Combination!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginSuccess = async (event) => {
    const role = "ambulance";
    localStorage.setItem("role", role);
    setAdminAmbulanceLogin(true);
    Swal.fire({
      title: "Logged In Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      navigate("/sans/dashboard");
    }, 2000);
  };

  const handleClickResetPassword = () => {
    navigate("/sans/forgot");
  };

  async function adminLogin(formVal) {
    var email = formVal.email;
    var password = formVal.password;
    Axios.post(`${url}/ambAdminLogin`, {
      email,
      password,
    }).then((response) => {
      localStorage.setItem("ambulanceToken", response.data.token);
      localStorage.setItem("ambulanceId", response.data.result[0].id);
      if (response.data.Message === "Succesfully logged in!") {
        loginSuccess();
      } else {
        if (response.data.Message === "User doesn't exist") {
          loginUser();
          // setEmailError(response.data.Message);
        } else {
          LoginFail();
          // setPassError(response.data.Message);
        }
      }
    });
  }

  return (
    <>
      <div>
        <img src={logo} alt="Logo" className="login_logo" />
      </div>
      <div className="container">
        <img src={log1} alt="logo" className="imageLogoo" />
        <div class="text">
          <img src={recg} alt="rec" className="rec_img"></img>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await adminLogin(values);

              setStatus({ success: false });
              // loginSuccess();
              // setSubmitting(false);
              // navigate("/DashBoard");
            } catch (err) {
              setStatus({ success: false });
              setErrors({ submit: err.Message });
              // setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="formLogin">
              <div>
                <p></p>
                {/* <h3 style={{ color: "#007196" }}>Telecaller Manager </h3> */}
                <div className="linedot">
                  <div className="linewitdh"></div>
                  <div className="linewitdh1"></div>
                  <div className="linewitdh2"></div>
                </div>

                <h2 style={{ color: "#007196" }}> Login as Sans Admin</h2>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">Username</InputLabel>
                    <OutlinedInput
                      id="email-login"
                      type="email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Username"
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Stack>
                  {emailError && <div className="em_error">{emailError}</div>}
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-login">Password</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      id="-password-login"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Enter password"
                    />
                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </Stack>
                  {passError && <div className="pass_error">{passError}</div>}
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    SIGN IN
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ mt: -1 }}>
                  <span style={{ textDecoration: "none" }}>
                    <p
                      className="adminforgotPass"
                      onClick={handleClickResetPassword}
                    >
                      Forgot password?
                    </p>
                  </span>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AmbulanceAdminLogin;
