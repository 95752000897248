import React, { useEffect, useRef, useState } from "react";
import SidebarAHC from "../../../../../components/sidebarAhc";
import HeaderAHC from "../../../../../components/headerAhc";
function AhcAdminDashboard() {
  //   let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "ahc-admin");
  }, []);

  return (
    <>
      <SidebarAHC />
      <div className="sidebarleft">
        <HeaderAHC />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <div>Dashboard - AHC ADMIN</div>
            <div
              style={{
                margin: "112px",
                paddingLeft: "535px",
              }}
            >
              <b style={{ width: "10px", height: "5px" }}>Coming Soon...</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AhcAdminDashboard;
