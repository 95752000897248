import React, { useState, useEffect } from "react";
import "../../css/allAppointments.css";
import "../../../css/doctor.css";
import OnlineAvailability from "./online-availability";
import OfflineAvailability from "./offline-availability";

function AvailabilityDashboard(doc_id) {
  const storedAvailability = localStorage.getItem("availability");
  const [activeButton, setActiveButton] = useState(
    storedAvailability || "online"
  );
  const doc_id1 = doc_id.doc_id;

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const renderContent = () => {
    if (activeButton === "online") {
      return <OnlineAvailability doc_id={doc_id1} />;
    } else if (activeButton === "offline") {
      return <OfflineAvailability doc_id={doc_id1} />;
    }
  };

  // Update local storage when the active button changes
  useEffect(() => {
    localStorage.setItem("availability", activeButton);
  }, [activeButton]);

  return (
    <div className="main_appointments">
      <div className="submenu_doc">
        <button
          className={`btn-all-appointment ${
            activeButton === "online" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("online")}
        >
          <span>Online</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "offline" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("offline")}
        >
          <span>Offline</span>
        </button>
      </div>
      <div className="line_doc"></div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default AvailabilityDashboard;
