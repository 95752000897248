import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../../css/ahc-members-details.css";
import { useLocation } from "react-router-dom";
import moment from "moment";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarAHC from "../../../../../components/sidebarAhc";
import HeaderAHC from "../../../../../components/headerAhc";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function ViewAHCAdminMembersDetails() {
  let navigate = useNavigate();
  const location = useLocation();
  const tableRef = React.useRef();
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [bg, setBg] = useState("");

  const [memberId, setMemberId] = useState("");
  const [planName, setPlanName] = useState("");
  const [planCovers, setPalnCovers] = useState("");
  const [activateDate, setActivateDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [offBal, setOffBal] = useState("");
  const [onBal, setOnBal] = useState("");
  const [transRef, setTransRef] = useState("");

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
  };

  const [data, setData] = useState([]);

  const ahcMembersDetails = location?.state?.data;

  useEffect(() => {
    if (ahcMembersDetails?.userDetails?.userFullName) {
      setFullname(ahcMembersDetails?.userDetails?.userFullName);
    }

    if (ahcMembersDetails?.userDetails?.userMobile) {
      setMobile(ahcMembersDetails?.userDetails?.userMobile);
    }

    if (ahcMembersDetails?.userDetails?.userEmail) {
      setEmail(ahcMembersDetails?.userDetails?.userEmail);
    }

    if (ahcMembersDetails?.userDetails?.userGender) {
      setGender(ahcMembersDetails?.userDetails?.userGender);
    }

    if (ahcMembersDetails?.userDetails?.userDob) {
      setDob(ahcMembersDetails?.userDetails?.userDob);
    }

    if (ahcMembersDetails?.userDetails?.userAddress) {
      setAddress(ahcMembersDetails?.userDetails?.userAddress);
    }

    if (ahcMembersDetails?.userDetails?.userBloodGroup) {
      setBg(ahcMembersDetails?.userDetails?.userBloodGroup);
    }

    if (ahcMembersDetails?.AhcMembershipId) {
      setMemberId(ahcMembersDetails?.AhcMembershipId);
    }

    if (ahcMembersDetails?.AhcPlan?.AhcPlanName) {
      setPlanName(ahcMembersDetails?.AhcPlan?.AhcPlanName);
    }

    if (ahcMembersDetails?.AhcPlan?.AhcPlanCovers) {
      setPalnCovers(ahcMembersDetails?.AhcPlan?.AhcPlanCovers);
    }

    if (ahcMembersDetails?.AhcMembershipActivationDate) {
      setActivateDate(ahcMembersDetails?.AhcMembershipActivationDate);
    }

    if (ahcMembersDetails?.AhcMembershipDuedate) {
      setDueDate(ahcMembersDetails?.AhcMembershipDuedate);
    }

    if (ahcMembersDetails?.AhcMembershipPaidAmount) {
      setAmountPaid(ahcMembersDetails?.AhcMembershipPaidAmount);
    }

    if (ahcMembersDetails?.AhcOfflineConsultationBalance) {
      setOffBal(ahcMembersDetails?.AhcOfflineConsultationBalance);
    }

    if (ahcMembersDetails?.AhcOnlineConsultationBalance) {
      setOnBal(ahcMembersDetails?.AhcOnlineConsultationBalance);
    }

    if (ahcMembersDetails?.AhcMembershipTransactionReference) {
      setTransRef(ahcMembersDetails?.AhcMembershipTransactionReference);
    }

    if (ahcMembersDetails?.FamilyInformation) {
      setData(ahcMembersDetails?.FamilyInformation);
    }
  }, [ahcMembersDetails]);

  useEffect(() => {
    localStorage.setItem("ahcactiveButton", "/ahcadmin/membershipdetails");
  }, []);

  const backbutton = () => {
    navigate("/ahcadmin/membershipdetails");
  };

  const columns = [
    {
      title: "Family Member Name",
      field: "FamilyMemberName",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile",
      field: "FamilyMemberMobileNumber",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "FamilyMemberGender",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Date of Birth",
      field: "FamilyMemberDob",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Relation",
      field: "RelationWithUser",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
  ];

  return (
    <>
      <SidebarAHC />
      <div className="sidebarleft">
        <HeaderAHC />
        <div>
          <form>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  AHC Members
                </span>
                <span>{" / View AHC Member Details "}</span>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">AHC Member Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Full Name:
                    </label>
                    <h4 className="ahc-members-label-h4">{fullname}</h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile:
                    </label>
                    <h4 className="ahc-members-label-h4">{mobile}</h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Email:
                    </label>
                    <h4 className="ahc-members-label-h4">{email}</h4>
                  </div>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="gender">
                      Gender:
                    </label>
                    <h4 className="ahc-members-label-h4">{gender}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="dob">
                      Date of Birth:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {dob ? (
                        moment(dob).format("DD-MM-YYYY")
                      ) : (
                        <em>no info</em>
                      )}
                    </h4>
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="address">
                      Address:
                    </label>
                    <h4 className="ahc-members-label-h4">{address}</h4>
                  </div>
                </div>
                <div className="section2-ahc-members-details1">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="bg">
                      Blood Group:
                    </label>
                    <h4 className="ahc-members-label-h4">{bg}</h4>
                  </div>
                </div>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">AHC Membership Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="memberId">
                      Membership ID:
                    </label>
                    <h4 className="ahc-members-label-h4">{memberId}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="planName">
                      Plan Name:
                    </label>
                    <h4 className="ahc-members-label-h4">{planName}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="planCovers">
                      Plan Covers:
                    </label>
                    <h4 className="ahc-members-label-h4">{planCovers}</h4>
                  </div>
                </div>

                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="activateDate">
                      Activation Date:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {activateDate ? (
                        moment(activateDate).format("DD-MM-YYYY")
                      ) : (
                        <em>no info</em>
                      )}
                    </h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="dueDate">
                      Due Date:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {dueDate ? (
                        moment(dueDate).format("DD-MM-YYYY")
                      ) : (
                        <em>no info</em>
                      )}
                    </h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="amountPaid">
                      Amount Paid:
                    </label>
                    <h4 className="ahc-members-label-h4">{amountPaid}</h4>
                  </div>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="offBal">
                      Offline Consultation Balance:
                    </label>
                    <h4 className="ahc-members-label-h4">{offBal}</h4>
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="onBal">
                      Online Consultation Balance:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {Number(onBal) > 1000 ? "Unlimited" : onBal}
                    </h4>
                  </div>

                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="transRef">
                      Transaction Reference:
                    </label>

                    <h4 className="ahc-members-label-h4">{transRef}</h4>
                  </div>
                </div>
              </div>
              <div className="materialTableClass">
                <MaterialTable
                  tableRef={tableRef}
                  title="Family Members Details"
                  columns={columns}
                  data={data}
                  icons={customIcons}
                  style={{ zIndex: 1, borderRadius: "10px" }}
                  options={{
                    detailPanelColumnAlignment: "right",
                    exportButton: false,
                    sorting: false,
                    pageSizeOptions: false,
                    search: false,
                    searchOnEnter: true,
                    debounceInterval: 500,
                    searchAutoFocus: false,
                    rowStyle: {
                      wordWrap: "break-word",
                    },
                    searchFieldVariant: "outlined",
                    paginationType: false,
                    showFirstLastPageButtons: false,
                    actionsColumnIndex: columns.length,

                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      size: "40px",
                      background: "rgba(0, 0, 0, 0.05)",
                      top: "0",
                    },
                    stickyHeader: true,
                    maxBodyHeight: "50.5vh",
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ViewAHCAdminMembersDetails;
