import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import SidebarLab from "../../../components/sidebarLab";
import HeaderLab from "../../../components/HeaderLab";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import Axios from "axios";
import Swal from "sweetalert2";

function LabTestAdd() {
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategoryName, setSubCategoryName] = useState("");
  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [description01, setDiscrption01] = useState("");
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const verifyToken = localStorage.getItem("token");
  let navigate = useNavigate();
  const url = process.env.REACT_APP_URL;
  const verifyAdminToken = localStorage.getItem("token");
  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validate();

    if (isValid) {
      const subname = subcategoryName;
      const description = description01;
      // console.log(description);
      const requestData = {
        test_name: subname,
        description: description,
      };

      Axios.post(`${url}/insertLabtest`, requestData, {
        headers: {
          Authorization: `Bearer ${verifyAdminToken}`,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.Message === "Lab Test details inserted successfully.") {
            loginSuccess1();
          } else if (res.data.Message) {
            loginErrorCamp();
          }
        })
        .catch((error) => {
          console.error(error);
          if (error?.response?.data?.Message === "test_name should be unique") {
            loginErrorCampDup();
          }
        });
    }
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Added Lab Test Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });
    setSubCategoryName("");
    setDiscrption01("");
  };

  const loginErrorCamp = async (event) => {
    Swal.fire("Error Updating Lab Test!", "", "error");
  };

  const loginErrorCampDup = async (event) => {
    Swal.fire("Lab test name already exists!", "", "error");
  };

  const validate = () => {
    const newErrors = {};

    if (!description01.trim()) {
      newErrors.description = "Description is required";
    }

    if (!subcategoryName.trim()) {
      newErrors.name = "Sub category name is required";
    }

    setNameError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClear = () => {
    setSubCategoryName("");
    setDiscrption01("");
  };
  const backbutton = () => {
    navigate("/LabTests");
  };

  return (
    <>
      <SidebarLab />
      <div className="sidebarleft">
        <HeaderLab />
        <div className="containerAdd02">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Lab Test</u>/ Add New
              </b>
            </header>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="maincontainer">
              <div className="subCatergoryEditinput1">
                <label className="subCategoryLable01">
                  <b> Lab Test Name </b>
                  <div className="redStart">*</div>
                </label>
                <input
                  type="text"
                  className="subCategory01"
                  placeholder=" Lab Test Name "
                  value={subcategoryName}
                  onChange={(e) => setSubCategoryName(e.target.value)}
                />
                {nameError.name && (
                  <div className="error">{nameError.name}</div>
                )}
              </div>
              <div className="subCatergoryEditinput2">
                <label className="subCategoryLable01">
                  <b> Description </b>
                  <div className="redStart">*</div>
                </label>
                <textarea
                  className="productdetials001"
                  value={description01}
                  placeholder="Description"
                  onChange={(e) => setDiscrption01(e.target.value)}
                />
                {nameError.description && (
                  <div className="error">{nameError.description}</div>
                )}
              </div>
            </div>
            <br />

            <button
              type="button"
              onClick={handleClear}
              className="clearbuttonSubCategoryEdit01"
            >
              Clear
            </button>
            <button type="submit" className="SubmitbuttonSubCategoryEdit01">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default LabTestAdd;
