import React, { useState } from "react";
import "../../../../doctor_profile/css/allAppointments.css";
import "../../../../css/doctor.css";
import AllMyFollowUps from "./allmyfollowups";
function MyFollowUps() {
  const [activeButton, setActiveButton] = useState("allfollowups");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const renderContent = () => {
    if (activeButton === "allfollowups") {
      return <AllMyFollowUps />;
    }
  };

  return (
    <div className="main_appointments">
      <div className="submenu_telecaller">
        <button
          className={`btn-all-appointment ${
            activeButton === "allfollowups" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("allfollowups")}
        >
          <span>All Followups</span>
        </button>
      </div>
      <div className="line_doc"></div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default MyFollowUps;
