import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import Axios from "axios";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../../../css/bb-popup.css";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function PopUpDialog({ openPopup, setOpenPopUp, data }) {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  let navigate = useNavigate();
  // console.log(data?.id, "dataaaaaaaaaaaaaaaaa");
  const [address, setAddress] = useState("");
  const [long, setLongitude] = useState("");
  const [id, setId] = useState("");
  let did = data?.id;
  // console.log(did, "didddddd");
  const [lat, setLatitude] = useState("");
  const [pincode, setPincode] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [stateList, setStateList] = useState({});
  const [districtList, setDistrictList] = useState({});
  const [cityList, setCityList] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  useEffect(() => {
    if (data?.name) {
      setName(data?.name);
    }
    if (data?.mobile) {
      setMobile(data?.mobile);
    }
    if (data?.state) {
      setState(data?.state);
    }
    if (data?.district) {
      setDistrict(data?.district);
    }
    if (data?.city) {
      setCity(data?.city);
    }
    if (data?.address) {
      setAddress(data?.address);
    }
    if (data?.pincode) {
      setPincode(data?.pincode);
    }
    if (data?.lat) {
      setLatitude(data?.lat);
    }
    if (data?.long) {
      setLongitude(data?.long);
    }
    if (data?.id) {
      setId(data?.iid);
    }
  }, [data]);

  const validateForm = () => {
    let errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!mobile) {
      errors.mobile = "Mobile is required";
    }
    if (!state) {
      errors.state = "State is required";
    }
    if (!district) {
      errors.district = "District is required";
    }
    if (!city) {
      errors.city = "City is required";
    }
    if (!address) {
      errors.address = "Address is required";
    }
    if (!pincode) {
      errors.pincode = "Pincode is required";
    } else if (!/^\d{6}$/.test(pincode)) {
      errors.pincode = "Pincode must be 6 digits";
    }
    if (!lat) {
      errors.lat = "Latitude is required";
    } else if (!/^-?\d{1,3}\.\d+$/.test(lat)) {
      errors.lat =
        "Latitude must be a valid number with up to 3 decimal places";
    }
    if (!long) {
      errors.long = "Longitude is required";
    } else if (!/^-?\d{1,3}\.\d+$/.test(long)) {
      errors.long =
        "Longitude must be a valid number with up to 3 decimal places";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // console.log(name, address, lat, long, mobile, city, pincode, did);
      Axios.post(`${url}/updatebloodbank`, {
        name: name,
        address: address,
        mobile: mobile,
        city: city,
        pincode: pincode,
        lat: lat,
        long: long,
        id: did,
      }).then((res) => {
        // console.log(res, "loggggggggggggggggggg");
        if (res.data.Message === "Successfully Updated") {
          loginSuccess1();
        }
      });
    }
  };
  const handleClose = () => {
    setOpenPopUp(false);
    setFormErrors({});
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Blood Bank Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000, // set the duration in milliseconds
    });
    // navigate("/AllBloodbank");
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  // using the below useEffect to trigger the dropdowns accoring to state, district and city
  useEffect(() => {
    Axios.get(`${url}/statelist`).then((response) => {
      const newList = {};
      response.data?.states?.forEach(
        (item) => (newList[item.id] = item.statename)
      );
      setStateList(newList);
    });
    var stateid = state;
    var districtid = district;
    if (stateid) {
      Axios.get(`${url}/districtsbystate/${stateid}`).then((response) => {
        const distList = {};
        // console.log(response.data.districts,"from backend distric")
        response.data?.result?.forEach(
          (item) => (distList[item.id] = item.districtname)
        );
        // console.log("district data", distList, response.data);
        setDistrictList(distList);
      });
    }
    if (districtid) {
      Axios.get(`${url}/citiesbydistrict/${districtid}`).then((response) => {
        const cityyList = {};
        response.data?.result?.forEach(
          (item) => (cityyList[item.id] = item.cityname)
        );
        // console.log("city dta", cityyList, response.data.cities);
        setCityList(cityyList);
      });
    }
  }, [state, district]);

  const handleStateChange = (e) => {
    setState(e.target.value);
    setCityList({});
    setDistrictList({});
    setDistrict("");
    setCity("");
  };

  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
    setCity("");
    setCityList({});
  };
  return (
    <>
      {openPopup && (
        <div>
          {openPopup && (
            <div>
              {" "}
              {openPopup && (
                <div>
                  {" "}
                  <Dialog onClose={handleClose} open={openPopup}>
                    <DialogTitle dividers>
                      View Blood Bank
                      <div className="closeIcon" onClick={handleClose}>
                        <HighlightOffOutlinedIcon />
                      </div>
                    </DialogTitle>{" "}
                    <DialogContent dividers color="#007196">
                      <p className="uinfo">Blood Bank Information</p>
                    </DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="first-form">
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="name">
                              Full Name <span className="redstar"> *</span>
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control-input1"
                              name="name"
                              value={name}
                              onChange={(event) => {
                                setName(event.target.value);
                              }}
                            />
                            {formErrors.name && (
                              <div className="error">{formErrors.name}</div>
                            )}
                          </div>
                          <div className="form-state">
                            <label className="formLabeldonor" htmlFor="mobile">
                              Mobile/ Landline Number{" "}
                              <span className="redstar"> *</span>
                            </label>
                            <input
                              id="mobile"
                              className="form-control-input1"
                              type="text"
                              name="mobile"
                              value={mobile}
                              onChange={(event) => {
                                const inputValue = event.target.value;
                                const numericValue = inputValue.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setMobile(numericValue);
                              }}
                            />
                            {formErrors.mobile && (
                              <div className="error">{formErrors.mobile}</div>
                            )}
                          </div>
                        </div>
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="state">
                              State <span className="redstar"> *</span>
                            </label>
                            <select
                              id="state"
                              className="form-control-input1"
                              value={state}
                              onChange={handleStateChange}
                            >
                              <option value="">Select State</option>
                              {Object.keys(stateList).map((id) => (
                                <option value={id} key={id}>
                                  {stateList[id]}
                                </option>
                              ))}
                            </select>
                            {/* {formErrors.state && (
                          <div className="error"> {formErrors.state}</div>
                        )} */}
                            {formErrors.state && (
                              <div className="error">{formErrors.state}</div>
                            )}
                          </div>
                          <div className="form-state">
                            <label
                              className="formLabeldonor"
                              htmlFor="district"
                            >
                              District <span className="redstar"> *</span>
                            </label>
                            <select
                              id="district"
                              className="form-control-input1"
                              value={district}
                              onChange={handleDistrictChange}
                            >
                              <option value="">Select district</option>
                              {Object.keys(districtList).map((id) => (
                                <option value={id} key={id}>
                                  {districtList[id]}{" "}
                                </option>
                              ))}
                              {/* Add more options for all available districts */}
                            </select>
                            {formErrors.district && (
                              <div className="error">{formErrors.district}</div>
                            )}
                          </div>
                        </div>
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="city">
                              City <span className="redstar"> *</span>
                            </label>
                            <select
                              id="city"
                              className="form-control-input1"
                              value={city}
                              onChange={(event) => setCity(event.target.value)}
                            >
                              <option value="">Select city</option>

                              {Object.keys(cityList).map((id) => (
                                <option value={id} key={id}>
                                  {cityList[id]}{" "}
                                </option>
                              ))}
                              {/* Add more options for all available cities */}
                            </select>
                            {formErrors.city && (
                              <div className="error">{formErrors.city}</div>
                            )}
                          </div>
                          <div className="form-state">
                            <label className="formLabeldonor" htmlFor="address">
                              Address <span className="redstar"> *</span>
                            </label>
                            <input
                              id="address"
                              type="text"
                              className="form-control-input1"
                              name="address"
                              value={address}
                              onChange={(event) =>
                                setAddress(event.target.value)
                              }
                            />
                            {formErrors.address && (
                              <div className="error">{formErrors.address}</div>
                            )}
                          </div>
                        </div>
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="pincode">
                              Pincode <span className="redstar"> *</span>
                            </label>
                            <input
                              id="pincode"
                              type="text"
                              className="form-control-input1"
                              name="pincode"
                              value={pincode}
                              onChange={(event) =>
                                setPincode(event.target.value)
                              }
                            />
                            {formErrors.pincode && (
                              <div className="error">{formErrors.pincode}</div>
                            )}
                          </div>
                          <div className="form-state">
                            <label className="formLabeldonor" htmlFor="lat">
                              Latitude <span className="redstar"> *</span>
                            </label>
                            <input
                              id="lat"
                              type="text"
                              className="form-control-input1"
                              name="lat"
                              value={lat}
                              onChange={(event) =>
                                setLatitude(event.target.value)
                              }
                            />
                            {formErrors.lat && (
                              <div className="error">{formErrors.lat}</div>
                            )}
                          </div>
                        </div>
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="long">
                              Longitude <span className="redstar"> *</span>
                            </label>
                            <input
                              id="long"
                              type="text"
                              className="form-control-input1"
                              name="long"
                              value={long}
                              onChange={(event) =>
                                setLongitude(event.target.value)
                              }
                            />
                            {formErrors.long && (
                              <div className="error">{formErrors.long}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="buttonSec">
                        <div className="cancle">
                          <button
                            className="cancle1"
                            type="button"
                            onClick={handleSubmit}
                          >
                            Update
                          </button>
                        </div>
                        <div className="submit">
                          <button
                            className="submit1"
                            type="cancel"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog>{" "}
                </div>
              )}{" "}
            </div>
          )}{" "}
        </div>
      )}
    </>
  );
}
