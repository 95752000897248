// import TelecallerHeader from "../components/tc-header";
import Header2 from "../Header2";
import docimg from "../../../images/support_7363068.png";
import "../../../css/doctor.css";
import Swal from "sweetalert2";
import React, { useEffect, useState, useContext } from "react";
import MaterialTable from "material-table";
import "../../../css/bloodreqlist.css";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import PopupDashboard from "../popupDashboard";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Navigate } from "react-router-dom";
import NotificationContext from "../../../pages/context/NotificationContext";
import moment from "moment";
import Axios from "axios";
// import Paginate from "../../notification/paginate";
import { useCallback } from "react";
// import PopUpNotification from "./popupnotification";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const DoctorNotifications = () => {
  const { count, setCount } = useContext(NotificationContext);
  const doctorToken = localStorage.getItem("doctor_token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [selectedRow, setSelectedRow] = useState(null);
  const [totalCou, setTotalCou] = useState([]);
  const tableRef = React.useRef();
  const navigate = useNavigate();

  const verifyDoctorToken = localStorage.getItem("doctor_token");
  const url = process.env.REACT_APP_URL;

  const [name, setName] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [email, setEmail] = useState("");
  const [aboutdoctor, setAboutdoctor] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [address, setAddress] = useState("");
  const [specializations, setSpecializations] = useState("");
  const [doc_id, setDoc_Id] = useState("");

  const [openPopUp, setOpenPopUp] = useState(false);

  const editProfile = () => {
    setOpenPopUp(true);
  };
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  useEffect(() => {
    fetchData1();
    removeNotificationCount();
  }, []);

  const fetchData1 = () => {
    // var page = page;
    Axios.get(`${url}/appointmentNotifications`, {
      headers: {
        Authorization: `Bearer ${doctorToken}`,
      },
    }).then((response) => {
      // console.log(response);
      // console.log(response.data.result);
      if (response.data) {
        setData(response.data.result);
      }
    });
  };



  const removeNotificationCount = async () => {
    var idDoctor = localStorage.getItem("doctor_id");
    // console.log(idDoctor);
    const doctor_id = parseInt(idDoctor, 10);

    // console.log(doctor_id, "remove");

    try {
      await Axios.put(`${url}/updateDoctorRequestStatus`, {
        doctor_id,
      }).then((response) => {
        if (
          response.data.Message ===
          "Doctor Request Status Updated Successfully."
        ) {
          setCount(0);
        }
      });
    } catch (error) {}
  };

  const columns = [
    {
      title: "Patient ID",
      field: "user_id",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Name",
      field: "user_fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "user_mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "user_gender",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Consultation Mode",
      field: "treatment_mode",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Reason for Consultation",
      field: "reason_for_appointment",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Date",
      field: "appointment_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_date) {
          const formattedDate = moment(rowData.appointment_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Slot",
      field: "time_slot",
      emptyValue: () => <em>no info</em>,
    },
    // {
    //   title: "Price",
    //   field: "consultation_fee",
    //   emptyValue: () => <em>no info</em>,
    //   render: (rowData) => `₹${rowData?.consultation_fee}`,
    // },
    {
      title: "Payment Method",
      field: "payment_mode",
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await Axios.get(`${url}/getdoctorDetailswithToken`, {
        headers: {
          Authorization: `Bearer ${verifyDoctorToken}`,
        },
      }).then((response) => {
        setName(response.data.result.name);
        setExperience(response.data.result.experience);
        setQualification(response.data.result.qualification);
        setEmail(response.data.result.email);
        setAboutdoctor(response.data.result.about_doctor);
        setPhone_no(response.data.result.phone_no);
        setAddress(response.data.result.address);
        setSpecializations(response.data.result.specializations);
        setDoc_Id(response.data.result.doctor_id);
      });
    } catch (error) {}
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);

  const backbutton = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <div className="main">
        <Header2 />

        <div className="profile_doc">
          <div className="image-container">
            <div
              className="circle-image"
              style={{ backgroundImage: `url(${docimg})` }}
            ></div>
          </div>
          <div className="name_doc">
            <div className="text_arrange">
              <div className="welcome_text">Welcome , Dr. {name}</div>
              <div className="drProfile">
                <div className="key_text">
                  <span className="bullet">&#8226;</span>
                  {specializations}
                </div>
                <div className="key_text">
                  <span className="bullet">&#8226;</span>
                  {qualification}
                </div>
                <div className="key_text">
                  <span className="bullet">&#8226;</span>
                  {experience}
                </div>
              </div>
              <div className="drProfile">
                <div className="key_text">
                  <strong>Contact Info : </strong> {phone_no} / {email}
                </div>
              </div>

              <div
                className="body_text"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {aboutdoctor}
              </div>
            </div>
            <div className="editProfile">
              <button className="edit_doc_button" onClick={editProfile}>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
        <div className="notiMain">
          <span className="dashNoti" onClick={backbutton}>
            <p>
              <ArrowLeftIcon />
            </p>
            Dashboard
          </span>
          <br />
          <div className="container2">
            <MaterialTable
              tableRef={tableRef}
              title="Notification"
              columns={columns}
              data={data}
              icons={customIcons}
              style={{ zIndex: 1, borderRadius: "4px" }}
              onRowClick={(event, rowData) => {
                // navigate("/ListRequesties", {
                //   state: {
                //     data: rowData,
                //   },
                // });
              }}
              options={{
                detailPanelColumnAlignment: "right",
                exportButton: false,
                exportAllData: false,
                sorting: true,
                search: false,
                searchOnEnter: true,
                debounceInterval: 500,
                searchAutoFocus: false,
                searchFieldVariant: "outlined",
                actionsColumnIndex: columns.length,
                stickyHeader: true,
                pageSize: 50,
                pageSizeOptions: [20, 30, 50],
                // paginationType: "stepped",
                emptyRowsWhenPaging: false,
                headerStyle: {
                  size: "40px",
                  background: "rgba(0, 0, 0, 0.05)",
                  position: "sticky",
                  top: "0",
                },
                stickyHeader: true,
                maxBodyHeight: "76.5vh",
              }}
              //   components={{
              //     Pagination: (props) => (
              //       <Paginate
              //         nPages={nPages}
              //         currentPage={currentPage}
              //         setCurrentPage={setCurrentPage}
              //       />
              //     ),
              //   }}
            />
            {/* <PopUpNotification
            openPopup={openPopUp}
            setOpenPopUp={setOpenPopUp}
            allBloodReqData={selectedRow}
          /> */}
          </div>
        </div>
      </div>
      {openPopUp && (
        <PopupDashboard openPopup={openPopUp} setOpenPopUp={setOpenPopUp} />
      )}
    </>
  );
};

export default DoctorNotifications;
