import React, { useState, useEffect } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "../../../css/BR-modal.css";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";

export default function ViewRejBloodReq({
  openPopup,
  setOpenPopUp,
  allBloodReqData,
}) {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  // setLoggedIn(true);
  const handleClose = () => {
    setOpenPopUp(false);
  };
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  return (
    <>
      {" "}
      {openPopup && (
        <div>
          {" "}
          <Dialog
            onClose={handleClose}
            open={openPopup}
            className="viewDialogBR"
          >
            <DialogTitle dividers>
              View Requests
              <div className="closeIcon" onClick={handleClose}>
                <HighlightOffOutlinedIcon />
              </div>
            </DialogTitle>{" "}
            <DialogContent dividers color="#007196">
              <p className="uinfo">Request Information</p>
            </DialogContent>
            <form>
              <div className="first-form">
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="ipnumber">
                      IP Number
                    </label>
                    <input
                      id="ipnumber"
                      type="text"
                      className="form-control-input"
                      name="ipnumber"
                      value={allBloodReqData.ipnumber}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="hospitalname">
                      Hospital Name
                    </label>
                    <input
                      id="hospitalname"
                      className="form-control-input"
                      type="text"
                      name="hospitalname"
                      value={allBloodReqData.hospitalname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="state">
                      State
                    </label>
                    <input
                      id="state"
                      className="form-control-input"
                      type="text"
                      name="state"
                      value={allBloodReqData.tblstate?.statename}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="district">
                      District
                    </label>
                    <input
                      id="district"
                      type="text"
                      className="form-control-input"
                      name="district"
                      value={allBloodReqData.tbldistrict?.districtname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="city">
                      City
                    </label>
                    <input
                      id="city"
                      className="form-control-input"
                      type="text"
                      name="city"
                      value={allBloodReqData.tblcity?.cityname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="bloodgroup">
                      Blood Group
                    </label>
                    <input
                      id="bloodgroup"
                      className="form-control-input"
                      type="text"
                      name="bloodgroup"
                      value={allBloodReqData.bloodgroup}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="bloodparticulars"
                    >
                      Blood Particulars
                    </label>
                    <input
                      id="bloodparticulars"
                      type="text"
                      className="form-control-input"
                      name="bloodparticulars"
                      value={allBloodReqData.bloodparticulars}
                    />
                  </div>
                  {/* <div className="form-state">
                    <label className="formLabeldonor" htmlFor="units">
                      Units
                    </label>
                    <input
                      id="units"
                      className="form-control-input"
                      type="number"
                      name="units"
                      value={allBloodReqData.units}
                    />
                  </div> */}
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="approved">
                      Approved
                    </label>
                    <input
                      id="approved"
                      className="form-control-input"
                      type="text"
                      name="approved"
                      value={allBloodReqData.approved}
                    />
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="orderedby">
                      Ordered By
                    </label>
                    <input
                      id="orderedby"
                      type="text"
                      className="form-control-input"
                      name="orderedby"
                      value={allBloodReqData.orderedby}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="doctorname">
                      Doctor Name
                    </label>
                    <input
                      id="doctorname"
                      className="form-control-input"
                      type="text"
                      name="doctorname"
                      value={allBloodReqData.doctorname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="phone">
                      Mobile Number
                    </label>
                    <input
                      id="phone"
                      className="form-control-input"
                      type="text"
                      name="phone"
                      value={allBloodReqData.phone}
                    />
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="patientname">
                      Patient Name
                    </label>
                    <input
                      id="patientname"
                      type="text"
                      className="form-control-input"
                      name="patientname"
                      value={allBloodReqData.patientname}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="gender">
                      Gender
                    </label>
                    <input
                      id="gender"
                      className="form-control-input"
                      type="text"
                      name="gender"
                      value={allBloodReqData.gender}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="age">
                      Age
                    </label>
                    <input
                      id="age"
                      className="form-control-input"
                      type="text"
                      name="age"
                      value={allBloodReqData.age}
                    />
                  </div>
                </div>
              </div>
              <div className="buttonSec11">
                <div className="approvedddd">
                  <button type="submit" className="approveBR">
                    Approve
                  </button>
                </div>
              </div>
            </form>
          </Dialog>{" "}
        </div>
      )}{" "}
    </>
  );
}
