import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import Header from "../../../components/Header";
import Sidebars from "../../../components/Sidebar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import uploadImage from "../../../images/Vector.png";
import downloadImage from "../../../images/Vector (3).png";
import downloadImage1 from "../../../images/Vector (4).png";

import "./Form.css";
import BBAllBloodBank from "./bb-allBloodBank";

function BBUpdateBloodGroup() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [membershipId, setMembershipId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [altMobile, setAltMobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [nomineeName, setNomineeName] = useState("");
  const [nomineeAge, setNomineeAge] = useState("");
  const [ice1, setIce1] = useState("");
  const [ice2, setIce2] = useState("");
  const [gender, setGender] = useState("");
  const [occupation, setOccupation] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [relationship, setRelationship] = useState("");
  const [bloodDonationDate, setBloodDonationDate] = useState("");
  const [bloodDonationVenue, setBloodDonationVenue] = useState("");
  const [bloodDonationBefore, setBloodDonationBefore] = useState(false);
  const [knownAllergy, setKnownAllergy] = useState(false);
  const [allergy, setAllergy] = useState("no");
  const [allergyType, setAllergyType] = useState("");

  const [donationDate, setDonationDate] = useState("");
  const [donationVenue, setDonationVenue] = useState("");
  const [donationHistory, setDonationHistory] = useState("");
  // const [allergy, setAllergy] = useState('')

  const handleAllergyChange = (event) => {
    setAllergy(event.target.value);
    if (event.target.value === "no") {
      setAllergyType("");
    }
  };

  // setLoggedIn(true);
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  const handleAllergyTypeChange = (event) => {
    setAllergyType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission logic
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCancel = () => {
    setSelectedFile(null);
  };

  const handleUpload = () => {
    // Perform upload logic using `selectedFile`
    //.log("Selected file:", selectedFile);
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />

        <div>
          <form onSubmit={handleSubmit}>
            <div className="upload-container">
              <div className="budt">
                <a className="bulk-upload">Bulk Upload</a>

                <a
                  href="/template.xlsx"
                  download="template.xlsx"
                  className="downloadTemplate"
                >
                  <img className="imagev" src={downloadImage} /> Download
                  template
                </a>
              </div>
              <div className="form-upload">
                <div className="excelupload">
                  <label htmlFor="file-upload" className="label-drag">
                    <img className="imagevv" src={downloadImage1} />{" "}
                    <p>Drag and Drop here or</p>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".xls, .xlsx, .csv"
                    onChange={handleFileSelect}
                  />
                </div>
              </div>
              <div className="text">
                <div>
                  <p className="filesupported">
                    File Supported: CSV, Excel Sheet
                  </p>
                </div>
                <div>
                  <p className="maximumsize" style={{ textDecoration: "none" }}>
                    Maximun Size: 2MB
                  </p>
                </div>
              </div>
              <div className="btn-upload">
                <div>
                  <button
                    className="cancle-btn"
                    type="button"
                    onClick={handleUpload}
                  >
                    <img src={uploadImage} /> Upload
                  </button>
                </div>
                <div>
                  <button
                    className="upload-btn"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancle
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default BBUpdateBloodGroup;
