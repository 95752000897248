import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import "../../css/adddonor.css";
import Sidebars from "../Sidebar";
function AppConfigaration() {
  let navigate = useNavigate();
  const verifyToken = localStorage.getItem("token");

  const [app_name, setApp_name] = useState("");
  const [app_logo, setApp_logo] = useState("");
  const [app_address, setApp_address] = useState("");
  const [app_phone, setApp_phone] = useState("");
  const [app_email, setApp_email] = useState("");
  const [minimum_order_value, setMinimum_order_value] = useState("");
  const [delivery_charge, setDelivery_charge] = useState("");
  const [currency_symbol, setCurrency_symbol] = useState("");
  const [cash_on_delivery, setCash_on_delivery] = useState("");
  const [country, setCountry] = useState("");
  const [digital_payment, setDigital_payment] = useState("");

  const [decimal_point_settings, setDecimal_point_settings] = useState("");
  const [saveyo_master_otp, setSaveYoMasterOTP] = useState("");
  const [currency_symbol_position, setCurrency_symbol_position] = useState("");
  const [maintenance_mode, setMaintenance_mode] = useState("");
  const [master_otp, setMaster_otp] = useState("");
  const [app_version, setApp_version] = useState("");
  const [razorpay_api_key, setRazorpay_api_key] = useState("");

  const [saveyo_number, setSaveyo_number] = useState("");

  const [playstore, setPlaystore] = useState({});
  const [appstore, setAppstore] = useState({});
  const [configurl, setConfigurl] = useState({});

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    localStorage.setItem("role", "admin");
    getConfig();
  }, []);

  const getConfig = () => {
    Axios.get(`${url}/getConfig/`).then((response) => {
      console.log(response, "config");
      if (response?.data) {
        setApp_name(response?.data?.app_name);
        setApp_logo(response?.data?.app_logo);
        setApp_address(response?.data?.app_address);
        setApp_phone(response?.data?.app_phone);
        setApp_email(response?.data?.app_email);
        setMinimum_order_value(response?.data?.minimum_order_value);
        setDelivery_charge(response?.data?.delivery_charge);
        setCurrency_symbol(response?.data?.currency_symbol);
        setCash_on_delivery(response?.data?.cash_on_delivery);
        setCountry(response?.data?.country);
        setDigital_payment(response?.data?.digital_payment);
        setDecimal_point_settings(response?.data?.decimal_point_settings);
        setSaveYoMasterOTP(response?.data?.saveyo_master_otp);
        setCurrency_symbol_position(response?.data?.currency_symbol_position);
        setMaintenance_mode(response?.data?.maintenance_mode);
        setMaster_otp(response?.data?.master_otp);
        setApp_version(response?.data?.app_version);
        setRazorpay_api_key(response?.data?.Razorpay_api_key);
        setSaveyo_number(response?.data?.saveyo_number);
        setPlaystore(response?.data?.play_store_config);
        setAppstore(response?.data?.app_store_config);
        setConfigurl(response?.data?.base_urls);
      }
    });
  };

  const handleInputChangeForAppStore = (key, newValue) => {
    setAppstore({
      ...appstore,
      [key]: newValue,
    });
  };

  const handleInputChangeForPlayStore = (key, newValue) => {
    setPlaystore({
      ...playstore,
      [key]: newValue,
    });
  };

  const handleInputChangeForUrl = (key, newValue) => {
    setConfigurl({
      ...configurl,
      [key]: newValue,
    });
  };

  // console.log(configurl, "plls");

  const handleSubmit = (event) => {
    event.preventDefault();

    const configToken = {
      headers: {
        Authorization: `Bearer ${verifyToken}`,
      },
    };

    const numericId = parseInt(saveyo_master_otp, 10);

    const updates = [
      { key: "app_name", value: app_name },

      { key: "app_logo", value: app_logo },
      { key: "app_address", value: app_address },
      { key: "app_phone", value: app_phone },
      { key: "app_email", value: app_email },
      { key: "minimum_order_value", value: minimum_order_value },
      { key: "delivery_charge", value: delivery_charge },
      { key: "currency_symbol", value: currency_symbol },
      { key: "cash_on_delivery", value: cash_on_delivery },
      { key: "country", value: country },
      { key: "digital_payment", value: digital_payment },
      { key: "decimal_point_settings", value: decimal_point_settings },
      { key: "saveyo_master_otp", value: numericId },
      { key: "currency_symbol_position", value: currency_symbol_position },
      { key: "maintenance_mode", value: maintenance_mode },
      { key: "master_otp", value: master_otp },
      { key: "app_version", value: app_version },
      { key: "Razorpay_api_key", value: razorpay_api_key },

      { key: "saveyo_number", value: saveyo_number },
      { key: "app_store_config", value: appstore },
      { key: "play_store_config", value: playstore },
      { key: "base_urls", value: configurl },
    ];

    Axios.put(`${url}/updateConfig`, { updates }, configToken).then(
      (response) => {
        if (response.data.result === "Successfully updated") {
          updateSuccess();
        }
      }
    );
  };

  const updateSuccess = async (event) => {
    Swal.fire({
      title: "App Configuration updated successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="back-button1">
                <span>{" Configuration "}</span>
              </div>
              <br />
              <div className="first-form">
                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="app_name">
                      App Name
                    </label>
                    <input
                      id="app_name"
                      type="text"
                      className="input-row-2"
                      value={app_name}
                      onChange={(event) => setApp_name(event.target.value)}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="app_logo">
                      App Logo
                    </label>
                    <input
                      id="app_logo"
                      className="input-row-2"
                      type="app_logo"
                      value={app_logo}
                      onChange={(event) => setApp_logo(event.target.value)}
                    />
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="app_address">
                      App Address
                    </label>
                    <input
                      id="app_address"
                      className="input-row-2"
                      type="tel"
                      value={app_address}
                      onChange={(event) => setApp_address(event.target.value)}
                    />
                  </div>
                </div>

                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="app_phone">
                      App Phone
                    </label>
                    <input
                      id="app_phone"
                      type="text"
                      className="input-row-2"
                      value={app_phone}
                      onChange={(event) => setApp_phone(event.target.value)}
                    />
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="app_email">
                      App Email
                    </label>
                    <input
                      id="app_email"
                      type="text"
                      className="input-row-2"
                      value={app_email}
                      onChange={(event) => setApp_email(event.target.value)}
                    />
                  </div>
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="minimum_order_value"
                    >
                      Minimum Order Value
                    </label>
                    <input
                      id="minimum_order_value"
                      className="input-row-2"
                      type="text"
                      value={minimum_order_value}
                      onChange={(event) =>
                        setMinimum_order_value(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="delivery_charge">
                      Delivery Charge
                    </label>
                    <input
                      id="delivery_charge"
                      className="input-row-2"
                      type="text"
                      value={delivery_charge}
                      onChange={(event) =>
                        setDelivery_charge(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="currency_symbol">
                      Currency Symbol
                    </label>
                    <input
                      id="currency_symbol"
                      className="input-row-2"
                      type="text"
                      value={currency_symbol}
                      onChange={(event) =>
                        setCurrency_symbol(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="cash_on_delivery"
                    >
                      Cash On Delivery
                    </label>
                    <input
                      id="cash_on_delivery"
                      className="input-row-2"
                      type=""
                      value={cash_on_delivery}
                      onChange={(event) =>
                        setCash_on_delivery(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="country">
                      Country
                    </label>
                    <input
                      id="country"
                      type="text"
                      className="input-row-2"
                      value={country}
                      onChange={(event) => setCountry(event.target.value)}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="digital_payment">
                      Digital Payment
                    </label>
                    <input
                      id="digital_payment"
                      className="input-row-2"
                      type="digital_payment"
                      value={digital_payment}
                      onChange={(event) =>
                        setDigital_payment(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-city">
                    <label
                      className="formLabeldonor"
                      htmlFor="decimal_point_settings"
                    >
                      Decimal Point Settings
                    </label>
                    <input
                      id="decimal_point_settings"
                      className="input-row-2"
                      type="tel"
                      value={decimal_point_settings}
                      onChange={(event) =>
                        setDecimal_point_settings(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="section2">
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="saveyo_master_otp"
                    >
                      SaveYo Master OTP
                    </label>
                    <input
                      id="saveyo_master_otp"
                      type="text"
                      className="input-row-2"
                      value={saveyo_master_otp}
                      onChange={(event) =>
                        setSaveYoMasterOTP(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-email">
                    <label
                      className="formLabeldonor"
                      htmlFor="currency_symbol_position"
                    >
                      Currency Symbol Position
                    </label>
                    <input
                      id="currency_symbol_position"
                      type="text"
                      className="input-row-2"
                      value={currency_symbol_position}
                      onChange={(event) =>
                        setCurrency_symbol_position(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="maintenance_mode"
                    >
                      Maintenance Mode
                    </label>
                    <input
                      id="maintenance_mode"
                      className="input-row-2"
                      type="text"
                      value={maintenance_mode}
                      onChange={(event) =>
                        setMaintenance_mode(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="master_otp">
                      Master Otp
                    </label>
                    <input
                      id="master_otp"
                      className="input-row-2"
                      type="text"
                      value={master_otp}
                      onChange={(event) => setMaster_otp(event.target.value)}
                    />
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="app_version">
                      App Version
                    </label>
                    <input
                      id="app_version"
                      className="input-row-2"
                      type="text"
                      value={app_version}
                      onChange={(event) => setApp_version(event.target.value)}
                    />
                  </div>
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="razorpay_api_key"
                    >
                      Razorpay Api Key
                    </label>
                    <input
                      id="razorpay_api_key"
                      className="input-row-2"
                      type="text"
                      value={razorpay_api_key}
                      onChange={(event) =>
                        setRazorpay_api_key(event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="saveyo_number">
                      SaveYo Number
                    </label>
                    <input
                      id="saveyo_number"
                      className="input-row-2"
                      maxLength="10"
                      type="text"
                      value={saveyo_number}
                      // onChange={(event) => setSaveyo_number(event.target.value)}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setSaveyo_number(numericValue);
                      }}
                    />
                  </div>
                  <div className="formborder">
                    <label style={{ color: "#007196" }}>App Store Config</label>
                    <div className="form-dob-top">
                      {Object.entries(appstore).map(([key, value]) => (
                        <div className="row" key={key}>
                          <label
                            className="formLabeldonor"
                            htmlFor="master_otp"
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            className="input-row-2"
                            value={value}
                            onChange={(e) =>
                              handleInputChangeForAppStore(key, e.target.value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="formborder">
                    <label style={{ color: "#007196" }}>
                      Play Store Config
                    </label>
                    <div className="form-dob-top">
                      {Object.entries(playstore).map(([key, value]) => (
                        <div className="row" key={key}>
                          <label
                            className="formLabeldonor"
                            htmlFor="master_otp"
                          >
                            {key}
                          </label>
                          <input
                            type="text"
                            className="input-row-2"
                            value={value}
                            onChange={(e) =>
                              handleInputChangeForPlayStore(key, e.target.value)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="section11">
                  <div className="cancle">
                    <button
                      type="cancel"
                      className="cancle1"
                      onClick={() => navigate("/users-list")}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="submit">
                    <button type="submit" className="submit1" onSubmit>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default AppConfigaration;
