import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import "../../../css/labmodule.css";
import SidebarLab from "../../../components/sidebarLab";
import HeaderLab from "../../../components/HeaderLab";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import moment from "moment";
import Axios from "axios";
import Swal from "sweetalert2";
import Page_Bb from "../../services_component/blood_bank/Page_bb";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Switch from "@mui/material/Switch";
import { FormControl, IconButton } from "@material-ui/core";
import NextPlanIcon from "@mui/icons-material/NextPlan";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function LabBookings() {
  let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const tableRef1 = React.useRef();

  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [statusFilter, setStatusFilter] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [paymentStatus, setPaymentStatus] = useState("");

  const debouncedSearch = useCallback(
    debounce((search) => {
      fetchSearchResults(search);
    }, 500),
    []
  );

  const applyFilters = useMemo(() => {
    const filteredData = data?.filter((item) => {
      //let isStatusMatch = true;
      const endDateValue = moment(item.booking_date).format("DD-MM-YYYY");
      // console.log("createdAt ", endDateValue);
      if (
        statusFilter &&
        item.booking_status.toLowerCase() !== statusFilter.toLowerCase()
      ) {
        return false;
      }
      if (
        searchText &&
        item.labtest_booking_Id.toString() !== searchText.toString()
      ) {
        return false;
      }
      if (
        paymentStatus &&
        item.payment_status.toLowerCase() !== paymentStatus.toLowerCase()
      ) {
        return false;
      }
      if (
        startDate &&
        endDate &&
        endDateValue > startDate &&
        endDateValue < endDate
      ) {
        // console.log("startDate ", startDate, endDate, item.booking_date);
        return true;
      }
      if (
        startDate.toString().includes("GMT") ||
        endDate.toString().includes("GMT")
      ) {
        // console.log("else date");
        return true;
      }
      //return true;
    });

    return filteredData;
  }, [data, statusFilter, searchText, startDate, endDate, paymentStatus]);

  const handleSearchLabID = (event) => {
    setSearchText(event.target.value);
  };

  const handleStartDateChange = (event) => {
    //const parsedDate = new Date(event.target.value);
    setStartDate(event.target.value);
    // console.log("handleStartDateChange ", startDate, event.target.value);
  };

  const handleEndDateChange = (event) => {
    //const parsedDate = new Date(event.target.value);
    setEndDate(event.target.value);
    // console.log("handleEndDateChange ", endDate, event.target.value);
  };

  const eurl = "https://suitsdata.blob.core.windows.net";
  const prod_name = "category";

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const handleStatusToggle = (rowData) => {
    const id = rowData.id;
    const status = rowData.status;
    let booleanStatus;

    if (status === 1) {
      booleanStatus = false;
    } else if (status === 0) {
      booleanStatus = true;
    }
    // console.log(id, booleanStatus);
    Axios.put(`${url}/updateCategoryStatus`, {
      id: id,
      status: booleanStatus,
    })
      .then(() => {
        // Reload window here
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "lab");
  }, []);

  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const columns = [
    {
      title: "Booking ID",
      field: "labtest_booking_Id",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Consultation Date & Slot",
      field: "booking_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        const formattedDate = moment(rowData.booking_date).format("DD-MM-YYYY");
        return (
          <>
            <span>{formattedDate}</span> <br />
            <span>{rowData.booking_slot}</span>
          </>
        );
      },
    },
    {
      title: "Customer",
      field: "customer",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        return <div>{rowData.customer.customerName}</div>;
      },
    },
    {
      title: "Provider",
      field: "provider",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        return <div>{rowData.provider.provider_name}</div>;
      },
    },
    {
      title: "Package",
      field: "package_details",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        return <div>{rowData.package_details.package_name}</div>;
      },
    },
    {
      title: "Collection Address",
      field: "customerAddress",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        return <div>{rowData.customerAddress.customerAddress}</div>;
      },
    },
    {
      title: "Amount",
      field: "customerAddress",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        let backgroundColor = "";
        switch (rowData.payment_status.toLowerCase()) {
          case "unpaid":
            backgroundColor = "#e3c73b";
            break;
          case "paid":
            backgroundColor = "#5acc7a";
            break;
          case "cancelled":
          case "returned":
          case "failed":
            backgroundColor = "red";
            break;
          default:
            break;
        }
        return (
          <div>
            <span>{rowData.charges}</span>
            <br />
            <span style={{ fontSize: "12px", color: backgroundColor }}>
              {rowData.payment_status}
            </span>
          </div>
        );
        // const combinedValue = `${rowData.order_amount} ${rowData.payment_status}`;
        // return combinedValue;
      },
    },
    {
      title: "Booking Status",
      field: "booking_status",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        return <div>{rowData.booking_status}</div>;
      },
    },
  ];

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getAllLabTestBookings/${page} `)
      .then((response) => {
        // console.log("response ", response);
        setData(response.data.result);
        setDataCount(response.data.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    Axios.post(`${url}/searchCategory`, { search }).then((response) => {
      setData(response.data.result);
      setDataCount(response?.data?.result?.length);
      setLoading(false);
    });
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <SidebarLab />
      <div className="sidebarleft">
        <HeaderLab />
        <div className="container2">
          <MaterialTable
            title="Lab Test Bookings"
            columns={columns}
            data={applyFilters}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: (rowData) => (
                  <NextPlanIcon style={{ color: "#007196" }} />
                  // <Link to={`/OrderDetails/${rowData.orderId}`}>
                  //   {" "}
                  //   <NextPlanIcon style={{ color: "#007196" }} />
                  // </Link>
                ),
                tooltip: "View",
                onClick: (e, rowData) => {
                  // var mobile = rowData.mobile;

                  //history.push(`/OrderDetails/${rowData.orderId}`);
                  navigate(`/BookingDetails/${rowData.labtest_booking_Id}`);
                },
              },
              {
                icon: () => (
                  <FormControl>
                    <div className="search-box">
                      <input
                        type="text"
                        placeholder="Search by Order ID"
                        onChange={handleSearchLabID}
                        style={{
                          padding: "10px",
                          width: "200px",
                          border: "1px solid #a8d4f7",
                          marginTop: "10px",
                        }}
                      />
                      <IconButton
                        className="search-icon"
                        style={{
                          position: "absolute",
                          right: "1px",
                          top: "65%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <Search />
                      </IconButton>
                    </div>
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="startdate"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      Start Date
                    </label>
                    <input
                      id="startdate"
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      placeholder="Start Date"
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                    />
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="enddate"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      End Date
                    </label>
                    <input
                      id="enddate"
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                      placeholder="Start Date"
                    />
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="bookstatus"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      Booking Status
                    </label>
                    <select
                      id="bookstatus"
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      displayEmpty
                      input={<OutlinedInput label="Status" />}
                      renderValue={(selected) => {
                        if (selected === "") {
                          return <em>Status</em>;
                        }
                        return selected;
                      }}
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                    >
                      <option value="">All Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Confirmed">Confirmed</option>
                      <option value="Failed">Failed</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="amountID"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      Payment Status
                    </label>
                    <select
                      id="amountID"
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                    >
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                      <option value="Failed">Failed</option>
                    </select>
                  </FormControl>
                ),
                isFreeAction: true,
              },

              // {
              //   icon: () => <BorderColorIcon style={{ color: "#007196" }} />,
              //   tooltip: "Edit",
              //   onClick: (e, rowData) => {
              //     navigate("/CategoriesEdit", {
              //       state: { data: rowData },
              //     });
              //   },
              // },
              // {
              //   icon: () => <DeleteOutlinedIcon style={{ color: "#F44236" }} />,
              //   tooltip: "Delete",
              //   stickyHeader: true,
              //   onClick: (e, rowData) => {
              //     var ids = rowData.id;

              //     Swal.fire({
              //       title: "Confirm Delete?",
              //       showCancelButton: true,
              //       confirmButtonText: `Confirm`,

              //       confirmButtonColor: "#0f3374",
              //       cancelButtonColor: "#d32a2a", // timer: 10000,
              //     }).then((result) => {
              //       if (result.isConfirmed) {
              //         Swal.fire({
              //           title: "Deleted Successfully!",
              //           icon: "success",
              //           showConfirmButton: false,
              //         });

              //         Axios.delete(`${url}/deleteCategory?id=${ids}`)
              //           .then((response) => {
              //             console.log(response);
              //             console.log(response.Message, "approve");
              //           })
              //           .catch((error) => {
              //             console.error(error);
              //           });
              //         setTimeout(function () {
              //           window.location.reload();
              //         }, 2000);
              //         tableRef.current.onQueryChange();
              //       }
              //     });
              //   },
              // },
            ]}
            components={{
              Pagination: (props) => (
                <Page_Bb
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            options={{
              searchPlaceholder: "Search by Category",
              actionsColumnIndex: columns.length,
              detailPanelColumnAlignment: "right",
              pageSize: 50,
              exportButton: false,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: false,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,

              searchFieldVariant: "outlined",
              border: "0px",
              stickyHeader: true,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76vh",
            }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}
export default LabBookings;
