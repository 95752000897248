import React, { useState } from "react";
import "../../../../doctor_profile/css/allAppointments.css";
import "../../../../css/doctor.css";
import UpcomingOnlineConsultations from "./oc-online";
import HistoryOnlineConsultations from "./oc-history";
import TodayTelecallerOnlineConsultations from "./today";

// import Upcoming from "./upcoming";
// import History from "./history";

function OnlineConsultation() {
  const [activeButton, setActiveButton] = useState("today's");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const renderContent = () => {
    if (activeButton === "today's") {
      return <TodayTelecallerOnlineConsultations />;
    } else if (activeButton === "upcoming") {
      return <UpcomingOnlineConsultations />;
    } else if (activeButton === "history") {
      return <HistoryOnlineConsultations />;
    }
  };

  return (
    <div className="main_appointments">
      <div className="submenu_telecaller">
        <button
          className={`btn-all-appointment ${
            activeButton === "today's" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("today's")}
        >
          <span>Today's</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "upcoming" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("upcoming")}
        >
          <span>Upcoming</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "history" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("history")}
        >
          <span>History</span>
        </button>
      </div>
      <div className="line_doc"></div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default OnlineConsultation;
