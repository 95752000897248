import React, { useState, useEffect } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "../../css/BR-modal.css";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import Axios from "axios";
import Swal from "sweetalert2";
const url = process.env.REACT_APP_URL;
export default function PopUpNotification({
  openPopup,
  setOpenPopUp,
  allBloodReqData,
}) {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handelAccept = (event) => {
    // console.log(allBloodReqData, "aceptedddddddddddddddddddddddd");
    var id = allBloodReqData.id;
    Axios.post(`${url}/updatebloodreq`, { id: id }).then((res) => {
      // console.log(res);
      if (res.data.Message === "Successfully Updated") {
        loginSuccess1();
      }
    });
    // }
  };
  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Approved Request!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000,
    });

    // navigate("/AllBloodbank");
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const handelReject = (event) => {};

  // setLoggedIn(true);
  return (
    <>
      {" "}
      {openPopup && (
        <div>
          {" "}
          <Dialog
            onClose={handleClose}
            open={openPopup}
            className="viewDialogBR"
          >
            <DialogTitle dividers>
              View Requests
              <div className="closeIcon" onClick={handleClose}>
                <HighlightOffOutlinedIcon />
              </div>
            </DialogTitle>{" "}
            <DialogContent dividers color="#007196">
              <p className="uinfo">Request Information</p>
            </DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="first-form">
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="ipnumber">
                      IP Number
                    </label>
                    <input
                      id="ipnumber"
                      type="text"
                      className="form-control-input"
                      name="ipnumber"
                      value={allBloodReqData.ipnumber}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="hospitalname">
                      Hospital Name
                    </label>
                    <input
                      id="hospitalname"
                      className="form-control-input"
                      type="text"
                      name="hospitalname"
                      value={allBloodReqData.hospitalname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="state">
                      State
                    </label>
                    <input
                      id="state"
                      className="form-control-input"
                      type="text"
                      name="state"
                      value={allBloodReqData.tblstate?.statename}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="district">
                      District
                    </label>
                    <input
                      id="district"
                      type="text"
                      className="form-control-input"
                      name="district"
                      value={allBloodReqData.tbldistrict?.districtname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="city">
                      City
                    </label>
                    <input
                      id="city"
                      className="form-control-input"
                      type="text"
                      name="city"
                      value={allBloodReqData.tblcity?.cityname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="bloodgroup">
                      Blood Group
                    </label>
                    <input
                      id="bloodgroup"
                      className="form-control-input"
                      type="text"
                      name="bloodgroup"
                      value={allBloodReqData.bloodgroup}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="bloodparticulars"
                    >
                      Blood Particulars
                    </label>
                    <input
                      id="bloodparticulars"
                      type="text"
                      className="form-control-input"
                      name="bloodparticulars"
                      value={allBloodReqData.bloodparticulars}
                    />
                  </div>
                  {/* <div className="form-state">
                    <label className="formLabeldonor" htmlFor="units">
                      Units
                    </label>
                    <input
                      id="units"
                      className="form-control-input"
                      type="number"
                      name="units"
                      value={allBloodReqData.units}
                    />
                  </div> */}
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="approved">
                      Approved
                    </label>
                    <input
                      id="approved"
                      className="form-control-input"
                      type="text"
                      name="approved"
                      value={allBloodReqData.approved}
                    />
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="orderedby">
                      Ordered By
                    </label>
                    <input
                      id="orderedby"
                      type="text"
                      className="form-control-input"
                      name="orderedby"
                      value={allBloodReqData.orderedby}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="doctorname">
                      Doctor Name
                    </label>
                    <input
                      id="doctorname"
                      className="form-control-input"
                      type="text"
                      name="doctorname"
                      value={allBloodReqData.doctorname}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="phone">
                      Mobile Number
                    </label>
                    <input
                      id="phone"
                      className="form-control-input"
                      type="number"
                      name="phone"
                      value={allBloodReqData.phone}
                    />
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="patientname">
                      Patient Name
                    </label>
                    <input
                      id="patientname"
                      type="text"
                      className="form-control-input"
                      name="patientname"
                      value={allBloodReqData.patientname}
                    />
                  </div>
                </div>
                <div className="formGroupsec">
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="gender">
                      Gender
                    </label>
                    <input
                      id="gender"
                      className="form-control-input"
                      type="text"
                      name="gender"
                      value={allBloodReqData.gender}
                    />
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="age">
                      Age
                    </label>
                    <input
                      id="age"
                      className="form-control-input"
                      type="number"
                      name="age"
                      value={allBloodReqData.age}
                    />
                  </div>
                </div>
              </div>
              <div className="buttonSec11"></div>
            </form>
          </Dialog>{" "}
        </div>
      )}{" "}
    </>
  );
}
