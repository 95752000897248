import React, { useEffect, useRef, useState } from "react";

import { useContext } from "react";
import "../../css/allAppointments.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../../css/doctor.css";

import Upcoming from "./upcoming";
import History from "./history";

function OfflineDoc() {
  const [activeButton, setActiveButton] = useState("upcoming");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const renderContent = () => {
    if (activeButton === "upcoming") {
      return <Upcoming />;
    } else if (activeButton === "history") {
      return <History />;
    }
  };

  return (
    <div className="main_appointments">
      <div className="submenu_doc">
        <button
          className={`btn-all-appointment ${
            activeButton === "upcoming" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("upcoming")}
        >
          <span>Upcoming</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "history" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("history")}
        >
          <span>History</span>
        </button>
      </div>
      <div className="line_doc"></div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default OfflineDoc;
