import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../components/Header";
import "../../../../css/adddonor.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import "../../../../css/changePass.css";
import Swal from "sweetalert2";
import Sidebars from "../../../../components/Sidebar";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";

function TelecallerSignup() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const initialValues = {
    fullname: "",
    email: "",
    gender: "",
    mobile: "",
  };

  const handleClear = () => {
    formRef.current.resetForm(); // Reset the form fields
  };

  const url = process.env.REACT_APP_URL;

  const validationSchema = Yup.object({
    fullname: Yup.string().required("Fullname is required"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(?:\.[a-zA-Z0-9-]{2,})?$/,
        "Invalid email"
      ),
    mobile: Yup.string()
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
  });

  const registerSuccess = async (event) => {
    Swal.fire({
      title: "Telecaller registered successfully",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {
      navigate("/telecaller-lists");
    }, 2000);
  };

  useEffect(() => {
    localStorage.setItem("role", "admin");
  }, []);

  const emailError = async (event) => {
    Swal.fire({
      title: "Email is already registered",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {}, 2000);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const registerTelecaller = {
      fullname: values.fullname,
      email: values.email,
      gender: values.gender,
      mobile: values.mobile,
    };

    try {
      const response = await Axios.post(`${url}/telecallerSignup`, {
        ...registerTelecaller,
      });
      // console.log(response, "response");
      if (
        response.data.Message === "Succesfully Signed Up New Telecaller Records"
      ) {
        resetForm();
        registerSuccess();
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          const errorMessage =
            error.response.data.error || "Internal Server Error";
          if (errorMessage === "License number is already registered") {
            // licenseError();
          } else if (errorMessage === "Email is already registered") {
            emailError();
          } else {
            // console.log(errorMessage, "h");
          }
          // console.log(errorMessage); // Display or handle the error message as needed
        }
      } else if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
      } else {
        // Other errors occurred
        // console.log("Error", error.message);
      }
    }

    setSubmitting(false);
  };

  const backbutton = () => {
    navigate("/telecaller-lists");
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="password-container">
          <div className="subcategory">
            <header className="pointerBeam">
              <b style={{ fontSize: "20px", paddingLeft: "2rem" }}>
                <u onClick={backbutton}>Telecaller Lists</u>/ Add New Telecaller
              </b>
            </header>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
              <Form>
                <div>
                  {/* <p className="addDoctor">Add New Telecaller</p> */}

                  <div className="contianer-forms1">
                    <div className="pass-div-container">
                      <label htmlFor="fullname" className="password-label">
                        FullName <span className="redstar"> *</span>
                      </label>
                      <Field
                        as={OutlinedInput}
                        error={Boolean(touched.password && errors.password)}
                        type="text"
                        // type="text"
                        id="fullname"
                        name="fullname"
                        className="password-input"
                      />
                      <ErrorMessage
                        name="fullname"
                        component="div"
                        className="password-error"
                      />
                    </div>
                    <div className="pass-div-container">
                      <label htmlFor="email" className="password-label">
                        Email <span className="redstar"> *</span>
                      </label>
                      <Field
                        as={OutlinedInput}
                        error={Boolean(touched.password && errors.password)}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="email"
                        name="email"
                        className="password-input"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="password-error"
                      />
                    </div>
                  </div>
                </div>
                <div className="contianer-forms1">
                  <div className="pass-div-container">
                    <label htmlFor="mobile" className="password-label">
                      Mobile Number<span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(touched.password && errors.password)}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="mobile"
                      name="mobile"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="password-error"
                    />
                  </div>
                  <div className="pass-div-container">
                    <label htmlFor="gender" className="password-label">
                      Gender <span className="redstar"> *</span>
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      // value={values.spec_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="password-input"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="others">Others</option>
                    </select>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="password-error"
                    />
                  </div>
                </div>

                <div className="main-btn-div">
                  <div className="button-div1">
                    <button
                      type="clear"
                      className="pass-button1"
                      onClick={handleClear}
                      // onClick={handleClear()}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="button-div1">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="pass-button"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default TelecallerSignup;
