import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import Header from "../../../components/Header";
import "../../../css/users.css";
import MaterialTable from "material-table";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Navigate, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import Page_Bb from "../blood_bank/Page_bb";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PopUpNetwork from "./popupNetwork";
import moment from "moment";
import Swal from "sweetalert2";
import Axios from "axios";
import Sidebars from "../../../components/Sidebar";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function AllNetworkHospital() {
  let navigate = useNavigate();
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [openPopUp, setOpenPopUp] = useState(false);

  const tableRef1 = React.useRef();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = debounce((search) => fetchSearchResults(search), 500);

  const url = process.env.REACT_APP_URL;

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  const columns = [
    {
      title: "Network Hospital Name",
      field: "name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Date Enrolled",
      field: "createdAt",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.createdAt) {
          const formattedDate = moment(rowData.createdAt).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "State",
      field: "tblstate.statename",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "City",
      field: "tblcity.cityname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Pincode",
      field: "pincode",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Address",
      field: "address",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    // {
    //   title: "Action",
    //   field: "action",
    //   // filterPlaceholder: "Filter",
    //   // lookup: stateLookUp,
    //   emptyValue: () => <em>no info</em>,
    // },
    // {
    //   title: "District",
    //   field: "district",
    //   // filterPlaceholder: "Filter",
    //   // lookup: districtLookUp,
    //   emptyValue: () => <em>no info</em>,
    // },
    // {
    //   title: "City",
    //   field: "tblcity.cityname",
    //   // filterPlaceholder: "Filter",
    //   emptyValue: () => <em>no info</em>,
    // },
    // {
    //   title: "Donor Y/N",
    //   field: "donoryesno",
    //   // filterPlaceholder: "Filter",
    //   emptyValue: () => <em>no info</em>,
    // },
  ];

  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/hospitallist/${page} `).then((response) => {
      //.log(response.data);
      setData(response.data.users);
      setDataCount(response.data.totalcount);
      setLoading(false);
    });
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const fetchSearchResults = async (search) => {
    //.log(search);
    setLoading(true);
    Axios.post(`${url}/gethospitallistbysearch `, {
      search,
    }).then((response) => {
      //.log(response, "helo");
      setDataCount(response?.data?.result?.length);
      setData(response.data.result);
      setLoading(false);
    });
  };

  // setLoggedIn(true);

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);
  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MaterialTable
            title="Network Hospital / All Network Hospitals"
            columns={columns}
            data={data}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => (
                  <button
                    onClick={() => navigate("/AllNetworkHospitalDetails")}
                    className="add_blood_bank"
                  >
                    + Network Hospital
                  </button>
                ),
                isFreeAction: true,
              },
              {
                icon: () => <RemoveRedEyeIcon style={{ color: "#007196" }} />,
                tooltip: "View",
                onClick: (e, rowData) => {
                  setOpenPopUp(true), setSelectedRow(rowData);
                },
              },
              {
                icon: () => <CancelOutlinedIcon style={{ color: "#F44236" }} />,
                tooltip: "Block",
                stickyHeader: true,
                onClick: (e, rowData) => {
                  var id = rowData.id;
                  //.log(mobile, "phone");

                  Swal.fire({
                    title: "Confirm Block?",
                    showCancelButton: true,
                    confirmButtonText: `Confirm`,
                    confirmButtonColor: "#0f3374",
                    cancelButtonColor: "#d32a2a", // timer: 10000,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Blocked Successfully!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 15000,
                      });
                      Axios.post(`${url}/blockhospital`, { id })
                        .then((response) => {
                          //.log(response);
                          //.log(response.Message, "approve");
                        })
                        .catch((error) => {
                          //.error(error);
                        });
                      setTimeout(function () {
                        window.location.reload();
                      }, 2000);
                      tableRef.current.onQueryChange();
                    }
                  });
                },
              },
            ]}
            components={{
              Pagination: (props) => (
                <Page_Bb
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            options={{
              actionsColumnIndex: columns.length,
              detailPanelColumnAlignment: "right",
              pageSize: 50,
              exportButton: true,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              border: "0px",
              stickyHeader: true,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76vh",
            }}
            onSearchChange={(query) => handleSearch(query)}
          />
        </div>
      </div>
      {openPopUp && (
        <PopUpNetwork
          openPopup={openPopUp}
          setOpenPopUp={setOpenPopUp}
          data={selectedRow}
        ></PopUpNetwork>
      )}
    </>
  );
}
export default AllNetworkHospital;
