import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import "../../../../css/changePass.css";
import Swal from "sweetalert2";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import ICESidebar from "../../../../components/sidebarICE";
import ICEHeader from "../../../../components/iceheader";

const ICEAdminChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [email, setEmail] = useState("");
  const iceToken = localStorage.getItem("iceToken");
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const url = process.env.REACT_APP_URL;

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const changePasswordSuccess = async (event) => {
    Swal.fire({
      title: "Successfully Changed Password!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const changePasswordFail = async (event) => {
    Swal.fire({
      title: "Incorrect Old Password!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const changePassword = {
      email: email,
      password: values.currentPassword,
      newpassword: values.newPassword,
    };
    try {
      Axios.post(`${url}/reseticeadminpassword`, { ...changePassword }).then(
        (response) => {
          if (response.data.Message === "Successfully Changed Password") {
            resetForm();
            changePasswordSuccess();
          } else if (response.data.Message === "Wrong password combination") {
            changePasswordFail();
          } else {
            throw new Error(response.data);
          }
        }
      );
    } catch (error) {
      alert(error.message);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    localStorage.setItem("iceActiveButton", "/ice/change-password");
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    // const id=amb_id;
    try {
      await Axios.get(`${url}/geticeadmindetailswithToken`, {
        headers: {
          Authorization: `Bearer ${iceToken}`,
        },
      }).then((response) => {
        setEmail(response?.data?.result?.email);
      });
    } catch (error) {}
  };

  return (
    <>
      <ICESidebar />
      <div className="sidebarleft">
        <ICEHeader />
        <div className="password-container">
          <p className="pass-title">Change Password</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
              <Form>
                <div className="contianer-forms">
                  <div className="pass-div-container">
                    <label htmlFor="currentPassword" className="password-label">
                      Old Password <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      type={showPassword1 ? "text" : "password"}
                      id="currentPassword"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword1 ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="currentPassword"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="currentPassword"
                      component="div"
                      className="password-error"
                    />
                  </div>
                </div>
                <div className="contianer-forms">
                  <div className="pass-div-container">
                    <label htmlFor="newPassword" className="password-label">
                      New Password <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(touched.password && errors.password)}
                      type={showPassword ? "text" : "password"}
                      //    value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      id="newPassword"
                      name="newPassword"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="password-error"
                    />
                  </div>

                  <div className="pass-div-container">
                    <label htmlFor="confirmPassword" className="password-label">
                      Confirm Password <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(touched.password && errors.password)}
                      type={showPassword ? "text" : "password"}
                      //    value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      id="confirmPassword"
                      name="confirmPassword"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="password-error"
                    />
                  </div>
                </div>

                <div className="button-div">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="pass-button"
                  >
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ICEAdminChangePassword;
