import React, { useState, useEffect } from "react";
import NotificationsIcon from "@mui/icons-material/CalendarToday";
import VideocamIcon from "@mui/icons-material/VideoChat";
import docimg from "../../../../images/support_7363068.png";
import TelecallerManagerHeader from "./managerHeader";
import Axios from "axios";
import "../../../../css/doctor.css";
import OnlineConsultations from "../components/online/onc";
import OfflineConsultations from "../components/offline/ofc";

function ManagerDashboard() {
  const [activeButton, setActiveButton] = useState(
    localStorage.getItem("activeButton") || "consultations"
  );

  const managerId = localStorage.getItem("managerId");
  const url = process.env.REACT_APP_URL;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let id = managerId;
    Axios.get(`${url}/gettelemanagerdetails?id=${id}`).then((response) => {
      setName(response?.data?.result.name);
      setEmail(response?.data?.result.email);
      setPhone_no(response?.data?.result.mobile);
    });
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    localStorage.setItem("activeButton", buttonId);
  };

  const renderContent = () => {
    if (activeButton === "consultations") {
      return <OnlineConsultations />;
    } else if (activeButton === "onlineConsultation") {
      return <OfflineConsultations />;
    }
  };

  const handlePopState = () => {
    localStorage.removeItem("activeButton");
    window.location.reload();
  };
  useEffect(() => {
    return () => {
      handlePopState();
      localStorage.removeItem("activeButton");
    };
  }, []);

  return (
    <>
      <div className="main">
        <TelecallerManagerHeader />

        <div className="profile_doc">
          <div className="image-container">
            <div
              className="circle-image"
              style={{ backgroundImage: `url(${docimg})` }}
            ></div>
          </div>
          <div className="name_doc">
            <div className="text_arrange">
              <div className="welcome_text">Welcome , Mr/Mrs. {name}</div>

              <div className="telecallerProfile">
                <div className="key_text">
                  <strong>Mobile : </strong>{" "}
                  <p style={{ marginLeft: "4px" }}>{phone_no}</p>
                </div>
                <div className="key_text">
                  <strong>Email : </strong>
                  <p style={{ marginLeft: "4px" }}> {email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu_doc">
          <button
            className={`button__doc_main ${
              activeButton === "consultations" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("consultations")}
          >
            <VideocamIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Online Consultations</span>
          </button>
          <button
            className={`button__doc_main ${
              activeButton === "onlineConsultation" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("onlineConsultation")}
          >
            <NotificationsIcon
              className="button-icon"
              style={{ fontSize: "3rem" }}
            />
            <span>Offline Consultation</span>
          </button>
        </div>
        <div className="table_doc">{renderContent()}</div>
      </div>
    </>
  );
}

export default ManagerDashboard;
