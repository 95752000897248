import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import "../../../../css/adddonor.css";
import { useLocation } from "react-router-dom";
import Sidebars from "../../../../components/Sidebar";
function UpdateTelecaller() {
  let navigate = useNavigate();

  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState({});
  const url = process.env.REACT_APP_URL;

  const bloodData = location?.state?.data;

  useEffect(() => {
    localStorage.setItem("role", "admin");
  }, []);

  useEffect(() => {
    if (bloodData?.fullname) {
      setName(bloodData?.fullname);
    }
    if (bloodData?.email) {
      setEmail(bloodData?.email);
    }

    if (bloodData?.gender) {
      setGender(bloodData?.gender);
    }
    if (bloodData?.mobile) {
      setMobile(bloodData?.mobile);
    }
  }, [bloodData]);

  const validate = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Please enter fullname";
    }

    if (!email.trim()) {
      newErrors.email = "Please enter an email address";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(email.trim())) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!gender.trim()) {
      newErrors.gender = "Please select gender";
    }

    if (!mobile.trim()) {
      newErrors.mobile = "Please enter mobile number";
    } else if (!/^[0-9]{10}$/.test(mobile.trim())) {
      newErrors.mobile = "Invalid mobile number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    const updateDonor = {
      fullname: name,
      email: email,
      gender: gender,
      mobile: mobile,
      id: bloodData?.id,
    };

    event.preventDefault();

    const isValid = validate();

    if (isValid) {
      Axios.put(`${url}/updateahctelecallerlogin`, { ...updateDonor }).then(
        (response) => {
          if (response.data.Message === "Successfully Updated") {
            loginSuccess();
          }
        }
      );
    }
  };
  const backbutton = () => {
    navigate("/telecaller-lists");
  };

  const loginSuccess = async (event) => {
    Swal.fire("Successfully Updated Telecaller Details!", "", "success");
    navigate("/telecaller-lists");
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  Telecaller List
                </span>
                <span>{" / Update Telecaller "}</span>
              </div>
              <div className="first-form">
                <div className="section2" style={{ marginTop: "2rem" }}>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="name">
                      Full Name<p className="redstar">*</p>
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="input-row-2"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="email">
                      Email<p className="redstar">*</p>
                    </label>
                    <input
                      id="email"
                      className="input-row-2"
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {errors.email && (
                      <div className="error">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="section2" style={{ marginTop: "2rem" }}>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile<p className="redstar">*</p>
                    </label>
                    <input
                      id="mobile"
                      className="input-row-2"
                      type="tel"
                      value={mobile}
                      onChange={(event) => setMobile(event.target.value)}
                    />
                    {errors.mobile && (
                      <div className="error">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="gender">
                      Gender<p className="redstar">*</p>
                    </label>
                    <select
                      id="gender"
                      className="input-row-2"
                      value={gender}
                      onChange={(event) => setGender(event.target.value)}
                    >
                      {" "}
                      <option value="">Select Gender</option>{" "}
                      <option value="Male">Male</option>{" "}
                      <option value="Female">Female</option>{" "}
                      <option value="Others">Others</option>{" "}
                    </select>
                    {errors.gender && (
                      <div className="error">{errors.gender}</div>
                    )}
                  </div>
                </div>
                <div className="section11" style={{ marginTop: "2rem" }}>
                  <div className="cancle">
                    <button
                      type="cancel"
                      className="cancle1"
                      onClick={() => navigate("/telecaller-lists")}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="submit">
                    <button type="submit" className="submit1" onSubmit>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default UpdateTelecaller;
