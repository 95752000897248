import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/addCoupon.css";
import Swal from "sweetalert2";
import Axios from "axios";
function CouponsAdd() {
  const [couponCode, setCouponCode] = useState("");
  const [couponTitle, setCouponTitle] = useState("");
  const [usersLimit, setUsersLimit] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [disount, setDisount] = useState("");
  const [minPurchase, setMinPurchase] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponTypes, setCouponTypes] = useState([]);
  const [selectedCouponType, setSelectedCouponType] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const url = process.env.REACT_APP_URL;
  const verifyAdminToken = localStorage.getItem("token");
  let navigate = useNavigate();

  useEffect(() => {
    fetchCouponTypes();
  }, []);

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleCouponTypeChange = (event) => {
    setSelectedCouponType(event.target.value);
  };

  const handleCouponTitleChange = (event) => {
    setCouponTitle(event.target.value);
  };

  const handleUsersLimitChange = (event) => {
    setUsersLimit(event.target.value);
  };

  const handleDiscountTypeChange = (event) => {
    setDiscountType(event.target.value);
  };

  const handleDiscountChange = (event) => {
    let value = event.target.value;
    if (discountType === "Percentage") {
      value = Math.max(0, Math.min(value, 100));
    }
    setDisount(value);
  };

  const handleMinPurchaseChange = (event) => {
    setMinPurchase(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const validateForm = () => {
    let errors = {};

    const val11 = parseFloat(disount);
    const val12 = parseFloat(minPurchase);
    if (!couponCode) {
      errors.couponCode = "Please generate coupon code";
    }

    if (!selectedCouponType) {
      errors.selectedCouponType = "Please select coupon type";
    }

    if (!couponTitle) {
      errors.couponTitle = "Coupon Title is required";
    }
    if (!usersLimit) {
      errors.usersLimit = "Please enter Users Limit";
    } else if (Number(usersLimit) < 0) {
      errors.usersLimit = "User limit cannot be negative";
    }

    if (!discountType) {
      errors.discountType = "Please select discount type";
    }
    if (!disount || disount?.trim() === "") {
      errors.disount = "Please enter discount";
    } else if (Number(disount) < 0) {
      errors.disount = "Discount cannot be negative";
    }

    if (!minPurchase) {
      errors.minPurchase = "Please enter minimum purchase";
    } else if (Number(minPurchase) < 0) {
      errors.minPurchase = "Minimum purchase cannot be negative";
    }
    if (val11 >= val12) {
      errors.minPurchase =
        "Minimum Purchase should be greater than discount amount.";
    }

    if (!startDate) {
      errors.startDate = "Start date is required";
    }
    if (!endDate) {
      errors.endDate = "End date is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleClear = () => {
    setCouponCode("");
    setCouponTitle("");
    setUsersLimit("");
    setDiscountType("");
    setDisount("");
    setMinPurchase("");
    setStartDate("");
    setEndDate("");
    setSelectedCouponType("");
    setFormErrors({});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const verifyAdminToken = localStorage.getItem("token");
    if (validateForm()) {
      Axios.post(
        `${url}/insertCoupon`,
        {
          code: couponCode,
          title: couponTitle,
          min_purchase: minPurchase,
          discount: disount,
          discount_type: discountType,
          coupon_type_id: selectedCouponType,
          user_limit: usersLimit,
          start_date: startDate,
          expire_date: endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${verifyAdminToken}`,
          },
        }
      ).then((response) => {
        if (response.data.Message === "Coupon details inserted successfully.") {
          loginSuccess();
        }
      });
    }
  };

  const loginSuccess = async (event) => {
    Swal.fire({
      title: "Successfully Added Coupon Details!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });
    setTimeout(function () {
      navigate("/coupons");
    }, 2000);
  };

  const fetchCouponTypes = async () => {
    const verifyAdminToken = localStorage.getItem("token");
    Axios.get(`${url}/getCouponTypes`, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    }).then((response) => {
      const couponTypesData = response.data.result;
      setCouponTypes(couponTypesData);
    });
  };

  function handleGenCode() {
    const verifyAdminToken = localStorage.getItem("token");
    Axios.post(`${url}/generateCouponCode`, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    }).then((response) => {
      const genCode = response.data.result;
      setCouponCode(genCode);
    });
  }

  const backbutton = () => {
    navigate("/coupons");
  };

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="containerAdd01">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Coupons</u>/ Add New
              </b>
            </header>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <br />
            <div className="AddCoupon-2-container">
              <div className="div-container-coupon">
                <label className="couponInfo">
                  <b>Coupon Information</b>
                </label>
                {/* div one */}
                <div className="colomproductCoupon">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <div className="codeGen">
                        <label
                          className="subCategoryLable01"
                          htmlFor="couponCode"
                        >
                          Coupon Code <div className="redStart">*</div>
                        </label>
                        <p
                          className="genCodeText"
                          style={{ cursor: "pointer" }}
                          onClick={handleGenCode}
                        >
                          Generate code
                        </p>
                      </div>
                      <input
                        type="text"
                        className="Coupon_code"
                        id="couponCode"
                        name="couponCode"
                        value={couponCode}
                      />
                    </div>
                    {formErrors.couponCode && (
                      <div className="error">{formErrors.couponCode}</div>
                    )}
                  </div>
                </div>

                {/* div two */}

                <div className="couponSecDiv">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="selectedCouponType"
                      >
                        Coupon Type <div className="redStart">*</div>
                      </label>
                      <select
                        id="selectedCouponType"
                        name="selectedCouponType"
                        className="couponTypeTitle"
                        value={selectedCouponType}
                        onChange={handleCouponTypeChange}
                      >
                        <option value="">Select</option>
                        {couponTypes.map((couponType) => (
                          <option key={couponType.id} value={couponType.id}>
                            {couponType.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {formErrors.selectedCouponType && (
                      <div className="error">
                        {formErrors.selectedCouponType}
                      </div>
                    )}
                  </div>
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        style={{ marginLeft: "1.2rem" }}
                        htmlFor="couponTitle"
                      >
                        Coupon Title <div className="redStart">*</div>
                      </label>
                      <input
                        type="text"
                        className="couponTypeTitle1"
                        id="couponTitle"
                        name="couponTitle"
                        value={couponTitle}
                        onChange={handleCouponTitleChange}
                      />
                    </div>
                    {formErrors.couponTitle && (
                      <div className="error" style={{ marginLeft: "1.2rem" }}>
                        {formErrors.couponTitle}
                      </div>
                    )}
                  </div>
                </div>

                {/* div three */}
                <div className="colomproductCoupon">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="usersLimit"
                      >
                        Limit For Same User <div className="redStart">*</div>
                      </label>
                      <input
                        id="usersLimit"
                        name="usersLimit"
                        type="number"
                        className="Coupon_code"
                        placeholder="-Ex:3"
                        value={usersLimit}
                        onChange={handleUsersLimitChange}
                      />
                    </div>
                    {formErrors.usersLimit && (
                      <div className="error">{formErrors.usersLimit}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="div-container-coupon1">
                <label className="subCategoryLable01">
                  <b>Discount</b>
                </label>
                {/* div one */}
                <div className="couponSecDiv">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="discountType"
                      >
                        Discount Type <div className="redStart">*</div>
                      </label>
                      <select
                        id="discountType"
                        name="discountType"
                        type="text"
                        className="couponTypeTitle"
                        value={discountType}
                        onChange={handleDiscountTypeChange}
                      >
                        <option value="">Select</option>
                        <option value="Amount">Amount</option>
                        <option value="Percentage">Percentage</option>
                      </select>
                    </div>
                    {formErrors.discountType && (
                      <div className="error">{formErrors.discountType}</div>
                    )}
                  </div>
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        style={{ marginLeft: "1.2rem" }}
                        htmlFor="disount"
                      >
                        {discountType === "Percentage"
                          ? "Discount Percentage"
                          : "Discount"}{" "}
                        <div className="redStart">*</div>
                      </label>
                      <input
                        id="disount"
                        name="disount"
                        type="number"
                        className="couponTypeTitle1"
                        placeholder={
                          discountType === "Amount" ? "-Ex:3" : "-Ex:10%"
                        }
                        value={disount}
                        onChange={handleDiscountChange}
                        disabled={
                          discountType === "Percentage" && disount > 100
                        }
                      />
                    </div>
                    {formErrors.disount && (
                      <div className="error" style={{ marginLeft: "1.2rem" }}>
                        {formErrors.disount}
                      </div>
                    )}
                  </div>
                </div>
                {/* div two */}
                <div className="colomproductCoupon">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        htmlFor="minPurchase"
                      >
                        Minimum Purchase <div className="redStart">*</div>
                      </label>
                      <input
                        id="minPurchase"
                        name="minPurchase"
                        type="number"
                        className="couponTypeTitle"
                        placeholder="-Ex:3"
                        value={minPurchase}
                        onChange={handleMinPurchaseChange}
                      />
                    </div>
                    {formErrors.minPurchase && (
                      <div className="error">{formErrors.minPurchase}</div>
                    )}
                  </div>
                </div>
                {/* div three */}
                <div className="couponSecDiv">
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label className="subCategoryLable01" htmlFor="startDate">
                        Start Date <div className="redStart">*</div>
                      </label>
                      <input
                        id="startDate"
                        name="startDate"
                        type="date"
                        className="couponTypeTitle"
                        value={startDate}
                        onChange={handleStartDateChange}
                        min={getCurrentDate()}
                      />
                    </div>
                    {formErrors.startDate && (
                      <div className="error">{formErrors.startDate}</div>
                    )}
                  </div>
                  <div className="maincontainer_coupon">
                    <div className="input1">
                      <label
                        className="subCategoryLable01"
                        style={{ marginLeft: "1.2rem" }}
                        htmlFor="endDate"
                      >
                        End Date <div className="redStart">*</div>
                      </label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="couponTypeTitle1"
                        value={endDate}
                        min={getCurrentDate()}
                        onChange={handleEndDateChange}
                      />
                    </div>
                    {formErrors.endDate && (
                      <div className="error" style={{ marginLeft: "1.2rem" }}>
                        {formErrors.endDate}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="btn-products">
              <div>
                <button
                  type="button"
                  onClick={handleClear}
                  className="clearbuttonProductsEdit01"
                >
                  Clear
                </button>
              </div>
              <div className="product-submit-btn">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="SubmitbuttonProductsEdit01"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CouponsAdd;
