import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../images/logo.png";
import log1 from "../../../../images/resetPass.png";
import recg from "../../../../images/rec.png";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import "../../../../css/login.css";
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Axios from "axios";
import Swal from "sweetalert2";

function ICEForgotPasswordAdmin() {
  const [emailError, setEmailError] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("doctor_token");
    localStorage.removeItem("role");
    localStorage.removeItem("selectedDay");
    localStorage.removeItem("tele-manager-token");
    localStorage.removeItem("telecaller_token");
    localStorage.removeItem("ambulanceToken");
    localStorage.removeItem("iceToken");
  }, []);

  const sendOtp = async (email) => {
    try {
      const response = await Axios.post(`${url}/iceadminforgotpassword`, {
        email,
      });
      if (response.data.message === "Message sent") {
        setIsEmailVerified(true);
      } else if (response?.data?.result?.message === "Email not Registered") {
        otpErrorMsg();
      } else {
        setEmailError(
          response.data.message || "Failed to send OTP. Please try again."
        );
      }
    } catch (error) {
      // console.error("Error sending OTP:", error);
      setEmailError("Failed to send OTP. Please try again.");
      ErrorMessageForOTP();
    }
  };

  const otpErrorMsg = async () => {
    Swal.fire({
      title: "Email is not registered!",
      icon: "error",
      showConfirmButton: false,
      timer: 5000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 1500);
  };

  const ErrorMessageForOTP = async (event) => {
    Swal.fire({
      title: "Failed to send OTP. Please try again.",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const InvalidMessageForOTP = async (event) => {
    Swal.fire({
      title: "Invalid OTP, Please try again.",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {
      // window.location.reload();
    }, 2000);
  };

  const verifyOtp = async (email, otp) => {
    let enteredOtp = otp;
    try {
      const response = await Axios.post(`${url}/iceAdminverifyOtp`, {
        email,
        enteredOtp,
      });

      if (response?.data?.message === "OTP matched. Verification successful.") {
        setIsOtpVerified(true);
        loginSuccess();
      } else if (
        response?.data?.message ===
        "Invalid OTP or expired. Verification failed."
      ) {
        InvalidMessageForOTP();
        setIsOtpVerified(false);
      } else {
        setOtpError(response.data.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpError("Invalid OTP. Please try again.");
    }
  };

  const loginSuccess = async (email) => {
    Swal.fire({
      title: "OTP has been verified, Please change the password",
      icon: "success",
      showConfirmButton: true,
      timer: 5000,
    });
    setTimeout(function () {
      navigate("/ice/reset");
    }, 3000);
  };

  const handleSendOtp = async (values) => {
    try {
      await sendOtp(values.email);
      setIsEmailVerified(true);
    } catch (error) {
      setEmailError("Failed to send OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async (values) => {
    try {
      await verifyOtp(values.email, values.otp);

      localStorage.setItem("iceVerifyEmailID", values.email);
      setIsOtpVerified(true);
    } catch (error) {
      setOtpError("Invalid OTP. Please try again.");
    }
  };

  const backbutton = () => {
    navigate("/ice/login");
  };

  return (
    <>
      <div>
        <img src={logo} alt="Logo" className="login_logo" />
      </div>
      <div className="container">
        <div>
          <span className="dashNoti" onClick={backbutton}>
            <p style={{ paddingTop: "5px" }}>
              <ArrowLeftIcon />
            </p>
            <h3> Login</h3>
          </span>
        </div>
        <img src={log1} alt="logo" className="image" />
        <div class="text">
          <img src={recg} alt="rec" className="rec_img"></img>
        </div>
        <Formik
          initialValues={{
            email: "",
            otp: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required("Enter Your Registered Email"),
            otp: Yup.string().when("isEmailVerified", {
              is: true,
              then: Yup.string().required("Enter OTP"),
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (isEmailVerified) {
                await handleVerifyOtp(values);
              } else {
                await handleSendOtp(values);
              }
              setStatus({ success: false });
            } catch (err) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="formLogin">
              <div>
                <h2 className="signInText">
                  {isEmailVerified ? "Verify OTP" : "Reset Password"}
                </h2>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-login">Email</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      id="-password-login"
                      type="text"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="enter registered email"
                      disabled={isEmailVerified}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {isEmailVerified && (
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="otp">OTP</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.otp && errors.otp)}
                        id="otp"
                        type="text"
                        value={values.otp}
                        name="otp"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="enter OTP"
                      />
                      <p style={{ color: "#1976d2" }}>
                        please check your email for OTP
                      </p>
                      {touched.otp && errors.otp && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-otp"
                        >
                          {errors.otp}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                )}
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {isEmailVerified ? "Verify OTP" : "Send OTP"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default ICEForgotPasswordAdmin;
