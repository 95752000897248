import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TelecallerHeader from "../components/tc-header";
import * as Yup from "yup";
import Axios from "axios";
import "../../../css/changePass.css";
import docimg from "../../../images/support_7363068.png";
import "../../../css/doctor.css";
import Swal from "sweetalert2";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const TelecallerChangePassword = () => {
  const verifyTelecallerToken = localStorage.getItem("telecaller_token");

  const [name, setName] = useState("");
  const [experience, setExperience] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const navigate = useNavigate();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await Axios.get(`${url}/gettelecallerdetailswithToken`, {
        headers: {
          Authorization: `Bearer ${verifyTelecallerToken}`,
        },
      }).then((response) => {
        setName(response.data.result.fullname);
        setExperience(response.data.result.gender);
        setEmail(response.data.result.email);
        setPhone_no(response.data.result.mobile);
      });
    } catch (error) {}
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleClear = () => {
  //   (email = "admin@suitsadmin.com"),
  //     (currentPassword = ""),
  //     (newPassword = ""),
  //     (confirmPassword = "");
  // };

  // setLoggedIn(true);

  const url = process.env.REACT_APP_URL;

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const changePasswordSuccess = async (event) => {
    Swal.fire({
      title: "Successfully Changed Password!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const changePasswordFail = async (event) => {
    Swal.fire({
      title: "Incorrect Old Password!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const changePassword = {
      email: email,
      oldpassword: values.currentPassword,
      newpassword: values.newPassword,
    };
    try {
      Axios.put(`${url}/updateTelecallerPassword`, { ...changePassword }).then(
        (response) => {
          if (response.data.Message === "Successfully Changed Password") {
            resetForm();
            navigate("/csr/dashboard");
            changePasswordSuccess();
          } else if (response.data.Message === "Wrong password combination") {
            changePasswordFail();
          } else {
            throw new Error(response.data);
          }
        }
      );
    } catch (error) {
      alert(error.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="main">
        <TelecallerHeader />

        <div className="profile_doc">
          <div className="image-container">
            <div
              className="circle-image"
              style={{ backgroundImage: `url(${docimg})` }}
            ></div>
          </div>
          <div className="name_doc">
            <div className="text_arrange">
              <div className="welcome_text">Welcome , Mr/Mrs. {name}</div>

              <div className="telecallerProfile">
                <div className="key_text">
                  <strong>Mobile : </strong>{" "}
                  <p style={{ marginLeft: "4px" }}>{phone_no}</p>
                </div>
                <div className="key_text">
                  <strong>Email : </strong>
                  <p style={{ marginLeft: "4px" }}> {email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tele-password-container">
          <p className="pass-title">Change Password</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
              <Form>
                <div className="tele-pass-div-container">
                  <div>
                    <label htmlFor="currentPassword" className="password-label">
                      Old Password <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      type={showPassword1 ? "text" : "password"}
                      id="currentPassword"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword1 ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="currentPassword"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="currentPassword"
                      component="div"
                      className="password-error"
                    />
                  </div>
                </div>
                <div className="tele-pass-div-container">
                  <div>
                    <label htmlFor="newPassword" className="password-label">
                      New Password <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(touched.password && errors.password)}
                      type={showPassword ? "text" : "password"}
                      //    value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      id="newPassword"
                      name="newPassword"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="password-error"
                    />
                  </div>
                </div>

                <div className="tele-pass-div-container">
                  <div>
                    <label htmlFor="confirmPassword" className="password-label">
                      Confirm Password <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(touched.password && errors.password)}
                      type={showPassword ? "text" : "password"}
                      //    value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      id="confirmPassword"
                      name="confirmPassword"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="password-error"
                    />
                  </div>
                </div>

                <div className="tele-button-div">
                  <button
                    type="cancel"
                    // disabled={isSubmitting}
                    onClick={() => navigate("/csr/dashboard")}
                    className="tele-pass-button-cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="tele-pass-button"
                  >
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default TelecallerChangePassword;
