import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import "../../../css/labmodule.css";
import SidebarLab from "../../../components/sidebarLab";
import HeaderLab from "../../../components/HeaderLab";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import moment from "moment";
import Axios from "axios";
import Swal from "sweetalert2";
import Page_Bb from "../../services_component/blood_bank/Page_bb";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Switch from "@mui/material/Switch";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function LabPackages() {
  let navigate = useNavigate();

  const url = process.env.REACT_APP_URL;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const tableRef = useRef();
  const [pages01, setPages01] = useState("");
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const verifyAdminToken = localStorage.getItem("token");
  const [statusFilter, setStatusFilter] = useState("");
  const uniqueCategoryTypes = [
    ...new Set(data?.map((item) => item?.labtest_category?.name)),
  ];

  const debouncedSearch = useCallback(
    debounce((search) => {
      fetchSearchResults(search);
    }, 500),
    []
  );

  // console.log(data, "data");

  const applyFilters = () => {
    const filteredData = data?.filter((item) => {
      let isStatusMatch = true;
      let isCategoryMatch = true;

      if (statusFilter === "active" && item?.status !== true) {
        isStatusMatch = false;
      }
      if (statusFilter === "inactive" && item?.status !== false) {
        isStatusMatch = false;
      }

      if (categoryFilter && categoryFilter !== "all") {
        isCategoryMatch = item?.labtest_category?.name === categoryFilter;
      }
      return isStatusMatch && isCategoryMatch;
    });

    return filteredData;
  };

  const eurl = "https://suitsdata.blob.core.windows.net";
  const prod_name = "category";

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const handleStatusToggle = (rowData) => {
    const id = rowData?.id;
    const status = rowData?.status;
    let booleanStatus;

    if (status === true) {
      booleanStatus = false;
    } else if (status === false) {
      booleanStatus = true;
    }
    Axios.put(`${url}/updatePackageStatus`, {
      id: id,
      status: booleanStatus,
    })
      .then((response) => {
        // console.log(response, "res");
        // Reload window here
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "lab");
  }, []);
  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
      setPages01(currentPage);
    }
  }, [currentPage]);
  const columns = [
    {
      title: "Package Name",
      field: "package_name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Lab Test Category",
      field: "labtest_category.name", // Use the field property to access the array
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "No. Parameters",
      field: "labtestCount", // Use the field property to access the array
      emptyValue: () => <em>0 Product</em>,
    },
    {
      title: "Price",
      field: "package_cost", // Use the field property to access the array
      emptyValue: () => <em>0</em>,
      render: (rowData) => {
        const price = rowData?.package_cost;

        if (price) {
          return <div>{price}.00</div>;
        }
        return <em>0</em>;
      },
    },
    {
      title: "Status",
      field: "status",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => (
        <label className="switch">
          <input
            type="checkbox"
            checked={rowData?.status === true}
            onChange={() => handleStatusToggle(rowData)}
          />
          <span className="slider round"></span>
        </label>
      ),
    },
  ];

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getLabTestPackages/${page} `, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    })
      .then((response) => {
        setData(response.data.result);
        setDataCount(response.data.length);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    if (search) {
      Axios.get(
        `${url}/searchLabTestPackages?searchTerm=${search}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${verifyAdminToken}`,
          },
        }
      )
        .then((response) => {
          if (response.data.Message === "Successfully fetched data") {
            setData(response?.data?.result);
            setDataCount(response?.data?.length);
            setLoading(false);
          } else {
            // Handle the case when there is no data to fetch
            setData([]);
            setDataCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else {
      Axios.get(`${url}/getLabTestPackages/${currentPage}`, {
        headers: {
          Authorization: `Bearer ${verifyAdminToken}`,
        },
      })
        .then((response) => {
          // console.log(response.data.result);
          setData(response.data.result);
          setDataCount(response.data.length);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <SidebarLab />
      <div className="sidebarleft">
        <HeaderLab />
        <div className="container2">
          <MaterialTable
            title="Lab Test Packages"
            columns={columns}
            data={applyFilters()}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => (
                  <select
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput label="Category" />}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <em>Category</em>;
                      }
                      return selected;
                    }}
                    className="categoryFilter"
                  >
                    <option value="all">Category</option>
                    {uniqueCategoryTypes.map((Category) => (
                      <option key={Category} value={Category}>
                        {Category}
                      </option>
                    ))}
                  </select>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput label="Status" />}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <em>Status</em>;
                      }
                      return selected;
                    }}
                    className="statusFilter"
                  >
                    <option value="all">Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">In-Active</option>
                  </select>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <button
                    onClick={() => navigate("/add-test-package")}
                    className="add_blood_bank"
                  >
                    + New Package
                  </button>
                ),
                isFreeAction: true,
              },
              {
                icon: () => <BorderColorIcon style={{ color: "#007196" }} />,
                tooltip: "Edit",
                onClick: (e, rowData) => {
                  navigate("/edit-lab-testPackage", {
                    state: { data: rowData },
                  });
                },
              },
              {
                icon: () => <DeleteOutlinedIcon style={{ color: "#F44236" }} />,
                tooltip: "Delete",
                stickyHeader: true,
                onClick: (e, rowData) => {
                  var id = rowData.id;
                  const verifyAdminToken = localStorage.getItem("token");
                  // console.log(id, "id");
                  Swal.fire({
                    title: "Confirm Delete?",
                    showCancelButton: true,
                    confirmButtonText: `Confirm`,

                    confirmButtonColor: "#0f3374",
                    cancelButtonColor: "#d32a2a", // timer: 10000,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted Successfully!",
                        icon: "success",
                        showConfirmButton: false,
                      });

                      Axios.delete(`${url}/deleteLabTestPackages?id=${id}`, {
                        headers: {
                          Authorization: `Bearer ${verifyAdminToken}`,
                        },
                      })
                        .then((response) => {
                          // console.log(response);
                          // console.log(response.Message, "approve");
                        })
                        .catch((error) => {
                          // console.error(error);
                        });
                      setTimeout(function () {
                        window.location.reload();
                      }, 2000);
                      tableRef.current.onQueryChange();
                    }
                  });
                },
              },
            ]}
            components={{
              Pagination: (props) => (
                <Page_Bb
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            options={{
              searchPlaceholder: "Search by Category",
              actionsColumnIndex: columns.length,
              detailPanelColumnAlignment: "right",
              pageSize: 50,
              exportButton: false,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,

              searchFieldVariant: "outlined",
              border: "0px",
              stickyHeader: true,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76vh",
            }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}
export default LabPackages;
