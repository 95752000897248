import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../css/login.css";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import NotificationContext from "../pages/context/NotificationContext";

import suitsadmin from "../images/suits-admin.png";
import suitspharma from "../images/suits-pharma.png";

const HeaderLab = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const token = localStorage.getItem("token");
  const { count, setCount } = useContext(NotificationContext);

  let navigate = useNavigate();

  useEffect(() => {
    fetchData(1); // replace 1 with the actual page number you want to fetch
  }, []);

  const fetchData = (page) => {
    var page = page;
    Axios.get(`${url}/notifications/${page}`).then((response) => {
      if (response.data) {
        if (response.data.result && response.data.result.count)
          setCount(response.data.result.count);
      }
    });
  };

  useEffect(() => {
    if (token === null || token === "" || token === "undefined") {
      navigate("/admin");
    }
  }, [token]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const url = process.env.REACT_APP_URL;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutFun = async () => {
    // alert("login successfull");
    window.location.href = "/admin";
  };

  function handleLogout() {
    setAnchorEl(null);

    localStorage.removeItem("role");
    localStorage.removeItem("token");
    logoutFun();
  }

  return (
    <header className="header_inner">
      <div className="header_noti_lab">
        <div className="logos01">
          {" "}
          <Link to="/DashboardPharma">
            <img src={suitspharma} alt="Logo 1" className="logolab" />
          </Link>
          <Link to="/users-list">
            <img src={suitsadmin} alt="Logo 2" className="logopharma" />
          </Link>
        </div>

        <div className="iconPerson">
          <p>Admin</p>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon
              style={{
                fontSize: 40,
                borderRadius: "50%",
                backgroundColor: "#ddd",
              }}
            />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>My Profile</MenuItem>
            <Link
              to="/ChangePassword"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Change Password</MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default HeaderLab;
