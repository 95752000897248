import React, { useEffect, useRef, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

import Header from "../../components/Header";
import MaterialTable from "material-table";
import "../../css/bloodreqlist.css";
import Swal from "sweetalert2";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Navigate } from "react-router-dom";
import NotificationContext from "../context/NotificationContext";
import Sidebars from "../../components/Sidebar";
import debounce from "lodash.debounce";
import moment from "moment";
import Axios from "axios";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Paginate from "./paginate";
import { useCallback } from "react";
import PopUpNotification from "./popupnotification";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const NotificationAll = () => {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const { count, setCount } = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [totalCou, setTotalCou] = useState([]);
  const tableRef = React.useRef();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_URL;
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };
  const columns = [
    {
      title: "From",
      field: "row.hospitalname",
      editable: false,
      emptyValue: () => <em>no info</em>,
      width: "10%",
    },
    {
      title: "Description",
      field: "description",
      editable: false,
      emptyValue: () => <em>no info</em>,
      // width: 400, // Set the width of the column to 400 pixels
      width: "10%",
    },
    {
      title: "Request On",
      field: "row.createdAt",
      emptyValue: () => <em>no info</em>,
      width: "80%",
      render: (rowData) => {
        if (rowData.row.createdAt) {
          const formattedDate = moment(rowData.row.createdAt).format("HH:mm");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
  ];
  useEffect(() => {
    localStorage.setItem("role", "admin");
    fetchData(currentPage);
    removeNotificationCount();
  }, [currentPage]);


  const removeNotificationCount = async () => {
    // var id = localStorage.getItem("adminId");
    let appointment_id_list = localStorage.getItem("bloodReqIds");
    let bloodReqId = JSON.parse(appointment_id_list);
    try {
      await Axios.put(`${url}/updateAdminRequestStatus`, { bloodReqId }).then(

        (response) => {
          console.log(response);
          if (
            response.data.Message ===
            "Blood Requisition Request Status Updated Successfully."
          ) {
            setCount(0);
          }
        }
      );
    } catch (error) {}
  };

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/notifications/${page}`).then((response) => {
      // console.log(response);
      if (response.data.result && response.data.result.result) {
        setData(response.data.result.result);
      }
      // setCount(response.data.result.count);
      setLoading(false);
    });
  };
  // const handleClear = useCallback((rowData) => {
  //   if (rowData.approved !== "NO") {
  //     Swal.fire({
  //       title: "Confirm Clear",
  //       text: "Are you sure you want to clear this notification?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, clear it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         var id = rowData.row.id;
  //         setLoading(true);

  //         const removedData =
  //           JSON.parse(localStorage.getItem("removedData")) || {};
  //         removedData[rowData.id] = rowData;
  //         localStorage.setItem("removedData", JSON.stringify(removedData));
  //         // Get the index of the row in the data array
  //         const rowIndex = data.findIndex((row) => row.id === rowData.id);
  //         // Create a new copy of the data array with the row data removed
  //         const newData = [
  //           ...data.slice(0, rowIndex),
  //           ...data.slice(rowIndex + 1),
  //         ];
  //         // Update the table's data source with the new data array
  //         setData(newData);
  //         Axios.get(`${url}/clear/${id}`, {
  //           id,
  //         });
  //         setCount(count - 1);
  //       }
  //     });
  //   }
  // });

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);
  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MaterialTable
            tableRef={tableRef}
            title="Notification"
            columns={columns}
            data={data}
            icons={customIcons}
            style={{ zIndex: 1, borderRadius: "4px" }}
            onRowClick={(event, rowData) => {
              navigate("/ListRequesties", {
                state: {
                  data: rowData,
                },
              });
            }}
            options={{
              tableLayout: "auto",
              detailPanelColumnAlignment: "right",
              exportButton: false,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: false,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              rowStyle: {
                wordWrap: "break-word",
              },
              searchFieldVariant: "outlined",
              // border: "0px",
              // stickyHeader: true,
              pageSize: 50,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              actionsColumnIndex: columns.length,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                // position: "fixed",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            actions={[
              // {
              //   icon: () => (
              //     <span
              //       onClick={() => {
              //         setData([]); // Clearing all the row data by setting an empty array to the 'data' variable
              //         Axios.get(`${url}/clearAll`).then((response) => {
              //           setCount(0);
              //         });
              //       }}
              //       className="clear_all"
              //       style={{
              //         color: "#007196",
              //         fontSize: "14px",
              //         fontWeight: "bold",
              //       }}
              //     >
              //       Clear All
              //     </span>
              //   ),
              //   isFreeAction: true,
              // },

              {
                icon: () => <RemoveRedEyeIcon style={{ color: "#007196" }} />,
                tooltip: "View",
                stickyHeader: true,
                onClick: (event, rowData) => {
                  // console.log(rowData.row);
                  setOpenPopUp(true), setSelectedRow(rowData.row);
                },
              },
              // {
              //   icon: (rowData) =>
              //     rowData.approved === "NO" ? (
              //       <span style={{ color: "red", fontSize: "14px" }}>
              //         Reject
              //       </span>
              //     ) : (
              //       <span style={{ color: "#007196", fontSize: "14px" }}>
              //         Clear
              //       </span>
              //     ),
              //   tooltip: "Clear",
              //   stickyHeader: true,
              //   onClick: (event, rowData) => {
              //     if (rowData.approved !== "NO") {
              //       handleClear(rowData);
              //     }
              //   },
              // },
            ]}
            components={{
              Pagination: (props) => (
                <Paginate
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
          />
          <PopUpNotification
            openPopup={openPopUp}
            setOpenPopUp={setOpenPopUp}
            allBloodReqData={selectedRow}
          />
        </div>
      </div>
    </>
  );
};
export default NotificationAll;
