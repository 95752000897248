import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../css/login.css";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import NotificationContext from "../pages/context/NotificationContext";

const ICEHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const iceToken = localStorage.getItem("iceToken");
  const { count, setCount } = useContext(NotificationContext);

  let navigate = useNavigate();

  // const fetchData = (page) => {
  //   var page = page;
  //   Axios.get(`${url}/notifications/${page}`).then((response) => {
  //     if (response.data) {
  //       if (response.data.result && response.data.result.count)
  //         setCount(response.data.result.count);
  //     }
  //   });
  // };

  useEffect(() => {
    if (iceToken === null || iceToken === "" || iceToken === "undefined") {
      navigate("/ice/login");
    }
  }, [iceToken]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const url = process.env.REACT_APP_URL;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutFun = async () => {
    // alert("login successfull");
    window.location.href = "/ice/login";
  };

  function handleLogout() {
    setAnchorEl(null);

    localStorage.removeItem("role");
    localStorage.removeItem("iceToken");
    localStorage.removeItem("iceActiveButton");
    logoutFun();
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      await Axios.get(`${url}/geticeadmindetailswithToken`, {
        headers: {
          Authorization: `Bearer ${iceToken}`,
        },
      }).then((response) => {
        setName(response?.data?.result?.name);
      });
    } catch (error) {}
  };

  return (
    <header className="header_inner">
      <div className="ahcHeaderLab">
        <div className="logos01"> </div>

        <div className="ahcIconPerson">
          <div className="ahcUserName">ICE Admin</div>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon
              style={{
                fontSize: 40,
                borderRadius: "50%",
                backgroundColor: "#ddd",
              }}
            />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link
              to="/ice/myprofile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              <MenuItem onClick={handleClose}>My Profile</MenuItem>
            </Link>
            <Link
              to="/ice/change-password"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Change Password</MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default ICEHeader;
