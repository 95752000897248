import React from "react";
import "../../css/pagination.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faStepBackward,
  faStepForward,
} from "@fortawesome/free-solid-svg-icons";

const Paginate = ({ nPages, currentPage, setCurrentPage }) => {
  if (!Number.isInteger(nPages) || nPages <= 1) {
    // Handle the case where nPages is not a valid positive integer
    nPages = 1; // Set a default value or show an error message
  }

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <a className="page-link" onClick={() => setCurrentPage(1)} href="#">
            <FontAwesomeIcon icon={faStepBackward} />
          </a>
        </li>
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <a
            className="page-link"
            onClick={() => setCurrentPage(currentPage - 1)}
            href="#"
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </a>
        </li>
        {pageNumbers.map(
          (pgNumber) =>
            pgNumber === currentPage && (
              <li key={pgNumber} className="page-item active">
                <a className="page-link" href="#">
                  {pgNumber}
                </a>
              </li>
            )
        )}
        <li
          className={`page-item ${
            currentPage === pageNumbers.length ? "disabled" : ""
          }`}
        >
          <a
            className="page-link"
            onClick={() => setCurrentPage(currentPage + 1)}
            href="#"
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </a>
        </li>
        <li
          className={`page-item ${
            currentPage === pageNumbers.length ? "disabled" : ""
          }`}
        >
          <a
            className="page-link"
            onClick={() => setCurrentPage(pageNumbers.length)}
            href="#"
          >
            <FontAwesomeIcon icon={faStepForward} />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Paginate;
