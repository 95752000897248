import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SidebarLab from "../../../components/sidebarLab";
import HeaderLab from "../../../components/HeaderLab";
import "../../../css/labTestPackage.css";
import Axios from "axios";
import Swal from "sweetalert2";
import { debounce } from "lodash";
function AddLabPackageTest() {
  const [packageName, setPackageName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [price, setPrice] = useState("");

  const [packageNameError, setPackageNameError] = useState("");
  const [categoryTypeError, setCategoryTypeError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [selectedTestsError, setSelectedTestsError] = useState("");

  const [categoryLists, setCategoryLists] = useState([]);

  const [labTests, setLabTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedTestIds, setSelectedTestIds] = useState([]);

  const url = process.env.REACT_APP_URL;
  const [searchTerm, setSearchTerm] = useState("");

  let navigate = useNavigate();

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      fetchSearchResults(searchTerm);
    }, 500),
    []
  );

  const handleClear = () => {
    setPackageName("");
    setCategoryType("");
    setPrice("");
    setSelectedTestIds([]);
    setSelectedTests([]);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    // debouncedSearch.cancel(); // Cancel any previous debounced search
    debouncedSearch(searchTerm);
  };

  useEffect(() => {
    fetchLabTestCategories();
    fetchLabTests();
  }, []);

  const fetchLabTestCategories = async () => {
    const verifyAdminToken = localStorage.getItem("token");
    Axios.get(`${url}/getLabTestCategoriesList`, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    }).then((response) => {
      const categoryTypesData = response.data;
      setCategoryLists(categoryTypesData);
    });
  };

  const fetchSearchResults = async (search) => {
    const verifyAdminToken = localStorage.getItem("token");
    Axios.get(`${url}/searchLabTest?searchTerm=${search} `, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    }).then((response) => {
      const labTestsData = response.data.result;
      setLabTests(labTestsData);
    });
  };

  const fetchLabTests = async () => {
    const verifyAdminToken = localStorage.getItem("token");
    Axios.get(`${url}/getLabTestsList`, {
      headers: {
        Authorization: `Bearer ${verifyAdminToken}`,
      },
    }).then((response) => {
      const labTestsData = response.data;
      setLabTests(labTestsData);
    });
  };

  const handleAddTest = (test) => {
    setSelectedTests((prevTests) => {
      if (!prevTests.some((prevTest) => prevTest.id === test.id)) {
        return [...prevTests, test];
      }
      return prevTests;
    });

    setLabTests((prevLabTests) =>
      prevLabTests.filter((prevTest) => prevTest.id !== test.id)
    );

    setSelectedTestIds((prevTestIds) => [...prevTestIds, test.id]);
  };

  const handleRemoveTest = (test) => {
    setSelectedTests((prevTests) =>
      prevTests.filter((prevTest) => prevTest.id !== test.id)
    );

    if (!labTests.some((prevTest) => prevTest.id === test.id)) {
      setLabTests((prevLabTests) => [...prevLabTests, test]);
    }

    setSelectedTestIds((prevTestIds) =>
      prevTestIds.filter((prevTestId) => prevTestId !== test.id)
    );
  };

  const validateFields = () => {
    let isValid = true;

    if (!packageName) {
      setPackageNameError("Package Name is required");
      isValid = false;
    } else {
      setPackageNameError("");
    }

    if (!categoryType) {
      setCategoryTypeError("Category Type is required");
      isValid = false;
    } else {
      setCategoryTypeError("");
    }

    if (!price) {
      setPriceError("Price is required");
      isValid = false;
    } else if (price < 1) {
      setPriceError("Price cannot be less than 1");
      isValid = false;
    } else {
      setPriceError("");
    }

    if (selectedTestIds.length === 0) {
      setSelectedTestsError("Please select at least one test");
      isValid = false;
    } else {
      setSelectedTestsError("");
    }

    return isValid;
  };

  const loginSuccess = async (event) => {
    Swal.fire({
      title: "Successfully Added LabTest Packages!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });
    setTimeout(function () {
      navigate("/Packages");
    }, 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const verifyAdminToken = localStorage.getItem("token");
    if (validateFields()) {
      const labTestObjects = selectedTestIds?.map((testId) => ({
        labtest_id: testId,
      }));

      const formData = {
        package_name: packageName,
        labtest_category_id: categoryType,
        package_cost: price,
        labTest_details: labTestObjects,
      };
      // console.log(formData, "formatdata");

      // Send the formData to the server
      Axios.post(
        `${url}/insertLabtestPackage`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${verifyAdminToken}`,
          },
        }
      )
        .then((response) => {
          // console.log(response.data);
          if (
            response.data.Message ===
            "Lab Test Package details inserted successfully."
          ) {
            loginSuccess();
          }
          // else if (res.data.Message === "Mobile number already taken") {
          //   loginErrorMob();
          // }
        })
        .catch((error) => {
          // console.log(error);
          if (
            error?.response?.data?.Message === "package_name should be unique"
          ) {
            Swal.fire({
              title: "Packages Name Already Exists!",
              icon: "error",
              showConfirmButton: false,
              timer: 2000, // set the duration in milliseconds
            });
          } else {
            Swal.fire({
              title: "Failed to Add New Packages",
              icon: "error",
              showConfirmButton: false,
              timer: 2000, // set the duration in milliseconds
            });
          }
        });
    }
  };
  const backbutton = () => {
    navigate("/Packages");
  };
  return (
    <>
      <SidebarLab />
      <div className="sidebarleft">
        <HeaderLab />
        <div className="containerAdd01">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Lab Test Packages</u>/ Add New
              </b>
            </header>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            {/* package name, category type and price fields */}
            <div className="ltp-main-container">
              <div className="ltp-input">
                <label className="subCategoryLable01">
                  Package Name <div className="redStart">*</div>
                </label>
                <div className="input-container">
                  <input
                    type="text"
                    className="ltp-input-value"
                    placeholder="Package Name"
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                  />{" "}
                </div>
                {packageNameError && (
                  <div className="error-messagess">{packageNameError}</div>
                )}
              </div>

              <div className="ltp-input">
                <label className="subCategoryLable01">
                  Category Type <div className="redStart">*</div>
                </label>
                <div className="input-container">
                  <select
                    type="text"
                    className="ltp-input-value"
                    placeholder="--select--"
                    value={categoryType}
                    onChange={(e) => setCategoryType(e.target.value)}
                  >
                    <option value="select">Select</option>
                    {categoryLists?.map((categoryList) => (
                      <option key={categoryList.id} value={categoryList.id}>
                        {categoryList.name}
                      </option>
                    ))}
                  </select>
                </div>
                {categoryTypeError && (
                  <div className="error-messagess">{categoryTypeError}</div>
                )}
              </div>

              <div className="ltp-input">
                <label className="subCategoryLable01">
                  Price<div className="redStart">*</div>
                </label>
                <div className="input-container">
                  <input
                    type="number"
                    className="ltp-input-value"
                    placeholder="-Ex -123"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
                {priceError && (
                  <div className="error-messagess">{priceError}</div>
                )}
              </div>
            </div>
            <br />
            <br />
            {/* parameters */}
            <div className="parameter-div">
              <label className="paramLabel">
                Parameters<div className="redStart">*</div>
              </label>
            </div>
            <div className="paramList-div">
              <br />
              <div className="ltp-search">
                <input
                  type="text"
                  className="ltp-search-value"
                  placeholder="Search Lab test"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <label className="added-ltp">Added</label>
              <br />
              <div className="parameters-two-divs">
                <div className="getLabTestPackageList">
                  <div className="labTestListContainer">
                    {labTests?.map((test) => (
                      <div className="labTestItem" key={test.id}>
                        {test.test_name}
                        <button
                          className="plusAdd-btn"
                          onClick={() => handleAddTest(test)}
                          type="button"
                        >
                          +
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="addLabTestPackageList">
                  <div className="labTestListContainer">
                    {selectedTests?.map((test) => (
                      <div className="labTestItem" key={test.id}>
                        {test.test_name}
                        <button
                          className="minus-btn"
                          onClick={() => handleRemoveTest(test)}
                          type="button"
                        >
                          -
                        </button>
                      </div>
                    ))}
                    {selectedTestsError && (
                      <div className="error-messagess">
                        {selectedTestsError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <br />
            {/* submit and clear buttons */}
            <div className="btn-packages">
              <div>
                <button
                  type="button"
                  onClick={handleClear}
                  className="clearbuttonProductsEdit01"
                >
                  Clear
                </button>
              </div>
              <div className="product-submit-btn">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="SubmitbuttonProductsEdit01"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddLabPackageTest;
