import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import Axios from "axios";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../../css/bb-popup.css";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function PopUpBug({ openPopup, setOpenPopUp, data }) {
  let navigate = useNavigate();
  let did = data?.id;
  const [requsta, setReqSta] = useState([]);
  const [requestStatus01, setRequestStatus01] = useState("");
  const url = process.env.REACT_APP_URL;
  const [nameError, setNameError] = useState("");
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");

  useEffect(() => {
    setReqSta(["Pending", "Approved", "Rejected", "Resolved"]);
    setRequestStatus01(data?.status);
  }, []);

  useEffect(() => {
    // Update the local storage role
    setComment(data?.comments);
    localStorage.setItem("role", "admin");

    // Check if comments are empty and set error message
    if (data?.comments?.trim() === "") {
      setCommentError("Comment is required");
    }
  }, []);

  useEffect(() => {
    setCommentError("");
  }, [comment]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!comment || comment.trim() === "") {
      setCommentError("Comment is required");
      return;
    }

    const requestData = {
      id: did,
      comments: comment,
      status: requestStatus01,
    };

    try {
      Axios.put(`${url}/updateBugReportStatus`, requestData).then((res) => {
        if (res.data.Message === "Bug Report Status Updated Successfully.") {
          loginSuccess1();
        } else {
          loginErrorMob();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: " Status Updated Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000, // set the duration in milliseconds
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorMob = async (event) => {
    Swal.fire("Error updating status!", "", "error");
  };

  return (
    <>
      {openPopup && (
        <Dialog className="popop" onClose={handleClose} open={openPopup}>
          <DialogTitle dividers>
            Bug Report
            <div className="closeIcon" onClick={handleClose}>
              <HighlightOffOutlinedIcon />
            </div>
          </DialogTitle>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className="first-form">
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="Email">
                    Bug ID
                  </label>
                  <input
                    id="name"
                    type="text"
                    className="form-control-input2"
                    name="email"
                    value={data?.service_abbreviation + "" + data?.id}
                    disabled
                  />
                </div>
              </div>

              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="aboutMe">
                    Comments<div className="redStart">*</div>
                  </label>
                  <textarea
                    id="aboutMe"
                    className="form-control-input5"
                    name="aboutMe"
                    value={comment}
                    onChange={(event) => {
                      setComment(event.target.value);
                    }}
                  ></textarea>
                  {commentError && <div className="error">{commentError}</div>}
                </div>
              </div>
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="Email">
                    Bug Status
                  </label>
                  <select
                    id="state"
                    className="form-control-input1"
                    value={requestStatus01}
                    onChange={(e) => setRequestStatus01(e.target.value)}
                  >
                    {requsta.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="buttonSec">
              <div className="cancle_ta">
                <button type="cancel" className="cancle1" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div className="submit">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="submit1"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Dialog>
      )}
    </>
  );
}
