import TelecallerHeader from "../components/tc-header";
import docimg from "../../../images/support_7363068.png";
import "../../../css/doctor.css";
import Swal from "sweetalert2";
import React, { useEffect, useState, useContext } from "react";
import MaterialTable from "material-table";
import "../../../css/bloodreqlist.css";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Navigate } from "react-router-dom";
import NotificationContext from "../../context/NotificationContext";
import moment from "moment";
import Axios from "axios";
import Paginate from "../../notification/paginate";
import { useCallback } from "react";
// import PopUpNotification from "./popupnotification";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const TelecallerNotifications = () => {
  const verifyTelecallerToken = localStorage.getItem("telecaller_token");
  const teleId = localStorage.getItem("teleId");

  const { count, setCount } = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [totalCou, setTotalCou] = useState([]);
  const tableRef = React.useRef();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [experience, setExperience] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");

  const url = process.env.REACT_APP_URL;
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  useEffect(() => {
    fetchData1();
    removeNotificationCount();
  }, []);

  const fetchData1 = async () => {
    try {
      await Axios.get(`${url}/gettelecallerdetailswithToken`, {
        headers: {
          Authorization: `Bearer ${verifyTelecallerToken}`,
        },
      }).then((response) => {
        setName(response.data.result.fullname);
        setExperience(response.data.result.gender);
        setEmail(response.data.result.email);
        setPhone_no(response.data.result.mobile);
      });
    } catch (error) {}
  };


  const removeNotificationCount = async () => {

    // var id = teleId;
    const telecaller_id = parseInt(teleId, 10);
    try {
      await Axios.put(`${url}/updateTelecallerRequestStatus`, {
        telecaller_id,
      }).then((response) => {
        // window.location.reload();
        console.log(response);
        if (
          response.data.Message ===
          "Telecaller Request Status Updated Successfully."
        ) {
          setCount(0);
        }
      });
    } catch (error) {}
  };


  const columns = [
    {
      title: "Patient Name",
      field: "user_fullname",
      editable: false,
      emptyValue: () => <em>no info</em>,
      width: "10%",
    },
    {
      title: "Mobile",
      field: "user_mobile",
      editable: false,
      emptyValue: () => <em>no info</em>,
      // width: 400, // Set the width of the column to 400 pixels
      width: "10%",
    },
    {
      title: "Doctor Name",
      field: "doctor_name",
      editable: false,
      emptyValue: () => <em>no info</em>,
      width: "10%",
    },
    {
      title: "Doctor Mobile",
      field: "doctor_mobile",
      editable: false,
      emptyValue: () => <em>no info</em>,
      // width: 400, // Set the width of the column to 400 pixels
      width: "10%",
    },
    {
      title: "Consultation Type",
      field: "treatment_mode",
      emptyValue: () => <em>no info</em>,
      width: "80%",
    },
    {
      title: "Appointment Reason",
      field: "reason_for_appointment",
      emptyValue: () => <em>no info</em>,
      width: "80%",
    },
    {
      title: "Appointment Date",
      field: "appointment_date",
      emptyValue: () => <em>no info</em>,
      width: "80%",
    },
    {
      title: "Time Slot",
      field: "time_slot",
      emptyValue: () => <em>no info</em>,
      width: "80%",
    },
    {
      title: "Payment Mode",
      field: "payment_mode",
      emptyValue: () => <em>no info</em>,
      width: "80%",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    const telecaller_id = teleId;
    setLoading(true);
    Axios.get(
      `${url}/getTeleCallerNewAppointmentNotifications?telecaller_id=${telecaller_id}`
    ).then((response) => {
      if (response.data) {
        setData(response.data.result);
      }
      setLoading(false);
    });
  };

  const handleClear = useCallback((rowData) => {
    if (rowData.approved !== "NO") {
      Swal.fire({
        title: "Confirm Clear",
        text: "Are you sure you want to clear this notification?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, clear it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var appointment_id = rowData.appointment_id;
          setLoading(true);

          const removedData =
            JSON.parse(localStorage.getItem("removedData")) || {};
          removedData[rowData.appointment_id] = rowData;
          localStorage.setItem("removedData", JSON.stringify(removedData));
          // Get the index of the row in the data array
          const rowIndex = data.findIndex(
            (row) => appointment_id === rowData.appointment_id
          );
          // Create a new copy of the data array with the row data removed
          const newData = [
            ...data.slice(0, rowIndex),
            ...data.slice(rowIndex + 1),
          ];
          // Update the table's data source with the new data array
          setData(newData);
          Axios.put(
            `${url}/clearTelecallerAppointment?appointmentId=${appointment_id}`
          ).then((response) => {
            if (
              response?.data?.Message ===
              "Successfully cleared telecaller notification"
            ) {
              loginSuccess();
            }
          });
          // setCount(count - 1);
        }
      });
    }
  });

  const loginSuccess = async (event) => {
    Swal.fire({
      title: "Notification cleared successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 1000,
    });
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);

  const backbutton = () => {
    navigate("/csr/dashboard");
  };

  return (
    <>
      <div className="main">
        <TelecallerHeader />

        <div className="profile_doc">
          <div className="image-container">
            <div
              className="circle-image"
              style={{ backgroundImage: `url(${docimg})` }}
            ></div>
          </div>
          <div className="name_doc">
            <div className="text_arrange">
              <div className="welcome_text">Welcome , Mr/Mrs. {name}</div>

              <div className="telecallerProfile">
                <div className="key_text">
                  <strong>Mobile : </strong>{" "}
                  <p style={{ marginLeft: "4px" }}>{phone_no}</p>
                </div>
                <div className="key_text">
                  <strong>Email : </strong>
                  <p style={{ marginLeft: "4px" }}> {email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notiMain">
          <span className="dashNoti" onClick={backbutton}>
            <p>
              <ArrowLeftIcon />
            </p>
            Dashboard
          </span>
          <br />
          <div className="container2">
            <MaterialTable
              tableRef={tableRef}
              title="Notification"
              columns={columns}
              data={data}
              icons={customIcons}
              style={{ zIndex: 1, borderRadius: "4px" }}
              onRowClick={(event, rowData) => {
                // navigate("/ListRequesties", {
                //   state: {
                //     data: rowData,
                //   },
                // });
              }}
              options={{
                tableLayout: "auto",
                detailPanelColumnAlignment: "right",
                exportButton: false,
                sorting: true,
                // pageSizeOptions: [10, 20],
                search: false,
                searchOnEnter: true,
                debounceInterval: 500,
                searchAutoFocus: false,
                rowStyle: {
                  wordWrap: "break-word",
                },
                searchFieldVariant: "outlined",
                // border: "0px",
                // stickyHeader: true,
                pageSize: 50,
                paginationType: "stepped",
                // showFirstLastPageButtons: false,
                actionsColumnIndex: columns.length,
                emptyRowsWhenPaging: false,
                headerStyle: {
                  size: "40px",
                  background: "rgba(0, 0, 0, 0.05)",
                  // position: "fixed",
                  top: "0",
                },
                stickyHeader: true,
                maxBodyHeight: "100vh",
              }}
              components={{
                Pagination: (props) => (
                  <Paginate
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ),
              }}
            />
            {/* <PopUpNotification
            openPopup={openPopUp}
            setOpenPopUp={setOpenPopUp}
            allBloodReqData={selectedRow}
          /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TelecallerNotifications;
