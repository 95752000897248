import React, { useEffect, useRef, useState, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import Header from "../../../components/Header";
import "../../../css/users.css";
import MatirialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import moment from "moment";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import Paginate from "../blood_requesties/paginate";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import debounce from "lodash.debounce";
import Axios from "axios";
import Sidebars from "../../../components/Sidebar";
import { useNavigate, Navigate } from "react-router-dom";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function PostDonation() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const tableRef1 = useRef();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = useCallback(
    debounce((search) => {
      fetchSearchResults(search);
    }, 500),
    []
  );
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const columns = [
    {
      title: "Full Name",
      field: "donorName",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "donorGender",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "DOB",
      field: "donordob",
      type: "date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.donordob) {
          const formattedDate = moment(rowData.donordob).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Mobile",
      field: "donorMobile",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Email Id",
      field: "donorEmail",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Address",
      field: "donorAddress",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "City",
      field: "cityName",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "District",
      field: "districtName",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "State",
      field: "stateName",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Pincode",
      field: "donorPincode",

      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Nominee Name",
      field: "nomineeName",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Nominee Gender",
      field: "nomineeGender",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Nominee DOB",
      field: "nomineedob",
      type: "date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.nomineedob) {
          const formattedDate = moment(rowData.nomineedob).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Nominee Relationship",
      field: "nomineeRelationship",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE Name 1",
      field: "ICENameOne",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE Mobile 1",
      field: "ICEMobileone",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE Name 2",
      field: "ICENametwo",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE Mobile 2",
      field: "ICEMobiletwo",
      hidden: true,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Camp Venue",
      field: "venue",
      // hidden: true,
      export: false,
      emptyValue: (rowData) => (
        <span>
          {rowData.bloodCampVenue !== null ? (
            rowData.bloodCampVenue
          ) : rowData.bloodBankVenue !== null ? (
            rowData.bloodBankVenue
          ) : (
            <em>no info</em>
          )}
        </span>
      ),
    },
    {
      title: "Blood Camp Venue",
      field: "bloodCampVenue",
      hidden: true,
      export: true,
      emptyValue: <em>no info</em>,
    },
    {
      title: "Blood Bank Venue",
      field: "bloodBankVenue",
      hidden: true,
      export: true,
      emptyValue: <em>no info</em>,
    },
    {
      title: "Blood Donation Date",
      field: "bloodDonationDate",
      type: "date",
      // hidden: true,
      // export: true,
      // emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.bloodDonationDate) {
          const formattedDate = moment(rowData.bloodDonationDate).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Saveyo Membership Id",
      field: "SaveyoMemberID",
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    localStorage.setItem("role", "admin");
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    Axios.get(`${url}/donorlistwithsaveyo`).then((response) => {
      //   setData(response.data.totalcount);
      setUserData(response?.data?.result);
      setLoading(false);
    });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    if (!search) {
      setLoading(false);
      window.location.reload();
    }
    Axios.post(`${url}/getdonorlistbysearch`, { search }).then((response) => {
      setUserData(response.data.result);
      setData(response?.data?.result?.length);
      setLoading(false);
    });
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    userData && userData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MatirialTable
            title="Post Donation"
            columns={columns}
            data={userData}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              actionsColumnIndex: columns.length,
              stickyHeader: true,
              pageSize: 50,
              pageSizeOptions: [20, 30, 50],
              // paginationType: "stepped",
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}
export default PostDonation;
