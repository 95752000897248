import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import Axios from "axios";
import Swal from "sweetalert2";

function SubCategoriesEdit() {
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [nameError, setNameError] = useState("");
  const location = useLocation();
  const verifyToken = localStorage.getItem("token");
  let navigate = useNavigate();

  const [ids, setIds] = useState("");
  const [subname, setSubName] = useState("");
  const [parname, setParName] = useState("");
  const [status, setStatus] = useState(true);

  const editData = location?.state?.data;
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    if (editData?.id) {
      setIds(editData?.id);
    }

    if (editData?.name) {
      setSubName(editData?.name);
    }

    if (editData?.parent_name) {
      setParName(editData?.parent_name);
    }

    if (editData?.status !== undefined) {
      setStatus(editData?.status === 1 ? true : false);
    }

    Axios.get(`${url}/getCategoriesList`)
      .then((response) => {
        setParentCategories(
          response.data.categories
            .filter((item) => item.status === 1)
            .map((item) => item.name)
        );
      })
      .catch((error) => {});
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (subname.trim() === "") {
      setNameError("Sub category name is required");
      return;
    }

    const id = ids;
    const subcategoryName = subname;
    const parcategoryName = parname;
    const categoryStatus = status;

    const requestData = {
      id: id,
      name: subcategoryName,
      parent_name: parcategoryName,
      status: categoryStatus,
    };

    Axios.put(`${url}/updateSubCategory`, requestData)
      .then((res) => {
        if (res.data.Message === "Sub Category details updated successfully.") {
          loginSuccess1();
        } else if (res.data.Message) {
          loginErrorCamp();
        }
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Sub Categories Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });

    setTimeout(function () {
      navigate("/SubCategories");
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorCamp = async (event) => {
    Swal.fire("Error Updating Sub Categories!", "", "error");
  };

  const handleClear = () => {
    setSubName(editData?.name);
    setParName(editData?.parent_name);
  };

  const backbutton = () => {
    navigate("/SubCategories");
  };

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Sub Category</u>/ Update
              </b>
            </header>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="maincontainer">
              <div className="subCatergoryEditinput1">
                <label className="subCategoryLable01">
                  <b> Sub Category Name </b>
                  <div className="redStart">*</div>
                </label>
                <input
                  type="text"
                  className="subCategory01"
                  placeholder="Sub Category Name"
                  value={subname}
                  onChange={(e) => setSubName(e.target.value)}
                />
                {nameError && <div className="error">{nameError}</div>}
              </div>
              <div className="subCatergoryEditinput2">
                <label className="subCategoryLable01">
                  <b> Parent Category Name </b>
                  <div className="redStart">*</div>
                </label>
                <select
                  className="subCategory01"
                  value={parname}
                  onChange={(e) => setParName(e.target.value)}
                >
                  {parentCategories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="subCatergoryEditinput3">
                <label className="subCategoryLable01">
                  <b> Status </b>
                  <div className="redStart">*</div>
                </label>
                <select
                  className="subCategory01"
                  value={status}
                  onChange={(e) => setStatus(e.target.value === "true")}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </div>
            </div>
            <button
              type="button"
              onClick={handleClear}
              className="clearbuttonSubCategoryEdit01"
            >
              Reset
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="SubmitbuttonSubCategoryEdit01"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SubCategoriesEdit;
