import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../images/logo.png";
import log1 from "../../../../images/resetPass.png";
import recg from "../../../../images/rec.png";
import "../../../../css/login.css";
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Axios from "axios";
import Swal from "sweetalert2";

function AHCAdminPasswordReset() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [passError, setPassError] = useState("");
  const [emailError, setEmailError] = useState("");
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  let navigate = useNavigate();
  const url = process.env.REACT_APP_URL;

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("doctor_token");
    localStorage.removeItem("role");
    localStorage.removeItem("activeButton");
    localStorage.removeItem("selectedDay");
    localStorage.removeItem("ghcToken");
    localStorage.removeItem("telecaller_token");
    localStorage.removeItem("tele-manager-token");
    localStorage.removeItem("ahcToken");
  }, []);

  const loginSuccess = async (event) => {
    Swal.fire({
      title: "Password Changed Successfully! Please login with New Password",
      icon: "success",
      showConfirmButton: true,
      timer: 5000,
    });
    setTimeout(function () {
      navigate("/ahcadmin/login");
    }, 3000);
  };

  async function telecallerLogin(formVal) {
    var email = localStorage.getItem("ahcVerifyEmailID");
    var newpassword = formVal.newPassword;
    Axios.put(`${url}/ahcadminupdatePassword`, {
      email,
      newpassword,
    }).then((response) => {
      if (response?.data?.Message === "Successfully Changed Password") {
        loginSuccess();
      } else {
        // LoginFail();
        // setPassError(response.data.Message);
      }
    });
  }

  return (
    <>
      <div>
        <img src={logo} alt="Logo" className="login_logo" />
      </div>
      <div className="container">
        <img src={log1} alt="logo" className="image" />
        <div class="text">
          <img src={recg} alt="rec" className="rec_img"></img>
        </div>
        <Formik
          initialValues={{
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string()
              .required("New password is required")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                "Password must be a combination of letters, numbers, cases, and symbols with a minimum length of 10 characters"
              ),
            confirmNewPassword: Yup.string()
              .required("Confirm password is required")
              .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await telecallerLogin(values);

              setStatus({ success: false });
              // loginSuccess();
              // setSubmitting(false);
              // navigate("/DashBoard");
            } catch (err) {
              setStatus({ success: false });
              setErrors({ submit: err.Message });
              // setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="formLogin">
              <div>
                <h2 className="signInText">Reset Password</h2>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-login">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      id="-password-login"
                      type={showPassword1 ? "text" : "newPassword"}
                      value={values.newPassword}
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                            edge="end"
                            size="large"
                          >
                            {showPassword1 ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Enter password"
                    />
                    {touched.newPassword && errors.newPassword && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.newPassword}
                      </FormHelperText>
                    )}
                  </Stack>
                  {passError && <div className="pass_error">{passError}</div>}
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-login">
                      Confirm New Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.confirmNewPassword && errors.confirmNewPassword
                      )}
                      id="-password-login"
                      type={showPassword2 ? "text" : "password"}
                      value={values.password}
                      name="confirmNewPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            edge="end"
                            size="large"
                          >
                            {showPassword2 ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Enter password"
                    />
                    {touched.confirmNewPassword &&
                      errors.confirmNewPassword && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.confirmNewPassword}
                        </FormHelperText>
                      )}
                  </Stack>
                  {passError && <div className="pass_error">{passError}</div>}
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AHCAdminPasswordReset;
