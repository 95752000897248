import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import { BlobServiceClient } from "@azure/storage-blob";
import Axios from "axios";
import Swal from "sweetalert2";

function CategoriesAdd() {
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");
  const [imageError, setImageError] = useState("");
  let navigate = useNavigate();
  const fileInputRef = useRef(null);
  const url = process.env.REACT_APP_URL;

  const azureAccountName = "suitsdata";
  const sasToken =
    "?sv=2021-12-02&ss=bf&srt=sco&sp=rwdlactfx&se=2029-03-29T11:31:53Z&st=2023-03-29T03:31:53Z&spr=https&sig=OX48TXg%2F6xwp3b32hwe7Tf6sggDFc4YpCvvwCU5EUj8%3D";
  const containerName = "category";

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    const name = selectedFile.name;
    setSelectedImage(selectedFile);
    setFile(selectedFile);
    setFileName(name);
  };

  const openFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleClear = () => {
    setCategoryName(null);
    setSelectedImage(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate input fields
    if (categoryName.trim() === "" && selectedImage === null) {
      setCategoryNameError("Category Name is required");
      setImageError("Image is required");
      return;
    } else {
      setCategoryNameError("");
      setImageError("");
    }
    if (categoryName.trim() === "") {
      setCategoryNameError("Category Name is required");
      return;
    } else {
      setCategoryNameError("");
    }

    if (selectedImage === null) {
      setImageError("Image is required");
      return;
    } else {
      setImageError("");
    }

    // console.log(azureAccountName, fileName, file);

    const blobServiceClient = new BlobServiceClient(
      `https://${azureAccountName}.blob.core.windows.net${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobClient = containerClient.getBlockBlobClient(fileName);

    blobClient.uploadData(file);

    const image = fileName;
    // console.log(categoryName, image);
    Axios.post(`${url}/insertCategory`, {
      name: categoryName,
      image: image,
    })
      .then((response) => {
        // console.log(response);
        if (
          response?.data?.Message === "Category details inserted successfully."
        ) {
          Swal.fire({
            title: "Added New Category!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000, // set the duration in milliseconds
          });
        } else {
          Swal.fire({
            title: "Failed to Add New Category",
            icon: "error",
            showConfirmButton: false,
            timer: 2000, // set the duration in milliseconds
          });
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error?.response?.data?.Message === "Duplicate Record") {
          Swal.fire({
            title: "Category Name Already Exists!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000, // set the duration in milliseconds
          });
        } else {
          Swal.fire({
            title: "Failed to Add New Category",
            icon: "error",
            showConfirmButton: false,
            timer: 2000, // set the duration in milliseconds
          });
        }
      });

    document.querySelector(".subCategory01").value = "";
    setCategoryName(null);
    setSelectedImage(null);
  };

  const backbutton = () => {
    navigate("/Categories");
  };

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="containerAdd01">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Category</u>/ Add New
              </b>
            </header>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="maincontainer">
              <div className="input1">
                <label className="subCategoryLable01">
                  Category Name <div className="redStart">*</div>
                </label>
                <input
                  type="text"
                  className="subCategory01"
                  placeholder="Category Name"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>
              <div className="categoryNameError01">
                {categoryNameError && (
                  <p className="categoryNameError">{categoryNameError}</p>
                )}
              </div>
              <div className="input2">
                <label className="subCategoryLable01">
                  Image<div className="redStart">*</div>
                </label>
                {selectedImage ? (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected Image"
                    style={{ width: "150px", height: "150px" }}
                    className="uploadicon01"
                  />
                ) : (
                  <CloudUploadIcon
                    style={{ width: "150px", height: "150px" }}
                    className="uploadicon01"
                    onClick={openFileUpload}
                  />
                )}
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />

                <p className="caption01">
                  <InfoIcon style={{ width: "23px", height: "13px" }} />
                  Only one image is acceptable
                </p>
                {imageError && <p className="imageError">{imageError}</p>}
              </div>
            </div>
            <br />

            <button
              type="reset"
              className="clearbutton01"
              onClick={handleClear}
            >
              Clear
            </button>
            <button type="submit" className="Submitbutton01">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default CategoriesAdd;
