import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../../../doctor_profile/css/popupModule.css";
import moment from "moment";
// import docimg from "../../images/doctor.jpg";
import Axios from "axios";
import Swal from "sweetalert2";

export default function PopupDashboardTelecaller({
  openPopup,
  setOpenPopUp,
  data,
}) {
  const [pname, setPname] = useState("");
  const [mobile, setMobile] = useState("");
  const [dsp, setDsp] = useState("");
  const [consult, setConsult] = useState("");
  const [appointment_id, setAppointment_Id] = useState("");
  const [telecaller_id, setTelecaller_Id] = useState("");
  const [followup_message, setFollowup_Message] = useState("");
  const [datas, setDatas] = useState([]);
  const [actions, setActions] = useState("");
  const [slotid, setSlotid] = useState("");
  const [doc_id, setDoc_id] = useState("");

  const [dname, setDname] = useState("");
  const [dmobile, setDMobile] = useState("");

  const [date, setDate] = useState("");
  const [formErrors, setFormErrors] = useState({}); // State to store form errors

  const verifyTelecallerToken = localStorage.getItem("telecaller_token");
  const url = process.env.REACT_APP_URL;
  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleSubmit = () => {
    const [id, startTime, endTime] = slotid.split("-");
    const startTimes = startTime;
    const endTimes = endTime;
    const ids = id;
    const varData = {
      appointment_id: appointment_id,
      telecaller_id: telecaller_id,
      followup_message: followup_message,
      action: actions,
      slot_id: !isNaN(ids) ? parseInt(ids, 10) : undefined,
      appointment_date: date,
      slotstarttime: startTimes,
      slotendtime: endTimes,
    };

    Axios.post(`${url}/insertFollowup`, { ...varData })
      .then((response) => {
        if (response.data.Message === "Successfully Created") {
          loginSuccess1();
        }
      })
      .catch((error) => {});
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Follow-Up Details Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const handleSlotChange = (event) => {
    const selectedSlotId = event.target.value;
    setSlotid(selectedSlotId);
  };

  useEffect(() => {
    fetchData();

    if (data?.user_fullname) {
      setPname(data?.user_fullname);
    }

    if (data?.user_mobile) {
      setMobile(data?.user_mobile);
    }

    if (data?.doctor_specializations) {
      setDsp(data?.doctor_specializations);
    }

    if (data?.treatment_mode) {
      setConsult(data?.treatment_mode);
    }

    if (data?.appointment_date) {
      setDate(data?.appointment_date);
    }

    if (data?.appointment_time_slot) {
      setSlotid(data?.appointment_time_slot);
    }

    if (data?.doctor_id) {
      setDoc_id(data?.doctor_id);
    }

    if (data?.appointment_id) {
      setAppointment_Id(data?.appointment_id);
    }

    if (data?.doctor_phone_no) {
      setDMobile(data?.doctor_phone_no);
    }

    if (data?.doctor_name) {
      setDname(data?.doctor_name);
    }
  }, [data]);

  const fetchData = async () => {
    try {
      await Axios.get(`${url}/gettelecallerdetailswithToken`, {
        headers: {
          Authorization: `Bearer ${verifyTelecallerToken}`,
        },
      }).then((response) => {
        setTelecaller_Id(response?.data?.result?.id);
      });
    } catch (error) {}
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDate(selectedDate);
    getSlotsByDate(selectedDate, doc_id);
  };

  const errorSlot = async (event) => {
    Swal.fire({
      title: "Selected date slots are not available!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
    // setTimeout(function () {
    //   window.location.reload();
    // }, 2000);
  };

  const getSlotsByDate = async (selectedDate, doc_id) => {
    const fdate = moment(selectedDate).format("DD-MM-YYYY");

    var consultation_type = data?.treatment_mode;

    try {
      await Axios.get(
        `${url}/getSlotsByDate?doc_id=${doc_id}&date=${fdate}&consultation_type=${consultation_type}`
      ).then((response) => {
        setDatas(response.data);
      });
    } catch (error) {
      if (
        error.response.data.Message ===
        "Something went wrong. Please try again."
      ) {
        errorSlot();
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!date) {
      errors.date = "Date is Required";
    }
    if (!actions) {
      errors.actions = "Action is required";
    }
    if (!followup_message) {
      errors.followup_message = "Follow-Up Message is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      handleSubmit();
    }
  };

  return (
    <>
      {openPopup && (
        <Dialog className="popop" onClose={handleClose} open={openPopup}>
          <DialogTitle dividers style={{ marginBottom: "12px" }}>
            Follow Up Details
            <div className="closeIcontelcaller" onClick={handleClose}>
              <HighlightOffOutlinedIcon />
            </div>
          </DialogTitle>

          <form onSubmit={handleFormSubmit}>
            <div className="first-form">
              <div className="formGroupsTelecaller">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="pname">
                      Patient Name:
                    </label>
                    <h4 className="form-control-input3-h4">{pname} </h4>
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="mobile">
                      Patient Mobile:
                    </label>
                    <h4 className="form-control-input3-h4">{mobile} </h4>
                  </div>
                </div>
              </div>

              <div className="formGroupsTelecaller">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="dname">
                      Doctor Name:
                    </label>
                    <h4 className="form-control-input3-h4">{dname} </h4>
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="dmobile">
                      Doctor Mobile:
                    </label>
                    <h4 className="form-control-input3-h4">{dmobile} </h4>
                  </div>
                </div>
              </div>

              <div className="formGroupsTelecaller">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="dsp">
                      Doctor Specializations:
                    </label>
                    <h4 className="form-control-input3-h4">{dsp} </h4>
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabelTelecaller" htmlFor="consult">
                      Type of Consultation:
                    </label>
                    <h4 className="form-control-input3-h4">{consult} </h4>
                  </div>
                </div>
              </div>

              <div className="formGroupsec1">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabeldonor1" htmlFor="date">
                      Appointment Date
                    </label>
                    <input
                      id="date"
                      type="date"
                      className="form-control-input3"
                      name="date"
                      value={date}
                      onChange={handleDateChange}
                    />
                    {formErrors.date && (
                      <div className="error-message">{formErrors.date}</div>
                    )}
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabeldonor1" htmlFor="slotid">
                      Time Slot
                    </label>
                    {datas?.length === 0 ? (
                      <select
                        id="slotid"
                        className="form-control-input4"
                        name="slotid"
                      >
                        <option value="id">{slotid}</option>
                      </select>
                    ) : (
                      <select
                        id="slotid"
                        className="form-control-input4"
                        name="slotid"
                        value={slotid}
                        onChange={handleSlotChange}
                      >
                        {/* {datas.map((slot) => (
                          <option key={slot.id} value={slot.id}>
                            {slot.startTime} - {slot.endTime}
                          </option>
                        ))} */}

                        {datas.map((slot) => (
                          <option
                            key={slot.id}
                            value={`${slot.id}-${slot.startTime}-${slot.endTime}`}
                          >
                            {slot.startTime} - {slot.endTime}
                          </option>
                        ))}
                      </select>
                    )}
                    {/* <select
                      id="slotid"
                      className="form-control-input4"
                      name="slotid"
                      value={slotid}
                      onChange={handleSlotChange}
                    >
                      {datas.map((slot) => (
                        <option key={slot.id} value={slot.id}>
                          {slot.start_time} - {slot.end_time} ({slot.type})
                        </option>
                      ))}
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="actions">
                    Action
                  </label>
                  <input
                    id="actions"
                    type="text"
                    className="form-control-input2"
                    name="actions"
                    value={actions}
                    onChange={(event) => {
                      setActions(event.target.value);
                    }}
                  />
                  {formErrors.actions && (
                    <div className="error-message">{formErrors.actions}</div>
                  )}
                </div>
              </div>
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="followup_message">
                    Follow-Up Message
                  </label>
                  <textarea
                    id="followup_message"
                    className="form-control-input5"
                    name="followup_message"
                    value={followup_message}
                    onChange={(event) => {
                      setFollowup_Message(event.target.value);
                    }}
                  ></textarea>
                  {formErrors.followup_message && (
                    <div className="error-message">
                      {formErrors.followup_message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="buttonSec">
              <div className="cancle_ta">
                <button type="cancel" className="cancle1" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div className="submit">
                <button type="submit" className="submit1">
                  Submit
                </button>
              </div>
            </div>
          </form>
          <DialogActions>{/* Add your action buttons here */}</DialogActions>
        </Dialog>
      )}
    </>
  );
}
