import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../../css/ahc-members-details.css";
import { useLocation } from "react-router-dom";
import moment from "moment";
import SidebarAHC from "../../../../../components/sidebarAhc";
import HeaderAHC from "../../../../../components/headerAhc";

function ViewAHCAdminConsultations() {
  let navigate = useNavigate();
  const location = useLocation();
  const [user_fullname, setUser_fullname] = useState("");
  const [user_mobile, setUser_mobile] = useState("");
  const [user_gender, setUser_gender] = useState("");

  const [treatment_mode, setTreatment_mode] = useState("");
  const [ahcmemberid, setAhcmemberid] = useState("");
  const [appointment_date, setAppointment_date] = useState("");
  const [appointment_taken_date, setAppointment_taken_date] = useState("");
  const [appointment_time_slot, setAppointment_time_slot] = useState("");
  const [consultation_fee, setConsultation_fee] = useState("");

  const [doctor_name, setDoctor_name] = useState("");
  const [doctor_phone_no, setDoctor_phone_no] = useState("");
  const [doctor_specializations, setDoctor_specializations] = useState("");

  const [telecallerfullname, setTelecallerfullname] = useState("");
  const [telecallermobile, setTelecallermobile] = useState("");
  const [telecalleremail, setTelecalleremail] = useState("");

  const [followup_date, setFollowup_date] = useState("");
  const [followup_action, setFollowup_action] = useState("");
  const [followup_message, setFollowup_message] = useState("");

  const ghcMembersDetails = location?.state?.data;

  const formattedDateappointment_taken_date = moment(
    appointment_taken_date
  ).format("DD-MM-YYYY");

  const formatappointment_date = moment(appointment_date).format("DD-MM-YYYY");

  useEffect(() => {
    if (ghcMembersDetails?.user_fullname) {
      setUser_fullname(ghcMembersDetails?.user_fullname);
    }

    if (ghcMembersDetails?.user_gender) {
      setUser_gender(ghcMembersDetails?.user_gender);
    }

    if (ghcMembersDetails?.user_mobile) {
      setUser_mobile(ghcMembersDetails?.user_mobile);
    }

    if (ghcMembersDetails?.treatment_mode) {
      setTreatment_mode(ghcMembersDetails?.treatment_mode);
    }

    if (ghcMembersDetails?.ahcmemberid) {
      setAhcmemberid(ghcMembersDetails?.ahcmemberid);
    }

    if (ghcMembersDetails?.appointment_date) {
      setAppointment_date(ghcMembersDetails?.appointment_date);
    }

    if (ghcMembersDetails?.appointment_taken_date) {
      setAppointment_taken_date(ghcMembersDetails?.appointment_taken_date);
    }

    if (ghcMembersDetails?.appointment_time_slot) {
      setAppointment_time_slot(ghcMembersDetails?.appointment_time_slot);
    }

    if (ghcMembersDetails?.consultation_fee) {
      setConsultation_fee(ghcMembersDetails?.consultation_fee);
    }

    if (ghcMembersDetails?.doctor_name) {
      setDoctor_name(ghcMembersDetails?.doctor_name);
    }

    if (ghcMembersDetails?.doctor_phone_no) {
      setDoctor_phone_no(ghcMembersDetails?.doctor_phone_no);
    }

    if (ghcMembersDetails?.doctor_specializations) {
      setDoctor_specializations(ghcMembersDetails?.doctor_specializations);
    }

    if (ghcMembersDetails?.telecallerfullname) {
      setTelecallerfullname(ghcMembersDetails?.telecallerfullname);
    }

    if (ghcMembersDetails?.telecallermobile) {
      setTelecallermobile(ghcMembersDetails?.telecallermobile);
    }

    if (ghcMembersDetails?.telecalleremail) {
      setTelecalleremail(ghcMembersDetails?.telecalleremail);
    }

    if (ghcMembersDetails?.followup_date) {
      setFollowup_date(ghcMembersDetails?.followup_date);
    }

    if (ghcMembersDetails?.followup_action) {
      setFollowup_action(ghcMembersDetails?.followup_action);
    }

    if (ghcMembersDetails?.followup_message) {
      setFollowup_message(ghcMembersDetails?.followup_message);
    }
  }, [ghcMembersDetails]);

  useEffect(() => {
    localStorage.setItem("ahcactiveButton", "/ahcadmin/consultationdetails");
  }, []);

  const backbutton = () => {
    navigate("/ahcadmin/consultationdetails");
  };

  return (
    <>
      <SidebarAHC />
      <div className="sidebarleft">
        <HeaderAHC />
        <div>
          <form>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  AHC Consultations
                </span>
                <span>{" / View AHC Consultation Details "}</span>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">User Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Full Name:
                    </label>
                    <h4 className="ahc-members-label-h4">{user_fullname}</h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile:
                    </label>
                    <h4 className="ahc-members-label-h4">{user_mobile}</h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Gender:
                    </label>
                    <h4 className="ahc-members-label-h4">{user_gender}</h4>
                  </div>
                </div>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">Appointment Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Treatment Mode:
                    </label>
                    <h4 className="ahc-members-label-h4">{treatment_mode}</h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      AHC Membership Id:
                    </label>
                    <h4 className="ahc-members-label-h4">{ahcmemberid}</h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Appointment Taken Date:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {formattedDateappointment_taken_date}
                    </h4>
                  </div>
                </div>

                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Appointment Date:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {" "}
                      {formatappointment_date}
                    </h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Time Slot:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {appointment_time_slot}
                    </h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Consultation Fee:
                    </label>
                    <h4 className="ahc-members-label-h4">{consultation_fee}</h4>
                  </div>
                </div>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">Doctor Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Name:
                    </label>
                    <h4 className="ahc-members-label-h4">{doctor_name}</h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile:
                    </label>
                    <h4 className="ahc-members-label-h4">{doctor_phone_no}</h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Specialization :
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {doctor_specializations}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="ahc-memberships-card-design">
                <div className="add-new-donor">
                  <h3 className="ahc-heading">Telecaller Details </h3>
                </div>
                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Name:
                    </label>
                    <h4 className="ahc-members-label-h4">
                      {telecallerfullname}
                    </h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Mobile:
                    </label>
                    <h4 className="ahc-members-label-h4">{telecallermobile}</h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Email :
                    </label>
                    <h4 className="ahc-members-label-h4">{telecalleremail}</h4>
                  </div>
                </div>

                <div className="section2-ahc-members-details">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="fullname">
                      Followup Date:
                    </label>
                    <h4 className="ahc-members-label-h4">{followup_date}</h4>
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Followup Action:
                    </label>
                    <h4 className="ahc-members-label-h4">{followup_action}</h4>
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="email">
                      Followup Message:
                    </label>
                    <h4 className="ahc-members-label-h4">{followup_message}</h4>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ViewAHCAdminConsultations;
