import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import Axios from "axios";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../../../css/bb-popup.css";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function PopUpDialogCamp({ openPopup, setOpenPopUp, data }) {
  let navigate = useNavigate();
  // console.log(data);
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [id, setId] = useState("");
  let did = data?.id;
  const [venue, setVenue] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [campDate, setCampdate] = useState("");
  const [campid, setCampid] = useState("");
  const [mobile, setMobile] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [stateList, setStateList] = useState({});
  const [districtList, setDistrictList] = useState({});
  const [cityList, setCityList] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  useEffect(() => {
    if (data?.campId) {
      setCampid(data?.campId);
    }

    if (data?.campDate) {
      setCampdate(data?.campDate);
    }

    if (data?.organizerBy) {
      setOrganizer(data?.organizerBy);
    }

    if (data?.organizerMobileNo) {
      setMobile(data?.organizerMobileNo);
    }

    if (data?.stateid) {
      setState(data?.stateid);
    }
    if (data?.districtid) {
      setDistrict(data?.districtid);
    }
    if (data?.cityid) {
      setCity(data?.cityid);
    }
    if (data?.venue) {
      setVenue(data?.venue);
    }

    if (data?.id) {
      setId(data?.id);
    }
  }, [data]);

  const validateForm = () => {
    let errors = {};
    if (!campid) {
      errors.campid = "Camp ID is required";
    }
    if (!campDate) {
      errors.campDate = "Camp Date is required";
    }
    if (!organizer) {
      errors.organizer = "Organization is required";
    }
    if (!mobile) {
      errors.mobile = "Mobile Number is required";
    }
    if (!state) {
      errors.state = "State is required";
    }
    if (!district) {
      errors.district = "District is required";
    }
    if (!city) {
      errors.city = "City is required";
    }
    if (!venue) {
      errors.venue = "Venue is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleClose = () => {
    setOpenPopUp(false);
    setFormErrors({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      // console.log(
      //   organizer,
      //   venue,
      //   mobile,
      //   city,
      //   campDate,
      //   campid,
      //   did,
      //   "samssssss"
      // );
      Axios.post(`${url}/updatebloodcamp`, {
        organizerby: organizer,
        venue: venue,
        organizermobileno: mobile,
        city: city,
        campdate: campDate,
        campid: campid,
        id: did,
      }).then((res) => {
        // console.log(res);
        if (res.data.Message === "Successfully Updated") {
          loginSuccess1();
        } else if (res.data.Message === "Camp Id already taken") {
          loginErrorCamp();
        }
      });
    }
  };
  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Blood Camp Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000, // set the duration in milliseconds
    });

    // navigate("/AllBloodbank");
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorCamp = async (event) => {
    Swal.fire("Camp Id already taken !", "", "error");
  };

  // using the below useEffect to trigger the dropdowns accoring to state, district and city
  useEffect(() => {
    Axios.get(`${url}/statelist`).then((response) => {
      const newList = {};
      response.data?.states?.forEach(
        (item) => (newList[item.id] = item.statename)
      );
      setStateList(newList);
    });
    var stateid = state;
    var districtid = district;
    if (stateid) {
      Axios.get(`${url}/districtsbystate/${stateid}`).then((response) => {
        const distList = {};
        // console.log(response.data.districts,"from backend distric")
        response.data?.result?.forEach(
          (item) => (distList[item.id] = item.districtname)
        );
        // console.log("district data", distList, response.data);
        setDistrictList(distList);
      });
    }
    if (districtid) {
      Axios.get(`${url}/citiesbydistrict/${districtid}`).then((response) => {
        const cityyList = {};
        response.data?.result?.forEach(
          (item) => (cityyList[item.id] = item.cityname)
        );
        // console.log("city dta", cityyList, response.data.cities);
        setCityList(cityyList);
      });
    }
  }, [state, district]);

  const handleStateChange = (e) => {
    setState(e.target.value);
    setCityList({});
    setDistrictList({});
    setDistrict("");
    setCity("");
  };

  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
    setCity("");
    setCityList({});
  };
  return (
    <>
      {openPopup && (
        <div>
          {openPopup && (
            <div>
              {" "}
              {openPopup && (
                <div>
                  {" "}
                  <Dialog onClose={handleClose} open={openPopup}>
                    <DialogTitle dividers>
                      View Blood Camp
                      <div className="closeIcon" onClick={handleClose}>
                        <HighlightOffOutlinedIcon />
                      </div>
                    </DialogTitle>{" "}
                    <DialogContent dividers color="#007196">
                      <p className="uinfo">Blood Camp Information</p>
                    </DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="first-form">
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="campid">
                              Camp ID <span className="redstar"> *</span>
                            </label>
                            <input
                              id="campid"
                              type="text"
                              className="form-control-input1"
                              name="campid"
                              value={campid}
                              onChange={(event) => {
                                setCampid(event.target.value);
                              }}
                            />
                            {formErrors.campid && (
                              <div className="error">{formErrors.campid}</div>
                            )}
                          </div>
                          <div className="form-state">
                            <label
                              className="formLabeldonor"
                              htmlFor="campDate"
                            >
                              Camp Date <span className="redstar"> *</span>
                            </label>
                            <input
                              id="campDate"
                              className="form-control-input1"
                              type="date"
                              name="campDate"
                              value={campDate.substring(0, 10)}
                              onChange={(event) => {
                                setCampdate(event.target.value);
                              }}
                            />
                            {formErrors.campDate && (
                              <div className="error">{formErrors.campDate}</div>
                            )}
                          </div>
                        </div>

                        <div className="formGroupsec">
                          <div className="form-dob">
                            <div className="form-state">
                              <label
                                className="formLabeldonor"
                                htmlFor="organizer"
                              >
                                Organization <span className="redstar"> *</span>
                              </label>
                              <input
                                id="organizer"
                                className="form-control-input1"
                                type="text"
                                name="organizer"
                                value={organizer}
                                onChange={(event) =>
                                  setOrganizer(event.target.value)
                                }
                              />
                              {formErrors.organizer && (
                                <div className="error">
                                  {formErrors.organizer}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-state">
                            <div className="form-state">
                              <label
                                className="formLabeldonor"
                                htmlFor="mobile"
                              >
                                Mobile Number{" "}
                                <span className="redstar"> *</span>
                              </label>
                              <input
                                id="mobile"
                                className="form-control-input1"
                                type="number"
                                name="mobile"
                                value={parseInt(mobile)}
                                onChange={(event) =>
                                  setMobile(event.target.value)
                                }
                              />
                              {formErrors.mobile && (
                                <div className="error">{formErrors.mobile}</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="state">
                              State <span className="redstar"> *</span>
                            </label>
                            <select
                              id="state"
                              className="form-control-input1"
                              value={state}
                              onChange={handleStateChange}
                            >
                              <option value="">Select State</option>
                              {Object.keys(stateList).map((id) => (
                                <option value={id} key={id}>
                                  {stateList[id]}
                                </option>
                              ))}
                            </select>
                            {formErrors.state && (
                              <div className="error">{formErrors.state}</div>
                            )}
                          </div>
                          <div className="form-state">
                            <label
                              className="formLabeldonor"
                              htmlFor="district"
                            >
                              District <span className="redstar"> *</span>
                            </label>
                            <select
                              id="district"
                              className="form-control-input1"
                              value={district}
                              onChange={handleDistrictChange}
                            >
                              <option value="">Select District</option>
                              {Object.keys(districtList).map((id) => (
                                <option value={id} key={id}>
                                  {districtList[id]}{" "}
                                </option>
                              ))}
                              {/* Add more options for all available districts */}
                            </select>
                            {formErrors.district && (
                              <div className="error">{formErrors.district}</div>
                            )}
                          </div>
                        </div>
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="city">
                              City <span className="redstar"> *</span>
                            </label>
                            <select
                              id="city"
                              className="form-control-input1"
                              value={city}
                              onChange={(event) => setCity(event.target.value)}
                            >
                              <option value="">Select City</option>
                              {Object.keys(cityList).map((id) => (
                                <option value={id} key={id}>
                                  {cityList[id]}{" "}
                                </option>
                              ))}
                              {/* Add more options for all available cities */}
                            </select>
                            {formErrors.city && (
                              <div className="error">{formErrors.city}</div>
                            )}
                          </div>
                          <div className="form-state">
                            <label className="formLabeldonor" htmlFor="venue">
                              Venue <span className="redstar"> *</span>
                            </label>
                            <input
                              id="venue"
                              type="text"
                              className="form-control-input1"
                              name="venue"
                              value={venue}
                              onChange={(event) => setVenue(event.target.value)}
                            />
                            {formErrors.venue && (
                              <div className="error">{formErrors.venue}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="buttonSec">
                        <div className="cancle">
                          <button
                            className="cancle1"
                            type="button"
                            onClick={handleSubmit}
                          >
                            Update
                          </button>
                        </div>
                        <div className="submit">
                          <button
                            className="submit1"
                            type="cancel"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog>{" "}
                </div>
              )}{" "}
            </div>
          )}{" "}
        </div>
      )}
    </>
  );
}
