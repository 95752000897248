import React, { useEffect, useRef, useState } from "react";
import MaterialTable from "material-table";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import moment from "moment";
import Axios from "axios";
import PopUpIceCard from "../../../../ice_card/popupIceCard";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ICESidebar from "../../../../../components/sidebarICE";
import ICEHeader from "../../../../../components/iceheader";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function AdminIceCard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef1 = React.useRef();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = debounce((search) => fetchSearchResults(search), 500);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  // const [open, setOpen] = useState(false);
  const url = process.env.REACT_APP_URL;

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const columns = [
    {
      title: "Request ID",
      export: true,
      field: "emergency_contacts[0].uniqueIceId",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Request Date",
      export: true,
      field: "date",
      render: (rowData) => {
        if (rowData.date) {
          const formattedDate = moment(rowData.date).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Customer",
      export: false,
      field: "user_signup",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.user_signup) {
          const { fullname, mobile } = rowData.user_signup;
          return (
            <div>
              <span>{fullname}</span>
              <br />
              <span>{mobile}</span>
            </div>
          );
        } else {
          return <em>no info</em>;
        }
      },
    },

    // for export customer name and mobile
    {
      title: "Customer Name",
      export: true,
      field: "user_signup.fullname",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Customer Mobile",
      export: true,
      hidden: true,
      field: "user_signup.mobile",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "DOB",
      export: true,
      field: "user_signup.dob", // Replace "field" with the actual field name
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE1",
      export: false,
      field: "emergency_contacts[0]",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.emergency_contacts[0]) {
          const { nameone, mobileone } = rowData.emergency_contacts[0];
          return (
            <div>
              <span>{nameone}</span>
              <br />
              <span>{mobileone}</span>
            </div>
          );
        } else {
          return <em>no info</em>;
        }
      },
    },

    // for export ICE1 name and mobile
    {
      title: "ICE1 Name",
      export: true,
      field: "emergency_contacts[0].nameone",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE1 Mobile",
      export: true,
      hidden: true,
      field: "emergency_contacts[0].mobileone",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "ICE2",
      field: "emergency_contacts[0]",
      export: false,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.emergency_contacts[0]) {
          const { nametwo, mobiletwo } = rowData.emergency_contacts[0];
          return (
            <div>
              <span>{nametwo}</span>
              <br />
              <span>{mobiletwo}</span>
            </div>
          );
        } else {
          return <em>no info</em>;
        }
      },
    },

    // for export ICE1 name and mobile
    {
      title: "ICE2 Name",
      export: true,
      field: "emergency_contacts[0].nametwo",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE2 Mobile",
      export: true,
      hidden: true,
      field: "emergency_contacts[0].mobiletwo",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "ICE3",
      field: "emergency_contacts[0]",
      export: false,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.emergency_contacts[0]) {
          const { namethree, mobilethree } = rowData.emergency_contacts[0];
          return (
            <div>
              <span>{namethree}</span>
              <br />
              <span>{mobilethree}</span>
            </div>
          );
        } else {
          return <em>no info</em>;
        }
      },
    },

    // for export ICE1 name and mobile
    {
      title: "ICE3 Name",
      export: true,
      field: "emergency_contacts[0].namethree",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "ICE3 Mobile",
      export: true,
      hidden: true,
      field: "emergency_contacts[0].mobilethree",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Blood Group",
      export: true,
      field: "user_signup.bloodgroup", // Replace "field" with the actual field name
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Medical Condition",
      export: true,
      field: "user_signup.medicalcondition", // Replace "field" with the actual field name
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Request Status",
      export: true,
      field: "request_status", // Replace "field" with the actual field name
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Delivery Address",
      export: true,
      field: "customer_address.address",
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData();
    }
  }, []);

  const fetchData = () => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getAllEmergencyCardRequests `)
      .then((response) => {
        // console.log(response, "users");
        setData(response.data.users);
        setDataCount(response.data.length);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchSearchResults = async (search, page) => {
    setLoading(true);
    if (search) {
      Axios.get(
        `${url}/getEmergencyRequestListBySearch/${currentPage}?keyword=${search} `
      )
        .then((response) => {
          // console.log(response);
          if (response.data.Message === "Succesfully fetched data") {
            // console.log(response);
            setData(response?.data?.users);
            setDataCount(response?.data?.users?.length);
            setLoading(false);
          } else {
            // Handle the case when there is no data to fetch
            setData([]);
            setDataCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.error(error);
          setLoading(false);
        });
    } else {
      Axios.get(`${url}/getAllEmergencyCardRequests`)
        .then((response) => {
          // console.log(response);
          setData(response.data.users);
          setDataCount(response.data.length);
          setLoading(false);
        })
        .catch((error) => {
          // console.error(error);
          setLoading(false);
        });
    }
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  // setLoggedIn(true);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <ICESidebar />
      <div className="sidebarleft">
        <ICEHeader />
        <div className="container2">
          <MaterialTable
            title="ICE Card Requests"
            columns={columns}
            data={data}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => <RemoveRedEyeIcon style={{ color: "#007196" }} />,
                tooltip: "View",
                onClick: (e, rowData) => {
                  // var mobile = rowData.mobile;
                  setOpenPopUp(true), setSelectedRow(rowData);
                },
              },
            ]}
            // components={{
            //   Pagination: (props) => (
            //     <Page_Bb
            //       nPages={nPages}
            //       currentPage={currentPage}
            //       setCurrentPage={setCurrentPage}
            //     />
            //   ),
            // }}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              actionsColumnIndex: columns.length,
              stickyHeader: true,
              pageSize: 50,
              pageSizeOptions: [20, 30, 50],
              // paginationType: "stepped",
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
      {openPopUp && (
        <PopUpIceCard
          openPopup={openPopUp}
          setOpenPopUp={setOpenPopUp}
          data={selectedRow}
        ></PopUpIceCard>
      )}
    </>
  );
}
export default AdminIceCard;
