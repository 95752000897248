import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import Header from "../../components/Header";
import Axios from "axios";
import "../../css/changePass.css";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import Sidebars from "../../components/Sidebar";

const AddMasterData = () => {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [errors, setErrors] = useState({});
  const [stateList, setStateList] = useState({});
  const [districtList, setDistrictList] = useState({});
  const [cityList, setCityList] = useState({});

  const url = process.env.REACT_APP_URL;

  // setLoggedIn(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();
    const id = city;

    if (isValid) {
      Axios.post(`${url}/addcity`, { id }).then((response) => {
        if (response.data.Message === "Successfully Added") {
          cityAddedSuccess();
        } else if (response.data.Message === "City already exists") {
          cityExists();
        } else {
        }
      });
    }
  };
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  const cityExists = async (event) => {
    Swal.fire("City already exists !", "", "error");
    navigate("/addMasterData");
  };

  const cityAddedSuccess = async (event) => {
    Swal.fire({
      title: "City Added Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000, // set the duration in milliseconds
    });
    // navigate("/AllBloodbank");
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const validate = () => {
    const newErrors = {};
    if (!state.trim()) {
      newErrors.state = "Please select state";
    }

    if (!district.trim()) {
      newErrors.district = "Please select district";
    }

    if (!city.trim()) {
      newErrors.city = "Please select city";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCLear = () => {
    setCity("");
    setState("");
    setDistrict("");
  };

  // Define a  function to fetch the state and update the state list

  useEffect(() => {
    Axios.get(`${url}/masterstatelist`).then((response) => {
      const newList = {};
      response.data?.states.forEach((item) => {
        newList[item.id] = item.statename;
      });
      setStateList(newList);
    });
  }, []);

  // Define a  function to fetch the district and update the district list
  useEffect(() => {
    if (state) {
      Axios.get(`${url}/masterdistrictsbystate/${state}`).then((response) => {
        const distList = {};

        response.data?.districts?.forEach((item) => {
          distList[item.id] = item.districtname;
        });

        setDistrictList(distList);
      });
    }
  }, [state]);

  useEffect(() => {
    if (district) {
      Axios.get(`${url}/mastercitiesbydistrict/${district}`).then(
        (response) => {
          const cityList = {};
          response.data?.cities?.forEach((item) => {
            cityList[item.id] = item.cityname;
          });
          setCityList(cityList);
        }
      );
    }
  }, [district]);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="password-container">
          <p className="pass-title">Add New Master Data</p>
          <form onSubmit={handleSubmit}>
            <div className="master-contianer-forms">
              <div className="master-div-container">
                <label className="password-label" htmlFor="state">
                  State<p className="redstar">*</p>
                </label>
                <select
                  id="state"
                  className="password-input"
                  value={state}
                  onChange={(event) => setState(event.target.value)}
                  style={{ borderColor: "rgba(0, 113, 150, 0.3)" }}
                >
                  {" "}
                  <option value="">Select State</option>{" "}
                  {Object.keys(stateList).map((id) => (
                    <option value={id} key={id}>
                      {stateList[id]}{" "}
                    </option>
                  ))}
                </select>
                {errors.state && <div className="error">{errors.state}</div>}
              </div>
              <div className="master-div-container">
                <label className="password-label" htmlFor="district">
                  District<p className="redstar">*</p>
                </label>
                <select
                  id="district"
                  className="password-input"
                  value={district}
                  onChange={(event) => setDistrict(event.target.value)}
                  style={{ borderColor: "rgba(0, 113, 150, 0.3)" }}
                >
                  {" "}
                  <option value="">Select District</option>{" "}
                  {Object.keys(districtList).map((id) => (
                    <option value={id} key={id}>
                      {districtList[id]}{" "}
                    </option>
                  ))}
                </select>
                {errors.district && (
                  <div className="error">{errors.district}</div>
                )}
              </div>
              <div className="master-div-container">
                <label className="password-label" htmlFor="city">
                  City<span className="redstar">*</span>
                </label>
                <select
                  id="city"
                  className="password-input"
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  style={{ borderColor: "rgba(0, 113, 150, 0.3)" }}
                >
                  {" "}
                  <option value="">Select City</option>{" "}
                  {Object.keys(cityList).map((id) => (
                    <option value={id} key={id}>
                      {cityList[id]}{" "}
                    </option>
                  ))}
                </select>
                {errors.city && <div className="error">{errors.city}</div>}
              </div>
            </div>
            <div className="button-div">
              <div className="clearMaster" style={{ padding: "1em" }}>
                <button
                  type="clear"
                  className="master-button"
                  onClick={handleCLear}
                >
                  Clear
                </button>
              </div>
              <div className="submitMaster">
                <button type="submit" className="pass-button" onSubmit>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddMasterData;
