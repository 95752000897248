import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Fragment } from "react";

import AdminLogin from "./components/authentication/admin";
import { useContext } from "react";
import { AuthContext } from "./pages/context/AuthContext";

import "./styles.css";
import Header from "./components/Header";

//UserData Imports

//Service Imports
import AllAmbulance from "./pages/services_component/ambulance/allAmbulance";
import AllAmbulanceDetails from "./pages/services_component/ambulance/allAmbulanceDetails";
import AmbBlockList from "./pages/services_component/ambulance/amb-blocklist";

import BBAllBloodBank from "./pages/services_component/blood_bank/bb-allBloodBank";
import BBAllBloodBankDetails from "./pages/services_component/blood_bank/bb-allBloodBankDetails";
import BBSearchBloodGroup from "./pages/services_component/blood_bank/bb-searchBloodGroup";
import BBUpdateBloodGroup from "./pages/services_component/blood_bank/bb-updateBloodGroup";
import BBBlockList from "./pages/services_component/blood_bank/bb-blocklist";
import BBBloodCampManagement from "./pages/services_component/blood_bank/bb-bloodCampManagement";
import BBBloodCampManagementDetails from "./pages/services_component/blood_bank/bb-bloodCampManagementDetails";

import AllNetworkHospital from "./pages/services_component/network_hospital/allNetworkHospital";
import AllNetworkHospitalDetails from "./pages/services_component/network_hospital/allNetworkHospitalDetails";
import NetBlockList from "./pages/services_component/network_hospital/net-blocklist";

import AllSCareTeam from "./pages/services_component/s_care_team/allSCareTeam";
import AllSCareTeamDetails from "./pages/services_component/s_care_team/allSCareTeamDetails";
import SCareBlockList from "./pages/services_component/s_care_team/scare-blocklist";

// component of blood requestees
import ListRequesties from "./pages/user_data_component/blood_requesties/ListRequesties";
import ApproveBloodReq from "./pages/user_data_component/blood_requesties/ApproveBloodReq";
import RejectedBloodReq from "./pages/user_data_component/blood_requesties/RejectedBloodReq";

// components of donors data
import ListsDonors from "./pages/user_data_component/donors/ListsDonors";
import AddBloodDonors from "./pages/user_data_component/donors/AddBloodDonors";

// components of users data
import UpateUserPopup from "./pages/user_data_component/user_data/UpateUserPopup";
import UsersLists from "./pages/user_data_component/user_data/UsersLists";
import ChangePassword from "./components/changePassword/ChangePassword";
//compoents for notificaton
import NotificationAll from "./pages/notification/notificationAll";
import UpdateBloodDonor from "./pages/user_data_component/donors/UpdateBloodDonor";

// master data components
import MasterData from "./pages/master_data/masterData";
import AddMasterData from "./pages/master_data/addMasterData";

// Doctor Profile
import DoctorLogin from "./doctor_profile/authentication/doctor";
//Doctor components
// import AllAppointments from "./doctor_profile/dashboard/AllAppointments/allAppointments";
import DashboarddDoc from "./doctor_profile/dashboard/dashboard";
import DoctorsList from "./pages/doctorsInfo/doctorsList";
import AddDoctor from "./pages/doctorsInfo/addDoctor";
import ResetPassword from "./doctor_profile/authentication/reset_password";

//Pharma components
import DashboardPharma from "./pages/suits_pharma/dashboardPharma";
import Banner from "./pages/suits_pharma/banner";
import Categories from "./pages/suits_pharma/category/categories";
import Coupons from "./pages/suits_pharma/coupons/coupons";
import Products from "./pages/suits_pharma/product/Products";
import SubCategories from "./pages/suits_pharma/subcategory/subCategories";
import Orders from "./pages/suits_pharma/orders/Orders";
import OrderDetails from "./pages/suits_pharma/orders/OrderDetails";
import CategoriesAdd from "./pages/suits_pharma/category/categoriesAdd";
import SubCategoriesAdd from "./pages/suits_pharma/subcategory/subCategoriesAdd";
import CouponsAdd from "./pages/suits_pharma/coupons/couponsAdd";
import ProductsAdd from "./pages/suits_pharma/product/ProductsAdd";
import ProductsEdit from "./pages/suits_pharma/product/ProductsEdit";
import SubCategoriesEdit from "./pages/suits_pharma/subcategory/subCategoriesEdit";
import CategoriesEdit from "./pages/suits_pharma/category/categoriesEdit";

import CouponsEdit from "./pages/suits_pharma/coupons/couponsEdit";
//Lab Modules
import DashboardLab from "./pages/suits_lab/dashboardLab";
import LabTestProviders from "./pages/suits_lab/labTestProviders/lab_test_providers";
import LabCategories from "./pages/suits_lab/labTestCategories/labTestCategories";
import LabPackages from "./pages/suits_lab/labTestPackages/labTestPacakges";
import LabTests from "./pages/suits_lab/labTests/labTests";
import LabBookings from "./pages/suits_lab/labTestsBookings/labTestsBookings";
import LabProvidersAdd from "./pages/suits_lab/labTestProviders/labProvidersAdd";
import LabProvidersEdit from "./pages/suits_lab/labTestProviders/labProvidersEdit";
import LabTestCatAdd from "./pages/suits_lab/labTestCategories/labTestCatAdd";
import LabTestCatEdit from "./pages/suits_lab/labTestCategories/labTestCatEdit";
import LabTestAdd from "./pages/suits_lab/labTests/labTestAdd";
import LabTestEdit from "./pages/suits_lab/labTests/labTestEdit";
//ice cards and bug requests
import IceCard from "./pages/ice_card/iceCardreq";
import BugReq from "./pages/bug_report/bugReq";
import RejResBug from "./pages/bug_report/rejresbug";
import AddLabPackageTest from "./pages/suits_lab/labTestPackages/add-test-package";
import LabTestsBookingDetails from "./pages/suits_lab/labTestsBookings/labTestsBookingDetails";
import EditLabTestPackage from "./pages/suits_lab/labTestPackages/edit-lab-testPackage";
import BC_Donation from "./pages/user_data_component/bc-donation";
import BB_Donation from "./pages/user_data_component/bb-donation";

// AHC components

import MembershipPlans from "./pages/AHC/manage-memberships/ahc-memberships-plans";
// import AHCMemebrs from "./pages/AHC/manage-ahc-members/ahc-members";
import AhcConsultations from "./pages/AHC/ahc-consultations/ahc-consultations";
import AHCMemberships from "./pages/AHC/manage-booking-details/ahc-memberships";
import AHCMembershipHistory from "./pages/AHC/ahc-membership-history/ahc-membership-history";
import TelecallerLists from "./pages/AHC/telecaller/telecaller-lists/telecaller-lists";
import TelecallerSignup from "./pages/AHC/telecaller/telecaller-signup/telecaller-signup";
import TelecallerLogin from "./pages/Telecaller/authentication/telecaller";
import AddAHCPlans from "./pages/AHC/manage-memberships/add-ahc-plans";
import TelecallerResetPassword from "./pages/Telecaller/authentication/reset-password";
import ViewAHCPlans from "./pages/AHC/manage-memberships/view-ahc-plans";
import TelecallerDashboard from "./pages/Telecaller/dashboard/telecaller-dashboard";
import ViewAHCMembersDetails from "./pages/AHC/manage-booking-details/ahc-members-details";
import ViewGHCMembers from "./pages/GHC/ghc-membership-details/view-ghc-members";
import GHCHistory from "./pages/GHC/ghc-history/ghc-history";
import AddGHCPlans from "./pages/GHC/ghc-plans/add-ghc-plans";
import ViewGhcPlans from "./pages/GHC/ghc-plans/view-ghc-plans";
import GHCMembers from "./pages/GHC/ghc-membership-details/ghc-member-details";
import GhcPlans from "./pages/GHC/ghc-plans/ghc-plans";
import ViewAHCConsultations from "./pages/AHC/ahc-consultations/view-ahc-consultations";
import TelecallerChangePassword from "./pages/Telecaller/authentication/change-password";
import UpdateTelecaller from "./pages/AHC/telecaller/telecaller-signup/update-telecaller";
import UpdateDoctor from "./pages/doctorsInfo/update-doctor";
import TelecallerNotifications from "./pages/Telecaller/authentication/telecaller-notification";
import DoctorChangePassword from "./doctor_profile/authentication/reset_password";
import PasswordReset from "./doctor_profile/authentication/password_reset";
import ForgotPasswordDoctor from "./doctor_profile/authentication/forgot_password";
import TelecallerManagerSignIn from "./pages/Telecaller/manager/authentication/manager";
import ForgotPasswordAdmin from "./components/authentication/admin_forgot_password";
import AdminPasswordReset from "./components/authentication/admin_reset_password";
import ForgotPasswordTelecaller from "./pages/Telecaller/forgot-password/telecaller-forgot";
import PasswordResetTelecaller from "./pages/Telecaller/forgot-password/telecaller-reset";
import AppConfigaration from "./components/settings/configaration";
import ForgotPasswordTelecallerManager from "./pages/Telecaller/manager/authentication/manager-forgot";
import PasswordResetTelecallerManager from "./pages/Telecaller/manager/authentication/manager-reset";
import PostDonation from "./pages/user_data_component/post_donation/postDonation";
import TelecallerManagerChangePassword from "./pages/Telecaller/manager/authentication/change-password";
import ManagerDashboard from "./pages/Telecaller/manager/dashboard/manager-dashboard";
import AhcAdminLogin from "./pages/Admins/ahc/authentication/ahclogin";
import AHCForgotPasswordDoctor from "./pages/Admins/ahc/authentication/ahcForgotPassword";
import AHCAdminPasswordReset from "./pages/Admins/ahc/authentication/ahcResetPassword";
import AHCAdminMembershipPlans from "./pages/Admins/ahc/components/plans/ahcplanslist";
import AHCAdminMembershipHistory from "./pages/Admins/ahc/components/history/ahchistory";
import AhcAdminConsultations from "./pages/Admins/ahc/components/consultations/consultation";
import ViewAHCAdminConsultations from "./pages/Admins/ahc/components/consultations/view-consultations";
import AddAHCAdminPlans from "./pages/Admins/ahc/components/plans/addahcplan";
import ViewAHCAdminPlans from "./pages/Admins/ahc/components/plans/updatePlans";
import ViewAHCAdminMembersDetails from "./pages/Admins/ahc/components/membership/viewmembershipdetails";
import AHCAdminMemberships from "./pages/Admins/ahc/components/membership/membershipdetails";
import AHCAdminChangePassword from "./pages/Admins/ahc/authentication/ahcChangePassword";

import GHCAdminLogin from "./pages/Admins/ghc/authentication/ghclogin";
import GhcAdminPlans from "./pages/Admins/ghc/components/plans/planslists";
import AddGHCAdminPlan from "./pages/Admins/ghc/components/plans/addplans";
import ViewGhcAdminPlans from "./pages/Admins/ghc/components/plans/viewplans";
import GHCAdminHistory from "./pages/Admins/ghc/components/history/history";
import GHCAdminMembers from "./pages/Admins/ghc/components/membershipdetails/membershipdetails";
import ViewAdminGHCMembers from "./pages/Admins/ghc/components/membershipdetails/viewmembership";
import GHCAdminChangePassword from "./pages/Admins/ghc/authentication/ghcChangePassword";
import GHCADMINForgotPasswordDoctor from "./pages/Admins/ghc/authentication/ghcForgot";
import GHCAdminPasswordReset from "./pages/Admins/ghc/authentication/ghcReset";
import IntrestedBloodDonor from "./pages/user_data_component/donor_intrest/donorIntrest";
import ManagerNotifications from "./pages/Telecaller/manager/components/notifications";
import AmbulanceAdminLogin from "./pages/Admins/ambulance/authentication/ambulancelogin";
import AmbulanceForgotPasswordAdmin from "./pages/Admins/ambulance/authentication/ambulanceForgot";
import ICEAdminLogin from "./pages/Admins/ICE/authentication/login";
import ICEForgotPasswordAdmin from "./pages/Admins/ICE/authentication/forgot";
import ICEPasswordReset from "./pages/Admins/ICE/authentication/reset";
import AmbulanceAdminPasswordReset from "./pages/Admins/ambulance/authentication/ambulanceReset";
import AdminAllAmbulance from "./pages/Admins/ambulance/components/ambulance/allambulance";
import AmbBlockListAdmin from "./pages/Admins/ambulance/components/ambulance/blockedamb";
import AdminAddAmbulanceDetails from "./pages/Admins/ambulance/components/ambulance/addambu";
import AmbulanceAdminChangePassword from "./pages/Admins/ambulance/authentication/ambulanceChangePassword";
import AdminIceCard from "./pages/Admins/ICE/components/ice card/icecardreqlist";
import ICEAdminChangePassword from "./pages/Admins/ICE/authentication/changePass";
import AhcAdminDashboard from "./pages/Admins/ahc/components/dashboard/dashboard";
import AmbulanceAdminDashboard from "./pages/Admins/ambulance/components/dashboard/dashbaordAmbulance";
import GHCAdminDashboard from "./pages/Admins/ghc/components/dashboard/dashboardGhc";
import ICEAdminDashboard from "./pages/Admins/ICE/components/dashboard/IceDashbaord";
import AdminMyProfile from "./components/settings/adminProfile";
import AHCAdminMyProfile from "./pages/Admins/ahc/authentication/myProfile";
import GHCAdminMyProfile from "./pages/Admins/ghc/authentication/ghcMyProfile";
import AmbulanceAdminMyProfile from "./pages/Admins/ambulance/authentication/myProfile";
import ICEAdminMyProfile from "./pages/Admins/ICE/authentication/myProfile";
import DoctorNotifications from "./doctor_profile/dashboard/notifications/doctor-notificaitons";

function App() {
  const {
    loggedIn,
    userRole,
    doctorLogin,
    telecallerLogin,
    telecallerManager,
    ahcAdminLogin,
    ghcAdminLogin,
    adminAmbulanceLogin,
    iceAdminLogin,
  } = useContext(AuthContext);

  return (
    <>
      <BrowserRouter>
        {/* --------------------------------- DOCTOR ROUTES START------------------------------------------- */}
        <Routes>
          <Route path="/" element={!doctorLogin && <DoctorLogin />} />
          {/* <Route path="/reset_password" element={<ResetPassword />} /> */}
        </Routes>
        <Routes>
          <Route path="/doctor" element={doctorLogin && <DashboarddDoc />} />

          <Route path="/dashboard" element={<DashboarddDoc />} />
          <Route path="/reset_password" element={<DoctorChangePassword />} />

          <Route
            path="/doctor/notification"
            element={<DoctorNotifications />}
          />
          <Route path="/password_reset" element={<PasswordReset />} />
          <Route path="/forgot_password" element={<ForgotPasswordDoctor />} />
        </Routes>
        {/* --------------------------------- DOCTOR ROUTES END------------------------------------------- */}

        {/* --------------------------------- TELECALLER ROUTES START------------------------------------------- */}

        <Routes>
          <Route
            path="/csr/login"
            element={!telecallerLogin && <TelecallerLogin />}
          />
          <Route path="/reset-password" element={<TelecallerResetPassword />} />
        </Routes>

        <Routes>
          <Route
            path="/csr/login"
            element={telecallerLogin && <TelecallerDashboard />}
          />

          <Route
            path="/csr/password-reset"
            element={<PasswordResetTelecaller />}
          />

          <Route
            path="/csr/forgot-password"
            element={<ForgotPasswordTelecaller />}
          />

          <Route path="/csr/dashboard" element={<TelecallerDashboard />} />
          <Route
            path="/change-password"
            element={<TelecallerChangePassword />}
          />
          <Route
            path="/csr/notification"
            element={<TelecallerNotifications />}
          />
        </Routes>

        {/* --------------------------------- TELECALLER ROUTES END ------------------------------------------- */}

        {/* --------------------------------- TELECALLER MANAGER ROUTES START------------------------------------------- */}

        <Routes>
          <Route
            path="/csr/manager/login"
            element={!telecallerManager && <TelecallerManagerSignIn />}
          />
        </Routes>

        <Routes>
          <Route
            path="/csr/manager"
            element={telecallerManager && <ManagerDashboard />}
          />

          <Route path="/csr/manager/dashboard" element={<ManagerDashboard />} />

          <Route
            path="/csr/manager/forgot-password"
            element={<ForgotPasswordTelecallerManager />}
          />
          <Route
            path="/csr/manager/password-reset"
            element={<PasswordResetTelecallerManager />}
          />
          <Route
            path="/csr/manager/change-password"
            element={<TelecallerManagerChangePassword />}
          />
          <Route
            path="/csr/manager/notifications"
            element={<ManagerNotifications />}
          />
        </Routes>
        {/* --------------------------------- TELECALLER MANAGER ROUTES END------------------------------------------- */}

        {/* --------------------------------- AHC ADMIN ROUTES START------------------------------------------- */}

        <Routes>
          <Route
            path="/ahcadmin/login"
            element={!ahcAdminLogin && <AhcAdminLogin />}
          />
          <Route
            path="/ahcadmin/forgot"
            element={<AHCForgotPasswordDoctor />}
          />
          <Route path="/ahcadmin/reset" element={<AHCAdminPasswordReset />} />
        </Routes>
        <Routes>
          <Route
            path="/ahcadmin/login"
            element={ahcAdminLogin && <AhcAdminDashboard />}
          />{" "}
          <Route path="/ahcadmin/dashboard" element={<AhcAdminDashboard />} />{" "}
          <Route
            path="/ahcadmin/planlists"
            element={<AHCAdminMembershipPlans />}
          />
          <Route
            path="/ahcadmin/history"
            element={<AHCAdminMembershipHistory />}
          />
          <Route
            path="/ahcadmin/consultationdetails"
            element={<AhcAdminConsultations />}
          />
          <Route
            path="/ahcadmin/view-consultation"
            element={<ViewAHCAdminConsultations />}
          />
          <Route path="/ahcadmin/addplan" element={<AddAHCAdminPlans />} />
          <Route path="/ahcadmin/editplan" element={<ViewAHCAdminPlans />} />
          <Route
            path="/ahcadmin/membershipdetails"
            element={<AHCAdminMemberships />}
          />
          <Route
            path="/ahcadmin/viewmembershipdetails"
            element={<ViewAHCAdminMembersDetails />}
          />
          <Route
            path="/ahcadmin/changepassword"
            element={<AHCAdminChangePassword />}
          />
          <Route path="/ahcadmin/myprofile" element={<AHCAdminMyProfile />} />
        </Routes>
        {/* --------------------------------- AHC ADMIN ROUTES END------------------------------------------- */}

        {/* --------------------------------- GHC ADMIN ROUTES START------------------------------------------- */}

        <Routes>
          <Route
            path="/ghcadmin/login"
            element={!ghcAdminLogin && <GHCAdminLogin />}
          />
          <Route
            path="/ghcadmin/forgot"
            element={<GHCADMINForgotPasswordDoctor />}
          />
          <Route path="/ghcadmin/reset" element={<GHCAdminPasswordReset />} />
        </Routes>
        <Routes>
          <Route
            path="/ghcadmin/login"
            element={ghcAdminLogin && <GHCAdminDashboard />}
          />{" "}
          <Route path="/ghcadmin/dashboard" element={<GHCAdminDashboard />} />{" "}
          <Route path="/ghcadmin/planlists" element={<GhcAdminPlans />} />
          <Route path="/ghcadmin/addplan" element={<AddGHCAdminPlan />} />
          <Route path="/ghcadmin/editplan" element={<ViewGhcAdminPlans />} />
          <Route path="/ghcadmin/history" element={<GHCAdminHistory />} />
          <Route
            path="/ghcadmin/membershipdetails"
            element={<GHCAdminMembers />}
          />
          <Route
            path="/ghcadmin/viewmembers"
            element={<ViewAdminGHCMembers />}
          />
          <Route
            path="/ghcadmin/changepassword"
            element={<GHCAdminChangePassword />}
          />
          <Route path="/ghcadmin/myprofile" element={<GHCAdminMyProfile />} />
        </Routes>
        {/* --------------------------------- GHC ADMIN ROUTES END------------------------------------------- */}

        {/* --------------------------------- Ambulance ADMIN ROUTES START------------------------------------------- */}
        <Routes>
          <Route
            path="/sans/login"
            element={!adminAmbulanceLogin && <AmbulanceAdminLogin />}
          />
          <Route
            path="/sans/forgot"
            element={<AmbulanceForgotPasswordAdmin />}
          />
          <Route path="/sans/reset" element={<AmbulanceAdminPasswordReset />} />
        </Routes>

        <Routes>
          <Route
            path="/sans/login"
            element={adminAmbulanceLogin && <AdminAllAmbulance />}
          />{" "}
          <Route
            path="/sans/dashboard"
            element={adminAmbulanceLogin && <AmbulanceAdminDashboard />}
          />{" "}
          <Route path="/sans/list" element={<AdminAllAmbulance />} />
          <Route path="/sans/block-list" element={<AmbBlockListAdmin />} />
          <Route path="/sans/addnew" element={<AdminAddAmbulanceDetails />} />
          <Route
            path="/sans/change-password"
            element={<AmbulanceAdminChangePassword />}
          />
          <Route path="/sans/myprofile" element={<AmbulanceAdminMyProfile />} />
        </Routes>

        {/* ---------------------------------Ambulance ADMIN ROUTES END------------------------------------------- */}

        {/* --------------------------------- ICE ADMIN ROUTES START------------------------------------------- */}
        <Routes>
          <Route
            path="/ice/login"
            element={!iceAdminLogin && <ICEAdminLogin />}
          />
          <Route path="/ice/forgot" element={<ICEForgotPasswordAdmin />} />
          <Route path="/ice/reset" element={<ICEPasswordReset />} />
        </Routes>

        <Routes>
          <Route
            path="/ice/login"
            element={iceAdminLogin && <AdminIceCard />}
          />{" "}
          <Route path="/ice/dashboard" element={<ICEAdminDashboard />} />{" "}
          <Route path="/ice/requestlist" element={<AdminIceCard />} />
          <Route
            path="/ice/change-password"
            element={<ICEAdminChangePassword />}
          />
          <Route path="/ice/myprofile" element={<ICEAdminMyProfile />} />
        </Routes>

        {/* ---------------------------------ICE ADMIN ROUTES END------------------------------------------- */}

        {/* --------------------------------- ADMIN ROUTES START------------------------------------------- */}

        {loggedIn && userRole === "admin"}
        <Routes>
          <Route path="/admin" element={!loggedIn && <AdminLogin />} />
        </Routes>
        <Routes>
          <Route
            path="/admin/forgot-password"
            element={<ForgotPasswordAdmin />}
          />
          <Route
            path="/admin/password-reset"
            element={<AdminPasswordReset />}
          />

          <Route path="/app/configuration" element={<AppConfigaration />} />

          <Route path="/DashboardPharma" element={<DashboardPharma />} />
          <Route path="/Banner" element={<Banner />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/Coupons" element={<Coupons />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/ProductsEdit" element={<ProductsEdit />} />
          <Route path="/SubCategories" element={<SubCategories />} />
          <Route path="/Orders" element={<Orders />} />
          <Route path="/OrderDetails/:orderId" element={<OrderDetails />} />
          <Route path="/CategoriesAdd" element={<CategoriesAdd />} />
          <Route path="/SubCategoriesAdd" element={<SubCategoriesAdd />} />
          <Route path="/CouponsAdd" element={<CouponsAdd />} />
          <Route path="/ProductsAdd" element={<ProductsAdd />} />
          <Route path="/SubCategoriesEdit" element={<SubCategoriesEdit />} />
          <Route path="/CategoriesEdit" element={<CategoriesEdit />} />
          <Route path="/couponsEdit" element={<CouponsEdit />} />
        </Routes>

        <div className="com12">
          <Routes>
            <Route path="/admin" element={loggedIn && <UsersLists />} />
            <Route path="/Header" element={<Header />} />

            {/* Routing of Users */}
            <Route path="/UpateUserPopup" element={<UpateUserPopup />} />
            <Route path="/users-list" element={<UsersLists />} />
            <Route path="/ListRequesties" element={<ListRequesties />} />
            <Route path="/ApproveBloodReq" element={<ApproveBloodReq />} />
            <Route path="/ListsDonors" element={<ListsDonors />} />
            <Route path="/RejectedBloodReq" element={<RejectedBloodReq />} />
            <Route path="/add/blood-donor" element={<AddBloodDonors />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/myprofile" element={<AdminMyProfile />} />
            <Route path="/UpdateBloodDonor" element={<UpdateBloodDonor />} />
            <Route
              path="/intrestedblooddonor"
              element={<IntrestedBloodDonor />}
            />

            {/* Routing for GHC */}
            <Route path="/view-ghc-members" element={<ViewGHCMembers />} />
            <Route path="/ghc-history" element={<GHCHistory />} />
            <Route path="/add-ghc-plans" element={<AddGHCPlans />} />
            <Route path="/ghc-member-details" element={<GHCMembers />} />
            <Route path="/view-ghc-plans" element={<ViewGhcPlans />} />
            <Route path="/ghc-plans" element={<GhcPlans />} />

            {/* Routing for AHC */}
            <Route
              path="/view-ahc-consultations"
              element={<ViewAHCConsultations />}
            />
            <Route
              path="/ahc-memberships-plans"
              element={<MembershipPlans />}
            />
            <Route path="/ahc-consultations" element={<AhcConsultations />} />
            <Route path="/ahc-memberships" element={<AHCMemberships />} />
            <Route
              path="/ahc-membership-history"
              element={<AHCMembershipHistory />}
            />
            <Route path="/telecaller-lists" element={<TelecallerLists />} />
            <Route path="/telecaller-signup" element={<TelecallerSignup />} />
            <Route path="/add-ahc-plans" element={<AddAHCPlans />} />
            <Route path="/view-ahc-plans" element={<ViewAHCPlans />} />
            <Route path="/update-telecaller" element={<UpdateTelecaller />} />
            <Route path="/update-doctor" element={<UpdateDoctor />} />
            <Route
              path="/ahc-members-details"
              element={<ViewAHCMembersDetails />}
            />

            <Route path="/bc-donation" element={<BC_Donation />} />
            <Route path="/bb-donation" element={<BB_Donation />} />

            {/* Routing for Services  */}

            <Route path="/AllAmbulance" element={<AllAmbulance />} />
            <Route
              path="/AllAmbulanceDetails"
              element={<AllAmbulanceDetails />}
            />
            <Route path="/AmbBlockList" element={<AmbBlockList />} />

            <Route path="/AllBloodbank" element={<BBAllBloodBank />} />
            <Route
              path="/BBAllBloodBankDetails"
              element={<BBAllBloodBankDetails />}
            />
            <Route path="/allSCareTeam" element={<AllSCareTeam />} />
            <Route
              path="/BBBloodCampManagementDetails"
              element={<BBBloodCampManagementDetails />}
            />
            <Route path="/SearchBloodGroup" element={<BBSearchBloodGroup />} />
            <Route path="/UpdateBloodGroup" element={<BBUpdateBloodGroup />} />
            <Route path="/BBBlockList-BB" element={<BBBlockList />} />
            <Route
              path="/BBBloodCampManagement-BB"
              element={<BBBloodCampManagement />}
            />

            <Route
              path="/AllNetworkHospital"
              element={<AllNetworkHospital />}
            />
            <Route
              path="/AllNetworkHospitalDetails"
              element={<AllNetworkHospitalDetails />}
            />
            <Route path="/NetBlockList" element={<NetBlockList />} />
            <Route path="/NetBlockList" element={<NetBlockList />} />

            <Route path="/post-donation" element={<PostDonation />} />
            <Route
              path="/AllSCareTeamDetails"
              element={<AllSCareTeamDetails />}
            />
            <Route path="/SCareBlockList" element={<SCareBlockList />} />

            <Route path="/NotificationAll" element={<NotificationAll />} />

            {/* routing for master data */}
            <Route path="/masterData" element={<MasterData />} />
            <Route path="/addMasterData" element={<AddMasterData />} />
            <Route path="/doctorsList" element={<DoctorsList />} />
            <Route path="/addDoctor" element={<AddDoctor />} />

            {/* routing for ICE cards and Bug report */}
            <Route path="/IceCard" element={<IceCard />} />
            <Route path="/BugReq" element={<BugReq />} />
            <Route path="/RejResBug" element={<RejResBug />} />

            {/* routing for suits pharma */}
            <Route path="/SuitsLab" element={<DashboardLab />} />
            <Route path="/Providers" element={<LabTestProviders />} />
            <Route path="/LabCategories" element={<LabCategories />} />
            <Route path="/Packages" element={<LabPackages />} />
            <Route path="/LabTests" element={<LabTests />} />
            <Route path="/Bookings" element={<LabBookings />} />
            <Route
              path="/BookingDetails/:labId"
              element={<LabTestsBookingDetails />}
            />
            <Route path="/LabProvidersEdit" element={<LabProvidersEdit />} />
            <Route path="/LabProvidersAdd" element={<LabProvidersAdd />} />
            <Route path="/LabTestCatAdd" element={<LabTestCatAdd />} />
            <Route path="/LabTestCatEdit" element={<LabTestCatEdit />} />
            <Route path="/LabTestAdd" element={<LabTestAdd />} />
            <Route path="/LabTestEdit" element={<LabTestEdit />} />
            <Route path="/add-test-package" element={<AddLabPackageTest />} />
            <Route
              path="/edit-lab-testPackage"
              element={<EditLabTestPackage />}
            />
          </Routes>
        </div>

        {/* --------------------------------- ADMIN ROUTES END------------------------------------------- */}
      </BrowserRouter>
    </>
  );
}

export default App;
