import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import SidebarLab from "../../../components/sidebarLab";
import HeaderLab from "../../../components/HeaderLab";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import Axios from "axios";
import Swal from "sweetalert2";

function LabTestEdit() {
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [nameError, setNameError] = useState("");
  const location = useLocation();
  const verifyToken = localStorage.getItem("token");
  let navigate = useNavigate();

  const [ids, setIds] = useState("");
  const [subname, setSubName] = useState("");
  const [description, setDiscrption] = useState("");
  const [parname, setParName] = useState("");

  const editData = location?.state?.data;
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    // console.log(editData);

    if (editData?.id) {
      setIds(editData?.id);
    }

    if (editData?.test_name) {
      setSubName(editData?.test_name);
    }

    if (editData?.description) {
      setDiscrption(editData?.description);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validate();

    if (isValid) {
      const id = ids;
      const subcategoryName = subname;
      const parcategoryName = description;

      const requestData = {
        id: id,
        test_name: subcategoryName,
        description: parcategoryName,
      };

      Axios.put(`${url}/updateLabtests`, requestData, {
        headers: {
          Authorization: `Bearer ${verifyToken}`,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.Message === "Labtest details updated successfully.") {
            loginSuccess1();
          } else if (res.data.Message) {
            loginErrorCamp();
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle the error
        });
    }
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Lab Tests Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });

    setTimeout(function () {
      navigate("/LabTests");
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorCamp = async (event) => {
    Swal.fire("Error Updating Lab Tests!", "", "error");
  };

  const validate = () => {
    const newErrors = {};

    if (!subname.trim()) {
      newErrors.subname = "Lab Test Name is required";
    }

    if (!description.trim()) {
      newErrors.description = "Description is required";
    }

    setNameError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClear = () => {
    setSubName(editData?.test_name);
    setDiscrption(editData?.description);
  };

  const backbutton = () => {
    navigate("/LabTests");
  };

  return (
    <>
      <SidebarLab />
      <div className="sidebarleft">
        <HeaderLab />
        <div className="subCategoryEditcontainerAdd01">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Lab Test</u>/ Update
              </b>
            </header>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="maincontainer">
              <div className="subCatergoryEditinput1">
                <label className="subCategoryLable01">
                  <b> Lab Test Name </b>
                  <div className="redStart">*</div>
                </label>
                <input
                  type="text"
                  className="subCategory01"
                  placeholder=" Lab Test Name"
                  value={subname}
                  onChange={(e) => setSubName(e.target.value)}
                />
                {nameError.subname && (
                  <div className="error">{nameError.subname}</div>
                )}
              </div>
              <div className="subCatergoryEditinput2">
                <label className="subCategoryLable01">
                  <b> Discription </b>
                  <div className="redStart">*</div>
                </label>

                <textarea
                  className="productdetials001"
                  value={description}
                  onChange={(e) => setDiscrption(e.target.value)}
                />
                {nameError.description && (
                  <div className="error">{nameError.description}</div>
                )}
              </div>
            </div>
            <button
              type="button"
              onClick={handleClear}
              className="clearbuttonSubCategoryEdit01"
            >
              Reset
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="SubmitbuttonSubCategoryEdit01"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default LabTestEdit;
