import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../css/popupModule.css";
import docimg from "../../images/doctor.jpg";
import Axios from "axios";
import Swal from "sweetalert2";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";

export default function PopupDashboard({ openPopup, setOpenPopUp }) {
  const [name, setName] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [email, setEmail] = useState("");
  const [aboutdoctor, setAboutdoctor] = useState("");
  const [docid, setDocid] = useState("");

  const [phone_no, setPhone_no] = useState("");
  const [hospital_location, setHospital_location] = useState("");
  const [DOB, setDOB] = useState("");
  const [license_no, setLicense_no] = useState("");
  const [specializations, setSpecializations] = useState("");

  const [optonline, setOptonline] = useState(false);

  const [formErrors, setFormErrors] = useState({}); // State to store form errors

  const verifyDoctorToken = localStorage.getItem("doctor_token");
  const url = process.env.REACT_APP_URL;

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleSubmit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${verifyDoctorToken}`,
      },
    };

    const data = {
      doctor_id: docid,
      hospital_location: hospital_location,
      experience: experience,
      phone_no: phone_no,
      email: email,
      about_doctor: aboutdoctor,
    };

    Axios.put(`${url}/updateDoctorDetails`, data)
      .then((res) => {
        if (res.data.Message === "email updated") {
          loginSuccess2();
        } else if (res.data.Message === "Successfully Updated Details") {
          loginSuccess1();
        } else if (res.data.Message === "Mobile number already taken") {
          loginErrorMob();
        } else if (res.data.Message === "Email is already taken") {
          EmailloginErrorMob();
        }
      })
      .catch((error) => {});
  };

  const EmailloginErrorMob = async (event) => {
    Swal.fire("Email already taken, please use different email!", "", "error");
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Doctor details Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const loginSuccess2 = async (event) => {
    Swal.fire({
      title: "Email updated Successfully, Please login again!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000, // set the duration in milliseconds
    });

    setTimeout(function () {
      localStorage.removeItem("doctor_token");
      localStorage.removeItem("role");
      localStorage.removeItem("activeButton");
      localStorage.removeItem("selectedDay");
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorMob = async (event) => {
    Swal.fire("Mobile Number Already Taken !", "", "error");
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await Axios.get(`${url}/getdoctorDetailswithToken`, {
        headers: {
          Authorization: `Bearer ${verifyDoctorToken}`,
        },
      }).then((response) => {
        // console.log(response, "response");
        setDocid(response?.data?.result?.doctor_id);
        setName(response.data.result.name);
        setExperience(response.data.result.experience);
        setQualification(response.data.result.qualification);
        setEmail(response.data.result.email);
        setAboutdoctor(response.data.result.about_doctor);
        setPhone_no(response.data.result.phone_no);
        setHospital_location(response.data.result.hospital_location);
        setDOB(response.data.result.date_of_birth);
        setLicense_no(response.data.result.license_no);
        setSpecializations(response.data.result.specializations);
        setOptonline(response.data.result.opt_online);
      });
    } catch (error) {}
  };

  const validateForm = () => {
    const errors = {};

    if (!name) {
      errors.name = "Please enter your full name";
    }
    if (!hospital_location) {
      errors.hospital_location = "Please enter Hospital Location";
    }
    if (!experience) {
      errors.experience = "Please enter your experience";
    }
    if (!phone_no) {
      errors.phone_no = "Please enter your  mobile number";
    } else if (!/^[0-9]{10}$/.test(phone_no)) {
      errors.phone_no = "Please enter valid mobile number";
    }
    if (!email) {
      errors.email = "Please enter your email";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Please enter a valid email address";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // Form is valid, submit the data
      handleSubmit();
    }
  };

  // console.log("doc_id: ", optonline);

  const handleSwitchChange = (event) => {
    var doc_id = docid;
    // console.log("doc_id: ", doc_id);
    if (optonline === false || optonline === "false") {
      setOptonline(event.target.checked ? true : false);
      if (event.target.checked) {
        Axios.get(`${url}/authorizeUserGoogleAccount`, {
          headers: {
            Authorization: `Bearer ${verifyDoctorToken}`,
          },
        }).then((response) => {
          // console.log(response, "on");

          window.location.href = response.data.url;
        });
      }
    } else if (optonline === true || optonline === "true") {
      Axios.put(`${url}/offOptOnline?doctor_id=${doc_id}`).then((response) => {
        // window.location.href = response.data.url;
        window.location.reload();
        // console.log(response, "off");
      });
    }
  };

  return (
    <>
      {openPopup && (
        <Dialog className="popop" onClose={handleClose} open={openPopup}>
          <DialogTitle dividers>
            Edit Profile
            <div className="closeIcon" onClick={handleClose}>
              <HighlightOffOutlinedIcon />
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="image-text-container">
              <div
                className="circle-image1"
                style={{ backgroundImage: `url(${docimg})` }}
              ></div>
              <div className="text-container">
                <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                  Dr. {name}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Specialty : </strong> {specializations}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Qualification : </strong> {qualification}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Registration Number : </strong> {license_no}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>DOB : </strong>{" "}
                  {DOB ? new Date(DOB).toLocaleDateString("en-GB") : "No DoB"}
                </Typography>
              </div>
            </div>
          </DialogContent>

          <form onSubmit={handleFormSubmit}>
            <div className="first-form">
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="hospitallocation">
                    Hospital Location<span className="redstar"> *</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    className="form-control-input2"
                    name="location"
                    value={hospital_location}
                    onChange={(event) => {
                      setHospital_location(event.target.value);
                    }}
                  />
                  {formErrors.hospital_location && (
                    <div className="error-message">
                      {formErrors.hospital_location}
                    </div>
                  )}
                </div>
              </div>
              <div className="formGroupsec1">
                <div className="form-doc-inline">
                  <div className="form-doc-inline-item">
                    <label className="formLabeldonor1" htmlFor="experience">
                      Experience<span className="redstar"> *</span>
                    </label>
                    <input
                      id="experience"
                      type="text"
                      className="form-control-input3"
                      name="experience"
                      value={experience}
                      onChange={(event) => {
                        setExperience(event.target.value);
                      }}
                    />
                    {formErrors.experience && (
                      <div className="error-message">
                        {formErrors.experience}
                      </div>
                    )}
                  </div>
                  <div className="form-doc-inline-item">
                    <label className="formLabeldonor1" htmlFor="mobile">
                      Mobile Number<span className="redstar"> *</span>
                    </label>
                    <input
                      id="mobile"
                      type="text"
                      className="form-control-input4"
                      name="mobile"
                      value={phone_no}
                      onChange={(event) => {
                        setPhone_no(event.target.value);
                      }}
                    />
                    {formErrors.phone_no && (
                      <div className="error-message1">
                        {formErrors.phone_no}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="Email">
                    Email ID<span className="redstar"> *</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    className="form-control-input2"
                    name="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  {formErrors.email && (
                    <div className="error-message">{formErrors.email}</div>
                  )}
                </div>
              </div>
              <div className="formGroupsec1">
                <div className="form-doc">
                  <label className="formLabeldonor1" htmlFor="aboutMe">
                    About Me
                  </label>
                  <textarea
                    id="aboutMe"
                    className="form-control-input5"
                    name="aboutMe"
                    value={aboutdoctor}
                    onChange={(event) => {
                      setAboutdoctor(event.target.value);
                    }}
                  ></textarea>
                  {/* {formErrors.aboutdoctor && (
                    <div className="error-message">
                      {formErrors.aboutdoctor}
                    </div>
                  )} */}
                </div>
              </div>

              <div className="formGroup12">
                <div className="form-doc12">
                  <span className="gmeet12">
                    <strong>Opt for online consultation (G-Meet)</strong>
                  </span>
                  <Switch
                    {...label}
                    checked={optonline === true}
                    onChange={handleSwitchChange}
                    // disabled={optonline === true}
                  />
                </div>
              </div>
            </div>

            <div className="buttonSec">
              <div className="cancle_ta">
                <button type="cancel" className="cancle1" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div className="submit">
                <button type="submit" className="submit1">
                  Submit
                </button>
              </div>
            </div>
          </form>
          <DialogActions>{/* Add your action buttons here */}</DialogActions>
        </Dialog>
      )}
    </>
  );
}
