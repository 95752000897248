import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import "../../../../../css/adddonor.css";
import SidebarAHC from "../../../../../components/sidebarAhc";
import HeaderAHC from "../../../../../components/headerAhc";
function AddAHCAdminPlans() {
  let navigate = useNavigate();

  const [plan_name, setPlan_name] = useState("");
  const [plan_worth, setPlan_worth] = useState("");
  const [plan_price, setPlan_price] = useState("");
  const [health_checkup_worth, setHealth_checkup_worth] = useState("");
  const [health_checkup_tests, setHealth_checkup_tests] = useState("");
  const [min_age, setMin_age] = useState("");
  const [max_age, setMax_age] = useState("");
  const [covers, setCovers] = useState("");
  const [offline_consultations, setOffline_consultations] = useState("");
  const [online_consultations, setOnline_consultations] = useState("");
  const [discount_on_labs, setDiscount_on_labs] = useState("");
  const [discount_on_hospitalization, setDiscount_on_hospitalization] =
    useState("");
  const [discount_on_physiotherapy, setDiscount_on_physiotherapy] =
    useState("");
  const [errors, setErrors] = useState({});
  const url = process.env.REACT_APP_URL;

  const validate = () => {
    const newErrors = {};

    if (!plan_name.trim()) {
      newErrors.plan_name = "Please plan name";
    }

    if (!plan_worth.trim()) {
      newErrors.plan_worth = "Please enter plan worth";
    }

    if (!plan_price.trim()) {
      newErrors.plan_price = "Please enter plan price";
    }

    if (!health_checkup_worth.trim()) {
      newErrors.health_checkup_worth = "Please enter Health Checkup Worth";
    }

    if (!health_checkup_tests.trim()) {
      newErrors.health_checkup_tests = "Please enter Health Checkup Worth";
    }

    if (!min_age) {
      newErrors.min_age = "Please enter minimum age";
    } else if (!/^\d+$/.test(min_age)) {
      newErrors.min_age = "Please enter a valid age (numbers only)";
    } else if (parseInt(min_age) < 18) {
      newErrors.min_age = "Please enter a valid age above 18 ";
    }

    if (!max_age) {
      newErrors.max_age = "Please enter maximum age";
    } else if (!/^\d+$/.test(max_age)) {
      newErrors.max_age = "Please enter a valid age (numbers only)";
    } else if (parseInt(max_age) > 65) {
      newErrors.max_age = "Please enter a valid age below 65";
    }

    if (!covers.trim()) {
      newErrors.covers = "Please enter covers";
    }

    if (!offline_consultations.trim()) {
      newErrors.offline_consultations = "Please enter offline consultations";
    }

    if (!online_consultations.trim()) {
      newErrors.online_consultations = "Please enter online consultations";
    }

    if (!discount_on_labs.trim()) {
      newErrors.discount_on_labs = "Please enter discount on labs";
    }

    if (!discount_on_physiotherapy.trim()) {
      newErrors.discount_on_physiotherapy =
        "Please enter discount on physiotherapy";
    }

    if (!discount_on_hospitalization.trim()) {
      newErrors.discount_on_hospitalization =
        "Please enter discount on hospitalization";
    }

    if (!health_checkup_tests.trim()) {
      newErrors.health_checkup_tests = "Please enter health checkup tests";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();

    const varData = {
      planName: plan_name,
      planWorth: plan_worth,
      planPrice: plan_price,
      healthCheckupWorth: health_checkup_worth,
      healthcheckuptest: health_checkup_tests,
      minimumAge: min_age,
      maximumAge: max_age,
      covers: covers,
      offlineConsultations: offline_consultations,
      onlineConsultations: online_consultations,
      discountonLabs: discount_on_labs,
      discountonHospitalization: discount_on_hospitalization,
      discountonphysiotherapy: discount_on_physiotherapy,
    };
    if (isValid) {
      Axios.post(`${url}/insertAhcPlans`, { ...varData }).then((response) => {
        if (response.data.Message === "Successfully Created") {
          loginSuccess();
        }
      });
    }
  };

  const loginSuccess = async (event) => {
    Swal.fire("Successfully Added Membership Plans!", "", "success");
    navigate("/ahcadmin/planlists");
  };

  useEffect(() => {
    localStorage.setItem("ahcactiveButton", "/ahcadmin/planlists");
  }, []);

  const backbutton = () => {
    navigate("/ahcadmin/planlists");
  };

  return (
    <>
      <SidebarAHC />
      <div className="sidebarleft">
        <HeaderAHC />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  Membership Plans
                </span>
                <span>{" / Add Membership Plan "}</span>
              </div>
              <div className="add-new-donor">
                <h2 className="heading-addnewdonor1">
                  Add New Membership Plan
                </h2>
              </div>
              <div className="first-form">
                <div className="section2-ahc-plans">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="plan_name">
                      Plan Name<p className="redstar">*</p>
                    </label>
                    <input
                      id="plan_name"
                      className="input-row-2-ahc-plans"
                      type="text"
                      value={plan_name}
                      onChange={(event) => setPlan_name(event.target.value)}
                    />
                    {errors.plan_name && (
                      <div className="error">{errors.plan_name}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="plan_worth">
                      Plan Worth<p className="redstar">*</p>
                    </label>
                    <input
                      id="plan_worth"
                      className="input-row-2-ahc-plans"
                      type="text"
                      value={plan_worth}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setPlan_worth(numericValue);
                      }}
                    />
                    {errors.plan_worth && (
                      <div className="error">{errors.plan_worth}</div>
                    )}
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="plan_price">
                      Plan Price<p className="redstar">*</p>
                    </label>
                    <input
                      id="plan_price"
                      className="input-row-2-ahc-plans"
                      type="text"
                      value={plan_price}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setPlan_price(numericValue);
                      }}
                    />
                    {errors.plan_price && (
                      <div className="error">{errors.plan_price}</div>
                    )}
                  </div>
                </div>
                <div className="section2-ahc-plans">
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="health_checkup_worth"
                    >
                      Health Check-up Worth<p className="redstar">*</p>
                    </label>
                    <input
                      id="health_checkup_worth"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={health_checkup_worth}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setHealth_checkup_worth(numericValue);
                      }}
                    />
                    {errors.health_checkup_worth && (
                      <div className="error">{errors.health_checkup_worth}</div>
                    )}
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="min_age">
                      Minimum Age<p className="redstar">*</p>
                    </label>
                    <input
                      id="min_age"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={min_age}
                      pattern="[0-9]{2}"
                      maxLength="3"
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setMin_age(numericValue);
                      }}
                    />
                    {errors.min_age && (
                      <div className="error">{errors.min_age}</div>
                    )}
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="max_age">
                      Maximum Age<span className="redstar">*</span>
                    </label>
                    <input
                      id="max_age"
                      className="input-row-2-ahc-plans"
                      type="text"
                      pattern="[0-9]{2}"
                      maxLength="3"
                      value={max_age}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setMax_age(numericValue);
                      }}
                    />
                    {errors.max_age && (
                      <div className="error">{errors.max_age}</div>
                    )}
                  </div>
                </div>
                <div className="section2-ahc-plans">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="covers">
                      Plan Covers<p className="redstar">*</p>
                    </label>
                    <input
                      id="covers"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={covers}
                      onChange={(event) => setCovers(event.target.value)}
                    />
                    {errors.covers && (
                      <div className="error">{errors.covers}</div>
                    )}
                  </div>
                  <div className="form-email">
                    <label
                      className="formLabeldonor"
                      htmlFor="offline_consultations"
                    >
                      Offline Consultations<p className="redstar">*</p>
                    </label>
                    <input
                      id="offline_consultations"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={offline_consultations}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setOffline_consultations(numericValue);
                      }}
                    />
                    {errors.offline_consultations && (
                      <div className="error">
                        {errors.offline_consultations}
                      </div>
                    )}
                  </div>
                  <div className="form-email">
                    <label
                      className="formLabeldonor"
                      htmlFor="online_consultations"
                    >
                      Online Consultations<p className="redstar">*</p>
                    </label>
                    <input
                      id="online_consultations"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={online_consultations}
                      onChange={(event) =>
                        setOnline_consultations(event.target.value)
                      }
                    />
                    {errors.online_consultations && (
                      <div className="error">{errors.online_consultations}</div>
                    )}
                  </div>
                </div>

                <div className="section2-ahc-plans">
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="discount_on_labs"
                    >
                      Discount on Labs<p className="redstar">*</p>
                    </label>
                    <input
                      id="discount_on_labs"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={discount_on_labs}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setDiscount_on_labs(numericValue);
                      }}
                    />
                    {errors.discount_on_labs && (
                      <div className="error">{errors.discount_on_labs}</div>
                    )}
                  </div>
                  <div className="form-email">
                    <label
                      className="formLabeldonor"
                      htmlFor="discount_on_hospitalization"
                    >
                      Discount on Hospitalization<p className="redstar">*</p>
                    </label>
                    <input
                      id="discount_on_hospitalization"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={discount_on_hospitalization}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setDiscount_on_hospitalization(numericValue);
                      }}
                    />
                    {errors.discount_on_hospitalization && (
                      <div className="error">
                        {errors.discount_on_hospitalization}
                      </div>
                    )}
                  </div>
                  <div className="form-email">
                    <label
                      className="formLabeldonor"
                      htmlFor="discount_on_physiotherapy"
                    >
                      Discount on Physiotherapy<p className="redstar">*</p>
                    </label>
                    <input
                      id="discount_on_physiotherapy"
                      type="text"
                      className="input-row-2-ahc-plans"
                      value={discount_on_physiotherapy}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setDiscount_on_physiotherapy(numericValue);
                      }}
                    />
                    {errors.discount_on_physiotherapy && (
                      <div className="error">
                        {errors.discount_on_physiotherapy}
                      </div>
                    )}
                  </div>
                </div>
                <div className="section2-ahc-plans">
                  <div className="form-dob">
                    <label
                      className="formLabeldonor"
                      htmlFor="health_checkup_tests"
                    >
                      Health Checkup Tests<p className="redstar">*</p>
                    </label>
                    <textarea
                      id="health_checkup_tests"
                      className="input-row-2-ahc-plans1"
                      value={health_checkup_tests}
                      onChange={(event) =>
                        setHealth_checkup_tests(event.target.value)
                      }
                    ></textarea>
                    {errors.health_checkup_tests && (
                      <div className="error">{errors.health_checkup_tests}</div>
                    )}
                  </div>
                </div>
                <div className="section11">
                  <div className="cancle">
                    <button
                      type="cancel"
                      className="cancle1"
                      onClick={() => navigate("/ahcadmin/planlists")}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="submit">
                    <button type="submit" className="submit1" onSubmit>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default AddAHCAdminPlans;
