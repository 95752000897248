import React, { useEffect, useRef, useState, useMemo } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@mui/lab";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import Swal from "sweetalert2";
import Page_Bb from "../../services_component/blood_bank/Page_bb";
import NextPlanIcon from "@mui/icons-material/NextPlan";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Orders() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataone, setDataone] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef1 = React.useRef();
  const [searchText, setSearchText] = useState("");
  //const debouncedSearch = debounce((search) => fetchSearchResults(search), 500);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const url = process.env.REACT_APP_URL;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statusFilter, setStatusFilter] = useState("");
  const [prescriptionSelected, setPrescriptionSelected] = useState("");
  const [amountSelected, setAmountSelected] = useState("");

  let debounceTimer = null;

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const columns = [
    {
      title: "Order ID",
      field: "orderId",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Order Date",
      field: "date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.createdAt) {
          const formattedDate = moment(rowData.createdAt).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Customer",
      field: "customerName",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Address",
      field: "customerAddress",

      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Prescription",
      field: "prescriptionId",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.prescriptionId > 0) {
          return (
            <div>
              <span>Available</span>
            </div>
          );
        } else {
          return (
            <div>
              <span>Not Available</span>
            </div>
          );
        }
      },
    },
    {
      title: "Amount",
      field: "payment_status",
      render: (rowData) => {
        let backgroundColor = "";
        switch (rowData.payment_status.toLowerCase()) {
          case "unpaid":
            backgroundColor = "#e3c73b";
            break;
          case "paid":
            backgroundColor = "#5acc7a";
            break;
          case "canceled":
          case "returned":
          case "failed":
            backgroundColor = "red";
            break;
          default:
            break;
        }
        return (
          <div>
            <span>{rowData.order_amount}</span>
            <br />
            <span style={{ fontSize: "12px", color: backgroundColor }}>
              {rowData.payment_status}
            </span>
          </div>
        );
        // const combinedValue = `${rowData.order_amount} ${rowData.payment_status}`;
        // return combinedValue;
      },
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Order Status",
      field: "order_status",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        let backgroundColor = "";
        switch (rowData.order_status.toLowerCase()) {
          case "pending":
          case "packaging":
            backgroundColor = "#e3ca86";
            break;
          case "out for delivery":
          case "delivered":
          case "confirmed":
            backgroundColor = "#d8f0df";
            break;
          case "canceled":
          case "returned":
          case "failed":
            backgroundColor = "red";
            break;
          default:
            break;
        }
        //return { backgroundColor };
        return <div style={{ backgroundColor }}>{rowData.order_status}</div>;
      },
    },
  ];

  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "pharma");
  }, []);

  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getAllOrders/${page}`)
      .then((response) => {
        // console.log("tabledata ", response.data);

        setData(response?.data?.result);
        setDataCount(response?.data?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const debouncedSearch = (term) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleSearchTerm(term);
    }, 500); // Adjust the debounce delay as needed
  };

  const handleSearchTerm = async (search) => {
    //setLoading(true);
    try {
      // console.log("handleSearch ", search);
      const response = await Axios.get(
        `${url}/searchOrder?searchTerm=${search}`
      );
      // console.log("handleSearch ", response);
      setData(response.data.result);
      //setLoading(false);
    } catch (error) {
      // console.error("handleSearch error ", error);
      //setLoading(false);
    }
  };

  const handleSearchOrderID = (event) => {
    // console.log("search ", event.target.value);
    setSearchText(event.target.value);
    debouncedSearch(event.target.value);
  };
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  const handleStartDateChange = (event) => {
    //const parsedDate = new Date(event.target.value);
    if (event.target.value) {
      setStartDate(event.target.value);
      // console.log("handleStartDateChange ", event.target.value);
      // console.log("handleStartDateChange One", startDate, event.target.value);
    } else {
      // console.log("handleStartDateChange else", event.target.value);
      setStartDate("dd/mm/yyyy");
    }
  };

  const handleEndDateChange = (event) => {
    //const parsedDate = new Date(event.target.value);
    if (event.target.value) {
      setEndDate(event.target.value);
      // console.log("handleEndDateChange ", endDate, event.target.value);
    } else {
      setEndDate("dd/mm/yyyy");
    }
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handlePrescriptionChange = (event) => {
    setPrescriptionSelected(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmountSelected(event.target.value);
    //console.log("handleAmountChange amountSelected ", event.target.value);
  };

  const filteredData = useMemo(() => {
    // console.log("in memo");

    const filteredDataValue = data.filter((item) => {
      // console.log("memo Date ", startDate, endDate, item.date);
      //const endDateValue = new Date(item.createdAt);
      const endDateValue = moment(item.createdAt).format("DD-MM-YYYY");
      // console.log("createdAt ", endDateValue);
      if (
        statusFilter &&
        item.order_status.toLowerCase() !== statusFilter.toLowerCase()
      ) {
        return false;
      }
      // console.log("amountSelected ", amountSelected, item.payment_status);
      if (
        amountSelected &&
        item.payment_status.toLowerCase() !== amountSelected.toLowerCase()
      ) {
        // console.log("amountSelected in", amountSelected, item.payment_status);
        return false;
      }
      if (
        prescriptionSelected &&
        item.prescriptionStatus.toString().toLowerCase() !==
          prescriptionSelected.toString().toLocaleLowerCase()
      ) {
        return false;
      }
      // if (searchText && item.orderId.toString() !== searchText.toString()) {
      //   console.log("infilter searchtext ", searchText, item.orderId);
      //   return false;
      // }

      if (startDate === "dd/mm/yyyy" || endDate === "dd/mm/yyyy") {
        return true;
      }

      if (
        startDate &&
        endDate &&
        endDateValue > startDate &&
        endDateValue < endDate
      ) {
        // console.log("startDate ", startDate, endDate, item.date);
        return true;
      }
      if (
        startDate.toString().includes("GMT") ||
        endDate.toString().includes("GMT")
      ) {
        // console.log("else date");
        return true;
      }
      //return true;
    });
    // console.log("filteredData ", filteredDataValue);
    return filteredDataValue;
  }, [
    data,
    statusFilter,
    startDate,
    endDate,
    prescriptionSelected,
    amountSelected,
  ]);

  // const handleRowClick = (e, rowData) => {
  //   setSelectedRow(rowData.orderId);
  //   setOpenPopUp(true);
  // };

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="container2">
          <MaterialTable
            title="General Orders"
            columns={columns}
            data={filteredData}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: (rowData) => (
                  <NextPlanIcon style={{ color: "#007196" }} />
                  // <Link to={`/OrderDetails/${rowData.orderId}`}>
                  //   {" "}
                  //   <NextPlanIcon style={{ color: "#007196" }} />
                  // </Link>
                ),
                tooltip: "View",
                onClick: (e, rowData) => {
                  // var mobile = rowData.mobile;
                  setOpenPopUp(true), setSelectedRow(rowData.orderId);
                  //history.push(`/OrderDetails/${rowData.orderId}`);
                  navigate(`/OrderDetails/${rowData.orderId}`);
                },
              },
              {
                icon: () => (
                  <FormControl>
                    <div className="search-box">
                      <input
                        type="text"
                        placeholder="Search by Order ID"
                        onChange={handleSearchOrderID}
                        style={{
                          padding: "10px",
                          width: "200px",
                          border: "1px solid #a8d4f7",
                          marginTop: "10px",
                        }}
                      />
                      <IconButton
                        className="search-icon"
                        style={{
                          position: "absolute",
                          right: "1px",
                          top: "65%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <Search />
                      </IconButton>
                    </div>
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="startdate"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      <b> Start Date</b>
                    </label>
                    <input
                      id="startdate"
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      placeholder="Start Date"
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                    />
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="enddate"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      <b> End Date</b>
                    </label>
                    <input
                      id="enddate"
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                      placeholder="Start Date"
                    />
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="orderstatus"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      <b> Order Status </b>
                    </label>
                    <select
                      id="orderstatus"
                      value={statusFilter}
                      onChange={handleStatusChange}
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                    >
                      <option value="">All Status</option>
                      <option value="Pending">Pending</option>
                      <option value="Confirmed">Confirmed</option>
                      <option value="Packaging">Packaging</option>
                      <option value="OutForDelivery">Out For Delivery</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="prescriptionID"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      <b> Prescription</b>
                    </label>
                    <select
                      id="prescriptionID"
                      value={prescriptionSelected}
                      onChange={handlePrescriptionChange}
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                    >
                      <option value="">All</option>
                      <option value="Available">Available</option>
                      <option value="NotAvailable">Not Available</option>
                    </select>
                  </FormControl>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <FormControl>
                    <label
                      htmlFor="amountID"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "3px",
                      }}
                    >
                      <b> Payment Status</b>
                    </label>
                    <select
                      id="amountID"
                      value={amountSelected}
                      onChange={handleAmountChange}
                      style={{ padding: "8px", border: "1px solid #a8d4f7" }}
                    >
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                      <option value="Failed">Failed</option>
                    </select>
                  </FormControl>
                ),
                isFreeAction: true,
              },
            ]}
            components={{
              Pagination: (props) => (
                <Page_Bb
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            options={{
              actionsColumnIndex: columns.length,
              detailPanelColumnAlignment: "right",
              pageSize: 50,
              exportButton: false,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: false,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              border: "0px",
              stickyHeader: true,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76vh",
            }}
            onSearchChange={(search) => handleSearch(search)}
            onFilterChange={(changedColumn, filterValue) => {
              // if (changedColumn === "status") {
              //   console.log("status filter ", filterValue);
              //   setStatusFilter(filterValue);
              // }
              if (changedColumn === "orderdate") {
                // console.log("date filter ", filterValue);
                const [start, end] = filterValue.split("-");
                setStartDate(start.trim());
                setEndDate(end.trim());
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
export default Orders;
