import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import "../../css/changePass.css";
import Swal from "sweetalert2";
import Sidebars from "../../components/Sidebar";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function AddDoctor() {
  const [showPassword, setShowPassword] = useState(false);
  const [doctorSpec, setDoctorSpec] = useState([]);
  const navigate = useNavigate();
  const formRef = useRef(null);

  const url = process.env.REACT_APP_URL;

  // defining initial values as null
  const initialValues = {
    dname: "",
    email: "",
    password: "",
    confirmPassword: "",
    license_no: "",
    qualification: "",
    spec_id: "",
    gender: "",
    phone_no: "",
    experience: "",
    hospital_location: "",
    hospital_name: "",
    topSpec: "",
    date_of_birth: "",
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // handleclear Form function
  const handleClear = (event) => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
    // Prevent the default form submission behavior
    if (event) {
      event.preventDefault();
    }
  };

  // Form validation schema here
  const validationSchema = Yup.object({
    dname: Yup.string().required("Doctor name is required"),
    gender: Yup.string().required("Gender is required"),
    // date_of_birth: Yup.string().required("Date of Birth is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(?:\.[a-zA-Z0-9-]{2,})?$/,
        "Invalid email"
      ),
    license_no: Yup.string().required("Registration Number is required"),
    qualification: Yup.string()
      .required("Qualification is required")
      .max(20, "Qualification must be less than 20 characters"),
    spec_id: Yup.string().required("Specialty is required"),
    // hospital_location: Yup.string().required("Hospital Loation is required"),
    // topSpec: Yup.string().required("Top specialist  is required"),
    // hospital_name: Yup.string().required("Hospital Name is required"),
    phone_no: Yup.string()
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
    password: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
        "Password must be a combination of letters, numbers, cases, and symbols with a minimum length of 10 characters"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const registerSuccess = async (event) => {
    Swal.fire({
      title: "Doctor registered successfully",
      icon: "success",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    });
    setTimeout(function () {
      navigate("/doctorsList");
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  const licenseError = async (event) => {
    Swal.fire({
      title: "License number is already registered",
      icon: "error",
      showConfirmButton: false,
      timer: 2000, // set the duration in milliseconds
    }); //
    setTimeout(function () {
      // window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const emailError = async (event) => {
    Swal.fire({
      title: "Email is already registered",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
    setTimeout(function () {}, 2000);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const registerDoctor = {
      name: values.dname,
      email: values.email,
      license_no: values.license_no,
      qualification: values.qualification,
      spec_id: values.spec_id,
      password: values.password,
      gender: values.gender,
      phone_no: values.phone_no,
      experience: values.experience,
      hospital_location: values.hospital_location,
      top_specialist: values.topSpec,
      hospital_name: values.hospital_name,
      date_of_birth: values.date_of_birth,
    };

    try {
      const response = await Axios.post(`${url}/doctorSignup`, {
        ...registerDoctor,
      });
      if (response.data.Message === "Succesfully Signed Up Doctor") {
        resetForm();
        registerSuccess();
      } else {
        throw new Error(response.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          const errorMessage =
            error.response.data.error || "Internal Server Error";
          if (errorMessage === "License number is already registered") {
            licenseError();
          } else if (errorMessage === "Email is already registered") {
            emailError();
          } else {
          }
        }
      } else if (error.request) {
      } else {
      }
    }

    setSubmitting(false);
  };

  useEffect(() => {
    Axios.get(`${url}/getSpecializations`).then((response) => {
      const specList = {};
      response.data?.result?.forEach((item) => {
        specList[item.id] = item.name;
      });
      setDoctorSpec(specList);
    });
  }, []);

  const backbutton = () => {
    navigate("/doctorsList");
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="password-container">
          <div className="back-button1">
            <span onClick={backbutton} style={{ cursor: "pointer" }}>
              All Doctor's
            </span>
            <span>{" / Add Doctor "}</span>
          </div>
          <br />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
              <Form>
                <div>
                  <p className="addDoctor">Add New Doctor</p>

                  <div className="contianer-forms">
                    <div className="pass-div-container">
                      <label htmlFor="dname" className="password-label">
                        Doctor Name <span className="redstar"> *</span>
                      </label>
                      <Field
                        as={OutlinedInput}
                        error={Boolean(touched.password && errors.password)}
                        type="text"
                        // type="text"
                        id="dname"
                        name="dname"
                        className="password-input"
                      />
                      <ErrorMessage
                        name="dname"
                        component="div"
                        className="password-error"
                      />
                    </div>
                    <div className="pass-div-container">
                      <label htmlFor="license_no" className="password-label">
                        Registration Numbers<span className="redstar"> *</span>
                      </label>
                      <Field
                        as={OutlinedInput}
                        error={Boolean(touched.password && errors.password)}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="license_no"
                        name="license_no"
                        className="password-input"
                      />
                      <ErrorMessage
                        name="license_no"
                        component="div"
                        className="password-error"
                      />
                    </div>

                    <div className="pass-div-container">
                      <label htmlFor="qualification" className="password-label">
                        Qualification<span className="redstar"> *</span>
                      </label>
                      <Field
                        as={OutlinedInput}
                        error={Boolean(touched.password && errors.password)}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="qualification"
                        name="qualification"
                        className="password-input"
                      />
                      <ErrorMessage
                        name="qualification"
                        component="div"
                        className="password-error"
                      />
                    </div>
                  </div>
                </div>
                <div className="contianer-forms">
                  <div className="pass-div-container">
                    <label htmlFor="gender" className="password-label">
                      Gender <span className="redstar"> *</span>
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      // value={values.spec_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="password-input"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="others">Others</option>
                    </select>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="password-error"
                    />
                  </div>
                  <div className="pass-div-container">
                    <label htmlFor="email" className="password-label">
                      Email <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(touched.password && errors.password)}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="email"
                      name="email"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="password-error"
                    />
                  </div>

                  <div className="pass-div-container">
                    <label htmlFor="spec_id" className="password-label">
                      Specialty<span className="redstar"> *</span>
                    </label>
                    <select
                      id="spec_id"
                      name="spec_id"
                      // value={values.spec_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="password-input"
                    >
                      <option value="">Select Specialty</option>
                      {/* Replace the below line with your options */}
                      {Object.keys(doctorSpec).map((id) => (
                        <option value={id} key={id}>
                          {doctorSpec[id]}{" "}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      name="spec_id"
                      component="div"
                      className="password-error"
                    />
                  </div>
                </div>

                <div className="contianer-forms">
                  <div className="pass-div-container">
                    <label htmlFor="phone_no" className="password-label">
                      Mobile Number <span className="redstar"> *</span>
                    </label>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(touched.password && errors.password)}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="phone_no"
                      name="phone_no"
                      className="password-input"
                    />
                    <ErrorMessage
                      name="phone_no"
                      component="div"
                      className="password-error"
                    />
                  </div>
                  <div className="pass-div-container">
                    <label htmlFor="experience" className="password-label">
                      Experience
                    </label>
                    <Field
                      as={OutlinedInput}
                      // error={Boolean(touched.password && errors.password)}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="experience"
                      name="experience"
                      className="password-input"
                    />
                    {/* <ErrorMessage
                      name="experience"
                      component="div"
                      className="password-error"
                    /> */}
                  </div>

                  <div className="pass-div-container">
                    <label
                      htmlFor="hospital_location"
                      className="password-label"
                    >
                      Hospital Location
                    </label>
                    <Field
                      as={OutlinedInput}
                      // error={Boolean(touched.password && errors.password)}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="hospital_location"
                      name="hospital_location"
                      className="password-input"
                    />
                    {/* <ErrorMessage
                      name="hospital_location"
                      component="div"
                      className="password-error"
                    /> */}
                  </div>
                </div>

                <div className="contianer-forms">
                  <div className="pass-div-container">
                    <label htmlFor="hospital_name" className="password-label">
                      Hospital Name
                    </label>
                    <Field
                      as={OutlinedInput}
                      // error={Boolean(touched.password && errors.password)}
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="hospital_name"
                      name="hospital_name"
                      className="password-input"
                    />
                    {/* <ErrorMessage
                      name="hospital_name"
                      component="div"
                      className="password-error"
                    /> */}
                  </div>
                  <div className="pass-div-container">
                    <label htmlFor="topSpec" className="password-label">
                      Top Specialist
                    </label>
                    <select
                      id="topSpec"
                      name="topSpec"
                      // value={values.spec_id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="password-input"
                    >
                      <option value="">Select Specialist</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    {/* <ErrorMessage
                        name="topSpec"
                        component="div"
                        className="password-error"
                      /> */}
                  </div>

                  <div className="pass-div-container">
                    <label htmlFor="date_of_birth" className="password-label">
                      Date of Birth
                    </label>
                    <Field
                      as={OutlinedInput}
                      // error={Boolean(touched.password && errors.password)}
                      type="date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="date_of_birth"
                      name="date_of_birth"
                      className="password-input"
                    />
                    {/* <ErrorMessage
                      name="date_of_birth"
                      component="div"
                      className="password-error"
                    /> */}
                  </div>
                </div>
                <div>
                  <p className="addDoctor">Create Password</p>
                  <div className="contianer-forms">
                    <div className="pass-div-container">
                      <label htmlFor="password" className="password-label">
                        New Password <span className="redstar"> *</span>
                      </label>
                      <Field
                        as={OutlinedInput}
                        error={Boolean(touched.password && errors.password)}
                        type={showPassword ? "text" : "password"}
                        //    value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <EyeInvisibleOutlined />
                              ) : (
                                <EyeOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="password"
                        name="password"
                        className="password-input"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="password-error"
                      />
                    </div>

                    <div className="pass-div-container">
                      <label
                        htmlFor="confirmPassword"
                        className="password-label"
                      >
                        Confirm Password <span className="redstar"> *</span>
                      </label>
                      <Field
                        as={OutlinedInput}
                        error={Boolean(touched.password && errors.password)}
                        type={showPassword ? "text" : "password"}
                        //    value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <EyeInvisibleOutlined />
                              ) : (
                                <EyeOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="confirmPassword"
                        name="confirmPassword"
                        className="password-input"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="password-error"
                      />
                    </div>
                  </div>
                </div>
                <div className="main-btn-div">
                  <div className="button-div1">
                    <button
                      type="clear"
                      className="pass-button1"
                      onClick={handleClear}
                      // onClick={handleClear()}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="button-div1">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="pass-button"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default AddDoctor;
