import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import Axios from "axios";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import "../../css/bb-popup.css";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function PopUpIceCard({ openPopup, setOpenPopUp, data }) {
  let navigate = useNavigate();
  let did = data?.id;
  const [requsta, setReqSta] = useState([]);

  const Token = localStorage.getItem("token");
  const [requestStatus01, setRequestStatus01] = useState("");
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    setReqSta(["Pending", "Approved"]);
    setRequestStatus01(data?.request_status);
  }, []);

  useEffect(() => {
    // Update the local storage role

    localStorage.setItem("role", "admin");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(requestStatus01);
    const verifyAdminToken = localStorage.getItem("token");
    const requestData = {
      id: did,
      request_status: requestStatus01,
    };

    try {
      Axios.post(`${url}/updateEmergencyCardRequestStatus`, {
        id: did,
        request_status: requestStatus01,
      }).then((res) => {
        if (
          res.data.Message === "Emergency Request Status Updated Successfully."
        ) {
          loginSuccess1();
        } else {
          loginErrorMob();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "ICE Request Status Updated Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000, // set the duration in milliseconds
    });
    // navigate("/AllBloodbank");
    setTimeout(function () {
      window.location.reload();
    }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
  };

  const loginErrorMob = async (event) => {
    Swal.fire("Error updating status!", "", "error");
  };

  return (
    <>
      {openPopup && (
        <div>
          {openPopup && (
            <div>
              {" "}
              {openPopup && (
                <div>
                  {" "}
                  <Dialog onClose={handleClose} open={openPopup}>
                    <DialogTitle dividers>
                      View ICE Card Details
                      <div className="closeIcon" onClick={handleClose}>
                        <HighlightOffOutlinedIcon />
                      </div>
                    </DialogTitle>{" "}
                    <DialogContent dividers color="#007196">
                      <p className="uinfo">Customer Details - </p>

                      <p className="uinfo">
                        {data?.service_abbreviation}
                        {data?.id}
                      </p>
                    </DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="first-form">
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="name">
                              Full Name
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control-input1"
                              name="name"
                              value={data?.user_signup?.fullname}
                              disabled
                            />
                          </div>
                          <div className="form-state">
                            <label className="formLabeldonor" htmlFor="mobile">
                              Mobile Number
                            </label>
                            <input
                              id="mobile"
                              className="form-control-input1"
                              type="number"
                              name="mobile"
                              value={data?.user_signup?.mobile}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label className="formLabeldonor" htmlFor="name">
                              Request Date
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control-input1"
                              name="name"
                              // value={data?.date}
                              value={
                                data?.date
                                  ? moment(data?.date).format("DD-MM-YYYY")
                                  : "no info"
                              }
                              disabled
                            />
                          </div>
                          <div className="form-state">
                            <label className="formLabeldonor" htmlFor="mobile">
                              Address
                            </label>
                            <input
                              id="name"
                              className="form-control-input1"
                              type="text"
                              value={data?.customer_address.address}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="formGroupsec">
                          <div className="form-dob">
                            <label
                              className="formLabeldonor"
                              htmlFor="requestStatus"
                            >
                              Request Status <span className="redstar">*</span>
                            </label>
                            <select
                              id="requestStatus"
                              className="form-control-input1"
                              name="requestStatus"
                              value={requestStatus01}
                              onChange={(e) =>
                                setRequestStatus01(e.target.value)
                              }
                            >
                              {requsta.map((type, index) => (
                                <option key={index} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="buttonSec">
                        <div className="cancle">
                          <button
                            type="cancel"
                            className="cancle1"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="submit">
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="submit1"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog>{" "}
                </div>
              )}{" "}
            </div>
          )}{" "}
        </div>
      )}
    </>
  );
}
