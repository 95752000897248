import React, { useState } from "react";
import "../../../../../doctor_profile/css/allAppointments.css";
import "../../../../../css/doctor.css";
import ManagerTodayConsultations from "./today";
import ManagerUpcomingConsultations from "./upcoming";
import ManagerAssignedConsultations from "./assigned";
function OfflineConsultations() {
  const [activeButton, setActiveButton] = useState("newrequest");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const renderContent = () => {
    if (activeButton === "newrequest") {
      return <ManagerTodayConsultations />;
    } else if (activeButton === "upcoming") {
      return <ManagerUpcomingConsultations />;
    } else if (activeButton === "assigned") {
      return <ManagerAssignedConsultations />;
    }
  };

  return (
    <div className="main_appointments">
      <div className="submenu_telecaller">
        <button
          className={`btn-all-appointment ${
            activeButton === "newrequest" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("newrequest")}
        >
          <span>Today's</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "upcoming" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("upcoming")}
        >
          <span>Upcoming</span>
        </button>
        <button
          className={`btn-all-appointment ${
            activeButton === "assigned" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("assigned")}
        >
          <span>Assigned</span>
        </button>
      </div>
      <div className="line_doc"></div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default OfflineConsultations;
