import docimg from "../../../../images/support_7363068.png";
import "../../../../css/doctor.css";
import Swal from "sweetalert2";
import React, { useEffect, useState, useContext } from "react";
import MaterialTable from "material-table";
import "../../../../css/bloodreqlist.css";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Navigate } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import TelecallerManagerHeader from "../dashboard/managerHeader";
import Page_Bb from "../../../services_component/blood_bank/Page_bb";
import NotificationContext from "../../../context/NotificationContext";
// import PopUpNotification from "./popupnotification";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const ManagerNotifications = () => {
  const { count, setCount } = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [totalCou, setTotalCou] = useState([]);
  const tableRef = React.useRef();
  const navigate = useNavigate();

  const managerId = localStorage.getItem("managerId");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");

  const url = process.env.REACT_APP_URL;
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  useEffect(() => {
    fetchData1();
    removeNotificationCount();
  }, []);

  const removeNotificationCount = async () => {
    let appointment_id_list = localStorage.getItem("csrmNotiAppointmentIds");
    let appointmentId = JSON.parse(appointment_id_list);
    // console.log(appointment_id);

    try {
      await Axios.put(`${url}/updateTelecallerManagerRequestStatus`, {
        appointmentId,
      }).then((response) => {
        // window.location.reload();
        if (
          response.data.Message ===
          "Telecaller manager Request Status Updated Successfully."
        ) {
          setCount(0);
        }
      });
    } catch (error) {}
  };

  const fetchData1 = async () => {
    let id = managerId;
    Axios.get(`${url}/gettelemanagerdetails?id=${id}`).then((response) => {
      setName(response?.data?.result.name);
      setEmail(response?.data?.result.email);
      setPhone_no(response?.data?.result.mobile);
    });
  };

  const columns = [
    {
      title: "Patient Name",
      field: "user_fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "user_mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Name",
      field: "doctor_name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Mobile",
      field: "doctor_mobile",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Specialization",
      field: "doctor_specializations[0]",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "user_gender",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Date",
      field: "appointment_taken_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_taken_date) {
          const formattedDate = moment(rowData.appointment_taken_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },

    {
      title: "Reason",
      field: "reason_for_appointment",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Appointment Date",
      field: "appointment_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_date) {
          const formattedDate = moment(rowData.appointment_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Time Slot",
      field: "time_slot",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Consultation Fee",
      field: "doctor_consultation_fee",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => `₹${rowData?.doctor_consultation_fee}`,
    },

    {
      title: "Payment Method",
      field: "payment_mode",
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    setLoading(true);
    Axios.get(`${url}/getTeleManagerNewAppointmentNotifications`).then(
      (response) => {
        if (response.data) {
          setData(response.data.result);
        }
        setLoading(false);
      }
    );
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);

  const backbutton = () => {
    navigate("/csr/manager/dashboard");
  };

  return (
    <>
      <div className="main">
        <TelecallerManagerHeader />

        <div className="profile_doc">
          <div className="image-container">
            <div
              className="circle-image"
              style={{ backgroundImage: `url(${docimg})` }}
            ></div>
          </div>
          <div className="name_doc">
            <div className="text_arrange">
              <div className="welcome_text">Welcome , Mr/Mrs. {name}</div>

              <div className="telecallerProfile">
                <div className="key_text">
                  <strong>Mobile : </strong>{" "}
                  <p style={{ marginLeft: "4px" }}>{phone_no}</p>
                </div>
                <div className="key_text">
                  <strong>Email : </strong>
                  <p style={{ marginLeft: "4px" }}> {email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notiMain">
          <span className="dashNoti" onClick={backbutton}>
            <p>
              <ArrowLeftIcon />
            </p>
            Dashboard
          </span>
          <br />
          <div className="container2">
            <>
              <div className="main_doc">
                <div className="main_newreq1">
                  <div style={{ width: "100%" }}>
                    <MaterialTable
                      columns={columns}
                      title="Today Appointments"
                      data={data}
                      icons={customIcons}
                      style={{ borderRadius: "4px" }}
                      isLoading={loading}
                      options={{
                        detailPanelColumnAlignment: "right",

                        searchFieldStyle: { border: "2px solid #f00" },
                        selection: false,
                        exportButton: false,
                        sorting: true,
                        search: false,
                        toolbar: true,
                        searchOnEnter: true,
                        debounceInterval: 500,
                        searchAutoFocus: false,
                        actionsColumnIndex: columns.length,
                        searchFieldVariant: "outlined",
                        stickyHeader: true,
                        pageSize: 50,
                        paginationType: "stepped",
                        showFirstLastPageButtons: false,
                        emptyRowsWhenPaging: false,
                        headerStyle: {
                          size: "40px",
                          background: "rgba(0, 0, 0, 0.05)",
                          position: "sticky",
                          top: "0",
                        },
                        stickyHeader: true,
                        maxBodyHeight: "34.5vh",
                      }}
                      components={{
                        Pagination: (props) => (
                          <Page_Bb
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerNotifications;
