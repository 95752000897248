import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import "../../../css/adddonor.css";
import { useLocation } from "react-router-dom";
import Sidebars from "../../../components/Sidebar";
function ViewGhcPlans() {
  let navigate = useNavigate();
  const location = useLocation();
  const [plan_name, setPlan_name] = useState("");
  const [plan_worth, setPlan_worth] = useState("");
  const [errors, setErrors] = useState({});
  const url = process.env.REACT_APP_URL;

  const ghcplan = location?.state?.data;
  useEffect(() => {
    if (ghcplan?.plan_name) {
      setPlan_name(ghcplan?.plan_name);
    }

    if (ghcplan?.plan_cost !== undefined) {
      setPlan_worth(ghcplan?.plan_cost);
    }
  }, [ghcplan]);

  const validate = () => {
    const newErrors = {};

    if (!plan_name) {
      newErrors.plan_name = "Please enter plan name";
    }

    if (!plan_worth) {
      newErrors.plan_worth = "Please enter plan worth";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();

    if (isValid) {
      Axios.put(`${url}/updateGhcPlans`, {
        id: location?.state?.data?.id,
        plan_name: plan_name,
        plan_cost: plan_worth,
      }).then((response) => {
        if (
          response.data.Message === "GHC Plans Details Updated Successfully."
        ) {
          loginSuccess();
        }
      });
    }
  };

  const loginSuccess = async (event) => {
    Swal.fire("GHC Plans Details Updated Successfully!", "", "success");
    navigate("/ghc-plans");
  };

  useEffect(() => {
    localStorage.setItem("role", "admin");
  }, []);

  const backbutton = () => {
    navigate("/ghc-plans");
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  Membership Plans
                </span>
                <span>{" / Update Membership Plan "}</span>
              </div>
              <div className="add-new-donor">
                <h2 className="heading-addnewdonor1">Update Membership Plan</h2>
              </div>
              <div className="first-form">
                <div className="section2-ahc-plans">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="plan_name">
                      Plan Name<p className="redstar">*</p>
                    </label>
                    <input
                      id="plan_name"
                      className="input-row-2-ahc-plans"
                      type="text"
                      value={plan_name}
                      onChange={(event) => setPlan_name(event.target.value)}
                    />
                    {errors.plan_name && (
                      <div className="error">{errors.plan_name}</div>
                    )}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="plan_worth">
                      Plan Worth<p className="redstar">*</p>
                    </label>
                    <input
                      id="plan_worth"
                      className="input-row-2-ahc-plans"
                      type="text"
                      value={plan_worth}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setPlan_worth(numericValue);
                      }}
                    />
                    {errors.plan_worth && (
                      <div className="error">{errors.plan_worth}</div>
                    )}
                  </div>
                </div>
                <div className="section11">
                  <div className="cancle">
                    <button
                      type="cancel"
                      className="cancle1"
                      onClick={() => navigate("/ghc-plans")}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="submit">
                    <button type="submit" className="submit1" onSubmit>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ViewGhcPlans;
