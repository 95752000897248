import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../components/Header";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Sidebars from "../../../components/Sidebar";
import Axios from "axios";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import moment from "moment";
import Paginate from "../../../pages/user_data_component/blood_requesties/paginate";
import "../../../css/bloodreqlist.css";
import debounce from "lodash.debounce";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const AhcConsultations = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [totalCou, setTotalCou] = useState([]);
  const tableRef = React.useRef();
  const url = process.env.REACT_APP_URL;
  let navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = useCallback(
    debounce((search) => {
      fetchSearchResults(search);
    }, 500),
    []
  );

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
  };

  const columns = [
    {
      title: "Membership Id",
      field: "ahcmemberid",
      emptyValue: () => <em>Not Member</em>,
    },
    {
      title: "Patient Name",
      field: "user_fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "user_mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Name",
      field: "doctor_name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Mobile",
      field: "doctor_phone_no",
      // hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "user_gender",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Date",
      field: "appointment_taken_date",
      emptyValue: () => <em>no info</em>,
      hidden: true,
      render: (rowData) => {
        if (rowData.appointment_taken_date) {
          const formattedDate = moment(rowData.appointment_taken_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Type of Consultation",
      field: "treatment_mode",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Appointment Date",
      field: "appointment_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_date) {
          const formattedDate = moment(rowData.appointment_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Time Slot",
      field: "appointment_time_slot",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Consultation Fee",
      field: "consultation_fee",
      hidden: true,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => `₹${rowData?.consultation_fee}`,
    },

    {
      title: "Payment Method",
      field: "payment_mode",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
  ];

  const handleStatusToggle = (rowData) => {
    const id = rowData.id;
    const status = rowData.status;
    let booleanStatus;

    if (status === 1) {
      booleanStatus = false;
    } else if (status === 0) {
      booleanStatus = true;
    }
    // console.log(id, booleanStatus);
    Axios.put(`${url}/UpdateAhcPlanStatus`, {
      id: id,
      status: booleanStatus,
    })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    localStorage.setItem("role", "admin");
    fetchData();
  }, []);

  const fetchData = () => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getalltelecallerAppointments`).then((response) => {
      setData(response.data.result);
      setTotalCou(response.data.totalcount);
      setLoading(false);
    });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    Axios.post(`${url}/gettelecallerListBySearch`, { search }).then(
      (response) => {
        setData(response.data.result);
        setTotalCou(response?.data?.result?.length);
        setLoading(false);
      }
    );
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MaterialTable
            tableRef={tableRef}
            title="AHC Consultations"
            columns={columns}
            data={data}
            icons={customIcons}
            style={{ zIndex: 1, borderRadius: "4px" }}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              actionsColumnIndex: columns.length,
              stickyHeader: true,
              pageSize: 50,
              pageSizeOptions: [20, 30, 50],
              // paginationType: "stepped",
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            actions={[
              {
                icon: () => <RemoveRedEyeIcon style={{ color: "#007196" }} />,
                tooltip: "View",
                stickyHeader: true,
                onClick: (event, rowData) => {
                  navigate("/view-ahc-consultations", {
                    state: { data: rowData },
                  });
                },
              },
            ]}
            onSearchChange={(search) => handleSearch(search)}
            // components={{
            //   Pagination: (props) => (
            //     <Paginate
            //       nPages={nPages}
            //       currentPage={currentPage}
            //       setCurrentPage={setCurrentPage}
            //     />
            //   ),
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default AhcConsultations;
