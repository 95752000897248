import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Sidebars from "../../../components/Sidebar";
import Axios from "axios";
import BorderColorSharpIcon from "@mui/icons-material/BorderColorSharp";
import Paginate from "../../../pages/user_data_component/blood_requesties/paginate";
import "../../../css/bloodreqlist.css";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const GhcPlans = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [totalCou, setTotalCou] = useState([]);
  const tableRef = React.useRef();
  const url = process.env.REACT_APP_URL;
  let navigate = useNavigate();

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
  };

  const columns = [
    {
      title: "Plan Name",
      field: "plan_name",
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Plan Cost",
      field: "plan_cost",
      editable: false,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.plan_cost) {
          const formattedPrice =
            rowData.plan_cost % 1 === 0
              ? `${rowData.plan_cost}.00`
              : rowData.plan_cost;
          return <div>₹{formattedPrice}</div>;
        }
        return <em>0</em>;
      },
    },
    {
      title: "Status",
      field: "status",
      editable: false,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => (
        <label className="switch">
          <input
            type="checkbox"
            checked={rowData.status === 1}
            onChange={() => handleStatusToggle(rowData)}
          />
          <span className="slider round"></span>
        </label>
      ),
    },
  ];

  const handleStatusToggle = (rowData) => {
    const id = rowData.id;
    const status = rowData.status;
    let booleanStatus;

    if (status === 1) {
      booleanStatus = false;
    } else if (status === 0) {
      booleanStatus = true;
    }
    Axios.put(`${url}/UpdateGhcPlanStatus`, {
      id: id,
      status: booleanStatus,
    })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    localStorage.setItem("role", "admin");
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getGhcMembershipAllPlans/`).then((response) => {
      setData(response.data.result);
      setTotalCou(response.data.totalcount);
      setLoading(false);
    });
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MaterialTable
            tableRef={tableRef}
            title="Membership Plans"
            columns={columns}
            data={data}
            icons={customIcons}
            style={{ zIndex: 1, borderRadius: "4px" }}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              rowStyle: {
                wordWrap: "break-word",
              },
              searchFieldVariant: "outlined",
              pageSize: 50,
              paginationType: "stepped",
              actionsColumnIndex: columns.length,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            actions={[
              {
                icon: () => (
                  <button
                    onClick={() => navigate("/add-ghc-plans")}
                    className="add_new_donor"
                  >
                    + Add New Plan
                  </button>
                ),
                isFreeAction: true,
              },
              {
                icon: () => (
                  <BorderColorSharpIcon style={{ color: "#007196" }} />
                ),
                tooltip: "Edit",
                stickyHeader: true,
                onClick: (event, rowData) => {
                  navigate("/view-ghc-plans", {
                    state: { data: rowData },
                  });
                },
              },
            ]}
            components={{
              Pagination: (props) => (
                <Paginate
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default GhcPlans;
