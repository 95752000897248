import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../../components/Header";
import "../../../css/users.css";
import MatirialTable from "material-table";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import moment from "moment";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import Paginate from "../blood_requesties/paginate";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import debounce from "lodash.debounce";
import Axios from "axios";
import Sidebars from "../../../components/Sidebar";
import { useNavigate, Navigate } from "react-router-dom";
import Pagination from "../user_data/Pagination";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function IntrestedBloodDonor() {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const tableRef1 = useRef();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = useCallback(
    debounce((search) => {
      fetchSearchResults(search);
    }, 500),
    []
  );
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const columns = [
    {
      title: "Registration Date",
      field: "registration_date",
      export: true,
      render: (rowData) => {
        if (rowData.registration_date) {
          const formattedDate = moment(rowData.registration_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Fullname",
      export: true,
      field: "user_signup.fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile",
      export: true,
      field: "user_signup.mobile",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Gender",
      field: "user_signup.gender",
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "DOB",
      field: "user_signup.dob",
      type: "date",
      export: true,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.user_signup.dob) {
          const formattedDate = moment(rowData.user_signup.dob).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      export: true,
      title: "Address",
      field: "user_signup.address",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Blood Group",
      field: "user_signup.bloodgroup",
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Email",
      field: "user_signup.email",
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Medical Condition",
      field: "user_signup.medicalcondition",
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Occupation",
      field: "user_signup.occupation",
      export: true,

      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Pincode",
      field: "user_signup.pincode",
      export: true,
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    localStorage.setItem("role", "admin");
    fetchData();
  }, []);

  const fetchData = () => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getAllblooddonorinterest`).then((response) => {
      setData(response.data.totalcount);
      setUserData(response?.data?.result?.rows);
      setLoading(false);
    });
  };

  const fetchSearchResults = async (search) => {
    setLoading(true);
    var search_text = search;
    Axios.post(`${url}/serachInterestedBloodDonor`, { search_text }).then(
      (response) => {
        setUserData(response?.data?.result?.results);
        setData(response?.data?.result?.results?.length);
        setLoading(false);
      }
    );
  };

  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    userData && userData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MatirialTable
            title="Intrested Blood Donor"
            columns={columns}
            data={userData}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              actionsColumnIndex: columns.length,
              stickyHeader: true,
              pageSize: 50,
              pageSizeOptions: [20, 30, 50],
              // paginationType: "stepped",
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            // components={{
            //   Pagination: (props) => (
            //     <Pagination
            //       nPages={nPages}
            //       currentPage={currentPage}
            //       setCurrentPage={setCurrentPage}
            //     />
            //   ),
            // }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
    </>
  );
}
export default IntrestedBloodDonor;
