import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import SidebarPharma from "../../../components/sidebarPharma";
import HeaderPharma from "../../../components/HeaderPharma";
import "../../../css/adddonor.css";
import "../../../css/productadd.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import Axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";

function ProductsEdit() {
  const [nameError, setNameError] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageNames, setSelectedImageNames] = useState([]);
  const [imageError, setImageError] = useState("");
  const [imageUrls, setImageUrls] = useState([]); // New state for storing image URLs
  const [imageNumber, setImageNumber] = useState("");

  // forms values
  const [prodTypes, setProdTypes] = useState([]);
  const [prodType, setProdType] = useState("");
  const [prodname, setprodName] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [drugmol, setDrugmol] = useState("");
  const [medicineTypes, setMedicineTypes] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [batchno, setBatchno] = useState("");
  const [storename, setStorename] = useState("");
  const [vendorname, setVendorname] = useState("");
  const [expdate, setExpdate] = useState("");
  const [description, setDescription] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [benifits, setBenifits] = useState("");
  const [howtouse, setHowtouse] = useState("");
  const [saftyadvice, setSaftyadvice] = useState("");
  const [primaryuse, setPrimaryuse] = useState("");
  const [ifmiss, setIfmiss] = useState("");
  const [sideeffect, setSideeffect] = useState("");
  const [interaction, setInteraction] = useState([]);
  const [alcoholinteract, setAlcoholinteract] = useState("");
  const [pregnancyinteract, setPregnancyinteract] = useState("");
  const [lactationinteract, setLactationinteract] = useState("");
  const [drivinginteract, setDrivinginteract] = useState("");
  const [kidneyinteract, setKidneyinteract] = useState("");
  const [liverinteract, setLiverinteract] = useState("");
  const [packaging, setPackaging] = useState("");
  const [packagingtype, setPackagingtype] = useState("");
  const [label, setLabel] = useState("");
  const [factbox, setFactbox] = useState("");
  const [storage, setStorage] = useState("");
  const [manadd, setManadd] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcat, setSubcat] = useState([]);
  const [selectedsubCategory, setSelectedsubCategory] = useState("");
  const [unitmed, setUnitMed] = useState([]);
  const [unitmedi, setUnitmedi] = useState("");
  const [capacity, setCapacity] = useState("");
  const [maxordqua, setMaxordqua] = useState("");
  const [unitprice, setUnitprice] = useState("");
  const [totprosto, setTotprosto] = useState("");
  const [discounttype, setDiscounttype] = useState([]);
  const [discounttypevalue, setDiscounttypevalue] = useState("");
  const [discount, setDiscount] = useState("");
  const [taxtype, setTaxtype] = useState("");
  const [taxrate, setTaxrate] = useState("");
  const [parSats, setParSats] = useState("");
  const [subCats, setSubCats] = useState("");
  const [ids, setId] = useState("");
  // console.log(parSats, "hhhhhhhhhhhhhhhhhhhhhh");
  // forms values
  const fileInputRef = useRef(null);
  let location = useLocation();
  let navigate = useNavigate();
  const url = process.env.REACT_APP_URL;
  let editData = location?.state?.data;
  // console.log(editData, "hahahah");
  const azureAccountName = "suitsdata";
  const sasToken =
    "?sv=2021-12-02&ss=bf&srt=sco&sp=rwdlactfx&se=2029-03-29T11:31:53Z&st=2023-03-29T03:31:53Z&spr=https&sig=OX48TXg%2F6xwp3b32hwe7Tf6sggDFc4YpCvvwCU5EUj8%3D";
  const containerName = "productdev";
  const siteurl = "https://suitsdata.blob.core.windows.net/productdev/";

  const prodcat = editData?.product_categories;
  // const subcat = editData?.product_categories;
  useEffect(() => {
    setProdTypes(["General", "Pharma"]);
    setInteraction(["Safe", "Unsafe", "Avoid Alcohol", "Consult your Doctor"]);
    setUnitMed(["tablets", "ml", "gm", "capsules"]);
    setDiscounttype(["percentage", "rupees"]);
    setId(editData?.id);
    setProdType(editData?.product_type);
    setprodName(editData?.name);
    setManufacturer(editData?.manufacturer);
    setDrugmol(editData?.drug_molecule);
    setSelectedMedicine(editData?.medicine_type);
    setBatchno(editData?.batch_number);
    setStorename(editData?.store_name);
    setVendorname(editData?.vendor_name);
    setExpdate(editData?.expiry_date);
    setDescription(editData?.description);
    setIntroduction(editData?.introduction);
    setBenifits(editData?.benefits);
    setHowtouse(editData?.how_to_use);
    setSaftyadvice(editData?.safety_advise);
    setPrimaryuse(editData?.primary_use);
    setIfmiss(editData?.if_miss);
    setSideeffect(editData?.common_side_effect);
    setAlcoholinteract(editData?.alcoholInteraction);
    setPregnancyinteract(editData?.pregnancyInteraction);
    setLactationinteract(editData?.lactationInteraction);
    setDrivinginteract(editData?.drivingInteraction);
    setKidneyinteract(editData?.kidneyInteraction);
    setLiverinteract(editData?.liverInteraction);
    setPackaging(editData?.packaging);
    setPackagingtype(editData?.packaging_type);
    setLabel(editData?.label);
    setFactbox(editData?.fact_box);
    setStorage(editData?.storage);
    setManadd(editData?.manufacturer_address);
    setSelectedCategory(editData?.product_categories);
    setSelectedsubCategory(editData?.product_categories);
    setUnitmedi(editData?.unit);
    setCapacity(editData?.capacity);
    setMaxordqua(editData?.max_order_quantity);
    setUnitprice(editData?.price);
    setTotprosto(editData?.total_stock);
    setDiscounttypevalue(editData?.discount_type);
    setDiscount(editData?.discount);
    setTaxtype(editData?.tax_type);
    setTaxrate(editData?.tax);
    setSelectedImageNames(editData?.image);
  }, [editData]);

  // console.log(selectedMedicine, "sm");

  useEffect(() => {
    // console.log(editData?.image);
    setImageNumber(editData?.image?.length);
    // Fetch the image URLs from the editData and store them in imageUrls state
    if (editData && editData?.image) {
      setImageUrls(editData?.image);
    }

    if (prodcat && prodcat.length > 0) {
      prodcat.forEach((category) => {
        setSubCats(category?.category_id);
      });
    }
    if (prodcat && prodcat.length > 0) {
      prodcat.forEach((category) => {
        setParSats(category?.category?.parent_id);
      });
    }
  }, [editData]);
  let imgnum = parseInt(5) - parseInt(imageNumber);

  useEffect(() => {
    Axios.get(`${url}/getMedicineTypes`)
      .then((response) => {
        const mediType = response.data.result;
        setMedicineTypes(mediType);
      })
      .catch((error) => {
        // console.error("Error fetching parent categories:", error);
      });
  }, []);
  useEffect(() => {
    Axios.get(`${url}/getCategoriesList`)
      .then((response) => {
        const catTypes = response.data.categories;
        setParentCategories(catTypes);
      })
      .catch((error) => {
        // console.error("Error fetching parent categories:", error);
      });
  }, []);
  useEffect(() => {
    Axios.get(`${url}/getSubCategories/${selectedCategory}`)
      .then((response) => {
        // console.log(response.data.categories.map((item) => item.name));
        // console.log(response.data.result.map((item) => item.name));
        setSubcat(
          response.data.categories.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      })
      .catch((error) => {
        // console.error("Error fetching parent categories:", error);
      });
  }, [selectedCategory]);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);
    // Check if the number of selected images exceeds the limit (5)
    if (selectedImages.length + selectedFiles.length > imgnum) {
      setImageError("You can select up to 5 images");
      return;
    }

    setSelectedImages((prevImages) => [...prevImages, ...selectedFiles]);

    // Extract and save the file names of selected files
    const fileNames = selectedFiles.map((file) => file.name);

    // Combine the previous image file names with the selected file names
    setSelectedImageNames((prevNames) => [...fileNames, ...imageUrls]);
  };

  const backbutton = () => {
    navigate("/Products");
  };

  const openFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDeleteImage = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });

    setSelectedImageNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames.splice(index, 1);
      return updatedNames;
    });
    const updatedImageUrls = [...imageUrls];
    updatedImageUrls.splice(index, 1);
    setImageUrls(updatedImageUrls);
    setImageNumber((prevImageNumber) => prevImageNumber - 1); // Decrease the imgnum count
  };

  const handleDeleteExistingImage = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });

    setSelectedImageNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames.splice(index, 1);
      return updatedNames;
    });

    const updatedImageUrls = [...imageUrls];
    updatedImageUrls.splice(index, 1);
    setImageUrls(updatedImageUrls);
    setImageNumber((prevImageNumber) => prevImageNumber - 1); // Decrease the imgnum count
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const category_id001 = parseInt(selectedsubCategory);
    const productCategories = [{ category_id: category_id001 }];
    // console.log(productCategories);
    const isValid = validate();
    if (isValid) {
      // console.log(azureAccountName, selectedImageNames, selectedImages);
      const blobServiceClient = new BlobServiceClient(
        `https://${azureAccountName}.blob.core.windows.net${sasToken}`
      );
      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      for (let i = 0; i < selectedImageNames.length; i++) {
        const imageName = selectedImageNames[i];
        const imageData = selectedImages[i];
        const blobClient = containerClient.getBlockBlobClient(imageName);
        blobClient.uploadData(imageData);
      }
      const requestData = {
        product_type: prodType,
        name: prodname,
        manufacturer: manufacturer,
        drug_molecule: drugmol,
        medicine_type: selectedMedicine,
        batch_number: batchno,
        store_name: storename,
        vendor_name: vendorname,
        expiry_date: expdate,
        image: selectedImageNames,
        description: description,
        introduction: introduction,
        benefits: benifits,
        how_to_use: howtouse,
        safety_advise: saftyadvice,
        primary_use: primaryuse,
        if_miss: ifmiss,
        common_side_effect: sideeffect,
        alcoholInteraction: alcoholinteract,
        pregnancyInteraction: pregnancyinteract,
        lactationInteraction: lactationinteract,
        drivingInteraction: drivinginteract,
        kidneyInteraction: kidneyinteract,
        liverInteraction: liverinteract,
        packaging: packaging,
        packaging_type: packagingtype,
        label: label,
        fact_box: factbox,
        storage: storage,
        manufacturer_address: manadd,
        unit: unitmedi,
        capacity: capacity,
        max_order_quantity: maxordqua,
        price: unitprice,
        total_stock: totprosto,
        discount_type: discounttypevalue,
        discount: discount,
        tax_type: taxtype,
        tax: taxrate,
        product_categories: productCategories,
      };
      Axios.put(`${url}/updateProduct?id=${ids}`, requestData)
        .then((response) => {
          // console.log(response);
          Swal.fire({
            title: "Product details updated successfully!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000, // set the duration in milliseconds
          });
          setTimeout(function () {
            navigate("/Products");
          }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            title: "Failed to Update Product",
            icon: "error",
            showConfirmButton: false,
            timer: 2000, // set the duration in milliseconds
          });
        });
    }
  };
  const validate = () => {
    const newErrors = {};
    if (!prodType) {
      newErrors.prodType = "Please select a Product Type";
    }
    if (!prodname?.trim()) {
      newErrors.prodname = "Product name is required";
    }
    if (!manufacturer?.trim()) {
      newErrors.manufacturer = "Manufactures is required";
    }
    if (!drugmol?.trim()) {
      newErrors.drugmol = "Drug Molecule is required";
    }
    if (!selectedMedicine) {
      newErrors.selectedMedicine = "Please select a Medicine Type";
    }
    if (!batchno) {
      newErrors.batchno = "Batch Number is required";
    } else if (Number(batchno) < 0) {
      newErrors.batchno = "Batch Number cannot be negative";
    }

    if (!storename?.trim()) {
      newErrors.storename = "Store name is required";
    }
    if (!vendorname?.trim()) {
      newErrors.vendorname = "Vendor name is required";
    }
    if (!expdate?.trim()) {
      newErrors.expdate = "Expiry Date is required";
    }
    if (selectedImageNames.length === 0) {
      newErrors.imageerr = "Minimum 1 image is required";
    }
    if (!description?.trim()) {
      newErrors.description = "Description is required";
    }
    if (!introduction?.trim()) {
      newErrors.introduction = "Introduction is required";
    }
    if (!benifits?.trim()) {
      newErrors.benifits = "Benifits is required";
    }
    if (!howtouse?.trim()) {
      newErrors.howtouse = "How to is required";
    }
    if (!saftyadvice?.trim()) {
      newErrors.saftyadvice = "Safty advice is required";
    }
    if (!primaryuse?.trim()) {
      newErrors.primaryuse = "Primary use is required";
    }
    if (!ifmiss?.trim()) {
      newErrors.ifmiss = "If Miss is required";
    }
    if (!sideeffect?.trim()) {
      newErrors.sideeffect = "Side effect is required";
    }
    if (!alcoholinteract) {
      newErrors.alcoholinteract = "Please select Alcohol interact";
    }
    if (!pregnancyinteract) {
      newErrors.pregnancyinteract = "Please select Pregnancy interact";
    }
    if (!lactationinteract) {
      newErrors.lactationinteract = "Please select Lactation interact";
    }
    if (!drivinginteract) {
      newErrors.drivinginteract = "Please select Driving interact";
    }
    if (!kidneyinteract) {
      newErrors.kidneyinteract = "Please select Kidney interact";
    }
    if (!liverinteract) {
      newErrors.liverinteract = "Please select Liver interact";
    }
    if (!packaging?.trim()) {
      newErrors.packaging = "Packaging is required";
    }
    if (!packagingtype?.trim()) {
      newErrors.packagingtype = "Packaging type is required";
    }
    if (!label?.trim()) {
      newErrors.label = "Label is required";
    }
    if (!factbox?.trim()) {
      newErrors.factbox = "Fact box is required";
    }
    if (!storage?.trim()) {
      newErrors.storage = "Storage is required";
    }
    if (!manadd?.trim()) {
      newErrors.manadd = "Manufacturer Address is required";
    }
    if (!selectedCategory) {
      newErrors.selectedCategory = "Please select Category";
    }
    if (!selectedsubCategory) {
      newErrors.selectedsubCategory = "Please select Sub Category";
    }
    if (!unitmedi) {
      newErrors.unitmedi = "Please select Unit";
    }
    if (!capacity?.trim()) {
      newErrors.capacity = "Capacity is required";
    } else if (Number(capacity) < 0) {
      newErrors.capacity = "Capacity cannot be negative";
    }
    if (!maxordqua) {
      newErrors.maxordqua = "Maximum Order Quantity is required";
    } else if (Number(maxordqua) < 0) {
      newErrors.maxordqua = "Maximum Order Quantity cannot be negative";
    }

    if (!unitprice) {
      newErrors.unitprice = "Unit price is required";
    } else if (Number(unitprice) < 0) {
      newErrors.unitprice = "Unit price cannot be negative";
    }
    if (!totprosto) {
      newErrors.totprosto = "Total Product Stock is required";
    } else if (Number(totprosto) < 0) {
      newErrors.totprosto = "Total Product Stock cannot be negative";
    }

    if (!discounttypevalue) {
      newErrors.discounttypevalue = "Please select discount type value";
    }
    if (!discount?.trim()) {
      newErrors.discount = "Discount is required";
    } else if (Number(discount) < 0) {
      newErrors.discount = "Discount cannot be negative";
    }
    if (!taxtype) {
      newErrors.taxtype = "Please select Tax type";
    }
    if (!taxrate?.trim()) {
      newErrors.taxrate = "Tax rate is required";
    } else if (Number(taxrate) < 0) {
      newErrors.taxrate = "Tax rate cannot be negative";
    }
    setNameError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleClear = () => {
    setProdType(editData?.product_type);
    setprodName(editData?.name);
    setManufacturer(editData?.manufacturer);
    setDrugmol(editData?.drug_molecule);
    setSelectedMedicine(editData?.medicine_type);
    setBatchno(editData?.batch_number);
    setStorename(editData?.store_name);
    setVendorname(editData?.vendor_name);
    setExpdate(editData?.expiry_date);
    setDescription(editData?.description);
    setIntroduction(editData?.introduction);
    setBenifits(editData?.benefits);
    setHowtouse(editData?.how_to_use);
    setSaftyadvice(editData?.safety_advise);
    setPrimaryuse(editData?.primary_use);
    setIfmiss(editData?.if_miss);
    setSideeffect(editData?.common_side_effect);
    setAlcoholinteract(editData?.alcoholInteraction);
    setPregnancyinteract(editData?.pregnancyInteraction);
    setLactationinteract(editData?.lactationInteraction);
    setDrivinginteract(editData?.drivingInteraction);
    setKidneyinteract(editData?.kidneyInteraction);
    setLiverinteract(editData?.liverInteraction);
    setPackaging(editData?.packaging);
    setPackagingtype(editData?.packaging_type);
    setLabel(editData?.label);
    setFactbox(editData?.fact_box);
    setStorage(editData?.storage);
    setManadd(editData?.manufacturer_address);
    setSelectedCategory(editData?.product_categories);
    setSelectedsubCategory(editData?.product_categories);
    setUnitmedi(editData?.unit);
    setCapacity(editData?.capacity);
    setMaxordqua(editData?.max_order_quantity);
    setUnitprice(editData?.price);
    setTotprosto(editData?.total_stock);
    setDiscounttypevalue(editData?.discount_type);
    setDiscount(editData?.discount);
    setTaxtype(editData?.tax_type);
    setTaxrate(editData?.tax);
    setSelectedImageNames(editData?.image);
  };

  // console.log(selectedCategory.category_id, "sc");

  return (
    <>
      <SidebarPharma />
      <div className="sidebarleft">
        <HeaderPharma />
        <div className="containerAdd01">
          <div className="subcategory">
            <header className="pointerBeam">
              <b>
                <u onClick={backbutton}>Products</u>/ Edit Product
              </b>
            </header>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <br />
            <div className="containersborder01">
              <label className="subCategoryLable01">
                <b>Basic Information</b>
              </label>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input1">
                    <div>
                      <label className="subCategoryLable01">
                        Product Type <div className="redStart">*</div>
                      </label>
                      <select
                        className="subCategory01"
                        value={prodType}
                        onChange={(e) => setProdType(e.target.value)}
                      >
                        {prodTypes.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    {nameError.prodType && (
                      <div className="error">{nameError.prodType}</div>
                    )}
                  </div>
                  <div className="input2">
                    <label className="subCategoryLable01">
                      Product Name <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Product Name"
                      value={prodname}
                      onChange={(e) => setprodName(e.target.value)}
                    />
                    {nameError.prodname && (
                      <div className="error">{nameError.prodname}</div>
                    )}
                  </div>
                  <div className="input3">
                    <label className="subCategoryLable01">
                      Manufactures <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Manufactures"
                      value={manufacturer}
                      onChange={(e) => setManufacturer(e.target.value)}
                    />
                    {nameError.manufacturer && (
                      <div className="error">{nameError.manufacturer}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input4">
                    <label className="subCategoryLable01">
                      Drug Molecule <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Drug Molecule"
                      value={drugmol}
                      onChange={(e) => setDrugmol(e.target.value)}
                    />
                    {nameError.drugmol && (
                      <div className="error">{nameError.drugmol}</div>
                    )}
                  </div>
                  <div className="input5">
                    <label className="subCategoryLable01">
                      Medicine Type <div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={selectedMedicine}
                      onChange={(e) => setSelectedMedicine(e.target.value)}
                    >
                      {medicineTypes.map((medicine) => (
                        <option key={medicine.id} value={medicine.id}>
                          {medicine.name}
                        </option>
                      ))}
                    </select>
                    {nameError.selectedMedicine && (
                      <div className="error">{nameError.selectedMedicine}</div>
                    )}
                  </div>
                  <div className="input6">
                    <label className="subCategoryLable01">
                      Batch Number <div className="redStart">*</div>
                    </label>
                    <input
                      type="number"
                      className="subCategory01"
                      placeholder="Batch Number"
                      value={batchno}
                      onChange={(e) => setBatchno(e.target.value)}
                    />
                    {nameError.batchno && (
                      <div className="error">{nameError.batchno}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input7">
                    <label className="subCategoryLable01">
                      Store Name <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Store Name"
                      value={storename}
                      onChange={(e) => setStorename(e.target.value)}
                    />
                    {nameError.storename && (
                      <div className="error">{nameError.storename}</div>
                    )}
                  </div>
                  <div className="input8">
                    <label className="subCategoryLable01">
                      Vendor Name<div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Vendor Name"
                      value={vendorname}
                      onChange={(e) => setVendorname(e.target.value)}
                    />
                    {nameError.vendorname && (
                      <div className="error">{nameError.vendorname}</div>
                    )}
                  </div>
                  <div className="input9">
                    <label className="subCategoryLable01">
                      Expiry Date <div className="redStart">*</div>
                    </label>
                    <input
                      type="date"
                      className="subCategory01"
                      value={expdate}
                      min={getCurrentDate()}
                      onChange={(e) => setExpdate(e.target.value)}
                    />
                    {nameError.expdate && (
                      <div className="error">{nameError.expdate}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input2">
                    <label className="subCategoryLable01">
                      Image<div className="redStart">*</div>
                    </label>
                    <div className="selectedImagesContainer">
                      {selectedImages.map((image, index) => (
                        <div key={index} className="selectedImageItem">
                          <img
                            src={URL.createObjectURL(image)}
                            alt="Selected Image"
                            className="selectedImage"
                          />
                          <button
                            className="deleteImageButton"
                            onClick={() => handleDeleteImage(index)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      ))}
                      {imageUrls.map((imageUrl, index) => (
                        <div key={index} className="selectedImageItem">
                          <img
                            src={`${siteurl}${imageUrl}`}
                            alt="Existing Image"
                            className="selectedImage"
                          />
                          <button
                            className="deleteImageButton"
                            onClick={() => handleDeleteExistingImage(index)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      ))}
                      {selectedImages.length < imgnum && (
                        <div className="uploadIconContainer">
                          <CloudUploadIcon
                            style={{ width: "150px", height: "150px" }}
                            className="uploadicon01"
                            onClick={openFileUpload}
                          />
                          <input
                            type="file"
                            accept="image/jpeg, image/png, image/gif"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            multiple
                          />
                        </div>
                      )}
                    </div>
                    <p className="caption01">
                      <InfoIcon style={{ width: "23px", height: "13px" }} />
                      You can select up to 5 images
                    </p>
                    {selectedImages.length === 5 && (
                      <p className="caption01">
                        You have selected the maximum number of images
                      </p>
                    )}
                    {nameError.imageerr && (
                      <div className="error">{nameError.imageerr}</div>
                    )}
                    {imageError && <p className="imageError">{imageError}</p>}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="containersborder01">
              <label className="subCategoryLable01">
                <b>Product Detials</b>
              </label>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input4">
                    <label className="subCategoryLable01">
                      Description <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    {nameError.description && (
                      <div className="error">{nameError.description}</div>
                    )}
                  </div>
                  <div className="input5">
                    <label className="subCategoryLable01">
                      Introduction <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={introduction}
                      onChange={(e) => setIntroduction(e.target.value)}
                    />
                    {nameError.introduction && (
                      <div className="error">{nameError.introduction}</div>
                    )}
                  </div>
                  <div className="input6">
                    <label className="subCategoryLable01">
                      Benifits <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={benifits}
                      onChange={(e) => setBenifits(e.target.value)}
                    />
                    {nameError.benifits && (
                      <div className="error">{nameError.benifits}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input4">
                    <label className="subCategoryLable01">
                      How to Use <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={howtouse}
                      onChange={(e) => setHowtouse(e.target.value)}
                    />
                    {nameError.howtouse && (
                      <div className="error">{nameError.howtouse}</div>
                    )}
                  </div>
                  <div className="input5">
                    <label className="subCategoryLable01">
                      Safty Advise <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={saftyadvice}
                      onChange={(e) => setSaftyadvice(e.target.value)}
                    />
                    {nameError.saftyadvice && (
                      <div className="error">{nameError.saftyadvice}</div>
                    )}
                  </div>
                  <div className="input6">
                    <label className="subCategoryLable01">
                      Primary Use <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={primaryuse}
                      onChange={(e) => setPrimaryuse(e.target.value)}
                    />
                    {nameError.primaryuse && (
                      <div className="error">{nameError.primaryuse}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input4">
                    <label className="subCategoryLable01">
                      If Miss <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={ifmiss}
                      onChange={(e) => setIfmiss(e.target.value)}
                    />
                    {nameError.ifmiss && (
                      <div className="error">{nameError.ifmiss}</div>
                    )}
                  </div>
                  <div className="input5">
                    <label className="subCategoryLable01">
                      Common Side Effect <div className="redStart">*</div>
                    </label>
                    <textarea
                      className="productdetials001"
                      value={sideeffect}
                      onChange={(e) => setSideeffect(e.target.value)}
                    />
                    {nameError.sideeffect && (
                      <div className="error">{nameError.sideeffect}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input4">
                    <label className="subCategoryLable01">
                      Alcohol Interaction <div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={alcoholinteract}
                      onChange={(e) => setAlcoholinteract(e.target.value)}
                    >
                      {interaction.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {nameError.alcoholinteract && (
                      <div className="error">{nameError.alcoholinteract}</div>
                    )}
                  </div>
                  <div className="input5">
                    <label className="subCategoryLable01">
                      Pregnancy Interaction <div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={pregnancyinteract}
                      onChange={(e) => setPregnancyinteract(e.target.value)}
                    >
                      {interaction.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {nameError.pregnancyinteract && (
                      <div className="error">{nameError.pregnancyinteract}</div>
                    )}
                  </div>
                  <div className="input6">
                    <label className="subCategoryLable01">
                      Lactation Interaction <div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={lactationinteract}
                      onChange={(e) => setLactationinteract(e.target.value)}
                    >
                      {interaction.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {nameError.lactationinteract && (
                      <div className="error">{nameError.lactationinteract}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input7">
                    <label className="subCategoryLable01">
                      Driving Interaction <div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={drivinginteract}
                      onChange={(e) => setDrivinginteract(e.target.value)}
                    >
                      {interaction.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {nameError.drivinginteract && (
                      <div className="error">{nameError.drivinginteract}</div>
                    )}
                  </div>
                  <div className="input8">
                    <label className="subCategoryLable01">
                      Kidney Interaction<div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={kidneyinteract}
                      onChange={(e) => setKidneyinteract(e.target.value)}
                    >
                      {interaction.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {nameError.kidneyinteract && (
                      <div className="error">{nameError.kidneyinteract}</div>
                    )}
                  </div>
                  <div className="input9">
                    <label className="subCategoryLable01">
                      Liver Interaction <div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={liverinteract}
                      onChange={(e) => setLiverinteract(e.target.value)}
                    >
                      {interaction.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {nameError.liverinteract && (
                      <div className="error">{nameError.liverinteract}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input4">
                    <label className="subCategoryLable01">
                      Packaging <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Packaging"
                      value={packaging}
                      onChange={(e) => setPackaging(e.target.value)}
                    />
                    {nameError.packaging && (
                      <div className="error">{nameError.packaging}</div>
                    )}
                  </div>
                  <div className="input5">
                    <label className="subCategoryLable01">
                      Packaging Type <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Packaging Type"
                      value={packagingtype}
                      onChange={(e) => setPackagingtype(e.target.value)}
                    />
                    {nameError.packagingtype && (
                      <div className="error">{nameError.packagingtype}</div>
                    )}
                  </div>
                  <div className="input6">
                    <label className="subCategoryLable01">
                      Label <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Label"
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                    />
                    {nameError.label && (
                      <div className="error">{nameError.label}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  {/* Additional fields */}
                  <div className="input7">
                    <label className="subCategoryLable01">
                      Fact Box <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Fact Box"
                      value={factbox}
                      onChange={(e) => setFactbox(e.target.value)}
                    />
                    {nameError.factbox && (
                      <div className="error">{nameError.factbox}</div>
                    )}
                  </div>
                  <div className="input8">
                    <label className="subCategoryLable01">
                      Storage<div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Storage"
                      value={storage}
                      onChange={(e) => setStorage(e.target.value)}
                    />
                    {nameError.storage && (
                      <div className="error">{nameError.storage}</div>
                    )}
                  </div>
                  <div className="input9">
                    <label className="subCategoryLable01">
                      Manufacturer Address <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="Manufacturer Address"
                      value={manadd}
                      onChange={(e) => setManadd(e.target.value)}
                    />
                    {nameError.manadd && (
                      <div className="error">{nameError.manadd}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="containersborder01">
              <label className="subCategoryLable01">
                <b>Category</b>
              </label>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input1">
                    <div>
                      <label className="subCategoryLable01">
                        Category <div className="redStart">*</div>
                      </label>
                      <select
                        className="subCategory01"
                        value={parSats}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        {parentCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {nameError.selectedCategory && (
                      <div className="error">{nameError.selectedCategory}</div>
                    )}
                  </div>
                  <div className="input2">
                    <label className="subCategoryLable01">
                      Sub Category <div className="redStart">*</div>
                    </label>
                    <select
                      className="subCategory01"
                      value={subCats}
                      onChange={(e) => setSelectedsubCategory(e.target.value)}
                    >
                      {subcat.map((subcate) => (
                        <option key={subcate.id} value={subcate.id}>
                          {subcate.name}
                        </option>
                      ))}
                    </select>
                    {nameError.selectedsubCategory && (
                      <div className="error">
                        {nameError.selectedsubCategory}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input1">
                    <div>
                      <label className="subCategoryLable01">
                        Unit <div className="redStart">*</div>
                      </label>
                      <select
                        className="subCategory01"
                        value={unitmedi}
                        onChange={(e) => setUnitmedi(e.target.value)}
                      >
                        {unitmed.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    {nameError.unitmedi && (
                      <div className="error">{nameError.unitmedi}</div>
                    )}
                  </div>
                  <div className="input2">
                    <label className="subCategoryLable01">
                      Capacity <div className="redStart">*</div>
                    </label>
                    <input
                      type="number"
                      className="subCategory01"
                      placeholder="-Ex- 10"
                      value={capacity}
                      onChange={(e) => setCapacity(e.target.value)}
                    />
                    {nameError.capacity && (
                      <div className="error">{nameError.capacity}</div>
                    )}
                  </div>
                  <div className="input3">
                    <label className="subCategoryLable01">
                      Maximum Order Quantity <div className="redStart">*</div>
                    </label>
                    <input
                      type="text"
                      className="subCategory01"
                      placeholder="-Ex-5"
                      value={maxordqua}
                      onChange={(e) => setMaxordqua(e.target.value)}
                    />
                    {nameError.maxordqua && (
                      <div className="error">{nameError.maxordqua}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="containersborder01">
              <label className="subCategoryLable01">
                <b>Price Information</b>
              </label>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input1">
                    <div>
                      <label className="subCategoryLable01">
                        Unit Price <div className="redStart">*</div>
                      </label>
                      <input
                        type="number"
                        className="subCategory01"
                        placeholder="-Ex-123"
                        value={unitprice}
                        onChange={(e) => setUnitprice(e.target.value)}
                      />
                    </div>
                    {nameError.unitprice && (
                      <div className="error">{nameError.unitprice}</div>
                    )}
                  </div>
                  <div className="input2">
                    <label className="subCategoryLable01">
                      Total Product Stock <div className="redStart">*</div>
                    </label>
                    <input
                      type="number"
                      className="subCategory01"
                      placeholder="-Ex-100"
                      value={totprosto}
                      onChange={(e) => setTotprosto(e.target.value)}
                    />
                    {nameError.totprosto && (
                      <div className="error">{nameError.totprosto}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input1">
                    <div>
                      <label className="subCategoryLable01">
                        Discount Type <div className="redStart">*</div>
                      </label>
                      <select
                        className="subCategory01"
                        value={discounttypevalue}
                        onChange={(e) => setDiscounttypevalue(e.target.value)}
                      >
                        {discounttype.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    {nameError.discounttypevalue && (
                      <div className="error">{nameError.discounttypevalue}</div>
                    )}
                  </div>
                  <div className="input2">
                    <label className="subCategoryLable01">
                      Discount <div className="redStart">*</div>
                    </label>
                    <input
                      type="number"
                      className="subCategory01"
                      placeholder="-Ex-100"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                    {nameError.discount && (
                      <div className="error">{nameError.discount}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="colomproduct001">
                <div className="maincontainer">
                  <div className="input1">
                    <div>
                      <label className="subCategoryLable01">
                        Tax Type <div className="redStart">*</div>
                      </label>
                      <select
                        className="subCategory01"
                        value={taxtype}
                        onChange={(e) => setTaxtype(e.target.value)}
                      >
                        {discounttype.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    {nameError.taxtype && (
                      <div className="error">{nameError.taxtype}</div>
                    )}
                  </div>
                  <div className="input2">
                    <label className="subCategoryLable01">
                      Tax Rate <div className="redStart">*</div>
                    </label>
                    <input
                      type="number"
                      className="subCategory01"
                      placeholder="-Ex-100"
                      value={taxrate}
                      onChange={(e) => setTaxrate(e.target.value)}
                    />
                    {nameError.taxrate && (
                      <div className="error">{nameError.taxrate}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-products">
              <div>
                <button
                  type="button"
                  onClick={handleClear}
                  className="clearbuttonProductsEdit01"
                >
                  Reset
                </button>
              </div>
              <div className="product-submit-btn">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="SubmitbuttonProductsEdit01"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ProductsEdit;
