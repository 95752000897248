import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import Header from "../../../components/Header";
import MaterialTable from "material-table";
import moment from "moment";
import "../../../css/bloodreqlist.css";
import Swal from "sweetalert2";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Navigate } from "react-router-dom";
import debounce from "lodash.debounce";
import Axios from "axios";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Paginate from "./paginate";
import Sidebars from "../../../components/Sidebar";
import { useCallback } from "react";
import ViewRejBloodReq from "./ViewRejBloodReq";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const RejectedBloodReq = () => {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef1 = React.useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [totalCou, setTotalCou] = useState([]);

  const url = process.env.REACT_APP_URL;
  // setLoggedIn(true);
  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const columns = [
    {
      title: "ID",
      field: "id",
      editable: false,
      export: true,
      hidden: true,
    },
    {
      title: "Hospital Name",
      field: "hospitalname",
      export: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "phone",
      export: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "gender",
      export: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Blood (G)",
      field: "bloodgroup",
      export: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Need Type",
      field: "needtype",
      editable: false,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Blood Particulars",
      field: "bloodparticulars",
      hidden: true,
      export: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Units",
      field: "units",
      export: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "State",
      field: "tblstate.statename",
      editable: false,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "District",
      field: "tbldistrict.districtname",
      editable: false,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "City",
      field: "tblcity.cityname",
      editable: false,
      export: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Patient Name",
      field: "patientname",
      editable: false,
      export: true,
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Age",
      field: "age",
      export: true,
      hidden: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "IP Number",
      field: "ipnumber",
      editable: false,
      export: true,
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Doctor Name",
      field: "doctorname",
      hidden: true,
      export: true,
      editable: false,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Ordered By",
      field: "orderedby",
      editable: false,
      export: true,
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Requested Date",
      field: "createdAt",
      type: "date",
      export: true,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.createdAt) {
          const formattedDate = moment(rowData.createdAt).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Rejected Date",
      field: "rejectedDate",
      type: "date",
      export: true,
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.rejectedDate) {
          const formattedDate = moment(rowData.rejectedDate).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Approved",
      field: "approved",
      export: true,
      editable: false,
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },
  ];

  useEffect(() => {
    localStorage.setItem("role", "admin");
    rejectedBloodReq();
  }, []);

  const rejectedBloodReq = () => {
    var page = page;
    // setLoading(true);
    Axios.get(`${url}/rejectedbloodreq`).then((response) => {
      setData(response.data.users);
      setTotalCou(response.data.totalcount);
      // setLoading(false);
    });
  };

  const handleApprove = useCallback(
    (rowData) => {
      var id = rowData.id;

      // setLoading(true);
      Swal.fire({
        title: "Confirm Approval of Request?",
        showCancelButton: true,
        confirmButtonText: `Confirm`,
        confirmButtonColor: "#0f3374",
        cancelButtonColor: "#d32a2a",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Request Approved!", "", "success");
          setTimeout(function () {
            window.location.reload();
          }, 2000);
          Axios.post(`${url}/updatebloodreq`, { id })
            .then((response) => {
              if (response.data.mesage === "Successfully Updated") {
                // Update the row data with the new status value
                const updatedRowData = {
                  ...rowData,
                  status: "Yes",
                };
                // Update the table data in the local state
                const newTableData = [...data];
                const rowIndex = newTableData.findIndex(
                  (row) => row.id === rowData.id
                );
                newTableData[rowIndex] = updatedRowData;
                setData(newTableData);
              }
            })
            .catch((error) => {});
        }
      });
    },
    [data]
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(totalCou / recordsPerPage);

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MaterialTable
            title="Rejected Blood Requesties"
            columns={columns}
            data={data}
            // isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: (rowData) =>
                  rowData.approved === "YES" ? (
                    <CheckCircleOutlinedIcon style={{ color: "red" }} />
                  ) : (
                    <CheckCircleOutlinedIcon style={{ color: "#70A34E" }} />
                  ),
                tooltip: "Approve",
                onClick: (event, rowData) => {
                  if (rowData.approved !== "YES") {
                    handleApprove(rowData);
                  }
                },
              },
              {
                icon: () => <RemoveRedEyeIcon style={{ color: "#007196" }} />,
                tooltip: "View",
                stickyHeader: true,
                onClick: (event, rowData) => {
                  setOpenPopUp(true), setSelectedRow(rowData);
                },
              },
            ]}
            options={{
              detailPanelColumnAlignment: "right",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              search: true,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              actionsColumnIndex: columns.length,
              stickyHeader: true,
              pageSize: 50,
              pageSizeOptions: [20, 30, 50],
              // paginationType: "stepped",
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76.5vh",
            }}
            // components={{
            //   Pagination: (props) => (
            //     <Paginate
            //       nPages={nPages}
            //       currentPage={currentPage}
            //       setCurrentPage={setCurrentPage}
            //     />
            //   ),
            // }}
          />
          <ViewRejBloodReq
            openPopup={openPopUp}
            setOpenPopUp={setOpenPopUp}
            allBloodReqData={selectedRow}
          />
        </div>
      </div>
    </>
  );
};

export default RejectedBloodReq;
