import React, { useEffect, useRef, useState } from "react";
import "../../../../doctor_profile/css/allAppointments.css";
import "../../../../css/doctor.css";
import MaterialTable from "material-table";
import Page_Bb from "../../../services_component/blood_bank/Page_bb";
import Axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PublishIcon from "@mui/icons-material/Publish";
import Swal from "sweetalert2";
import ViewPopupDashboardTelecaller from "../view-follow-up";
import moment from "moment";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function HistoryOnlineConsultations() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);
  const [dataCount, setDataCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const url = process.env.REACT_APP_URL;
  const teleId = localStorage.getItem("teleId");

  const [selectedImage, setSelectedImage] = useState("");
  const [isImageSelected, setIsImageSelected] = useState(false);
  const eurl = "https://suitsdata.blob.core.windows.net";
  const prod_name = "prescription";

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
    setIsImageSelected(true);
  };

  const handleFollowUp = (rowData) => {
    setOpenPopUp(true);
    setSelectedRow(rowData);
  };

  const columns = [
    {
      title: "Patient Name",
      field: "user_fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "user_mobile",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Name",
      field: "doctor_name",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Doctor Mobile",
      field: "doctor_phone_no",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Gender",
      field: "user_gender",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Consultation Date",
      field: "appointment_taken_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_taken_date) {
          const formattedDate = moment(rowData.appointment_taken_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Type of Consultation",
      field: "treatment_mode",
      hidden: true,
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Appointment Date",
      field: "appointment_date",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.appointment_date) {
          const formattedDate = moment(rowData.appointment_date).format(
            "DD-MM-YYYY"
          );
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Time Slot",
      field: "appointment_time_slot",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Upload Prescription",
      field: "prescription",

      render: (rowData) =>
        rowData?.treatment_mode === "OFFLINE" ? (
          <em>NA</em>
        ) : (
          <>
            {" "}
            {rowData.prescription_files ? (
              <div className="upload-image-preview">
                <button
                  onClick={() => handleUploadButtonClick(rowData)}
                  className="upload-prescription-button"
                  style={{
                    backgroundColor: "#007196",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <PublishIcon
                    style={{
                      color: "white",
                      marginRight: "0.3rem",
                      marginLeft: "0.3rem",
                      marginTop: "0.3rem",
                      marginBottom: "0.3rem",
                    }}
                  />
                </button>
                <img
                  src={`${eurl}/${prod_name}/${rowData?.prescription_files}`}
                  style={{
                    width: 50,
                    cursor: "pointer",
                    height: 40,
                    marginLeft: "10px",
                  }}
                  onClick={() =>
                    handleImageClick(
                      `${eurl}/${prod_name}/${rowData?.prescription_files}`
                    )
                  }
                />
              </div>
            ) : (
              <button
                onClick={() => handleUploadButtonClick(rowData)}
                className="upload-prescription-button"
                style={{
                  backgroundColor: "#007196",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <PublishIcon
                  style={{
                    color: "white",
                    marginRight: "0.3rem",
                    marginLeft: "0.3rem",
                    marginTop: "0.3rem",
                    marginBottom: "0.3rem",
                  }}
                />
              </button>
            )}
          </>
        ),
    },
    {
      title: "Consultation Fee",
      field: "consultation_fee",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => `₹${rowData?.consultation_fee}`,
    },

    {
      title: "Payment Method",
      field: "payment_mode",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Follow Up",
      render: (rowData) => (
        <div>
          <p
            className="follow-up-btn-history"
            onClick={() => handleFollowUp(rowData)}
          >
            <RemoveRedEyeIcon />
          </p>
        </div>
      ),
    },
  ];

  const handleUploadButtonClick = (rowData) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];

      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        const fileName = file.name;
        const azureAccountName = "suitsdata";
        const sasToken =
          "?sv=2021-12-02&ss=bf&srt=sco&sp=rwdlactfx&se=2029-03-29T11:31:53Z&st=2023-03-29T03:31:53Z&spr=https&sig=OX48TXg%2F6xwp3b32hwe7Tf6sggDFc4YpCvvwCU5EUj8%3D";
        const containerName = "prescription";

        const blobServiceClient = new BlobServiceClient(
          `https://${azureAccountName}.blob.core.windows.net${sasToken}`
        );

        const containerClient =
          blobServiceClient.getContainerClient(containerName);

        const blobClient = containerClient.getBlockBlobClient(fileName);
        blobClient.uploadData(file);

        const user_id = rowData?.user_id;
        const app_id = rowData?.appointment_id;
        Axios.put(`${url}/updateAppointmentPrescription`, {
          appointment_id: app_id,
          user_id: user_id,
          prescription_file: fileName,
        })
          .then((response) => {
            if (response.data.Message === "Successfully Updated Details") {
              Swal.fire({
                title: "Prescription Uploaded Successfully!",
                icon: "success",
                showConfirmButton: true,
                timer: 2000, // set the duration in milliseconds
              });
              setTimeout(function () {
                window.location.reload();
              }, 2000); // set the delay in milliseconds (5 seconds = 5000 milliseconds)
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Failed to Upload Prescription!",
              icon: "error",
              showConfirmButton: false,
              timer: 2000, // set the duration in milliseconds
            }); //
          });
      }
    };
    input.click();
  };
  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const fetchData = (page) => {
    var page = page;
    const telecaller_id = teleId;
    // console.log(page, "page");
    setLoading(true);
    Axios.get(
      `${url}/getTelecallerOnlineAppointmentHistory/${page}?telecaller_id=${telecaller_id} `
    ).then((response) => {
      // console.log(response);
      // console.log(response.data.result);
      setData(response.data.result);
      setDataCount(response.data.totalCount);
      setLoading(false);
    });
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);

  return (
    <>
      <div className="main_doc">
        <div className="main_newreq1">
          <div style={{ width: "100%" }}>
            <MaterialTable
              columns={columns}
              data={data}
              icons={customIcons}
              options={{
                searchFieldStyle: { border: "2px solid #f00" },
                detailPanelColumnAlignment: "right",
                exportButton: true,
                exportButton: true,
                sorting: true,
                search: false,
                toolbar: false,
                searchOnEnter: true,
                debounceInterval: 500,
                searchAutoFocus: false,
                actionsColumnIndex: columns.length,
                searchFieldVariant: "outlined",
                stickyHeader: true,
                pageSize: 50,
                paginationType: "stepped",
                showFirstLastPageButtons: false,
                emptyRowsWhenPaging: false,
                headerStyle: {
                  size: "40px",
                  background: "rgba(0, 0, 0, 0.05)",
                  position: "sticky",
                  top: "0",
                },
                stickyHeader: true,
                maxBodyHeight: "34.5vh",
              }}
              components={{
                Pagination: (props) => (
                  <Page_Bb
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      {isImageSelected && (
        <div className="selected-image-container">
          <img src={selectedImage} alt="Selected" />
          <button
            className="close-button"
            onClick={() => setIsImageSelected(false)}
          >
            Close
          </button>
        </div>
      )}
      {openPopUp && (
        <ViewPopupDashboardTelecaller
          openPopup={openPopUp}
          setOpenPopUp={setOpenPopUp}
          data={selectedRow}
        />
      )}
    </>
  );
}

export default HistoryOnlineConsultations;
