import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SidebarLab from "../../../components/sidebarLab";
import HeaderLab from "../../../components/HeaderLab";
import "../../../css/adddonor.css";
import { Container } from "react-bootstrap";
import { colors } from "@material-ui/core";
import Axios from "axios";
import { useParams } from "react-router-dom";
import MaterialTable from "material-table";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import uploadImage from "../../../images/upload.png";
import personImage from "../../../images/person.png";
import locationImage from "../../../images/location.png";
import rupeeImage from "../../../images/rupee.png";
import printImage from "../../../images/printer.png";

function LabTestsBookingDetails() {
  const [personName, setPersonName] = useState("");
  const [personNumber, setpersonNumber] = useState("");
  const [personEmail, setPersonEmail] = useState("");

  const [personAddress, setPersonAddress] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [orderStatusSelected, setOrderStatusSelected] = useState("");

  const [couponDiscount, setCouponDiscount] = useState("");

  const [userId, setUserId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [bookingDetailsData, setBookingDetailsData] = useState([]);
  const [bookingDetailsCount, setBookingDetailsCount] = useState("");
  const { labId } = useParams();

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    Axios.get(`${url}/getLabTestBookingDetailsInAdminPortal/${labId}`)
      .then((response) => {
        // console.log("response ", response);
        setBookingDetailsData(response.data.result);
        //setBookingDetailsCount(response.data.result.orderDetails.length);

        setPersonName(response.data.result[0].customer.customerName);
        setpersonNumber(response.data.result[0].customer.customerMobileNumber);
        setPersonEmail(response.data.result[0].customerAddress.customerEmail);
        setPersonAddress(
          response.data.result[0].customerAddress.customerAddress
        );
        setPaymentStatus(response.data.result[0].payment_status);
        // setCouponDiscount(parseInt(response.data.result.order.coupon.discount));
        // setUserId(response.data.result.order.user_id);
        setOrderStatus(response.data.result[0].booking_status);

        getDateFormat(response.data.result[0].createdAt);
      })
      .catch((error) => {
        console.error("Error ", error);
      });
  }, []);

  const handleSave = async () => {
    if (orderStatus) {
      const requestBody = {
        id: labId,
        booking_status: orderStatus,
      };

      try {
        const urlValue = `${url}/updateBookingStatus`;
        const response = await Axios.put(urlValue, requestBody);
        // console.log("handleSave ", response);
        // Check the response status
        if (response.status !== 200) {
          throw new Error("Failed to update booking details");
        } else if (response.status === 200) {
          alert(response.data.Message);
        }

        // Order details updated successfully
        // Handle success or perform additional actions
      } catch (error) {
        // Handle error
        console.error(error);
      }
    } else {
      alert("Please select booking status to update");
    }
  };

  const handlePrint = () => {
    const contentDiv = document.getElementById("content-to-print");
    if (contentDiv) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write(contentDiv.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  const handleOrderStatus = (event) => {
    //setOrderStatusSelected(event.target.value);
    setOrderStatus(event.target.value);
  };

  const getDateFormat = (createdAtDate) => {
    const dateString = createdAtDate;
    const date = new Date(dateString);

    // Get the day name
    const options = { weekday: "short" };
    const dayName = new Intl.DateTimeFormat("en-US", options).format(date);

    // Get the month name and day
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getDate();

    // Get the year
    const year = date.getFullYear();

    // Get the time in 12-hour format
    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // Format the date string
    const formattedDate = `${dayName}, ${month} ${day} ${year}, ${time}`;
    setCreatedAt(formattedDate);
    // console.log("dateformat ", formattedDate);
  };

  return (
    <>
      <SidebarLab />
      <div className="sidebarleft">
        <HeaderLab />
        <div
          className="subCategoryEditcontainerAdd01"
          style={{ height: "100%" }}
        >
          <div className="subcategory" style={{ fontSize: "14px" }}>
            <b>
              <Link style={{ textDecoration: "none" }} to="/Bookings">
                Lab Bookings
              </Link>{" "}
              / Booking Details
            </b>
          </div>

          <div
            style={{
              fontSize: "14px",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "65%" }}>
              <span>Order ID #{labId}</span>
              <br />
              <div
                style={{
                  fontSize: "12px",
                  color: "grey",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div>
                  <CalendarMonthIcon
                    style={{ fontSize: "20px", paddingTop: "5px" }}
                  />
                </div>
                <div style={{ paddingTop: "5px" }}>
                  {createdAt && createdAt}
                </div>
              </div>
            </div>

            <div
              style={{ width: "35%", display: "flex", flexDirection: "row" }}
            >
              <div>
                <select
                  id="orderstatus"
                  value={orderStatus}
                  onChange={handleOrderStatus}
                  style={{
                    padding: "8px",
                    border: "1px solid #a8d4f7",
                    borderRadius: "4px",
                  }}
                >
                  <option value="">Change Booking Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>

              <div>
                <button
                  onClick={handleSave}
                  style={{
                    padding: "9px",
                    marginLeft: "20px",
                    width: "80px",
                    backgroundColor: "#ffffff",
                    border: "1px solid #a8d4f7",
                    outline: "none",
                    borderRadius: "4px",
                  }}
                >
                  Save
                </button>
              </div>

              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={handlePrint}
                  style={{
                    marginLeft: "20px",
                    padding: "4px",
                    display: "flex",
                    flexDirection: "row",
                    width: "120px",
                    border: "1px solid #a8d4f7",
                    outline: "none",
                    borderRadius: "4px",
                  }}
                >
                  <img
                    src={printImage}
                    style={{
                      paddingTop: "4px",
                      paddingLeft: "4px",
                      fontSize: "12px",
                      height: "25px",
                    }}
                  />
                  {/* <LocalPrintshopOutlinedIcon
                    style={{ paddingTop: "4px", paddingLeft: "4px" }}
                  /> */}
                  <span style={{ paddingTop: "5px", paddingLeft: "4px" }}>
                    Print Invoice
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div id="content-to-print">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
                width: "86%",
                justifyContent: "space-between",
              }}
            >
              {/* For Customer */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#dcf1f7",
                    borderRadius: "50px",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${personImage})`,
                      width: "35px",
                      height: "35px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <div>Customer</div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    {personName && personName}
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    {personEmail && personEmail}
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    {personNumber && personNumber}
                  </div>
                </div>
              </div>

              {/* For Payment */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#dcf1f7",
                    borderRadius: "50px",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${locationImage})`,
                      width: "35px",
                      height: "35px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <div>Deliver To</div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>State : </span> Telangana
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>City : </span>Hyderabad
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>Street : </span> Near Govt School
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                      width: "240px",
                      justifyContent: "center",
                    }}
                  >
                    <span>Address : </span>
                    <span>{personAddress && personAddress}</span>
                  </div>
                </div>
              </div>

              {/* For Payment */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#dcf1f7",
                    borderRadius: "50px",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${rupeeImage})`,
                      width: "35px",
                      height: "35px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <div>Payment</div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>Payment Status : </span>{" "}
                    <span style={{ color: "green" }}>
                      {paymentStatus && paymentStatus}
                    </span>
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    <span>Transaction ID : </span>{" "}
                    {paymentStatus && paymentStatus}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="card" style={{ width: "100%" }}>
                <table id="jsonTable" className="orderDetails">
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Package Details</th>
                      <th>Parameters</th>
                      <th>Appointment Date</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {bookingDetailsData &&
                      bookingDetailsData.map((rowData) => {
                        return (
                          <tr key={rowData.id} className="tableRow">
                            <td style={{ paddingLeft: "6px" }}>
                              {rowData.labtest_booking_Id}
                            </td>
                            <td>
                              <div
                                style={{
                                  paddingLeft: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span style={{ fontSize: "15px" }}>
                                  {rowData.package_details.package_name}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  Category:{rowData.category.category_name}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  Provider:{rowData.provider.provider_name}
                                </span>
                              </div>
                            </td>
                            <td>
                              <ol>
                                {rowData.package_details.Lab_Test.map(
                                  (testData) => {
                                    return (
                                      <li
                                        key={testData.id}
                                        style={{ fontSize: "14px" }}
                                      >
                                        {testData.test_name}
                                      </li>
                                    );
                                  }
                                )}
                              </ol>
                            </td>
                            <td>
                              <div
                                style={{
                                  paddingLeft: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  fontSize: "15px",
                                }}
                              >
                                <span>{rowData.booking_date}</span>
                                <span>{rowData.booking_slot}</span>
                              </div>
                            </td>
                            <td>
                              <span>{rowData.charges}</span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {bookingDetailsData &&
                  bookingDetailsData.map((data) => {
                    return (
                      <table className="priceDetails">
                        <tr>
                          <td>Amount :</td>

                          <td style={{ width: "70px" }}></td>
                          <td style={{ width: "70px" }}></td>
                          <td style={{ color: "#6f7273" }}>
                            Rs. {data.charges.toFixed(2)}
                          </td>
                        </tr>

                        <hr></hr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>Total</td>
                          <td style={{ width: "70px" }}></td>
                          <td style={{ color: "green", fontWeight: "bold" }}>
                            {paymentStatus}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {" "}
                            Rs. {data.charges.toFixed(2)}
                          </td>
                        </tr>
                      </table>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* End of print content */}
        </div>
      </div>
    </>
  );
}

export default LabTestsBookingDetails;
