import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import "../../../css/adddonor.css";
import Sidebars from "../../../components/Sidebar";
function AddBloodDonors() {
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [district, setDistrict] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [bloodgroup, setBloodgroup] = useState("");
  const [mobile, setMobile] = useState("");
  const [approved, setApproved] = useState("");
  const [errors, setErrors] = useState({});
  const [stateList, setStateList] = useState({});

  const [districtList, setDistrictList] = useState({});

  const [cityList, setCityList] = useState({});
  const url = process.env.REACT_APP_URL;
  // setLoggedIn(true);

  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const ageDiffMs = Date.now() - dobDate.getTime();
    const ageDate = new Date(ageDiffMs);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    setAge(calculatedAge);
  };

  const validate = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Please enter fullname";
    }

    // if (!email.trim()) {
    //   newErrors.email = "Please enter an email address";
    // } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(email.trim())) {
    //   newErrors.email = "Please enter a valid email address";
    // }

    if (!dob.trim()) {
      newErrors.dob = "Date of birth is required";
    }

    if (!gender.trim()) {
      newErrors.gender = "Please select gender";
    }
    if (!bloodgroup.trim()) {
      newErrors.bloodgroup = "Please select blood group";
    }

    if (!mobile.trim()) {
      newErrors.mobile = "Please enter mobile number";
    } else if (!/^[0-9]{10}$/.test(mobile.trim())) {
      newErrors.mobile = "Invalid mobile number";
    }

    if (!approved.trim()) {
      newErrors.approved = "approve status required";
    }

    if (!state.trim()) {
      newErrors.state = "Please select state";
    }

    if (!district.trim()) {
      newErrors.district = "Please select district";
    }

    if (!age) {
      newErrors.age = "Please enter your age";
    } else if (!/^\d+$/.test(age)) {
      newErrors.age = "Please enter a valid age (numbers only)";
    } else if (parseInt(age) < 18 || parseInt(age) > 120) {
      newErrors.age = "Please enter a valid age above 18 ";
    }

    if (!city.trim()) {
      newErrors.city = "Please select city";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();

    if (isValid) {
      Axios.post(`${url}/adddonor`, {
        name: name,
        email: email,
        gender: gender,
        dob: dob,
        // state: state,
        // district: district,
        city: city,
        bloodgroup: bloodgroup,
        age: age,
        mobile: mobile,
        approved: approved,
      }).then((response) => {
        if (response.data.Message === "Successfully Inserted") {
          loginSuccess();
        } else if (response.data.Message === "Mobile number already taken") {
          loginErrorMob();
        }
      });
    }
  };

  const loginSuccess = async (event) => {
    Swal.fire("Successfully Added Blood Donor!", "", "success");
    navigate("/ListsDonors");
  };

  const loginErrorMob = async (event) => {
    Swal.fire("Mobile Number Already Taken !", "", "error");
    navigate("/add/blood-donor");
  };
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);

  useEffect(() => {
    Axios.get(`${url}/statelist`).then((response) => {
      const newList = {};

      response.data?.states?.forEach(
        (item) => (newList[item.id] = item.statename)
      );

      setStateList(newList);
    });
  }, []);

  useEffect(() => {
    const id = state;
    if (id) {
      Axios.get(`${url}/districtsbystate/${id}`).then((response) => {
        const distList = {};

        response.data?.result?.forEach(
          (item) => (distList[item.id] = item.districtname)
        );
        setDistrictList(distList);
      });
    }
  }, [state]);

  useEffect(() => {
    var id = district;
    if (id) {
      Axios.get(`${url}/citiesbydistrict/${id}`).then((response) => {
        const cityyList = {};

        response.data?.result?.forEach(
          (item) => (cityyList[item.id] = item.cityname)
        );
        setCityList(cityyList);
      });
    }
  }, [district]);

  const backbutton = () => {
    navigate("/ListsDonors");
  };

  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="second-container">
              <div className="back-button1">
                <span onClick={backbutton} style={{ cursor: "pointer" }}>
                  Donor's List
                </span>
                <span>{" / Add Blood Donor "}</span>
              </div>
              <div className="add-new-donor">
                <h2 className="heading-addnewdonor1">Add New Donor</h2>
              </div>
              <h2 className="heading-addnewdonor">Donor Information</h2>

              <div className="first-form">
                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="name">
                      Donor's Full Name<p className="redstar">*</p>
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="input-row-2"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                  </div>
                  <div className="form-state">
                    <label className="formLabeldonor" htmlFor="email">
                      Donor's Email<p className="redstar">*</p>
                    </label>
                    <input
                      id="email"
                      className="input-row-2"
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {/* {errors.email && (
                      <div className="error">{errors.email}</div>
                    )} */}
                  </div>
                  <div className="form-city">
                    <label className="formLabeldonor" htmlFor="mobile">
                      Donor's Mobile<p className="redstar">*</p>
                    </label>
                    <input
                      id="mobile"
                      className="input-row-2"
                      type="tel"
                      value={mobile}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setMobile(numericValue);
                      }}
                    />
                    {errors.mobile && (
                      <div className="error">{errors.mobile}</div>
                    )}
                  </div>
                </div>
                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="dob">
                      Date of birth<p className="redstar">*</p>
                    </label>
                    <input
                      id="dob"
                      type="date"
                      className="input-row-2"
                      value={dob}
                      onChange={(event) => {
                        setDob(event.target.value);
                        calculateAge(event.target.value);
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      min={new Date("1900-01-01").toISOString().split("T")[0]}
                    />
                    {errors.dob && <div className="error">{errors.dob}</div>}
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="gender">
                      Gender<p className="redstar">*</p>
                    </label>
                    <select
                      id="gender"
                      className="input-row-2"
                      value={gender}
                      onChange={(event) => setGender(event.target.value)}
                    >
                      {" "}
                      <option value="">Select Gender</option>{" "}
                      <option value="Male">Male</option>{" "}
                      <option value="Female">Female</option>{" "}
                      <option value="Others">Others</option>{" "}
                    </select>
                    {errors.gender && (
                      <div className="error">{errors.gender}</div>
                    )}
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="age">
                      Age<span className="redstar">*</span>
                    </label>
                    <input
                      id="age"
                      className="input-row-2"
                      type="text"
                      pattern="[0-9]{2}"
                      maxLength="3"
                      value={age}
                      onChange={(event) => setAge(event.target.value)}
                      disabled={dob !== ""}
                    />
                    {errors.age && <div className="error">{errors.age}</div>}
                  </div>
                </div>
                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="bloodgroup">
                      Blood Group<p className="redstar">*</p>
                    </label>
                    <select
                      id="bloodgroup"
                      className="input-row-2"
                      value={bloodgroup}
                      onChange={(event) => setBloodgroup(event.target.value)}
                    >
                      {" "}
                      <option value="">Select Blood Group</option>{" "}
                      <option value="A Positive">A Positive</option>{" "}
                      <option value="AB Positive">B Positive</option>{" "}
                      <option value="B Positive">AB Positive</option>{" "}
                      <option value="O Positive">O Positive</option>{" "}
                      <option value="A Negative">A Negative</option>{" "}
                      <option value="AB Negative">AB Negative</option>{" "}
                      <option value="B Negative">B Negative</option>{" "}
                      <option value="O Negative">O Negative</option>{" "}
                    </select>
                    {errors.bloodgroup && (
                      <div className="error">{errors.bloodgroup}</div>
                    )}
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="approved">
                      Approve<p className="redstar">*</p>
                    </label>
                    <select
                      id="approved"
                      className="input-row-2"
                      value={approved}
                      onChange={(event) => setApproved(event.target.value)}
                    >
                      {" "}
                      <option value="all">Choose an option</option>{" "}
                      <option value="Yes">Yes</option>{" "}
                      <option value="No">No</option>{" "}
                    </select>
                    {errors.approved && (
                      <div className="error">{errors.approved}</div>
                    )}
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="country">
                      Country
                    </label>
                    <input
                      id="country"
                      className="input-row-2"
                      type=""
                      value={country}
                      onChange={(event) => setCountry(event.target.value)}
                    />
                    {errors.country && (
                      <div className="error">{errors.country}</div>
                    )}
                  </div>
                </div>
                <h2 className="heading-addnewdonor">Complete Address</h2>
                <div className="section2">
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="state">
                      State<p className="redstar">*</p>
                    </label>
                    <select
                      id="state"
                      className="input-row-2"
                      value={state}
                      onChange={(event) => setState(event.target.value)}
                    >
                      {" "}
                      <option value="">Select State</option>{" "}
                      {Object.keys(stateList).map((id) => (
                        <option value={id} key={id}>
                          {stateList[id]}{" "}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <div className="error">{errors.state}</div>
                    )}
                  </div>
                  <div className="form-email">
                    <label className="formLabeldonor" htmlFor="district">
                      District<p className="redstar">*</p>
                    </label>
                    <select
                      id="district"
                      className="input-row-2"
                      value={district}
                      onChange={(event) => setDistrict(event.target.value)}
                    >
                      {" "}
                      <option value="">Select District</option>{" "}
                      {Object.keys(districtList).map((id) => (
                        <option value={id} key={id}>
                          {districtList[id]}{" "}
                        </option>
                      ))}
                    </select>
                    {errors.district && (
                      <div className="error">{errors.district}</div>
                    )}
                  </div>
                  <div className="form-dob">
                    <label className="formLabeldonor" htmlFor="city">
                      City<span className="redstar">*</span>
                    </label>
                    <select
                      id="city"
                      className="input-row-2"
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                    >
                      {" "}
                      <option value="">Select City</option>{" "}
                      {Object.keys(cityList).map((id) => (
                        <option value={id} key={id}>
                          {cityList[id]}{" "}
                        </option>
                      ))}
                    </select>
                    {errors.city && <div className="error">{errors.city}</div>}
                  </div>
                </div>
                <div className="section11">
                  <div className="cancle">
                    <button
                      type="cancel"
                      className="cancle1"
                      onClick={() => navigate("/ListsDonors")}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="submit">
                    <button type="submit" className="submit1" onSubmit>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default AddBloodDonors;
