import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import Header from "../../components/Header";
import MaterialTable from "material-table";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button } from "react-scroll";
import Swal from "sweetalert2";
import PopUpDialog from "../services_component/blood_bank/popupdialog";
import Page_Bb from "../services_component/blood_bank/Page_bb";
import Sidebars from "../../components/Sidebar";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <FontAwesomeIcon icon={faChevronDown} {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function RejResBug() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef1 = React.useRef();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = debounce((search) => fetchSearchResults(search), 500);
  const [recordsPerPage] = useState(50);
  const [bugid, setBugid] = useState("");
  const [dataCount, setDataCount] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");
  const [isImageSelected, setIsImageSelected] = useState(false);

  // const [open, setOpen] = useState(false);
  const url = process.env.REACT_APP_URL;
  const eurl = "https://suitsdata.blob.core.windows.net";
  const prod_name = "bugreport";

  const customIcons = {
    ...tableIcons,
    Export: () => <button className="export_button">Export</button>,
    // add more custom icons here as needed
  };

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
    setIsImageSelected(true);
  };

  const columns = [
    {
      title: "Bug ID",
      field: "service_abbreviation",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        const serabb = rowData.service_abbreviation;
        const ids = rowData.id;
        if (serabb && ids) {
          return (
            <div>
              {serabb}
              {ids}
            </div>
          );
        }

        return <em>No Info</em>;
      },
    },
    {
      title: "User Name",
      field: "user_signup.fullname",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Mobile No",
      field: "user_signup.mobile",
      emptyValue: () => <em>no info</em>,
    },

    {
      title: "Image",
      field: "image",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => (
        <img
          src={`${eurl}/${prod_name}/${rowData.image}`}
          style={{ width: 50, cursor: "pointer" }}
          onClick={() =>
            handleImageClick(`${eurl}/${prod_name}/${rowData.image}`)
          }
        />
      ),
    },

    {
      title: "Bug Raised Date",
      field: "createdAt",
      emptyValue: () => <em>no info</em>,
      render: (rowData) => {
        if (rowData.createdAt) {
          const formattedDate = moment(rowData.createdAt).format("DD-MM-YYYY");
          return formattedDate;
        } else {
          return <em>no info</em>;
        }
      },
    },
    {
      title: "Mobile Device Name",
      field: "deviceName",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "OS Version",
      field: "version",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Issue in Section",
      field: "issue",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Report Message",
      field: "report",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Status",
      field: "status",
      emptyValue: () => <em>no info</em>,
    },
    {
      title: "Comments",
      field: "comments",
      emptyValue: () => <em>no info</em>,
    },
  ];
  useEffect(() => {
    // Update the local storage role
    localStorage.setItem("role", "admin");
  }, []);
  useEffect(() => {
    if (currentPage && typeof currentPage === "number") {
      fetchData(currentPage);
    }
  }, [currentPage]);
  const fetchData = (page) => {
    var page = page;
    setLoading(true);
    Axios.get(`${url}/getBugHistoryList/${page} `)
      .then((response) => {
        // console.log(response.data.users);

        setData(response.data.users);
        setDataCount(response.data.totalcount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handlePagination = (page) => {
    setCurrentPage(page);
  };
  const fetchSearchResults = async (search) => {
    setLoading(true);
    Axios.post(`${url}/getbloodbanklistbysearch `, { search }).then(
      (response) => {
        setData(response.data.result);
        setDataCount(response?.data?.result?.length);
        setLoading(false);
      }
    );
  };
  const handleSearch = (search) => {
    setSearchText(search);
    debouncedSearch(search);
  };

  // setLoggedIn(true);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  let currentRecords =
    data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(dataCount / recordsPerPage);
  return (
    <>
      <Sidebars />
      <div className="sidebarleft">
        <Header />
        <div className="container2">
          <MaterialTable
            title="Rejected/Resolved Bugs"
            columns={columns}
            data={data}
            isLoading={loading}
            icons={customIcons}
            style={{ borderRadius: "4px" }}
            actions={[
              {
                icon: () => (
                  <span
                    style={{
                      color: "#007196",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Delete
                  </span>
                ),
                tooltip: "Delete",
                stickyHeader: true,
                onClick: (e, rowData) => {
                  var ids = rowData.id;

                  Swal.fire({
                    title: "Delete Bug?",
                    showCancelButton: true,
                    confirmButtonText: `Confirm`,
                    confirmButtonColor: "#0f3374",
                    cancelButtonColor: "#d32a2a", // timer: 10000,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000, // set the duration in milliseconds
                      });

                      Axios.delete(`${url}/deleteBugReport`, {
                        params: {
                          id: ids,
                        },
                      })
                        .then((response) => {
                          // console.log(response);
                          // console.log(response.Message, "approve");
                        })
                        .catch((error) => {
                          // console.error(error);
                        });
                      setTimeout(function () {
                        window.location.reload();
                      }, 2000);
                      tableRef.current.onQueryChange();
                    }
                  });
                },
              },
            ]}
            components={{
              Pagination: (props) => (
                <Page_Bb
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ),
            }}
            options={{
              actionsColumnIndex: columns.length,
              detailPanelColumnAlignment: "right",
              pageSize: 50,
              exportButton: false,
              sorting: true,
              // pageSizeOptions: [10, 20],
              search: false,
              searchOnEnter: true,
              debounceInterval: 500,
              searchAutoFocus: false,
              searchFieldVariant: "outlined",
              border: "0px",
              stickyHeader: true,
              paginationType: "stepped",
              // showFirstLastPageButtons: false,
              emptyRowsWhenPaging: false,
              headerStyle: {
                size: "40px",
                background: "rgba(0, 0, 0, 0.05)",
                position: "sticky",
                top: "0",
              },
              stickyHeader: true,
              maxBodyHeight: "76vh",
            }}
            onSearchChange={(search) => handleSearch(search)}
          />
        </div>
      </div>
      {isImageSelected && (
        <div className="selected-image-container">
          <img src={selectedImage} alt="Selected" />
          <button
            className="close-button"
            onClick={() => setIsImageSelected(false)}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
}
export default RejResBug;
