import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../../../../css/changePass.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AmbulanceSidebar from "../../../../components/sidebarAmbulance";
import AmbulanceHeader from "../../../../components/ambulanceHeader";

const AmbulanceAdminMyProfile = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  //   const [id, setId] = useState("");
  //   const ghcToken = localStorage.getItem("ghcToken");

  const id = localStorage.getItem("ambulanceId");

  const url = process.env.REACT_APP_URL;

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();

    const numericId = parseInt(id, 10);

    var dataUpdate = {
      id: numericId,
      name: name,
      email: email,
      mobile: mobile,
    };

    if (isValid) {
      Axios.put(`${url}/updateAmbAdminDetails`, { ...dataUpdate }).then(
        (response) => {
          if (response.data.Message === "email updated") {
            loginSuccess2();
          } else if (response.data.Message === "Successfully Updated Details") {
            loginSuccess1();
          } else if (response.data.Message === "Mobile number already taken") {
            loginErrorMob();
          } else if (response.data.Message === "Email is already taken") {
            EmailloginErrorMob();
          }
        }
      );
    }
  };

  const EmailloginErrorMob = async (event) => {
    Swal.fire("Email already taken, please use different email!", "", "error");
  };

  const loginSuccess1 = async (event) => {
    Swal.fire({
      title: "Updated Admin details Successfully!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000,
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  const loginSuccess2 = async (event) => {
    Swal.fire({
      title: "Email updated Successfully, Please login again!",
      icon: "success",
      showConfirmButton: false,
      timer: 15000,
    });

    setTimeout(function () {
      localStorage.removeItem("role");
      localStorage.removeItem("ambulanceToken");
      localStorage.removeItem("ambulanceActiveButton");
      window.location.reload();
    }, 2000);
  };

  const loginErrorMob = async (event) => {
    Swal.fire("Mobile Number Already Taken !", "", "error");
  };

  useEffect(() => {
    localStorage.setItem("ambulanceActiveButton", "/sans/myprofile");
    fetchDetails(id);
  }, [id]);

  const fetchDetails = async (id) => {
    try {
      await Axios.get(`${url}/getambadmindetailswithToken?id=${id}`).then(
        (response) => {
          // setId(response?.data?.result?.id);
          setName(response?.data?.result?.name);
          setEmail(response?.data?.result?.email);
          setMobile(response?.data?.result?.mobile);
        }
      );
    } catch (error) {}
  };

  const validate = () => {
    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
    }

    if (!mobile.trim()) {
      newErrors.mobile = "Mobile Number is required";
    } else if (!/^[0-9]{10}$/.test(mobile.trim())) {
      newErrors.mobile = "Mobile number should be 10 digits";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCLear = () => {
    navigate("/sans/dashboard");
  };

  return (
    <>
      <AmbulanceSidebar />
      <div className="sidebarleft">
        <AmbulanceHeader />
        <div className="password-container">
          <p className="pass-title">Update Profile</p>
          <form onSubmit={handleSubmit}>
            <div className="master-contianer-forms">
              <div className="master-div-container">
                <label className="password-label" htmlFor="name">
                  Name<p className="redstar">*</p>
                </label>
                <input
                  id="name"
                  type="tex"
                  className="password-input"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  style={{
                    borderColor: "rgba(0, 113, 150, 0.3)",
                    padding: "5px",
                  }}
                />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>
              <div className="master-div-container">
                <label className="password-label" htmlFor="email">
                  Email<p className="redstar">*</p>
                </label>
                <input
                  type="text"
                  id="email"
                  className="password-input"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  style={{
                    borderColor: "rgba(0, 113, 150, 0.3)",
                    padding: "5px",
                  }}
                />

                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="master-div-container">
                <label className="password-label" htmlFor="mobile">
                  Mobile Number<span className="redstar">*</span>
                </label>
                <input
                  type="text"
                  id="mobile"
                  className="password-input"
                  value={mobile}
                  onChange={(event) => {
                    // Allow only numeric values
                    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    setMobile(inputValue);
                  }}
                  style={{
                    borderColor: "rgba(0, 113, 150, 0.3)",
                    padding: "5px",
                  }}
                />
                {errors.mobile && <div className="error">{errors.mobile}</div>}
              </div>
            </div>
            <div className="button-div">
              <div className="clearMaster" style={{ padding: "1em" }}>
                <button
                  type="submit"
                  style={{ cursor: "pointer" }}
                  className="master-button"
                  onSubmit
                >
                  Update
                </button>
              </div>
              <div className="submitMaster">
                <button
                  type="clear"
                  className="pass-button"
                  onClick={handleCLear}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AmbulanceAdminMyProfile;
