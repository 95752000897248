import "./styles1.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import logo1 from "../images/logo1.png";
import { RiEditLine, RiCalendar2Line, RiUserFollowLine } from "react-icons/ri";
import "../css/sidebar.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useEffect } from "react";

function AmbulanceSidebar() {
  const [ambulanceActiveButton, setAmbulanceActiveButton] = useState(
    localStorage.getItem("ambulanceActiveButton") || "/sans/dashboard"
  );
  let navigate = useNavigate();
  const history = createBrowserHistory();
  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleButtonClick = (buttonId) => {
    setAmbulanceActiveButton(buttonId);
    localStorage.setItem("ambulanceActiveButton", buttonId);
    const path = `${buttonId.toLowerCase()}`;
    history.push(path);
    window.location.reload();
  };

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    return () => {
      handlePopState();
      localStorage.removeItem("ambulanceActiveButton");
    };
  }, []);

  const handlePopState = () => {
    localStorage.removeItem("ambulanceActiveButton");
    window.location.reload();
  };

  return (
    <>
      <div>
        <Sidebar
          className={`app ${toggled ? "toggled" : ""}`}
          style={{ height: "100%", position: "absolute" }}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        >
          <div>
            <Link to="/DashboardPharma">
              <img src={logo1} alt="logo" className="image1" />
            </Link>
          </div>
          <hr />
          <Menu>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                ambulanceActiveButton === "/sans/dashboard" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/sans/dashboard")}
            >
              <p className="ahcLabelMenu"> DashBoard</p>{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                ambulanceActiveButton === "/sans/list" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/sans/list")}
            >
              <p className="ahcLabelMenu">All Ambulance</p>{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                ambulanceActiveButton === "/sans/block-list" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/sans/block-list")}
            >
              <p className="ahcLabelMenu"> Blocked Ambulance</p>{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                ambulanceActiveButton === "/sans/change-password"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleButtonClick("/sans/change-password")}
            >
              <p className="ahcLabelMenu"> Change Password</p>{" "}
            </MenuItem>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                ambulanceActiveButton === "/sans/myprofile" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/sans/myprofile")}
            >
              <p className="ahcLabelMenu"> My Profile</p>{" "}
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
}
export default AmbulanceSidebar;
