import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../../css/login2.css";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import PersonIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import logo from "../../images/logo1.png"; // Import your logo image

import { useNavigate } from "react-router-dom";
import NotificationContext from "../../pages/context/NotificationContext";
const Header2 = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const url = process.env.REACT_APP_URL;
  const { count, setCount } = useContext(NotificationContext);
  const doctorToken = localStorage.getItem("doctor_token");
  let navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      doctorToken === null ||
      doctorToken === "" ||
      doctorToken === "undefined"
    ) {
      navigate("/");
    }
    fetchData();
  }, [doctorToken]);

  const fetchData = () => {
    // var page = page;
    Axios.get(`${url}/appointmentNotifications`, {
      headers: {
        Authorization: `Bearer ${doctorToken}`,
      },
    }).then((response) => {
      if (response.data) {
        if (response.data && response.data.notificationCount)
          setCount(response.data.notificationCount);
      }
    });
  };

  const handlelogOut = () => {
    localStorage.removeItem("doctor_token");
    localStorage.removeItem("role");
    localStorage.removeItem("activeButton");
    localStorage.removeItem("selectedDay");
    window.location.reload();
  };

  return (
    <header className="header_inner1">
      <div className="header_logo1">
        <img src={logo} alt="Logo" />
      </div>
      <div className="header_right1">
        <Link to="/doctor/notification" className="Link12">
          <NotificationsOutlinedIcon
            className="bell-icon1"
            fontSize="large"
            style={{ color: "black" }}
          />
          {count > 0 && <div className="indicator2">{count}</div>}
        </Link>
        <div className="iconPerson1">
          <p className="doctor-name1">Doctor</p>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon
              style={{
                fontSize: 40,
                borderRadius: "50%",
                backgroundColor: "#ddd",
              }}
            />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link
              to="/reset_password"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Change Password</MenuItem>
            </Link>
            <Link
              onClick={handlelogOut}
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>Logout</MenuItem>
            </Link>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header2;
