import "./styles1.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import logo1 from "../images/logo1.png";

import {
  RiEditLine,
  RiTeamLine,
  RiCalendar2Line,
  RiFolder2Line,
  RiUserFollowLine,
  RiUserUnfollowLine,
} from "react-icons/ri";
import "../css/sidebar.css";
import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  //useProSidebar
} from "react-pro-sidebar";

function Sidebars() {
  // SET ACTIVE
  const [superAdminactiveButton, setSuperAdminActiveButton] = useState(
    localStorage.getItem("superAdminactiveButton") || "/users-list"
  );

  const handleButtonClick = (buttonId) => {
    setSuperAdminActiveButton(buttonId);
    localStorage.setItem("superAdminactiveButton", buttonId);
    const path = `${buttonId.toLowerCase()}`;
    history.push(path);
    window.location.reload();
  };

  let navigate = useNavigate();
  const history = createBrowserHistory();
  //const { collapseSidebar } = useProSidebar();

  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleSubmenuToggle = () => {
    setSubmenuOpen(!submenuOpen);
  };

  useEffect(() => {
    return () => {
      handlePopState();
      localStorage.removeItem("superAdminactiveButton");
    };
  }, []);

  const handlePopState = () => {
    localStorage.removeItem("superAdminactiveButton");
    window.location.reload();
  };

  return (
    <>
      <div>
        <Sidebar
          className={`app ${toggled ? "toggled" : ""}`}
          style={{ height: "100%", position: "absolute" }}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        >
          <div
          // style={{
          //   padding: "9px",
          //   // textTransform: "uppercase",
          //   fontWeight: "bold",
          //   fontSize: 14,
          //   letterSpacing: "1px",

          // }}
          >
            <Link to="/users-list">
              <img src={logo1} alt="logo" className="image1" />
            </Link>
          </div>
          <hr />

          <Menu>
            <MenuItem icon={<RiUserFollowLine />}>DashBoard </MenuItem>
            <hr />
            <p className="sidebar_title">Users</p>
            <hr></hr>
            <MenuItem
              icon={<RiUserFollowLine />}
              className={`ahcMenuItem ${
                superAdminactiveButton === "/users-list" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/users-list")}
            >
              <p className="ahcLabelMenu"> Users </p>
            </MenuItem>
            <hr />
            <SubMenu
              label={"Blood Requestees"}
              className="ahcLabelMenu"
              icon={<RiTeamLine />}
              open={submenuOpen}
              onClick={handleSubmenuToggle}
            >
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ListRequesties" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/ListRequesties")}
              >
                <p className="ahcLabelMenu"> All Blood Requests </p>
              </MenuItem>
              <MenuItem
                icon={<RiEditLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ApproveBloodReq" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/ApproveBloodReq")}
              >
                <p className="ahcLabelMenu">Approved Requests</p>
              </MenuItem>
              <MenuItem
                icon={<RiEditLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/RejectedBloodReq" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/RejectedBloodReq")}
              >
                <p className="ahcLabelMenu">Rejected Requests</p>
              </MenuItem>
            </SubMenu>
            <MenuItem
              icon={<RiFolder2Line />}
              className={`ahcMenuItem ${
                superAdminactiveButton === "/listsdonors" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/listsdonors")}
            >
              <p className="ahcLabelMenu">Blood Donors </p>
            </MenuItem>
            <MenuItem
              icon={<RiFolder2Line />}
              className={`ahcMenuItem ${
                superAdminactiveButton === "/post-donation" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/post-donation")}
            >
              <p className="ahcLabelMenu"> Post Donation </p>
            </MenuItem>
            <MenuItem
              icon={<RiFolder2Line />}
              className={`ahcMenuItem ${
                superAdminactiveButton === "/intrestedblooddonor"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleButtonClick("/intrestedblooddonor")}
            >
              <p className="ahcLabelMenu">Intrested Blood Donor </p>
            </MenuItem>
            <MenuItem
              icon={<RiFolder2Line />}
              className={`ahcMenuItem ${
                superAdminactiveButton === "/bc-donation" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/bc-donation")}
            >
              <p className="ahcLabelMenu"> Blood Camp Donation </p>
            </MenuItem>
            <MenuItem
              icon={<RiFolder2Line />}
              className={`ahcMenuItem ${
                superAdminactiveButton === "/bb-donation" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/bb-donation")}
            >
              <p className="ahcLabelMenu">Blood Bank Donation </p>
            </MenuItem>
            <p className="sidebar_title">Services</p>
            <hr />
            <SubMenu label={"Blood Bank"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/AllBloodbank" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/AllBloodbank")}
              >
                <p className="ahcLabelMenu"> All Blood Bank</p>
              </MenuItem>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/SearchBloodGroup" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/SearchBloodGroup")}
              >
                <p className="ahcLabelMenu"> Search Blood Group</p>
              </MenuItem>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/UpdateBloodGroup" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/UpdateBloodGroup")}
              >
                <p className="ahcLabelMenu"> Update Blood Group</p>
              </MenuItem>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/BBBloodCampManagement-BB"
                    ? "active"
                    : ""
                }`}
                onClick={() => handleButtonClick("/BBBloodCampManagement-BB")}
              >
                <p className="ahcLabelMenu">Blood Camps Management</p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/BBBlockList-BBt" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/BBBlockList-BB")}
              >
                <p className="ahcLabelMenu"> Block List </p>
              </MenuItem>
            </SubMenu>

            <SubMenu label={"Network Hospital"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/AllNetworkHospital"
                    ? "active"
                    : ""
                }`}
                onClick={() => handleButtonClick("/AllNetworkHospital")}
              >
                <p className="ahcLabelMenu"> All Network Hospitals </p>
              </MenuItem>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/NetBlockList" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/NetBlockList")}
              >
                <p className="ahcLabelMenu"> Block List </p>
              </MenuItem>
            </SubMenu>
            <SubMenu label={"Ambulance"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/AllAmbulance" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/AllAmbulance")}
              >
                <p className="ahcLabelMenu"> All Ambulance </p>
              </MenuItem>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/AmbBlockList" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/AmbBlockList")}
              >
                <p className="ahcLabelMenu">Block List </p>
              </MenuItem>
            </SubMenu>
            <SubMenu label={"S Care Team"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/AllSCareTeam" ? "active" : ""
                }`}
                onClick={() => navigate("/AllSCareTeam")}
              >
                <p className="ahcLabelMenu">All S Care Team </p>
              </MenuItem>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/SCareBlockList" ? "active" : ""
                }`}
                onClick={() => navigate("/SCareBlockList")}
              >
                <p className="ahcLabelMenu"> Block </p>
              </MenuItem>
            </SubMenu>

            {/* AHC Section */}
            {/* <p className="sidebar_title"></p> */}
            <hr></hr>
            <SubMenu label={"AHC"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ahc-memberships-plans"
                    ? "active"
                    : ""
                }`}
                onClick={() => handleButtonClick("/ahc-memberships-plans")}
              >
                <p className="ahcLabelMenu">Membership Plans </p>
              </MenuItem>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ahc-membership-history"
                    ? "active"
                    : ""
                }`}
                onClick={() => handleButtonClick("/ahc-membership-history")}
              >
                <p className="ahcLabelMenu">Membership History </p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ahc-memberships" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/ahc-memberships")}
              >
                <p className="ahcLabelMenu">Membership Details </p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ahc-consultations"
                    ? "active"
                    : ""
                }`}
                onClick={() => handleButtonClick("/ahc-consultations")}
              >
                <p className="ahcLabelMenu"> Consultations </p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                // onClick={() => navigate("/doctorsList")}
              >
                Reports
              </MenuItem>
            </SubMenu>

            <hr></hr>
            <SubMenu label={"GHC"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ghc-plans" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/ghc-plans")}
              >
                <p className="ahcLabelMenu">Membership Plans</p>
              </MenuItem>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ghc-history" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/ghc-history")}
              >
                <p className="ahcLabelMenu">Membership History </p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ghc-member-details"
                    ? "active"
                    : ""
                }`}
                onClick={() => handleButtonClick("/ghc-member-details")}
              >
                <p className="ahcLabelMenu">Membership Details </p>
              </MenuItem>
            </SubMenu>

            {/* Profile Section */}
            <p className="sidebar_title">Profile</p>
            <hr></hr>
            <SubMenu label={"Settings"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/ChangePassword" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/ChangePassword")}
              >
                <p className="ahcLabelMenu">Change Password </p>
              </MenuItem>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/app/configuration"
                    ? "active"
                    : ""
                }`}
                onClick={() => handleButtonClick("/app/configuration")}
              >
                <p className="ahcLabelMenu"> App Configuration </p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/doctorsList" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/doctorsList")}
              >
                <p className="ahcLabelMenu"> Doctors Info </p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/telecaller-lists" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/telecaller-lists")}
              >
                <p className="ahcLabelMenu">Telecaller </p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/NotificationAll" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/NotificationAll")}
              >
                <p className="ahcLabelMenu">Notification</p>
              </MenuItem>
              <MenuItem
                icon={<RiCalendar2Line />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/masterData" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/masterData")}
              >
                <p className="ahcLabelMenu"> Master Data</p>
              </MenuItem>
            </SubMenu>

            <SubMenu label={"Bug Report"} icon={<RiTeamLine />}>
              <MenuItem
                icon={<RiUserFollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/BugReq" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/BugReq")}
              >
                <p className="ahcLabelMenu"> Reports </p>
              </MenuItem>
              <MenuItem
                icon={<RiUserUnfollowLine />}
                className={`ahcMenuItem ${
                  superAdminactiveButton === "/RejResBug" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("/RejResBug")}
              >
                <p className="ahcLabelMenu">Reject/Resolved Bugs </p>
              </MenuItem>
            </SubMenu>

            <MenuItem
              icon={<RiCalendar2Line />}
              className={`ahcMenuItem ${
                superAdminactiveButton === "/IceCard" ? "active" : ""
              }`}
              onClick={() => handleButtonClick("/IceCard")}
            >
              <p className="ahcLabelMenu">ICE Card Requests </p>
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
}
export default Sidebars;
